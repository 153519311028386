import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Axios from "axios";

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cidades: [],
            estados: [],
            responsavel: [],
        };
    }

    componentDidMount = async () => {};

    searchCidade = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/cidades/json-options/" + inputQuery
            );
            return response.data;
        }
    };

    searchEstado = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/estado/json-options/"+ inputQuery
            );
            return response.data;
        }
    };

    searchUser = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                `/mapeamento/users/json-options/${inputQuery}?role=Mapeamento,Analista` 
            );
            return response.data;
        }
    };
    handleResponsavelChanged = async (e) => {
        await this.setState({
            ...this.state,
            responsavel: e,
        });

        this.props.handleResponsavelChanged(e);
    };
    clearState = async () => {
        this.setState({
            ...this.state,
            responsavel: [],
        });

        this.props.clearState();
    };

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-3">
                                <label>Status</label>
                                <Select
                                    value={this.props.status}
                                    placeholder={this.props.status}
                                    isMulti={false}
                                    onChange={this.props.handleStatusChanged}
                                    options={[
                                        {
                                            value: "Selecione",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "Pendente",
                                            label: "Pendente",
                                        },
                                        {
                                            value: "Em Andamento",
                                            label: "Em Andamento",
                                        },
                                        {
                                            value: "Concluído",
                                            label: "Concluído",
                                        },
                                        {
                                            value: "Inacabado",
                                            label: "Inacabado",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-3">
                                <label>Responsável</label>
                                <AsyncSelect
                                    cacheOptions
                                    isMulti
                                    value={this.state.responsavel}
                                    loadOptions={this.searchUser}
                                    onChange={this.handleResponsavelChanged}
                                />
                            </div>
                            <div className="form-group col-3">
                                <label>Estado</label>
                                <AsyncSelect
                                    cacheOptions
                                    isMulti
                                    value={this.props.estados}
                                    loadOptions={this.searchEstado}
                                    onChange={this.props.handleEstadoChanged}
                                />
                            </div>
                            <div className="form-group col-3">
                                <label>Cidade</label>
                                <AsyncSelect
                                    cacheOptions
                                    isMulti
                                    value={this.props.cidade}
                                    loadOptions={this.searchCidade}
                                    onChange={this.props.handleCidadeChanged}
                                />
                            </div>
                            <div className="form-group col-3">
                                <label>Período</label>
                                <input
                                    type="month"
                                    name="period"
                                    value={this.props.period}
                                    onChange={this.props.handlePeriodChanged}
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-3">
                                <label>Data Inicial Prazo</label>
                                <input
                                    type="date"
                                    name="date_limit_start"
                                    value={this.props.date_limit_initial}
                                    onChange={
                                        this.props.handleDateLimitInitialChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-3">
                                <label>Data Final Prazo</label>
                                <input
                                    type="date"
                                    name="date_limit_end"
                                    value={this.props.date_limit_final}
                                    onChange={
                                        this.props.handleDateLimitFinalChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-3">
                                <label>Data Inicial Abertura</label>
                                <input
                                    type="date"
                                    name="date_limit_start"
                                    value={this.props.created_at_initial}
                                    onChange={
                                        this.props.handleCreatedAtInitialChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-3">
                                <label>Data Final Abertura</label>
                                <input
                                    type="date"
                                    name="date_limit_end"
                                    value={this.props.created_at_final}
                                    onChange={
                                        this.props.handleCreatedAtFinalChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-success btn-block btn-round background-brain border-brain"
                                    onClick={this.props.getMapeamentos}
                                >
                                    <i className="fas fa-search"></i> Buscar
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-block btn-round"
                                    style={{ backgroundColor: "#FFBB27" }}
                                    onClick={this.clearState}
                                >
                                    <i className="far fa-file"></i> Limpar
                                </button>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;