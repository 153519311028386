import React, { Component } from "react";
import ReactDOM from "react-dom";
import getBrowserGeolocation from "../../util/geolocation";
import EmpreendimentoServiceCrawler from "../../services/empreendimentosServicesCrawler";
import colors from "../../util/colors";
import lodash from "lodash";
import FormFilters from "./FormFilters";
import FilterByType from "./FilterByType";
import geolocationService from "../../services/geolocationService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";
import MouseTooltip from "react-sticky-mouse-tooltip";
import StateService from "../../services/KmlService";
import { Modal, Button } from "react-bootstrap";
import ToolsBar from "../ToolsBar";
import ModalPolosGeradores from "../ModalPolosGeradores";
import SuppliersMap from "./SuppliersMap";
import TableViewer from "./TableViewer";
import EmpreendimentosServices from "../../services/empreendimentosServices";

class DefaultMap extends React.Component {
    initialState = {
        empreendimentos: [],
        currentPosition: { lat: -25.551136, lng: -49.1929618 },
        isSlideOpen: false,
        currentShowing: undefined,
        tipo_empreendimento: [],
        tipo_negocio: [],
        polygons: [],
        circles: [],
        markers: [],
        overlays: [],
        area_maior_que: "",
        area_menor_que: "",
        panel_empreendimento_visible: true,
        modal_empreendimento_visible: true,
        manualCircles: [],
        ticket_maior_que: "",
        ticket_menor_que: "",
        valor_metro_maior_que: "",
        valor_metro_menor_que: "",
        raio: 3000,
        cidade: [],
        estado: [],
        bairros: [],
        pontos: [],
        ano_lancamento: [],
        loading_empreendimento: false,
        fillColor: "green",
        selectedIcon: undefined,
        showInfoWindow: false,
        showTooltip: false,
        radiusChanging: "",
        countRectangles: 0,
        countPolygons: 0,
        zoom: 15,
        nome_empreendimento: [],
        address: "",
        bairrosOptions: [],
        quartos: [],
        vagas: [],
        juros_maximo: "",
        juros_minimo: "",
        valor_parcela_minima: "",
        valor_parcela_maxima: "",
        parcelas_minimo: "",
        parcelas_maximo: "",
        ranges: [],
        kml_bairros: [],
        kml_layers: [],
        kmls_to_show: [],
        showKMl: false,
        showInfo: false,
        showProjectName: false,
        projeto: "",
        projeto_name: [],
        show_polos_geradores: false, //mostrar polos geradores
        polos_geradores: [],
        show_modal_polos_geradores: false,
        polos_geradores_types_selected: [],
        showMarkers: true, //mostrar marcadores de empreendimentos
        polos_geradores_current_showing: [],
        polos_geradores_current_grouped: [],
        polos_geradores_current_cache_key: "", //chave de cache para os polos geradores, usado para exportar os polos geradores em Excel no Backend
        estadoSelecionado: null,
        busca_por_estado: false,
        tipoAnalise: "",
        showSupplyTable: false,
        empStatus: [],
        pattern: [],
        material: [],
        incorporators: [],
        estadosOptions : [
            { value : 'AC' , label : 'Acre' },
            { value : 'AL' , label : 'Alagoas' },
            { value : 'AP' , label : 'Amapá' },
            { value : 'AM' , label : 'Amazonas' },
            { value : 'BA' , label : 'Bahia' },
            { value : 'CE' , label : 'Ceará' },
            { value : 'DF' , label : 'Distrito Federal' },
            { value : 'ES' , label : 'Espírito Santo' },
            { value : 'GO' , label : 'Goías' },
            { value : 'MA' , label : 'Maranhão' },
            { value : 'MT' , label : 'Mato Grosso' },
            { value : 'MS' , label : 'Mato Grosso do Sul' },
            { value : 'MG' , label : 'Minas Gerais' },
            { value : 'PA' , label : 'Pará' },
            { value : 'PB' , label : 'Paraíba' },
            { value : 'PR' , label : 'Paraná' },
            { value : 'PE' , label : 'Pernambuco' },
            { value : 'PI' , label : 'Piauí' },
            { value : 'RJ' , label : 'Rio de Janeiro' },
            { value : 'RN' , label : 'Rio Grande do Norte' },
            { value : 'RS' , label : 'Rio Grande do Sul' },
            { value : 'RO' , label : 'Rondônia' },
            { value : 'RR' , label : 'Roraíma' },
            { value : 'SC' , label : 'Santa Catarina' },
            { value : 'SP' , label : 'São Paulo' },
            { value : 'SE' , label : 'Sergipe' },
            { value : 'TO' , label : 'Tocantins' }
        ],

    };

    constructor() {
        super();
        this.state = this.initialState;
    }

    //carrega kmls de bairros
    loadKml = async () => {
        try {
            let cidadesComKmlBairros = this.state.kml_bairros;

            if (cidadesComKmlBairros.length === 0) {
                return;
            }

            let kmls_to_show = [];
            for (let i = 0; i < cidadesComKmlBairros.length; i++) {
                let kmlFile = this.state.kml_bairros[i];
                kmls_to_show.push(kmlFile.kml);
            }

            await this.setState({
                ...this.state,
                kmls_to_show: [...kmls_to_show],
            });
        } catch (e) {}
    };

    //oculta ou mostra os kmls carregados
    kmlFunction = async () => {
        try {
            if (this.state.showKMl == true) {
                return await this.setState({
                    ...this.state,
                    showKMl: false,
                });
            } else {
                return await this.setState({
                    ...this.state,
                    showKMl: true,
                });
            }
        } catch (e) {}
    };

    saveState = async () => {
        let state = {
            ...this.state,
            polygons: [],
            circles: [],
            overlays: [],
        };

        let polygonsPaths = [];

        this.state.polygons.map((polygon) => {
            let polygonPath = this.getPolygonPath(polygon);
            state.polygons.push(polygonPath);
        });

        state.circles = await this.getCirclesPlainObjects();

        let response = StateService.save(state);
    };

    saveWork = async () => {
        let polygonsPaths = [];

        this.state.polygons.map((polygon) => {
            let polygonPath = this.getPolygonPath(polygon);
            polygonsPaths.push(polygonPath);
        });

        let state = this.state;

        await axios
            .post("/mapa-revenda-locacao/projeto", state)
            .then((response) => {
                this.handleCloseProjectName();
                alert(response.data.message);
            })
            .catch((error) => {});

        this.getProjects();
    };

    getProjects = async () => {
        try {
            await this.setState({
                ...this.state,
                projeto_name: [],
            });

            let projetos = await axios.get(
                route("getMapaRevendaLocacaoProjetos")
            );

            this.state.projeto_name.push({ value: null, label: "Selecione" });

            for (
                let index = 0;
                index < projetos.data.projects.length;
                index++
            ) {
                this.state.projeto_name.push({
                    value: projetos.data.projects[index],
                    label: projetos.data.projects[index],
                });
            }
        } catch (e) {}
    };

    deleteWork = async () => {
        let projeto = [{ label: "projeto", value: this.state.projeto }];

        axios
            .post(route("deleteMapaRevendaLocacaoProjetoByName"), projeto)
            .then((response) => {
                alert(response.data.message);
            })
            .catch((error) => {});

        await this.cleanState();
    };

    addPolygon = async (coordinate) => {
        let convertedCoordinates = [];

        coordinate.forEach(async (coord) => {
            convertedCoordinates.push({
                lat: coord[0],
                lng: coord[0],
            });
        });

        const polygon = new google.maps.Polygon({
            paths: convertedCoordinates,
            strokeColor: "#FF0000",
            strokeOpacity: 0.3,
            strokeWeight: 2,
            fillColor: this.getColor(),
            fillOpacity: 0.3,
        });

        polygon.type = "Polígono";
        polygon.color = polygon.fillColor;
        polygon.id = this.generateRandomString();
        polygon.name = this.state.countPolygons + 1;

        polygon.setMap(
            window.googleMap.context
                .__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
        );
    };

    addCircle = async (circleProperties) => {
        const radius = circleProperties.radius;

        let newCircle = {};
        newCircle.center = circleProperties.center;
        newCircle.radius = radius;

        newCircle.id = circleProperties.id;
        newCircle.name = circleProperties.name;
        newCircle.fillColor = circleProperties.fillColor;
        newCircle.fillOpacity = 0.3;
        newCircle.type = circleProperties.type;
        newCircle.color = circleProperties.color;

        await this.setState({
            ...this.state,
            drawingMode: null,
            manualCircles: [...this.state.manualCircles, newCircle],
            overlays: [...this.state.overlays, newCircle],
        });
    };

    getCirclesPlainObjects = async () => {
        return new Promise((resolve, reject) => {
            let circles = [];

            for (
                let index = 0;
                index < this.state.manualCircles.length;
                index++
            ) {
                const circle = this.state.manualCircles[index];

                if (circle.type === "Círculo Manual") {
                    let newCircle = {
                        latitude: circle.center.lat(),
                        longitude: circle.center.lng(),
                        radius: circle.radius,
                    };

                    circles.push(newCircle);
                } else {
                    let newCircle = {
                        latitude:
                            circle.center.lat ||
                            circle.getCenter().lat() ||
                            circle.center.lat(),
                        longitude:
                            circle.center.lng ||
                            circle.getCenter().lng() ||
                            circle.center.lng(),
                        radius: circle.radius,
                    };

                    circles.push(newCircle);
                }
            }

            resolve(circles);
        });
    };

    /**
     * Esconde o Painel de Informações do Empreendimento
     */
    handleHideClicked = (event) => {
        event.preventDefault();

        this.setState({
            ...this.state,
            panel_empreendimento_visible: false,
            modal_empreendimento_visible: false,
        });
    };

    togglePainelEmpreendimento = () => {
        this.setState({
            ...this.state,
            panel_empreendimento_visible:
                !this.state.panel_empreendimento_visible,
            modal_empreendimento_visible:
                !this.state.modal_empreendimento_visible,
        });
        document.getElementById("btn-results").click();
    };

    componentDidMount = async () => {
        window.circles = [];
        window.markers = [];
        window.refs = [];
        let currentPosition = await getBrowserGeolocation();

        this.timer = null;
        this.setState({
            ...this.state,
            currentPosition: currentPosition,
        });
    };

    onPolygonComplete = async (polygon) => {
        try {
            let path = this.getPolygonPath(polygon);

            //primeiro e último vértices devem ser iguais
            path.push(path[0]);

            polygon.type = "Polígono";
            polygon.color = polygon.fillColor;
            polygon.id = this.generateRandomString();
            polygon.name = this.state.countPolygons + 1;

            await this.setState({
                ...this.state,
                //empreendimentos: empreendimentos,
                drawingMode: null,
                polygons: [polygon, ...this.state.polygons],
                overlays: [polygon, ...this.state.overlays],
                countPolygons: this.state.countPolygons + 1,
            });

            await this.reloadEmpreendimentos();
        } catch (error) {}
    };

    cleanState = async () => {
        this.state.overlays.forEach((overlay) => {
            try {
                if (overlay.setMap !== undefined) {
                    overlay.setMap(null);
                }
            } catch (error) {}
        });

        this.state.kml_layers.forEach((kml_layer) => {
            try {
                if (kml_layer.setMap !== undefined) {
                    kml_layer.setMap(null);
                }
            } catch (error) {}
        });

        document.querySelector("#search_box").value = "";

       await this.setState({
            ...this.initialState,
            quartos: [],
            vagas: [],
            projeto_name: [],
        });

    };

    //Pega caminho do poligno
    getPolygonPath = (polygon) => {
        if (polygon.getBounds !== undefined) {
            let bounds = polygon.getBounds();
            let northEast = bounds.getNorthEast();
            let southWest = bounds.getSouthWest();

            let northWest = [northEast.lat(), southWest.lng()];
            let southEast = [southWest.lat(), northEast.lng()];

            let path = [
                [northEast.lat(), northEast.lng()],
                [northWest[0], northWest[1]],
                [southWest.lat(), southWest.lng()],
                [southEast[0], southEast[1]],
                [northEast.lat(), northEast.lng()],
            ];

            return path;
        }

        let path = [];

        for (var i = 0; i < polygon.getPath().getLength(); i++) {
            let coordinate = polygon
                .getPath()
                .getAt(i)
                .toUrlValue(10)
                .split(",");

            path.push([Number(coordinate[0]), Number(coordinate[1])]);
        }

        return path;
    };

    onMarkerClick = async (id) => {
        if ($("#card-results").hasClass("collapsed")) {
            $("#card-results").removeClass("collapsed");
            $("#btn-results").removeClass("btn-results-off");
            $(".side-panel").removeClass("side-panel-off");

            $(".sliding-panel-container").addClass("active");
            $("#results-icon").removeClass("fa-angle-double-left");
            $("#results-icon").addClass("fa-angle-double-right");
        }
        await this.setState({
            loading_empreendimento: true,
            currentShowing: undefined,
        });

        let empreendimento =
            await EmpreendimentoServiceCrawler.getEmpreendimento(id.$oid);

        let selectedIcon = {
            position: {
                lat: empreendimento.location.coordinates[1],
                lng: empreendimento.location.coordinates[0],
            },
            icon: "http://maps.google.com/mapfiles/kml/paddle/grn-stars.png",
        };

        await this.setState({
            currentShowing: empreendimento,
            panel_empreendimento_visible: true,
            modal_empreendimento_visible: true,
            selectedIcon: selectedIcon,
        });
        document.getElementById("btn-results").click();
        this.openPane();
    };

    generateRandomString = () => {
        return (
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15)
        );
    };

    onCircleComplete = async (circle) => {
        const center = circle.getCenter();
        const radius = circle.getRadius();
        circle.id = this.generateRandomString();
        circle.type = "Círculo";

        let ponto = {
            lat: circle.getCenter().lat(),
            lng: circle.getCenter().lng(),
        };

        let newCircle = {};
        newCircle.center = ponto;
        newCircle.radius = radius;

        newCircle.id = this.generateRandomString();

        circle.setMap(null);
        newCircle.name = this.state.manualCircles.length + 1;

        newCircle.fillColor = circle.fillColor;
        newCircle.fillOpacity = 0.3;
        newCircle.type = "Círculo";
        newCircle.color = circle.fillColor;

        await this.setState({
            ...this.state,
            //empreendimentos: empreendimentosWithoutDuplicates,
            drawingMode: null,
            //circles: [...this.state.circles, replaceCircle],
            manualCircles: [newCircle, ...this.state.manualCircles],
            overlays: [newCircle, ...this.state.overlays],
            //pontos: [...this.state.pontos, ponto]
        });

        await this.reloadEmpreendimentos();
    };

    onRectangleComplete = async (rectangle) => {
        let bounds = rectangle.getBounds();
        let northEast = bounds.getNorthEast();
        let southWest = bounds.getSouthWest();

        let northWest = [northEast.lat(), southWest.lng()];
        let southEast = [southWest.lat(), northEast.lng()];

        let path = [
            [northEast.lat(), northEast.lng()],
            [northWest[0], northWest[1]],
            [southWest.lat(), southWest.lng()],
            [southEast[0], southEast[1]],
            [northEast.lat(), northEast.lng()],
        ];

        rectangle.color = rectangle.fillColor;
        rectangle.id = "retangulo-" + this.generateRandomString();
        rectangle.name = this.state.countRectangles + 1;

        rectangle.type = "Retângulo";
        rectangle.path = path;

        await this.setState({
            ...this.state,
            //empreendimentos: empreendimentos,
            drawingMode: null,
            polygons: [rectangle, ...this.state.polygons],
            overlays: [rectangle, ...this.state.overlays],
            countRectangles: this.state.countRectangles + 1,
        });

        await this.reloadEmpreendimentos();
    };

    openPane = () => {
        this.setState({
            isSlideOpen: true,
        });
    };

    handleIncorporadoraChanged = async ( e )=>
    {
        if( e === null ){
            await this.setState({
                ...this.state,
                incorporators: []
            });

            return;
        }

        let incorporators = [];
        e.forEach( ( option )=> {
            if( !incorporators.includes( option )){
                incorporators = [...incorporators, option ];
            }

        });

        await this.setState({
            incorporators: [...incorporators],
            movimentar_mapa : true
        });

    }

    handleEmpStatusChanged = async ( empStatus ) => {
        await this.setState({
            ...this.state,
            empStatus: empStatus
        })
    }
    handlePatternChanged = async ( pattern ) => {
        await this.setState({
            ...this.state,
            pattern: pattern
        })
    }
    handleMaterialChanged = async ( m ) => {

        if( m === null ){
            await this.setState({
                ...this.state,
                material: []
            });

            return;
        }

        let material = [];
        m.forEach( ( option )=> {
            if( !material.includes( option )){
                material = [...material, option ];
            }

        });

        await this.setState({
            ...this.state,
            material: [...material],
        })
    }



    openSupplyFilter = async () => {

        await this.setState({
            ...this.state,
            showSupplyTable: true
        })
    }
    closeSupplyFilter = async () => {
        await this.setState({
            ...this.state,
            showSupplyTable: false
        })
    }

    handleAnoLancamentoChanged = async (e) => {
        if (e === null) {
            await this.setState({
                ...this.state,
                ano_lancamento: [],
            });

            return;
        }

        let ano_lancamento = [];

        e.forEach((ano) => {
            if (!ano_lancamento.includes(ano)) {
                ano_lancamento.push(ano);
            }
        });

        this.setState({
            ...this.state,
            ano_lancamento: ano_lancamento,
        });
    };

    closePane = () => {
        this.setState({
            isSlideOpen: false,
        });
    };

    handleTipoEmpreendimentoChanged = async (e) => {
        if (e === null) {
            await this.setState({
                ...this.state,
                tipo_empreendimento: [],
            });

            return;
        }

        let tipo_empreendimento = [];
        e.forEach((tipoSelected) => {
            if (tipoSelected.value == "Todos") {
                tipo_empreendimento = [
                    {
                        value: "Apartamento",
                        label: "Apartamento",
                    },
                    {
                        value: "Apartamento Duplex",
                        label: "Apartamento Duplex",
                    },
                    {
                        value: "Apartamento Garden",
                        label: "Apartamento Garden",
                    },
                    {
                        value: "Flat",
                        label: "Flat",
                    },
                    {
                        value: "Cobertura",
                        label: "Cobertura",
                    },
                    {
                        value: "Kitinet",
                        label: "Kitinet",
                    },
                    {
                        value: "Loft",
                        label: "Loft",
                    },
                    {
                        value: "Studio",
                        label: "Studio",
                    },
                    {
                        value: "Casa de Condomínio",
                        label: "Casa de Condomínio",
                    },
                    {
                        value: "Casa Residencial",
                        label: "Casa Residencial",
                    },
                    {
                        value: "Residência",
                        label: "Residência",
                    },
                    {
                        value: "Sobrado",
                        label: "Sobrado",
                    },
                    {
                        value: "Chácara",
                        label: "Chácara",
                    },
                    {
                        value: "Fazenda",
                        label: "Fazenda",
                    },
                    {
                        value: "Haras",
                        label: "Haras",
                    },
                    {
                        value: "Terreno",
                        label: "Terreno",
                    },
                    {
                        value: "Loteamento/Condomínio",
                        label: "Loteamento/Condomínio",
                    },
                    {
                        value: "Loja",
                        label: "Loja",
                    },
                    {
                        value: "Área Comercial",
                        label: "Área Comercial",
                    },
                    {
                        value: "Edifício Comercial",
                        label: "Edifício Comercial",
                    },
                    {
                        value: "Galpão Comercial",
                        label: "Galpão Comercial",
                    },
                    {
                        value: "Prédio Comercial",
                        label: "Prédio Comercial",
                    },
                    {
                        value: "Residência Comercial",
                        label: "Residência Comercial",
                    },
                    {
                        value: "Sala Comercial",
                        label: "Sala Comercial",
                    },
                    {
                        value: "Galpão",
                        label: "Galpão",
                    },
                    {
                        value: "Garagem",
                        label: "Garagem",
                    },
                ];

                return;
            }

            if (!tipo_empreendimento.includes(tipoSelected)) {
                tipo_empreendimento.push(tipoSelected);
            }
        });

        await this.setState({
            tipo_empreendimento: tipo_empreendimento,
        });
    };

    handleNomeEmpreendimentoChanged = async (e) => {
        if (e == null) {
            await this.setState({
                nome_empreendimento: [],
            });

            return;
        }

        let nome_empreendimento = [];
        e.forEach((option) => {
            if (!nome_empreendimento.includes(option)) {
                nome_empreendimento = [...nome_empreendimento, option];
            }
        });

        await this.setState({
            nome_empreendimento: [...nome_empreendimento],
            movimentar_mapa: true,
        });
    };

    searchEmpreendimentos = async (inputQuery) => {
        if (inputQuery.length > 1) {
            try {
                let endpoint = "/empreeendimentos-options?nome=" + inputQuery;

                if (this.state.cidade !== "" && this.state.cidade.length > 0) {
                    //join cidades label
                    let cidades = "";

                    this.state.cidade.forEach((cidade) => {
                        cidades += cidade.label + ",";
                    });

                    endpoint += `&cidade=${cidades.trim().slice(0, -1)}`;
                }

                if (this.state.estado.length > 0) {
                    let estados = "";

                    this.state.estado.forEach((estado) => {
                        estados += estado + ",";
                    });

                    endpoint += `&estado=${estados.trim().slice(0, -1)}`;
                }

                let empreendimentos = await axios.get(endpoint);

                return empreendimentos.data;
            } catch (error) {}
        }
    };

    reloadEmpreendimentosOnCircle = async () => {
        var empreendimentos = [];

        if (this.state.circles.length > 0) {
            for (let x = 0; x < this.state.circles.length; x++) {
                let circle = this.state.circles[x];
                const center = circle.getCenter();
                const radius = circle.getRadius();
                const color = circle.color;

                let empreendimentosResponse =
                    await EmpreendimentoService.getEmpreendimentosWithinRadius(
                        center.lat(),
                        center.lng(),
                        radius,
                        this.state
                    );

                circle.count_buildings =
                    empreendimentosResponse.data.count_buildings;


                circle.type = "cidade";
                circle.color = this.getColor();

                let empreendimentosFromResponse =
                    empreendimentosResponse.data.empreendimentos;

                empreendimentosFromResponse.map((empreendimento) => {
                    empreendimento.color = color;
                });

                let empreendimentosWithoutDuplicates =
                    await this.removeDuplicates([
                        ...this.state.empreendimentos,
                        ...empreendimentosFromResponse,
                    ]);

                empreendimentos = [
                    ...empreendimentos,
                    ...empreendimentosWithoutDuplicates,
                ];
            }
        }

        return { empreendimentos };
    }; //fim reloadEmpreendimentosOnCircle

    reloadEmpreendimentosOnManualCircle = async () => {
        var empreendimentos = [];

        if (this.state.manualCircles.length > 0) {
            for (let x = 0; x < this.state.manualCircles.length; x++) {
                let circle = this.state.manualCircles[x];
                const center = circle.center;
                const radius = circle.radius;

                try {
                    var lat = center.lat();
                    var lng = center.lng();
                } catch (e) {
                    var lat = center.lat;
                    var lng = center.lng;
                }

                let cidades = this.getCidadesASPlainArray();
                let empreendimentosResponse =
                    await EmpreendimentosServices.getEmpreendimentosWithoutMetaData(
                        {
                            circle: {
                                latitude: lat,
                                longitude : lng,
                                radius : radius
                            },
                            cidades: cidades,
                            estados: this.state.estados

                        }
                    );



                circle.type = "Círculo";
                circle.color = this.getColor();

                let empreendimentosFromResponse = empreendimentosResponse.data;

                let empreendimentosWithoutDuplicates =
                    await this.removeDuplicates([
                        ...this.state.empreendimentos,
                        ...empreendimentosFromResponse,
                    ]);

                empreendimentos = [
                    ...empreendimentos,
                    ...empreendimentosWithoutDuplicates,
                ];
            }
        }

        return { empreendimentos };
    };

    //Recarregar empreendimentos com polignos
    reloadEmpreendimentosOnPolygons = async () => {
        let empreendimentos = [];

        if (this.state.polygons.length > 0) {
            for (let x = 0; x < this.state.polygons.length; x++) {
                let polygon = this.state.polygons[x];
                let path = this.getPolygonPath(polygon);
                path.push(path[0]);

                let empreendimentosResponse =
                    await EmpreendimentosServices.getEmpreendimentosWithoutMetaData(
                        {
                            polygon: path,
                            cidades: this.getCidadesASPlainArray(),
                            estados: this.state.estados
                        }
                    );

                polygon.type = "Polygon";
                polygon.color = this.getColor();

                let empreendimentosFromResponse =
                    empreendimentosResponse.data;

                empreendimentosFromResponse.map((empreendimento) => {
                    empreendimento.color = polygon.color;
                });

                empreendimentos = [
                    ...empreendimentos,
                    ...empreendimentosResponse.data,
                ];
            }
        }

        return { empreendimentos };
    };

    reloadEmpreendimentos = async () => {
        if (window.innerWidth < 700) {
            $("#btn-filter").click();
        }

        await this.setState({
            ...this.state,
            loading: true,
        });

        //Caso não tenha formas desenhadas no mapa a busca será por cidade
        if (this.thereAreDrownShapes() === false) {
            if (
                this.state.overlays.length == 0 &&
                this.state.cidade == "" &&
                this.state.estado.length === 0
            ) {
                alert(
                    "Desenhe uma área no Mapa ou digite uma Cidade na caixa de busca do mapa"
                );

                await this.setState({
                    ...this.state,
                    loading: false,
                });
                return;
            }

            if (this.thereAreDrownShapes() === false) {
                await this.reloadEmpreendimentosOnCidade();
                return;
            }
        }

        await this.setState({
            ...this.state,
            empreendimentos: [],
        });

        let empreendimentos = [];

        //empreendimentos e polos geradores nos Círculos
        let empreendimentosAndPolosGeradores =
            await this.reloadEmpreendimentosOnCircle();
        let empreendimentosInCircles =
            empreendimentosAndPolosGeradores.empreendimentos;

        let empreendimentosAndPolosGeradoresOnManualCircles =
            await this.reloadEmpreendimentosOnManualCircle();
        let empreendimentosOnManualCircles =
            empreendimentosAndPolosGeradoresOnManualCircles.empreendimentos;

        //empreendimentos e polos geradores nos Retângulos
        let empreendimentosAndPolosGeradoresOnPolygons =
            await this.reloadEmpreendimentosOnPolygons();
        let empreendimentosOnPolygons =
            empreendimentosAndPolosGeradoresOnPolygons.empreendimentos;

        empreendimentos = [
            ...empreendimentosInCircles,
            ...empreendimentosOnPolygons,
            ...empreendimentosOnManualCircles,
        ];

        //retira os empreendimentos duplicados
        let empreedimentosWithoutDuplicate = await this.removeDuplicates(
            empreendimentos
        );

        let buildingsIds = empreedimentosWithoutDuplicate.map((empreendimento) => {
            return empreendimento.id_legado;
        });


        await this.setState({
            ...this.state,
            empreendimentos: empreedimentosWithoutDuplicate,
            buildingsIds: buildingsIds,
            loading: false,
            selectedIcon: undefined,
        });


        this.fitMap();

        if (empreedimentosWithoutDuplicate.length > 0) {
            this.openPane();
        } else {
            toast.warn("Nenhum resultado com os parametros fornecidos");
        }
    };

    fitMap = (asyn) => {
        if (this.state.movimentar_mapa) {
            try {
                let coordinatesObjectArray = this.getCoordinatesObjectArray();

                var bounds = new google.maps.LatLngBounds();

                let coords = {
                    lng: this.state.empreendimentos[0].location.coordinates[0],
                    lat: this.state.empreendimentos[0].location.coordinates[1],
                };

                bounds.extend(coords);

                window.googleMap.panTo(coords);
                window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
                    13
                );

                this.setState({
                    ...this.state,
                    movimentar_mapa: false,
                });
            } catch (error) {}
        }
    };

    getCoordinatesObjectArray = (async) => {
        let coordinates = [];

        this.state.empreendimentos.map((empreendimento) => {
            let coordinatesPoint = {
                latitude: empreendimento.location.coordinates[1],
                longitude: empreendimento.location.coordinates[0],
            };

            coordinates.push(coordinatesPoint);
        });

        return coordinates;
    };

    handleProjetoChangedToDelete = async (e) => {};



    handleAreaMinimaChanged = async (e) => {
        await this.setState({
            area_maior_que: parseFloat(e.target.value),
        });
    };

    handleRaioChanged = async (e) => {
        window.circles.forEach((circle) => {
            circle.setRadius(parseInt(e.target.value));
        });
        await this.setState({
            raio: parseInt(e.target.value),
        });
    };

    handleAreaMaximaChanged = async (e) => {
        await this.setState({
            area_menor_que: parseFloat(e.target.value),
        });
    };

    handleStatusChanged = async (e) => {
        if (e === null) {
            await this.setState({
                ...this.state,
                status: [],
            });

            return;
        }

        let status = [];
        e.forEach((statusSelected) => {
            if (statusSelected.value == "Todos") {
                status = [
                    { value: "Venda", label: "Revenda" },
                    { value: "Aluguel", label: "Locação" },
                ];

                return;
            }

            if (!status.includes(statusSelected)) {
                status.push(statusSelected);
            }
        });

        await this.setState({
            tipo_negocio: status,
        });
    };

    handleBairrosChanged = async (e) => {
        if (e === null) {
            await this.setState({
                ...this.state,
                bairros: [],
            });

            return;
        }

        let bairros = [];
        e.forEach((bairroSelected) => {
            if (!bairros.includes(bairroSelected)) {
                bairros.push(bairroSelected);
            }
        });

        await this.setState({
            bairros: bairros,
            movimentar_mapa: true,
        });
    };

    handleTicketMinimoChanged = async (value) => {
        await this.setState({
            ticket_maior_que: value,
        });
    };

    handleTicketMaximoChanged = async (value) => {
        await this.setState({
            ticket_menor_que: value,
        });
    };

    handleValorMetroMinimoChanged = async (value) => {
        await this.setState({
            valor_metro_maior_que: value,
        });
    };

    handleValorMetroMaximoChanged = async (value) => {
        this.setState({
            valor_metro_menor_que: value,
        });

        //this.reloadEmpreendimentos();
    };

    handleAnaliseChanged= async (value) => {
        console.log('value', value)
        this.setState({
            ...this.state,
            tipoAnalise: value,
        })
    }

    handleQuartosChanged = async (e) => {
        const value = parseInt(e.target.value);

        let quartos = this.state.quartos;

        if (e.target.checked && !quartos.includes(value)) {
            quartos.push(value);
        }

        if (!e.target.checked && quartos.includes(value)) {
            var index = quartos.indexOf(value);

            if (index > -1) {
                quartos.splice(index, 1);
            }
        }

        await this.setState({
            ...this.state,
            quartos: quartos,
        });

        //this.reloadEmpreendimentos();
    };
    handleVagasChanged = async (e) => {
        const value = parseInt(e.target.value);

        let vagas = this.state.vagas;

        if (e.target.checked && !vagas.includes(value)) {
            if (value == 4) {
                vagas.push(4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16);
            } else {
                vagas.push(value);
            }
        }

        if (!e.target.checked && vagas.includes(value)) {
            var index = vagas.indexOf(value);
            if (value == 4) {
                if (index > -1) {
                    vagas.splice(index, 13);
                }
            } else {
                if (index > -1) {
                    vagas.splice(index, 1);
                }
            }
        }

        await this.setState({
            ...this.state,
            vagas: vagas,
        });

        //this.reloadEmpreendimentos();
    };

    removeDuplicates = async (empreendimentos) => {
        let newEmpreendimentos = lodash.uniqBy(empreendimentos, function (a) {
            return a._id;
        });

        return newEmpreendimentos;
    };

    getCidadesASPlainArray = () => {
        let cidades = [];

        this.state.cidade.map((cidade) => {
            cidades.push(cidade.label);
        });

        return cidades;
    }

    reloadEmpreendimentosOnCidade = async () => {
        let overlay = {};

        let cidades = this.getCidadesASPlainArray();

        let empreendimentosResponse =
            await EmpreendimentosServices.getEmpreendimentosWithoutMetaData( {
                cidades : cidades, estados: this.state.estado
            } )



        overlay.type = "cidade";
        overlay.color = this.getColor();

        let empreendimentosFromResponse =
            empreendimentosResponse.data;

        let buidingIds = empreendimentosFromResponse.map((e) => e.id_legado);


        await this.setState({
            ...this.state,
            empreendimentos: empreendimentosFromResponse,
            overlays: [overlay, ...this.state.overlays],
            overlay_cidade: true,
            loading: false,
            buildingIds: buidingIds,
        });


        if (this.state.empreendimentos.length > 0) {
            this.fitMap();
            this.openPane();
        } else {
            toast.warn("Nenhum resultado com os parametros fornecidos");
        }
    };

    setLocation = async (place) => {
        try {
            await this.setState({
                ...this.state,
                loading: true,
            });

            let latitude = place[0].geometry.location.lat();
            let longitude = place[0].geometry.location.lng();

            //adiciona um Marca no Ponto da Busca
            let ponto = {
                lat: latitude,
                lng: longitude,
            };

            const reverse = await geolocationService.reverse(
                latitude,
                longitude,
                place
            );


            let cidadeValue = {
                label: reverse.cidade,
                value: `${reverse.uf}-${reverse.cidade}`,
            };

            let bairros = await this.fetchBairros(reverse.cidade);
            let newBairrosOptions = [...this.state.bairrosOptions, ...bairros];

            this.setState({
                ...this.state,
                cidade: [cidadeValue, ...this.state.cidade],
                estado: [reverse.uf, ...this.state.estado],
                bairrosOptions: newBairrosOptions,
                overlay_cidade: true,
                pontos: [...this.state.pontos, ponto],
            });

            await this.reloadEmpreendimentos();
            document.querySelector("#search_box").value = "";
        } catch (error) {
        } finally {
            await this.setState({
                ...this.state,
                loading: false,
            });
        }
    };

    handleEstadoChanged = async (event) => {
        await this.setState({
            ...this.state,
            estado: [event.value],
            estadoSelecionado: event,
        });

        try {
            //na busca pelo estado centraliza o mapa na capital do estado, para isso fazemos a busca dos dados da capital
            let response = await axios.get(
                route("state.capital", { state: event.value })
            );

            if (response.status === 200) {
                let coordinates = {
                    lat: response.data.lat,
                    lng: response.data.lng,
                };

                window.googleMap.panTo(coordinates);
                window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
                    12
                );
            }

            await this.reloadEmpreendimentos();
        } catch (error) {}
    };

    /**
     * Handle Cidade Changed pelo campo do filtro onde os resultados não tem latitude e longitude
     */
    handleCidadeChangedOnFormFilter = async (cidadeOption) => {
        if (cidadeOption === null) {
            this.setState({
                ...this.state,
                cidade: [],
                estado: [],
                newBairrosOptions: [],
            });

            return;
        }
        let estados = [];
        let cidades = [];
        let newBairrosOptions = [];

        for (let x = 0; x < cidadeOption.length; x++) {
            let option = cidadeOption[x];
            //alert( option );
            const cidadeParts = option.value.split("-");
            let estado = cidadeParts[0].trim();

            if (!cidades.includes(option)) {
                cidades.push(option);
            }

            if (!estados.includes(estado)) {
                estados.push(estado);
            }

            let bairros = await this.fetchBairros(option.label);

            newBairrosOptions = [...bairros, newBairrosOptions];
        }

        await this.setState({
            ...this.state,
            cidade: cidades,
            estado: estados,
            overlay_cidade: true,
            bairrosOptions: [...newBairrosOptions],
        });

        try {
            let cidade = this.state.cidade[this.state.cidade.length - 1].label;
            let estado =
                this.state.cidade[this.state.cidade.length - 1].value.split(
                    "-"
                )[0];

            let address = `${cidade} ${estado} - Brasil`;

            let location = await geolocationService.location(address);

            window.googleMap.panTo({
                lat: location.latitude,
                lng: location.longitude,
            });

            await this.reloadEmpreendimentos();
        } catch (error) {}
    };

    handleCidadeChanged = async (latitude, longitude, address) => {
        try {
            let ponto = {
                lat: latitude,
                lng: longitude,
            };

            const reverse = await geolocationService.reverse(
                latitude,
                longitude
            );

            let bairros = await this.fetchBairros(reverse.cidade);

            this.setState({
                ...this.state,
                cidade: reverse.cidade,
                estado: reverse.uf,
                bairrosOptions: bairros,
                overlay_cidade: true,
                pontos: [...this.state.pontos, ponto],
                address: address,
                zoom: 15,
            });

            await this.reloadEmpreendimentos();
            window.googleMap.context[
                "__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED"
            ].setOptions({ zoom: 15 });
        } catch (error) {}
    };

    fetchBairros = async (cidade) => {
        try {
            let bairros = await axios.get("/bairros/json/" + cidade);

            return bairros.data;
        } catch (error) {}
    };

    onMarkerComplete = async (marker, map, circle) => {
        marker.setMap(null);
        let newCircle = {};
        newCircle.center = marker.getPosition();
        newCircle.radius = 1000;
        newCircle.fillColor = this.getColor();
        newCircle.color = newCircle.fillColor;
        newCircle.fillOpacity = 0.3;
        newCircle.type = "Círculo Manual";
        newCircle.id = this.generateRandomString();

        newCircle.name = this.state.manualCircles.length + 1;
        await this.setState({
            ...this.state,
            manualCircles: [newCircle, ...this.state.manualCircles],
            overlays: [newCircle, ...this.state.overlays],
        });

        await this.reloadEmpreendimentos();

    };

    onChangeInputRadius = (id, newRadius) => {
        let manualCircles = this.state.manualCircles;

        if (manualCircles.length == 1) {
            var index = 0;
        } else {
            var index = this.state.manualCircles.findIndex((circle) => {
                return circle.id == id;
            });
        }

        manualCircles[index].radius = parseFloat(newRadius);
        this.setState({
            manualCircles: [...manualCircles],
        });
    };

    onCircleRadiusChanged = (id) => {
        let manualCircles = this.state.manualCircles;

        let newRadius = window.refs[id].getRadius();

        this.setState({
            showTooltip: true,
            radiusChanging: newRadius,
        });

        let index = this.state.manualCircles.findIndex((circle) => {
            return circle.id == id;
        });

        manualCircles[index].radius = newRadius;

        this.setState({
            ...this.state,
            manualCircles: manualCircles,
        });

        setTimeout(() => {
            this.setState({
                showTooltip: false,
            });
        }, 10000);
    };

    removeOverlay = async (id) => {
        let overlays = this.state.overlays;
        let circles = this.state.manualCircles;
        let poligonos = this.state.polygons;
        let countPolygons = this.state.countPolygons;
        let countRectangles = this.state.countRectangles;

        let index = overlays.findIndex((overlay) => {
            return overlay.id == id;
        });

        let overlay = overlays[index];

        if (overlay.type == "Retângulo" || overlay.type == "Polígono") {
            let indexPoligono = poligonos.findIndex((overlay) => {
                return overlay.id == id;
            });
            poligonos.splice(indexPoligono, 1);
            overlay.setMap(null);

            if (overlay.type == "Retângulo") {
                countRectangles--;
            } else {
                countPolygons--;
            }
        }

        if (overlay.type == "Círculo" || overlay.type == "Círculo Manual") {
            let indexCircle = circles.findIndex((overlay) => {
                return overlay.id == id;
            });
            circles.splice(indexCircle, 1);

            if (overlay.setMap !== undefined) {
                overlay.setMap(null);
            }
        }

        let newOverlays = overlays.splice(index, 1);

        await this.setState({
            overlays: [...overlays],
            manualCircles: [...circles],
            polygons: [...poligonos],
            countPolygons: countPolygons,
            countRectangles: countRectangles,
        });

        if (this.state.empreendimentos.length > 0) {
            await this.reloadEmpreendimentos();
        }
    };

    handleShowInfoWindow = () => {
        this.setState({
            showInfoWindow: true,
        });
    };

    handleHideInfoWindow = () => {
        this.setState({
            showInfoWindow: false,
        });
    };

    onCircleCenterChanged = (id) => {
        let manualCircles = this.state.manualCircles;

        let newRadius = window.refs[id].getCenter();

        let index = this.state.manualCircles.findIndex((circle) => {
            return circle.id == id;
        });

        manualCircles[index].center = newRadius;

        this.setState({
            ...this.state,
            manualCircles: manualCircles,
        });
    };

    getColor = () => {
        return colors[this.state.overlays.length];
    };

    handleCloseModalInfo = async () => {
        await this.setState({
            ...this.state,
            showInfo: false,
        });
    };

    handleCloseProjectName = async () => {
        await this.setState({
            ...this.state,
            showProjectName: false,
        });
    };

    handleCloseProjectNameToDelete = async () => {
        await this.setState({
            ...this.state,
            showProjectNameToDelete: false,
        });
    };

    openModalWork = async () => {
        await this.setState({
            ...this.state,
            showProjectName: true,
        });
    };

    openModalWorkDelete = async () => {
        await this.setState({
            ...this.state,
            showProjectNameToDelete: true,
        });
    };

    closeModalEmpreendimento = async () => {
        await this.setState({
            ...this.state,
            modal_empreendimento_visible: false,
        });
        document.getElementById("btn-results").click();
    };



    downloadSupplyFilter = async () => {
        alert('download file')
    }

    onlyIDs = async (emp) =>{
        let set = new Set();

        emp.forEach((e) => {
            set.add(e.id_legado)
        });


        return [...set]
    }

    render() {

        return (
            <>
                { this.state.showSupplyTable &&
                    <TableViewer
                        closeSupplyFilter={ this.closeSupplyFilter }
                        type={ this.state.tipoAnalise }
                        parentState={this.state}
                        filters={{
                            cities: this.state.cidade,
                            states: this.state.estado,
                            neighborhoods: this.state.bairros,
                            status: ['Ativo'],
                            supplies: this.state.material,
                            buildings: this.state.buildingsIds,
                            construction_status: this.state.empStatus,
                            pattern: this.state.pattern,
                            incorporators: this.state.incorporators,
                            ticket_less_than: this.state.ticket_menor_que,
                            ticket_greater_than: this.state.ticket_maior_que,
                            area_less_than: this.state.area_menor_que,
                            area_greater_than: this.state.area_maior_que,
                            there_are_shapes: this.thereAreDrownShapes()

                        }}
                    />
                }
                {this.state.loading && <Loader />}

                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: "60%", color: "#000", zIndex: 9999999 }}
                />


                <div className="row-fornecedores">
                    <div className="map-filter-fornecedores" >
                        <button
                            id="btn-filter"
                            className="btn btn-sm"
                            style={{
                                position: "relative",
                                float: "right",
                                top: "70px",
                                zIndex: 1500,
                                borderRadius: 0,
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                $("#sidebar").toggleClass("collapsed");
                                $("#content").toggleClass(
                                    "col-12 col-9 map-filter-off"
                                );
                                $("#btn-filter").toggleClass("btn-filter-off");

                                if (
                                    $("#filter-icon").hasClass(
                                        "fa-angle-double-left"
                                    )
                                ) {
                                    $("#filter-icon").removeClass(
                                        "fa-angle-double-left"
                                    );
                                    $("#filter-icon").addClass(
                                        "fa-angle-double-right"
                                    );
                                } else if (
                                    $("#filter-icon").hasClass(
                                        "fa-angle-double-right"
                                    )
                                ) {
                                    $("#filter-icon").removeClass(
                                        "fa-angle-double-right"
                                    );
                                    $("#filter-icon").addClass(
                                        "fa-angle-double-left"
                                    );
                                }
                            }}
                        >
                            <i id="filter-icon" className="fas fa-angle-double-left" ></i>
                        </button>
                        <div id="sidebar" style={{ paddingLeft: 0, paddingRight: 0, display: "contents" }} >
                            <div className="card-fornecedores">
                                <FormFilters
                                    cidade={this.state.cidade}
                                    handleCidadeChanged={ this.handleCidadeChangedOnFormFilter }
                                    tipoAnalise={this.state.tipoAnalise}
                                    handleAnaliseChanged={ this.handleAnaliseChanged }
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        //className="col-9 ed-mapa"
                        id="content"
                        style={{ position: "relative", left: "-30px", width: "85%",}}
                    >
                        <SuppliersMap
                            onMarkerComplete={this.onMarkerComplete}
                            currentPosition={this.state.currentPosition}
                            onPolygonComplete={this.onPolygonComplete}
                            onCircleComplete={this.onCircleComplete}
                            empreendimentos={this.state.empreendimentos}
                            onRectangleComplete={this.onRectangleComplete}
                            onMarkerClick={this.onMarkerClick}
                            manualCircles={this.state.manualCircles}
                            raio={this.state.raio}
                            zoom={this.state.zoom}
                            setLocation={this.setLocation}
                            pontos={this.state.pontos}
                            onCircleRadiusChanged={this.onCircleRadiusChanged}
                            onCircleCenterChanged={this.onCircleCenterChanged}
                            getColor={this.getColor}
                            fillColor={this.state.fillColor}
                            selectedIcon={this.state.selectedIcon}
                            showInfoWindow={this.showInfoWindow}
                            handleShowInfoWindow={this.handleShowInfoWindow}
                            handleHideInfoWindow={this.handleHideInfoWindow}
                            showKMl={this.state.showKMl}
                            kml_layers={this.state.kmls_to_show}
                            show_polos_geradores={
                                this.state.show_polos_geradores
                            }
                            showMarkers={this.state.showMarkers}
                            polos_geradores={this.state.polos_geradores}
                        />
                        { this.state.tipoAnalise &&
                            <FilterByType
                                analise={this.state.tipoAnalise.value}
                                area_minima={this.state.area_maior_que}
                                handleAreaMinimaChanged={ this.handleAreaMinimaChanged }
                                area_maxima={this.state.area_menor_que}
                                handleAreaMaximaChanged={ this.handleAreaMaximaChanged }
                                ticket_menor_que={ this.state.ticket_menor_que }
                                handleTicketMinimoChanged={ this.handleTicketMinimoChanged }
                                ticket_maior_que={ this.state.ticket_maior_que }
                                handleTicketMaximoChanged={ this.handleTicketMaximoChanged }
                                bairro={this.state.bairros}
                                bairroOptions={ this.state.bairrosOptions }
                                handleBairrosChanged={ this.handleBairrosChanged }
                                incorporators={this.state.incorporators}
                                handleIncorporadoraChanged={ this.handleIncorporadoraChanged }
                                empStatus={this.state.empStatus}
                                handleEmpStatusChanged={this.handleEmpStatusChanged}
                                pattern={this.state.pattern}
                                handlePatternChanged={this.handlePatternChanged}
                                material={this.state.material}
                                handleMaterialChanged={this.handleMaterialChanged}

                                downloadSupplyFilter={this.downloadSupplyFilter}

                                limparFiltro={this.cleanState}
                                openSupplyFilter={ this.openSupplyFilter }

                            />
                        }


                    </div>
                </div>
            </>
        );
    }

    //verifica se tem formas desenhadas no mapa
    thereAreDrownShapes() {
        return (
            this.state.polygons.length > 0 ||
            this.state.manualCircles.length > 0 ||
            this.state.circles.length > 0
        );
    }

    async getKmlBairros(cidade, uf) {
        try {
            //verifica se o kml da cidade já existe no this.state.kml_bairros
            let kmlCidade = this.state.kml_bairros.find(
                (kml) => kml.cidade === cidade && kml.uf === uf
            );

            if (kmlCidade) {
                return;
            }

            let response = await axios.get(
                route("bairros.kml", { cidade: cidade, estado: uf })
            );
            let kmlBairros = this.state.kml_bairros;
            kmlBairros[cidade] = response.data.kml;

            if (
                response.data.kml !== null &&
                response.data.kml !== undefined &&
                response.data.kml !== ""
            ) {
                this.setState({
                    kml_bairros: [
                        ...this.state.kml_bairros,
                        {
                            cidade: cidade,
                            kml: response.data.kml,
                            uf: uf,
                        },
                    ],
                });

                //await axios.get( response.data.kml );
            }
        } catch (e) {}
    }

    //limpa kmls de bairros
    cleanKmlLayers() {
        this.state.kml_layers.forEach((kml_layer) => {
            try {
                if (kml_layer.setMap !== undefined) {
                    kml_layer.setMap(null);
                }
            } catch (error) {}
        });

        this.setState({
            ...this.state,
            kml_layers: [],
            kmls_to_show: [],
        });
    }

    openModalPolosGeradores = async () => {
        this.setState({
            ...this.state,
            show_modal_polos_geradores: true,
        });
    };

    closeModalPolosGeradores = async () => {
        this.setState({
            ...this.state,
            show_modal_polos_geradores: false,
        });
    };

    setShowBuildings = async (showBuildings) => {
        this.setState({
            ...this.state,
            showBuildings: showBuildings,
        });
    };

    setShowPolosGeradores = async (show_polos_geradores) => {
        this.setState({
            ...this.state,
            show_polos_geradores: show_polos_geradores,
        });
    };

    setShowMarkers = async (showMarkers) => {
        this.setState({
            ...this.state,
            showMarkers: showMarkers,
        });
    };

    setPolosGeradoresTypes = async (polos_geradores_types) => {
        this.setState({
            ...this.state,
            polos_geradores_types_selected: polos_geradores_types,
        });
    };

    getPolosGeradoresTypesAsArray = () => {
        let polos_geradores_types = [];

        if (this.state.polos_geradores_types_selected == null) {
            return polos_geradores_types;
        }

        this.state.polos_geradores_types_selected.forEach(
            (polo_gerador_type) => {
                polos_geradores_types.push(polo_gerador_type.value);
            }
        );

        return polos_geradores_types;
    };

    comparaPolosOnArea = async () => {
        let payload = [];

        this.state.overlays.forEach((area) => {
            if (
                area.polos_geradores_cache_key === "" ||
                area.polos_geradores_cache_key === undefined
            ) {
                return;
            }

            let areaName = "";

            if (area.name !== undefined) {
                areaName = area.name;
            }

            payload.push({
                cache_key: area.polos_geradores_cache_key,
                area: area.type.split(" ")[0] + " " + areaName,
            });
        });
    };
    setBuscaPorEstado = (e) => {
        this.setState({
            ...this.state,
            busca_por_estado: e.target.checked,
        });
    };
}

export default DefaultMap;

if (document.getElementById("mapa_fornecedores")) {
    ReactDOM.render( <DefaultMap />, document.getElementById("mapa_fornecedores"))
}
