import React, { Component } from "react";
import Axios from "axios";

class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visitadosNoDia: 0,
            visitadosNoMes: 0,
            saldoDeSites: 0,
            auditadosNoMes: 0,
            pesoAuditados: 0,
        };
    }
    componentDidMount = async () => {
        this.getData();
    };

    getData = async () => {
        await Axios.get("/mapeamento/dados-por-usuario").then((response) =>
            this.setState({
                visitadosNoDia: response.data.visitadosNoDia,
                visitadosNoMes: response.data.visitadosNoMes,
                saldoDeSites: response.data.saldoDeSites,
                auditadosNoMes: response.data.auditadosNoMes,
                pesoAuditados: response.data.pesoAuditados,
            })
        );
    };

    render() {
        return (
            <div class="row" style={{ justifyContent: "space-around" }}>
                <div style={{ width: "18%" }}>
                    <div class="card card-stats">
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-5">
                                    <div class="icon-big text-center icon-warning">
                                        <i
                                            class="fas fa-award"
                                            style={{ color: "#0b2e13" }}
                                        ></i>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="numbers">
                                        <p class="card-category">
                                            Visitados no dia
                                        </p>
                                        <h4 class="card-title">
                                            {this.state.visitadosNoDia}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer ">
                            <hr />
                            <div class="stats">
                                <i class="fa fa-refresh"></i> Visitados no dia
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "18%" }}>
                    <div class="card card-stats">
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-5">
                                    <div class="icon-big text-center icon-warning">
                                        <i
                                            class="fas fa-award"
                                            style={{ color: "#0b2e13" }}
                                        ></i>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="numbers">
                                        <p class="card-category">
                                            Visitados no mês
                                        </p>
                                        <h4 class="card-title">
                                            {this.state.visitadosNoMes}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer ">
                            <hr />
                            <div class="stats">
                                <i class="fa fa-refresh"></i>
                                Visitados no mês
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "18%" }}>
                    <div class="card card-stats">
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-5">
                                    <div class="icon-big text-center icon-warning">
                                        <i
                                            class="fas fa-award"
                                            style={{ color: "#0b2e13" }}
                                        ></i>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="numbers">
                                        <p class="card-category">
                                            Saldo de Sites
                                        </p>
                                        <h4 class="card-title">
                                            {this.state.saldoDeSites}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer ">
                            <hr />
                            <div class="stats">
                                <i class="fa fa-refresh"></i>Saldo de Sites
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "18%" }}>
                    <div class="card card-stats">
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-5">
                                    <div class="icon-big text-center icon-warning">
                                        <i
                                            class="fas fa-award"
                                            style={{ color: "#0b2e13" }}
                                        ></i>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="numbers">
                                        <p class="card-category">
                                            Auditados no mês
                                        </p>
                                        <h4 class="card-title">
                                            {this.state.auditadosNoMes}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer ">
                            <hr />
                            <div class="stats">
                                <a>
                                    <i class="fa fa-refresh"></i>
                                    Auditados no mês
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "18%" }}>
                    <div class="card card-stats">
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-5">
                                    <div class="icon-big text-center icon-warning">
                                        <i
                                            class="fas fa-award"
                                            style={{ color: "#0b2e13" }}
                                        ></i>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="numbers">
                                        <p class="card-category">
                                            Peso Auditados
                                        </p>
                                        <h4 class="card-title">
                                            {this.state.pesoAuditados}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer ">
                            <hr />
                            <div class="stats">
                                <a>
                                    <i class="fa fa-refresh"></i>
                                    Peso Auditados
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Card;
