import React, { Component } from 'react';

class TableQuartos extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <table className="table table-striped table-condensed" style={{marginBottom: 50, border: `3px solid ${this.props.color}`}}>
                <thead>
                    <tr>
                        <td colSpan={1} className="text-center" style={{
                            backgroundColor: this.props.color,
                        }}>
                        </td>
                        <td colSpan={3} className="text-center" style={{
                            backgroundColor: this.props.color,
                            color: '#ffffff',
                            fontWeight: 'bold',
                            padding: '7px',
                            fontSize: 12

                        }}>
                            M2 PRIVATIVO
                        </td>
                        <td colSpan={3} className="text-center" style={{
                            backgroundColor: this.props.color,
                            color: '#ffffff',
                            fontWeight: 'bold',
                            padding: '7px',
                            fontSize: 12

                        }}>
                            R$/M2 PRIVATIVO
                        </td>
                    </tr>
                    <tr className="text-center" style={{
                        backgroundColor: this.props.color,
                        color: '#ffffff'

                    }}>
                        <th className="text-center" style={{ color: '#ffffff' }}>Tipologia</th>
                        <th className="text-center" style={{ color: '#ffffff' }}>Mínimo</th>
                        <th className="text-center" style={{ color: '#ffffff' }}>Média</th>
                        <th className="text-center" style={{ color: '#ffffff' }}>Máximo</th>
                        <th className="text-center" style={{ color: '#ffffff' }}>Mínimo</th>
                        <th className="text-center" style={{ color: '#ffffff' }}>Média</th>
                        <th className="text-center" style={{ color: '#ffffff' }}>Máximo</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.tipologias.map((tipologia, index) => {
                        return (
                            <tr className="text-center" key={index*1051}>
                                <td className="text-center" style={{whiteSpace: 'nowrap'}}>{tipologia.numero_quartos} DORMITÓRIOS</td>
                                <td className="text-center">{tipologia.area_minima}</td>
                                <td className="text-center">{tipologia.area_media}</td>
                                <td className="text-center">{tipologia.area_maxima}</td>
                                <td className="text-center">{tipologia.valor_minimo_metro}</td>
                                <td className="text-center">{tipologia.valor_medio_metro}</td>
                                <td className="text-center">{tipologia.valor_maximo_metro}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }
}

export default TableQuartos;
