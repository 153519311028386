import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Axios from "axios";
import IntlCurrencyInput from "react-intl-currency-input";

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = async () => {};

    getAges = () => {
        let ages = [];
        for (let i = 18; i < 99; i++) {
            ages.push({ value: i, label: i + " Anos" });
        }

        return ages;
    };

    handleChanged = async (event, value, maskedValue) => {
        this.props.handleChanged({
            name: "income",
            value: value,
        });
    };

    getData = async() => {
        this.props.getData();
    }

    render() {
        const currencyConfig = {
            locale: "pt-BR",
            formats: {
                number: {
                    BRL: {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    },
                },
            },
        };

        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-4">
                                <label>Tipo do Cliente</label>
                                <Select
                                    value={this.props.state.userType}
                                    placeholder={this.props.state.userType}
                                    isMulti={true}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "user_type",
                                            value: value,
                                        })
                                    }
                                    name="userType"
                                    options={[
                                        {
                                            value: "Selecione",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "lead",
                                            label: "Lead",
                                        },
                                        {
                                            value: "visitante",
                                            label: "Visitante",
                                        },
                                        {
                                            value: "cliente",
                                            label: "Cliente",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Idade</label>
                                <Select
                                    value={this.props.state.age}
                                    placeholder={this.props.state.age}
                                    isMulti={true}
                                    options={this.getAges()}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "age",
                                            value: value,
                                        })
                                    }
                                    name="age"
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Gênero</label>
                                <Select
                                    value={this.props.state.genre}
                                    placeholder={this.props.state.genre}
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "genre",
                                            value: value.value,
                                        })
                                    }
                                    name="genre"
                                    options={[
                                        {
                                            value: "Selecione",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "masculino",
                                            label: "Masculino",
                                        },
                                        {
                                            value: "feminino",
                                            label: "Feminino",
                                        },
                                        {
                                            value: "transgênero",
                                            label: "Transgênero",
                                        },
                                        {
                                            value: "gênero neutro",
                                            label: "Gênero neutro",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Estado Civil</label>
                                <Select
                                    value={this.props.state.marital_status}
                                    placeholder={this.props.state.marital_status}
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "marital_status",
                                            value: value.value,
                                        })
                                    }
                                    name="marital_status"
                                    options={[
                                        {
                                            value: "Selecione",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "solteiro",
                                            label: "Solteiro(a)",
                                        },
                                        {
                                            value: "casado",
                                            label: "Casado(a)",
                                        },
                                        {
                                            value: "separado",
                                            label: "Separado(a)",
                                        },
                                        {
                                            value: "divorciado",
                                            label: "Divorciado(a)",
                                        },
                                        {
                                            value: "viúvo",
                                            label: "Viúvo(a)",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Filhos</label>
                                <Select
                                    value={this.props.state.children}
                                    placeholder={this.props.state.children}
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "children",
                                            value: value.value,
                                        })
                                    }
                                    name="children"
                                    options={[
                                        {
                                            value: "Selecione",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "true",
                                            label: "Sim",
                                        },
                                        {
                                            value: "false",
                                            label: "Não",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Renda</label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    value={this.props.state.income}
                                    name="income"
                                    onChange={this.handleChanged}
                                    style={{ padding: "3px" }}
                                    className="valor form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-6">
                                <button className="btn btn-sm btn-success btn-block background-brain border-brain btn-round"
                                    onClick={this.getData}
                                    >
                                    <i className="fas fa-search"></i> Filtrar
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-block btn-primary button-brain btn-round"
                                    //style={{ backgroundColor: "#FFBB27" }}
                                    onClick={this.clearState}
                                >
                                    <i className="far fa-file"></i> Limpar
                                </button>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
