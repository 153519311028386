import Axios from "axios";
import buildingsServices from "../../services/empreendimentosServices";
import colors from "../../util/colors";
import SimpleEmpreendimentosService from "../../services/SimpleEmpreendimentosService";

class ReloadBuildingsWithoutMetaData {
    getColor = (length) => {
        return colors[length];
    };

    reloadEmpreendimentosOnCidade = async (state) => {
        let overlay = {
            type: "cidade",
            color: this.getColor(state.overlays.length),
        };
        let cities = state.cidade.map((city) => {
            return city.label;
        });
        let buildingsFromResponse =
            await buildingsServices.getEmpreendimentosWithoutMetaData({
                cidades: cities,
                estados: state.estado,
            });

        let buidingIds = buildingsFromResponse.data.map((e) => e.id_legado);
        return {
            empreendimentos: buildingsFromResponse.data,
            overlays: overlay,
            buildingIds: buidingIds,
        };
    };

    reloadBuildingsWithoutMetaData = async (state) => {
        let buildings = [];

        let buildingsOnCircles = await this.getBuildingsOnCircles(state);
        let buildingsOnPolygons = await this.getBuildingsOnPolygons(state);

        buildings = [
            ...buildingsOnCircles.buildings,
            ...buildingsOnPolygons.buildings,
        ];

        return buildings;
    };

    getBuildingsOnCircles = async (state) => {
        var buildings = [];
        let circles = state.circles;

        if (circles.length > 0) {
            for (let x = 0; x < circles.length; x++) {
                let circle = circles[x];

                if( circle.getMap() === null ){
                    continue;
                }

                const center = circle.center;
                const radius = circle.radius;

                try {
                    var lat = center.lat();
                    var lng = center.lng();
                } catch (e) {
                    var lat = center.lat;
                    var lng = center.lng;
                }

                let buildingsFromResponse =
                    await SimpleEmpreendimentosService.getEmpreendimentosWithinRadius(lat, lng, radius, state);

                buildings = [...buildings, ...buildingsFromResponse.data];
            }
        }

        return { buildings };
    };

    getBuildingsOnPolygons = async (state) => {
        let buildings = [];

        if (state.polygons.length > 0) {
            for (let x = 0; x < state.polygons.length; x++) {
                let polygon = state.polygons[x];
                polygon.color = this.getColor();

                if( polygon.getMap() === null ){
                    continue;
                }


                let path = this.getPolygonPath(polygon);
                path.push(path[0]);

                let buildingsFromResponse =
                    await SimpleEmpreendimentosService.getEmpreendimentosWithinPolygon(path, state);

                buildings = [...buildings, ...buildingsFromResponse.data];
            }
        }

        return { buildings };
    };

    getPolygonPath = (polygon) => {
        if (polygon.getBounds !== undefined) {
            let bounds = polygon.getBounds();
            let northEast = bounds.getNorthEast();
            let southWest = bounds.getSouthWest();

            let northWest = [northEast.lat(), southWest.lng()];
            let southEast = [southWest.lat(), northEast.lng()];

            let path = [
                [northEast.lat(), northEast.lng()],
                [northWest[0], northWest[1]],
                [southWest.lat(), southWest.lng()],
                [southEast[0], southEast[1]],
                [northEast.lat(), northEast.lng()],
            ];

            return path;
        }

        let path = [];

        for (var i = 0; i < polygon.getPath().getLength(); i++) {
            let coordinate = polygon
                .getPath()
                .getAt(i)
                .toUrlValue(10)
                .split(",");

            path.push([Number(coordinate[0]), Number(coordinate[1])]);
        }

        return path;
    };
}

export default new ReloadBuildingsWithoutMetaData();
