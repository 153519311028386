import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import IntlCurrencyInput from "react-intl-currency-input"
import { SelectButton } from 'primereact/selectbutton';
import Pin from "./Pin/Pin";
import Slider from '@material-ui/core/Slider';


var currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                //style: "currency",
                //currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

var currencyConfigInteger = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                //style: "currency",
                //currency: "BRL",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
        },
    },
};

const labels = {
    "area_media": "Área média",
    "ticket_medio_metro": "Ticket Médio R$/m²",
    "total_unidades": "Oferta Inicial",
    "oferta_final": "Estoque",
}



class ModalRanges extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            min: 0,
            max: null,
            type: null,
            marker: null,
            errorMessage: null,
            iconsImages : [],
            markerType: 'color',
            markersTypes: [
                {label: 'Cor', value: 'color'},
                {label: 'Imagem', value: 'image'}
            ],
            uploading: false,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount = async () => {
        let iconsUrls = await axios.get( route('web.marcadores_selecionaveis.index') );

        this.setState({
            iconsImages: iconsUrls.data
        });

    }

    handleMarkerChange = (selectedMarker) => {
        this.setState({
            ...this.state,
            marker: selectedMarker
        });
    }

    submit = async () => {

    }

    openModal(area) {
        this.setState({
            modalIsOpen: true,
            area: area
        });
    }

    handleValorMinimumChange = async (event, value, maskedValue) => {

        event.preventDefault();
        await this.setState({
            ...this.state,
            min: value

        });
    }

    handleValorMaximumChange = async (event, value, maskedValue) => {

        event.preventDefault();
        await this.setState({
            ...this.state,
            max: value

        });
    }

    handleTipoChange = (event) => {
        //console.log(event);

        this.setState({
            ...this.state,
            type: event
        });
    }

    needsIntegerInputRange = () => {
        return this.state.type?.value === "total_unidades" || this.state.type?.value === "oferta_final";
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            area: null
        });
    }

    addRange = async () => {

        if (this.state.max == null) {
            this.setState({
                ...this.state,
                max: 99999999
            });

            return;
        }


        if (this.state.min == null || this.state.type == null || this.state.marker == null) {
            this.setState({
                ...this.state,
                errorMessage: "Preencha todos os campos"
            });

            return;
        }


        if (this.state.max < this.state.min) {
            this.setState({
                ...this.state,
                errorMessage: "Valor máximo deve ser maior que o mínimo"
            });

            return;
        }


        let rangeExitis = this.props.ranges.find(range => {
            return range.min === this.state.min && range.max === this.state.max && range.type === this.state.type.value;
        });

        if (rangeExitis) {
            this.setState({
                ...this.state,
                errorMessage: "Já existe um range com esses valores"
            });

            return;
        }


        let markerExists = this.props.ranges.find(range => {
            return range.marker === this.state.marker.value;
        });

        if (markerExists) {
            this.setState({
                ...this.state,
                errorMessage: "Já existe um range com esse marcador"
            });

            return;
        }


        let overlaps = this.props.ranges.find(range => {
            return (this.state.min >= range.min && this.state.min <= range.max) || (this.state.max >= range.min && this.state.max <= range.max);
        });

        if (overlaps) {
            this.setState({
                ...this.state,
                errorMessage: "O range se sobrepõe a outro range"
            });

            return;
        }


        console.log(this.state);
        let range = {
            min: this.state.min,
            max: this.state.max,
            type: this.state.type.value,
            marker: this.state.marker,
            id: this.generateRandomString()
        }

        await this.props.addRange(range);

        this.setState({
            ...this.state,
            min: null,
            max: null,
            errorMessage: null,
            marker: null,
        });


    }

    generateRandomString = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    uploadFile = async (event) => {

        event.preventDefault();

        try{

            await this.setState({
                uploading: true,
                errorMessage: null
            });

            let file = event.target.files[0];
            let formData = new FormData();
            formData.append('file', file);
            let response = await axios.post( route('custom-marker-upload'), formData);

            this.setState({
                ...this.state,
                iconsImages: [response.data[0].url,...this.state.iconsImages ],
                marker: {
                    ...this.state.marker,
                    value: response.data[0].url,
                }

            });
        }catch (e){
            if( e.response && e.response.status === 422 ){
                this.setState({
                    errorMessage: e.response.data.errors.file[0] + ' ' + e.response.data.errors.file[1]
                });
            }
        }finally {
            this.setState({
                uploading: false,
                fileSelected: undefined
            });
        }
    }


    render() {

        let configRangeInput = currencyConfig;

        if (this.needsIntegerInputRange()) {
            configRangeInput = currencyConfigInteger;
        }

        return (
            <div style={{zIndex: '9999'}}>
                <Modal show={this.props.show} onHide={this.props.closeModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Criar Ranges</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    {this.state.errorMessage &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                    <label>Tipo de Marcador:</label>
                                    <SelectButton
                                        optionLabel="label"
                                        value={this.state.markerType}
                                        options={this.state.markersTypes}
                                        onChange={(e) => {
                                            this.setState({
                                                markerType: e.value,
                                                marker: {
                                                    value: this.state.marker?.value,
                                                    type: e.value
                                                }

                                            })
                                        }}></SelectButton>

                                </div>

                                <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                    <label>Range Por:</label>
                                    <Select
                                        value={this.state.type}
                                        placeholder="Selecione o tipo"
                                        onChange={this.handleTipoChange}
                                        options={[
                                            {value: 'area_media', label: 'Área Média'},
                                            {value: 'ticket_medio_metro', label: 'Ticket Médio R$/m²'},
                                            {value: 'total_unidades', label: 'Oferta Inicial'},
                                            {value: 'oferta_final', label: 'Estoque'},
                                        ]}
                                    />
                                </div>

                                <div className="col-12 col-md-3 col-sm-3 col-lg-3">
                                    <label>A partir de</label>
                                    <IntlCurrencyInput
                                        currency="BRL"
                                        config={configRangeInput}
                                        value={this.state.min}
                                        onChange={this.handleValorMinimumChange}
                                        style={{padding: '3px'}}
                                        className="valor form-control"

                                    />
                                </div>

                                <div className="col-12 col-md-3 col-sm-3 col-lg-3">
                                    <label>Até</label>
                                    <IntlCurrencyInput
                                        currency="BRL"
                                        config={configRangeInput}
                                        onChange={this.handleValorMaximumChange}
                                        style={{padding: '3px'}}
                                        className="valor form-control"
                                        value={this.state.max}
                                    />

                                </div>

                            </div>

                            {this.state.markerType === 'color' &&
                                <div className="form-group row">
                                    <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                        <img style={{width: '200px'}} src="/img/marker_parts.png" alt="partes do marcador"/>
                                    </div>
                                    <div className="col-12 col-md-10 col-sm-10 col-lg-10">
                                        <div className="row form-group">
                                            <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                                <label>Cor de Fundo</label>
                                                <input type="color" value={this.state.marker?.background || '#fff' } onChange={(e) => {

                                                    this.setState({
                                                        ...this.state,
                                                        marker: {
                                                            ...this.state.marker,
                                                            background: e.target.value,
                                                        }
                                                    });
                                                }} />

                                            </div>
                                            <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                                <label>Cor da Borda</label> &nbsp;
                                                <input type="color"
                                                    value={this.state.marker?.borderColor || '#000'}
                                                    onChange={e => this.setState({
                                                        ...this.state,
                                                        marker: {
                                                            ...this.state.marker,
                                                            borderColor: e.target.value,
                                                        }
                                                    })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                                <label>Cor do Glifo</label> &nbsp;
                                                <input type="color"
                                                    value={this.state.marker?.glyphColor || '#000'}
                                                    onChange={e => this.setState({
                                                        ...this.state,
                                                        marker: {
                                                            ...this.state.marker,
                                                            glyphColor: e.target.value,
                                                            type: 'color'
                                                        }
                                                    })}
                                                />
                                            </div>

                                            <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                                <label>Escala</label> &nbsp;
                                                <Slider
                                                    value={this.state.marker?.scale || 1 }
                                                    aria-labelledby="discrete-slider-small-steps"
                                                    step={0.1}
                                                    marks
                                                    min={0.1}
                                                    max={2}
                                                    valueLabelDisplay="on"
                                                    onChange={(e, newValue )=>{

                                                        this.setState({
                                                            ...this.state,
                                                            marker: {
                                                                ...this.state.marker,
                                                                scale: newValue
                                                            }
                                                        });

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {this.state.markerType === 'image' &&
                                <div className="form-group row">
                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                        <label>Selecione uma imagem disponível ou envie uma imagem de sua escolha</label> <br />
                                        <div style={{marginBottom: '15px'}}>
                                            <input className="form-control-file"
                                                   type="file"
                                                   disabled={this.state.uploading}
                                                   onChange={async (event)=>{
                                                       event.persist();
                                                       await this.uploadFile(event);
                                                   }} />
                                            { this.state.uploading && <div className="alert alert-info">Enviando arquivo...</div> }
                                        </div>
                                        <div className="d-flex justify-content-center align-itens-center flex-wrap border border-primary"
                                             style={{height: '172px', overflowX: 'scroll'}}>
                                            {this.state.iconsImages.map((icon, index) => {
                                                let className = this.state.marker?.value === icon ? 'selectable-icons-div selectable-icons-div-selected bg-success' : 'selectable-icons-div';
                                                return (
                                                    <div
                                                        role="button"
                                                        className={className}
                                                        onClick={() => this.setState({
                                                            ...this.state,
                                                            marker: {value: icon, type: 'image'}
                                                        })}
                                                        key={index}>
                                                    <img src={icon} alt={icon} style={{width: '25px'}}/>
                                                        <input type="hidden" id={icon} name="icon" value={icon}/>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }

                                <div className="form-group row">
                                    <div className="col-md-12 col-sm-12 col-lg-12" style={{marginTop: '10px'}}>
                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <button className="btn btn-xs btn-primary button-brain btn-round"
                                                    onClick={this.addRange}>
                                                Adicionar Range
                                            </button>
                                            <button
                                                className="btn btn-xs btn-success border-brain background-brain btn-round"
                                                onClick={() => {
                                                    this.props.replaceMarkers();
                                                    this.props.closeModal();
                                                }}>
                                                Aplicar
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div style={{height: '250px', overflowY: 'scroll'}}>
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>A partir de</th>
                                                    <th>Até</th>
                                                    <th>Tipo</th>
                                                    <th>Marcador</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.props.ranges.map((range, index) => {

                                                    let hexaBackground =null;

                                                    if( range.marker.type === 'color' ){
                                                        hexaBackground = range.marker.background;
                                                    }

                                                    let icon = range.marker.type === 'image' ? range.marker.value : null;

                                                    return (
                                                        <tr key={index}>
                                                            <td>{Number(range.min).toLocaleString()}</td>
                                                            <td>{(() => {
                                                                if (range.max === 99999999) {
                                                                    return null
                                                                }
                                                                return Number(range.max).toLocaleString();
                                                            })()}</td>
                                                            <td>{labels[range.type]}</td>
                                                            <td>
                                                                {hexaBackground && <> <Pin backgroundColor={hexaBackground}
                                                                                            borderColor={range.marker.borderColor}
                                                                                            glyphColor={range.marker.glyphColor}
                                                                                           />
                                                                </>}
                                                                {icon &&
                                                                    <img src={icon} alt={icon}
                                                                         style={{width: '25px'}}/>}
                                                            </td>
                                                            <td>

                                                                <button className="btn btn-xs btn-danger"
                                                                        onClick={() => this.props.removeRange(range)}>
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                    </Modal.Body>
                </Modal>
            </div>
        )

    }
}

export default ModalRanges;
