import Axios from "axios";
import { map } from "jquery";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import FormUpdate from "../MapeamentoLinks/FormUpdate";

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalUpdate: false,
            showModalSemEmpreendimentos: false,
            link: "",
            observacao: "",
            link_id: "",
            portal: "",
            status: "",
            type: "",
            abas_visitadas: 0,
            fotos_analisadas: 0,
            phone_number: false,
            indexAtual: null,
        };
    }

    filterByType = (value) => {
        return value.type == this.props.type;
    };

    newEmpreendimento = async (index) => {
        window.open(
            route("building.create", {
                mapeamentoComLink: true,
                link_id: index.id,
                type: this.props.type,
                mapeamento_id: this.props.mapeamento.id,
            }),
            "_blank"
        );
    };

    newEmpreendimentoSemLink = async () => {
        window.open(
            route("building.create", {
                mapeamentoComLink: false,
                type: this.props.type,
                mapeamento_id: this.props.mapeamento.id,
            }),
            "_blank"
        );
    };

    semEmpreendimento = async (index) => {
        axios
            .post("/mapeamento-worker-sem-empreendimento", {
                user_id: this.props.user.id,
                city_id: this.props.city.id,
                period: this.props.mapeamento.period,
                type: this.props.type,
                link_id: this.state.indexAtual
                    ? this.state.indexAtual.id
                    : null,
                mapeamento_id: this.props.mapeamento.id,
                abas_visitadas: this.state.abas_visitadas,
                fotos_analisadas: this.state.fotos_analisadas,
                status: "Sem Empreendimentos",
            })
            .then((response) => {
                this.props.getLinks();
                let resposta = "Produtividade Salva com sucesso.";
                if (response.data.status != "Success") {
                    resposta = "Erro ao salvar produtividade.";
                }
                this.setState({
                    ...this.state,
                    abas_visitadas: 0,
                    fotos_analisadas: 0,
                });
                Swal.fire({
                    title: resposta,
                    icon: "warning",
                    confirmButtonColor: "#FB404B",
                    confirmButtonText: "Fechar!",
                }).then((result) => {
                    if (result.value) {
                        this.closeModalSemEmpreendimentos();
                    }
                });
            });
    };

    formatDate = (index) => {
        let date = new Date(index);

        let day = date.getDate() < 10 ? 0 + date.getDate() : date.getDate();
        let month =
            date.getMonth() < 10 ? 0 + date.getMonth() : date.getMonth();

        return day + "/" + month + "/" + date.getFullYear();
    };

    mapeamentoFisicoRender = (mapFisico) => {
        if (mapFisico.length > 0) {
            let count = 0;
            let last_update = "";
            mapFisico.map((index, key) => {
                if (index.status !== "Sem Empreendimentos") {
                    count++;
                    last_update = index.last_update;
                }
            });

            return (
                <tr key="asdfas">
                    <td>#</td>
                    <td>Mapeamento Físico</td>
                    <td></td>
                    <td>{count == 0 ? "Sem Empreendimentos" : count}</td>
                    <td>{moment(last_update).format("DD/MM/YYYY")}</td>
                    <td>
                        <a
                            className="btn btn-success btn-sm btn-link"
                            onClick={() =>
                                this.newEmpreendimentoSemLink(this.props.type)
                            }
                        >
                            + CADASTRAR EMPREENDIMENTO
                        </a>
                        <button
                            className="btn btn-danger btn-sm btn-link"
                            onClick={() =>
                                this.opemModalSemEmpreendimento(null)
                            }
                        >
                            X NENHUM ENCONTRADO
                        </button>
                    </td>
                </tr>
            );
        } else {
            return (
                <tr key="asdfas">
                    <td>#</td>
                    <td>Mapeamento Físico</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <a
                            className="btn btn-success btn-sm btn-link"
                            onClick={() =>
                                this.newEmpreendimentoSemLink(this.props.type)
                            }
                        >
                            + CADASTRAR EMPREENDIMENTO
                        </a>
                        <button
                            className="btn btn-danger btn-sm btn-link"
                            onClick={() =>
                                this.opemModalSemEmpreendimento(null)
                            }
                        >
                            X NENHUM ENCONTRADO
                        </button>
                    </td>
                </tr>
            );
        }
    };

    filterByStatusAtivo = (value) => {
        return value.status == "Ativo";
    };
    filterByStatusVisitado = (value) => {
        return (
            value.worker_status == "Visitado" ||
            value.worker_status == "Sem Empreendimentos"
        );
    };

    openModalUpdate = async (index) => {
        await this.setState({
            ...this.state,
            link_id: index.id,
            link: index.link,
            observacao: index.observacao,
            portal: index.portal,
            status: index.status,
            type: index.type,
            phone_number: index.phone_number == true ? true : false,
            showModalUpdate: true,
        });

        console.log(this.state.phone_number);
    };
    opemModalSemEmpreendimento = async (index) => {
        await this.setState({
            ...this.state,
            showModalSemEmpreendimentos: true,
            indexAtual: index,
            abas_visitadas: index?.abas_visitadas ?? 0,
            fotos_analisadas: index?.fotos_analisadas ?? 0,
        });
    };
    handleChanged = async (e) => {
        await this.setState({
            ...this.state,
            [e.name]: e.value.target.value,
        });
    };

    handleLinkChanged = async (e) => {
        await this.setState({
            ...this.state,
            link: e.target.value,
        });
    };
    handlePortalChanged = async (e) => {
        await this.setState({
            ...this.state,
            portal: e.target.value,
        });
    };
    handleTypeChanged = async (e) => {
        await this.setState({
            ...this.state,
            type: e.value,
        });
    };
    handlePhoneNumberChanged = async (e) => {
        await this.setState({
            ...this.state,
            phone_number: this.state.phone_number == false ? true : false,
        });
    };
    handleObservacaoChanged = async (e) => {
        await this.setState({
            ...this.state,
            observacao: e.target.value,
        });
    };
    closeModalUpdate = async () => {
        await this.setState({
            ...this.state,
            showModalUpdate: false,
        });
    };
    closeModalSemEmpreendimentos = async () => {
        await this.setState({
            ...this.state,
            showModalSemEmpreendimentos: false,
        });
    };
    updateLink = async () => {
        await axios
            .post("/mapeamento-links/update/" + this.state.link_id, {
                link: this.state.link,
                portal: this.state.portal,
                observacao: this.state.observacao,
                type: this.state.type,
                phone_number: this.state.phone_number,
            })
            .then(
                (response) => {
                    if (response.data.status === "Success") {
                        this.setState({
                            ...this.state,
                            link: "",
                            portal: "",
                            observacao: "",
                            type: "",
                            phone_number: false,
                        });
                        this.props.getLinks();
                        this.closeModalUpdate();
                    }
                },
                (error) => {}
            );
    };
    inativarOuAtivarLink = async () => {
        await axios
            .post("/mapeamento-links/update/" + this.state.link_id, {
                link: this.state.link,
                portal: this.state.portal,
                type: this.state.type,
                status: this.state.status == "Inativo" ? "Ativo" : "Inativo",
            })
            .then(
                (response) => {
                    if (response.data.status === "Success") {
                        this.setState({
                            ...this.state,
                            link: "",
                            portal: "",
                            type: "",
                        });
                        this.props.getLinks();
                        this.closeModalUpdate();
                    }
                },
                (error) => {}
            );
    };

    render() {
        let mapFisico = this.props.mapFisico;
        // let mapFisico = this.props.mapFisico.filter(this.filterByType);
        let links = this.props.links;
        // let links = this.props.links.filter(this.filterByType);
        let ativos = links.filter(this.filterByStatusAtivo);
        let visitados = links.filter(this.filterByStatusVisitado);
        let count = 10;

        return (
            <div className="card">
                <span>
                    Total: {ativos.length} - Visitados: {visitados.length} -
                    Empreendimentos Cadastrados: {this.props.cadastrados}
                </span>
                <div className="card-body">
                    <table
                        className="table table-striped table-condensed"
                        style={{
                            wordWrap: "break-word",
                            tableLayout: "fixed",
                        }}
                    >
                        <thead>
                            <tr>
                                <th>Portal</th>
                                <th>Fonte</th>
                                <th>Observação</th>
                                <th>Empreendimentos Cadastrados</th>
                                <th>Data da última Inserção</th>
                                <th className="text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.mapeamentoFisicoRender(mapFisico)}
                            {links.map((index, key) => {
                                return (
                                    <tr key={key}>
                                        <td>
                                            <button
                                                className="btn btn-sm btn-link"
                                                onClick={() =>
                                                    this.openModalUpdate(index)
                                                }
                                            >
                                                <i className="fas fa-edit"></i>
                                            </button>
                                            {index.portal}{" "}
                                            {index.phone_number == true && (
                                                <span className="badge badge-info">
                                                    Ligação
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            <a
                                                href={index.link}
                                                target="_blank"
                                            >
                                                {index.link
                                                    .substring(0, 50)
                                                    .replace("https://", " ")
                                                    .replace("http://", "")
                                                    .replace("www.", "")}
                                            </a>
                                        </td>
                                        <td>{index.observacao}</td>
                                        <td>
                                            {index.worker_status ==
                                            "Sem Empreendimentos"
                                                ? "Sem Empreendimentos"
                                                : index.buildings_created}
                                        </td>
                                        <td>
                                            {index.last_update
                                                ? moment(
                                                      index.last_update
                                                  ).format("DD/MM/YYYY")
                                                : ""}
                                        </td>
                                        <td>
                                            <a
                                                className="btn btn-success btn-sm btn-link"
                                                onClick={() =>
                                                    this.newEmpreendimento(
                                                        index
                                                    )
                                                }
                                            >
                                                + CADASTRAR EMPREENDIMENTO
                                            </a>
                                            <button
                                                className="btn btn-danger btn-sm btn-link"
                                                onClick={() =>
                                                    this.opemModalSemEmpreendimento(
                                                        index
                                                    )
                                                }
                                            >
                                                X NENHUM ENCONTRADO
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Modal
                    size="lg"
                    show={this.state.showModalUpdate}
                    onHide={this.closeModalUpdate}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Atualizar Link</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormUpdate
                            handleSubmit={this.updateLink}
                            handleObservacaoChanged={
                                this.handleObservacaoChanged
                            }
                            handlePortalChanged={this.handlePortalChanged}
                            handleLinkChanged={this.handleLinkChanged}
                            handleTypeChanged={this.handleTypeChanged}
                            handlePhoneNumberChanged={
                                this.handlePhoneNumberChanged
                            }
                            observacao={this.state.observacao}
                            link={this.state.link}
                            portal={this.state.portal}
                            type={this.state.type}
                            phone_number={this.state.phone_number}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-success"
                            onClick={this.updateLink}
                        >
                            Atualizar
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={this.inativarOuAtivarLink}
                        >
                            {this.state.status == "Inativo"
                                ? "Ativar"
                                : "Inativar"}
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    size="lg"
                    show={this.state.showModalSemEmpreendimentos}
                    onHide={this.closeModalSemEmpreendimentos}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Informe A Quantidade De Abas Abertas/Fotos
                            Analisadas
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-group col-6">
                                        <label>Abas Visitadas</label>
                                        <input
                                            type="number"
                                            name="abas_visitadas"
                                            value={this.state.abas_visitadas}
                                            disabled={
                                                this.state.indexAtual != null &&
                                                this.state.indexAtual
                                                    .abas_visitadas != null
                                            }
                                            onChange={(value) =>
                                                this.handleChanged({
                                                    value: value,
                                                    name: "abas_visitadas",
                                                })
                                            }
                                            style={{ padding: "3px" }}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Fotos Analisadas</label>
                                        <input
                                            type="number"
                                            name="fotos_analisadas"
                                            value={this.state.fotos_analisadas}
                                            disabled={
                                                this.state.indexAtual != null &&
                                                this.state.indexAtual
                                                    .fotos_analisadas != null
                                            }
                                            onChange={(value) =>
                                                this.handleChanged({
                                                    value: value,
                                                    name: "fotos_analisadas",
                                                })
                                            }
                                            style={{ padding: "3px" }}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-success"
                            onClick={this.semEmpreendimento}
                        >
                            Salvar Sem Empreendimento
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Table;
