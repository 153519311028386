import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import FormCreate from "./FormCreate";

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_type: "",
            cep: "",
            age: "",
            genre: "",
            marital_status: "",
            children: "",
            income: 0,
            property_of_interest: "",
            typology_of_interest: "",
            text: "",
            id: 0,
            file: [],

            showModalUpdate: false,
            showModalCreate: false,
            showModalFile: false,
        };
    }

    openModalUpdate = async (event) => {
        await this.setState({
            id: event.id,
            user_type: event.user_type,
            cep: event.cep,
            age: event.age,
            genre: event.genre,
            marital_status: event.marital_status,
            children: event.children ? "Sim" : "Não",
            income: event.income,
            property_of_interest: event.property_of_interest,
            typology_of_interest: event.typology_of_interest,
            text: event.text,
            showModalUpdate: true,
        });
    };

    closeModalUpdate = async () => {
        await this.setState({
            id: 0,
            user_type: "",
            cep: "",
            age: "",
            genre: "",
            marital_status: "",
            children: "",
            income: 0,
            property_of_interest: "",
            typology_of_interest: "",
            text: "",

            file: [],
            showModalUpdate: false,
        });
    };

    openModalCreate = async () => {
        await this.setState({
            user_type: "",
            cep: "",
            age: "",
            genre: "",
            marital_status: "",
            children: "",
            income: "",
            property_of_interest: "",
            typology_of_interest: "",
            text: "",
            showModalCreate: true,
        });
    };

    closeModalCreate = async () => {
        await this.setState({
            user_type: "",
            cep: "",
            age: "",
            genre: "",
            marital_status: "",
            children: "",
            income: 0,
            property_of_interest: "",
            typology_of_interest: "",
            text: "",
            showModalCreate: false,
        });
    };

    modalImport = async () => {
        await this.setState({
            file: [],
            showModalFile: !this.state.showModalFile,
        });
    };

    update = async () => {
        axios
            .post("/geo-referenciamento/update/" + this.state.id, this.state)
            .then((response) => {
                if (response.data.status == "Success") {
                    this.props.getData();
                    this.closeModalUpdate();
                }
            });
    };

    create = async () => {
        axios
            .post("/geo-referenciamento/create", this.state)
            .then((response) => {
                if (response.data.status == "Success") {
                    this.props.getData();
                    this.closeModalUpdate();
                }
            });
    };

    handleChanged = async (event) => {
        await this.setState({
            [event.name]: event.value,
        });
    };

    import = async () => {
        if (this.state.file.lengh == 0) {
            return alert("Insira um arquivo xlsx!");
        }

        let formData = new FormData();
        formData.append("file", this.state.file);

        axios
            .post("/geo-referenciamento/import", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                if (response.data.status == "Success") {
                    this.props.getData();
                    this.modalImport();
                }
            });
    };

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-10">
                            <a
                                className="float-end btn btn-success buttonGeo background-brain border-brain btn-round"
                                href="georreferenciamento.xlsx"
                                target="_blank"
                            >
                                Baixar Modelo Excel
                            </a>
                            <button
                                className="float-end btn btn-success buttonGeo background-brain border-brain btn-round"
                                onClick={() => this.modalImport()}
                            >
                                Importar
                            </button>
                            <button
                                className="float-end btn btn-success buttonGeo background-brain border-brain btn-round"
                                onClick={() => this.openModalCreate()}
                            >
                                Adicionar
                            </button>
                        </div>
                    </div>

                    <table
                        id="datatables"
                        className="table table-striped table-condensed table-padroes"
                    >
                        <thead>
                            <tr>
                                <th className="text-center">Tipo de Usuário</th>
                                <th className="text-center">Cep</th>
                                <th className="text-center">Idade</th>
                                <th className="text-center">Gênero</th>
                                <th className="text-center">Estado Civil</th>
                                <th className="text-center">Filhos</th>
                                <th className="text-center">Renda</th>
                                <th className="text-center">
                                    Imóvel de Interesse
                                </th>
                                <th className="text-center">
                                    Tipologia de Interesse
                                </th>
                                <th className="text-center">Fonte Livre</th>
                                <th className="text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.data.map((index, key) => {
                                return (
                                    <tr key={key}>
                                        <th className="text-center">
                                            {index.user_type}
                                        </th>
                                        <th className="text-center">
                                            {index.cep}
                                        </th>
                                        <th>{index.age} Anos</th>
                                        <th className="text-center">
                                            {index.genre}
                                        </th>
                                        <th className="text-center">
                                            {index.marital_status}
                                        </th>
                                        <th className="text-center">
                                            {index.children ? "Sim" : "Não"}
                                        </th>
                                        <th>{index.income}</th>
                                        <th className="text-center">
                                            {index.property_of_interest}
                                        </th>
                                        <th className="text-center">
                                            {index.typology_of_interest}
                                        </th>
                                        <th className="text-center">
                                            {index.text}
                                        </th>
                                        <th className="text-center">
                                            <button
                                                className="btn btn-link btn-warning"
                                                onClick={() =>
                                                    this.openModalUpdate(index)
                                                }
                                            >
                                                <i className="fa-solid fa-pen"></i>
                                            </button>
                                        </th>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <Modal
                        size="lg"
                        show={this.state.showModalUpdate}
                        onHide={this.closeModalUpdate}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Atualizar</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormCreate
                                state={this.state}
                                handleChanged={this.handleChanged}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-sm btn-success btn-block"
                                onClick={this.update}
                            >
                                <i className="fas fa-search"></i> Atualizar
                            </button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        size="lg"
                        show={this.state.showModalCreate}
                        onHide={this.closeModalCreate}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Criar</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormCreate
                                state={this.state}
                                handleChanged={this.handleChanged}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-sm btn-success btn-block"
                                onClick={this.create}
                            >
                                Salvar
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        size="lg"
                        show={this.state.showModalFile}
                        onHide={this.modalImport}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Importar</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group col-12 text-center">
                                Fazer uma importação irá sobrescrever todos os
                                dados anteriores!
                            </div>
                        </Modal.Body>
                        <Modal.Body>
                            <input
                                type="file"
                                name="file"
                                onChange={(value) =>
                                    this.handleChanged({
                                        name: "file",
                                        value: value.target.files[0],
                                    })
                                }
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-sm btn-success btn-block"
                                onClick={this.import}
                            >
                                Salvar
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default Table;
