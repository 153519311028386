class PolosGeradoresService{

    getPolosGeradoresWithinRadius = async (lat, lng, radius, types = null ) => {

        let payload = {
            'radius': radius,
            'latitude': lat,
            'longitude': lng,
        }

        if( types ){
            payload.types = types;
        }

        let response = await axios.post(route('polos-geradores'), payload);

        return {
            polos_geradores : response.data.polos_geradores,
            cache_key : response.data.cache_key
        };
    }

    getPolosGeradoresFromCity = async ( city, types = null ) => {

        let cities = [];

        city.forEach( (item) => {
            cities.push(item.label);
        });

        let payload = {
            'city': cities,
        }

        if( types ){

            payload.types = types;

        }

        let response = await axios.post(route('polos-geradores'), payload);

        return {
            polos_geradores : response.data.polos_geradores,
            cache_key : response.data.cache_key
        };
    }

    getPolosGeradoresWithinPolygon = async ( path, types = null ) => {

        let payload = {
            'polygon': path,
        }

        if( types ){

            payload.types = types;

        }

        let response = await axios.post(route('polos-geradores'), payload);

        return {
            polos_geradores : response.data.polos_geradores,
            cache_key : response.data.cache_key
        };
    }

}

export default new PolosGeradoresService();
