import Axios from "axios";
import { map } from "jquery";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalUpdate: false,

            mapeamento_id: "",
            fotos_analisadas: "",
            new_fotos_analisadas_value: "",
            abas_visitadas: "",
            new_abas_visitadas_value: "",
            user: [],
        };
    }

    modalUpdate = async (index = null) => {
        let fotos_analisadas = "";
        let new_fotos_analisadas_value = "";
        let abas_visitadas = "";
        let new_abas_visitadas_value = "";
        let mapeamento_id = "";
        let user = [];

        if (index != null) {
            mapeamento_id = index.id;
            fotos_analisadas = index.fotos_analisadas ?? 0;
            new_fotos_analisadas_value = index.new_fotos_analisadas_value ?? 0;
            abas_visitadas = index.abas_visitadas ?? 0;
            new_abas_visitadas_value = index.new_abas_visitadas_value ?? 0;
            user = index.user;
        }

        await this.setState({
            ...this.state,
            modalUpdate: !this.state.modalUpdate,
            fotos_analisadas: fotos_analisadas,
            mapeamento_id: mapeamento_id,
            new_fotos_analisadas_value: new_fotos_analisadas_value,
            abas_visitadas: abas_visitadas,
            new_abas_visitadas_value: new_abas_visitadas_value,
            user: user,
        });
    };

    getClass = (index) => {
        if (
            index.new_fotos_analisadas_value > 0 ||
            index.new_abas_visitadas_value > 0
        ) {
            return "btn btn-danger btn-sm btn-link";
        }
        return "btn btn-success btn-sm btn-link";
    };

    handleChanged = async (e) => {
        await this.setState({
            ...this.state,
            [e.name]: e.value.target.value,
        });
    };

    updateProdutividade = async () => {
        Axios.post("/mapeamento/painel/gerencial", this.state)
            .then((response) => {
                this.modalUpdate(null);
                this.props.reloadProdutividade();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <table
                        className="table table-striped table-condensed"
                        style={{
                            wordWrap: "break-word",
                            tableLayout: "fixed",
                        }}
                    >
                        <thead>
                            <tr>
                                <th>Operador</th>
                                <th>Período</th>
                                <th>Cidade</th>
                                <th>Empreendimento</th>
                                <th>Fotos Analisadas</th>
                                <th>Abas Visitadas</th>
                                <th className="text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.produtividade.map((index, key) => {
                                return (
                                    <tr key={key}>
                                        <td className="col-3">
                                            {index.user.name}
                                        </td>
                                        <td>{index.period}</td>
                                        <td>{index.city.city}</td>
                                        <td>{index.building?.name}</td>
                                        <td className="col-3">
                                            {index.fotos_analisadas ?? 0}
                                        </td>
                                        <td className="col-3">
                                            {index.abas_visitadas ?? 0}
                                        </td>
                                        <td className="col-3">
                                            <button
                                                className={this.getClass(index)}
                                                onClick={() =>
                                                    this.modalUpdate(index)
                                                }
                                            >
                                                Visualizar
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>

                        <Modal
                            size="lg"
                            show={this.state.modalUpdate}
                            onHide={this.modalUpdate}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                    Atualizar Produtividade
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <ul>
                                            <li>
                                                Valor atual para Fotos
                                                Analisadas:{" "}
                                                {this.state.fotos_analisadas}
                                            </li>
                                            <li>
                                                Valor atual para Abas Visitadas:{" "}
                                                {this.state.abas_visitadas}
                                            </li>
                                            <li>
                                                Responsável:{" "}
                                                {this.state.user?.name}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="form-group col-6">
                                        <label>
                                            Novo valor para Abas Visitadas
                                        </label>
                                        <input
                                            type="number"
                                            name="new_abas_visitadas_value"
                                            value={
                                                this.state
                                                    .new_abas_visitadas_value
                                            }
                                            onChange={(value) =>
                                                this.handleChanged({
                                                    value: value,
                                                    name: "new_abas_visitadas_value",
                                                })
                                            }
                                            style={{ padding: "3px" }}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-6">
                                        <label>
                                            Novo valor para Fotos Analisadas
                                        </label>
                                        <input
                                            type="number"
                                            name="new_fotos_analisadas_value"
                                            value={
                                                this.state
                                                    .new_fotos_analisadas_value
                                            }
                                            onChange={(value) =>
                                                this.handleChanged({
                                                    value: value,
                                                    name: "new_fotos_analisadas_value",
                                                })
                                            }
                                            style={{ padding: "3px" }}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-success"
                                    onClick={() => this.updateProdutividade()}
                                >
                                    Atualizar
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </table>
                </div>
            </div>
        );
    }
}

export default Table;
