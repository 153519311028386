import React from "react";
import Modal from 'react-bootstrap/Modal'
import anos_lancamento from "../util/anos_lancamento";
import ordenacoes from "../util/ordenacoes";
import Select from "react-select";
import IntlCurrencyInput from "react-intl-currency-input";
import Axios from "axios";

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};
const currencyConfigDecimal = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

class ModalDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rangesAno: [],
            rangesMetro: [],
            rangesPreco: [],

            precoMin: 0,
            precoMax: 0,
            errorPreco: false,

            metroMin: 0,
            metroMax: 0,
            errorMetro: false,

            orderBy: "",
            logo: "",

        };
    }

    handleChange = async (e) => {
        await this.setState({
            ...this.state,
            [e.name]: e.value,
        })
    }

    addRangePreco = async () => {

        if (this.state.precoMin == 0 || this.state.precoMax == 0) {
            return this.setState({
                ...this.state,
                errorPreco: "Preços não podem ser 0"

            })
        }

        if (this.state.precoMin > this.state.precoMax) {
            return this.setState({
                ...this.state,
                errorPreco: "Preço Inicial não pode ser menor que Final"

            })
        }


        var rangesPreco = {
            precoMin: this.state.precoMin,
            precoMax: this.state.precoMax,
            id: this.generateRandomString()
        };

        await this.setState({
            ...this.state,
            rangesPreco: [...this.state.rangesPreco, rangesPreco],
            precoMax: 0,
            precoMin: 0,
            errorPreco: false

        })

    }

    addRangeMetro = async () => {

        if (this.state.metroMin == 0 || this.state.metroMax == 0) {
            return this.setState({
                ...this.state,
                errorMetro: "Metragem não podem ser 0"

            })
        }

        if (this.state.metroMin > this.state.metroMax) {
            return this.setState({
                ...this.state,
                errorMetro: "Metragem Inicial não pode ser menor que Final"

            })
        }

        var rangesMetro = {
            metroMin: this.state.metroMin,
            metroMax: this.state.metroMax,
            id: this.generateRandomString()
        };

        await this.setState({
            ...this.state,
            rangesMetro: [...this.state.rangesMetro, rangesMetro],
            metroMin: 0,
            metroMax: 0,
            errorMetro: false

        })

        console.log(this.state);
    }

    removeRange = async (range, name) => {
        this.setState({
            ...this.state,
            [name]: this.state[name].filter(r => r.id !== range.id)
        });
    }

    generateRandomString = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    submit = async () => {
        if (this.state.rangesAno.length == 0) {
            return alert("Ranges por ano não pode ser 0");
        }
        if (this.state.rangesMetro.length == 0) {
            return alert("Ranges por metro não pode ser 0");
        }
        if (this.state.rangesPreco.length == 0) {
            return alert("Ranges por preço não pode ser 0");
        }

        this.props.setLoading(true);

        var originalState = this.props.originalState;

        Axios.post(originalState, this.state, { responseType: 'blob' }) // Definindo o tipo de resposta como blob
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'relatorio.pdf');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log("Erro:", error);
            }).finally(async () => {
                this.props.setLoading(false);

            });
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <Modal size="lg" show={this.props.show} onHide={this.props.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Filtros</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group row">
                                <div className="col-md-6 col-sm-6 col-lg-6">
                                    <label>Logo</label>
                                    <br />
                                    <input type="file"
                                        onChange={(event) => {
                                            const file = event.target.files[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onload = (loadEvent) => {
                                                    const base64 = loadEvent.target.result;
                                                    this.handleChange({
                                                        name: 'logo',
                                                        value: base64
                                                    });
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />

                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6 col-sm-6 col-lg-6">
                                    <label>Ordernar por</label>
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderRadius: 20,
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#F2F2F2",
                                                primary: "#5B7537",
                                            },
                                        })}
                                        value={this.state.orderBy}
                                        placeholder="Selecione"
                                        isMulti={false}
                                        onChange={(value) => this.handleChange({
                                            name: "orderBy",
                                            value: value
                                        })}
                                        options={ordenacoes}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-lg-6">
                                    <label>Anos de Lançamento</label>
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderRadius: 20,
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#F2F2F2",
                                                primary: "#5B7537",
                                            },
                                        })}
                                        value={this.state.ano_lancamento}
                                        placeholder="Selecione"
                                        isMulti={true}
                                        onChange={(value) => this.handleChange({
                                            name: "rangesAno",
                                            value: value
                                        })}
                                        options={anos_lancamento}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6 col-sm-6 col-lg-6">
                                    <label>Preço a partir de</label>
                                    <IntlCurrencyInput
                                        currency="BRL"
                                        config={currencyConfig}
                                        value={this.state.precoMin}
                                        onChange={(value, maskedValue) => this.handleChange({
                                            name: "precoMin",
                                            value: maskedValue
                                        })}
                                        style={{ padding: "3px" }}
                                        className="valor form-control"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-lg-6">
                                    <label>Até</label>
                                    <IntlCurrencyInput
                                        currency="BRL"
                                        config={currencyConfig}
                                        value={this.state.precoMax}
                                        onChange={(value, maskedValue) => this.handleChange({
                                            name: "precoMax",
                                            value: maskedValue
                                        })}
                                        style={{ padding: "3px" }}
                                        className="valor form-control"
                                    />
                                </div>
                                {this.state.errorPreco && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {this.state.errorPreco}
                                    </div>
                                )}
                                <button onClick={() => this.addRangePreco()} className="btn btn-small btn-link"> Adicionar</button>

                            </div>

                            <div className="form-group row">
                                <div className="col-md-6 col-sm-6 col-lg-6">
                                    <label>Metragem a partir de</label>
                                    <IntlCurrencyInput
                                        currency="BRL"
                                        config={currencyConfigDecimal}
                                        value={this.state.metroMin}
                                        onChange={(value, maskedValue) => this.handleChange({
                                            name: "metroMin",
                                            value: maskedValue
                                        })}
                                        style={{ padding: "3px" }}
                                        className="valor form-control"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-lg-6">
                                    <label>Até</label>
                                    <IntlCurrencyInput
                                        currency="BRL"
                                        config={currencyConfigDecimal}
                                        value={this.state.metroMax}
                                        onChange={(value, maskedValue) => this.handleChange({
                                            name: "metroMax",
                                            value: maskedValue
                                        })}
                                        style={{ padding: "3px" }}
                                        className="valor form-control"
                                    />
                                </div>
                                {this.state.errorMetro && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {this.state.errorMetro}
                                    </div>
                                )}
                                <button onClick={() => this.addRangeMetro()} className="btn btn-small btn-link">Adicionar</button>

                            </div>


                            <div className="row">

                                <div className="col-12">
                                    <div style={{ height: '250px', overflowY: 'scroll' }}>
                                        <button onClick={() => this.submit()} className="btn btn-small btn-link">Gerar PDF</button>
                                        <table className="table table-striped"  >
                                            <thead>
                                                <tr>
                                                    <th>A partir de</th>
                                                    <th>Até</th>
                                                    <th>Tipo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.rangesPreco.map((range, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>R$ {Number(range.precoMin.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 }) }</td>
                                                            <td>R$ {Number(range.precoMax.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 }) }</td>
                                                            <td>Preço</td>
                                                            <td>
                                                                <button className="btn btn-xs btn-danger" onClick={() => this.removeRange(range, "rangesPreco")}>
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                                {this.state.rangesMetro.map((range, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{Number(range.metroMin.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 }) }</td>
                                                            <td>{Number(range.metroMax.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 }) }</td>
                                                            <td>Metro</td>
                                                            <td>
                                                                <button className="btn btn-xs btn-danger" onClick={() => this.removeRange(range, "rangesMetro")}>
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }
}


export default ModalDashboard;