import {React, Component} from 'react';

export default class TabelaDespesas extends Component {

    render() {
        let total = this.props.despesas.reduce((acc, custo) => {
            return acc + parseFloat(custo.valor);
        }, 0);

        return(
            <>
                <table className="table table-bordered table-striped table-sm">
                    <thead>
                        <tr>
                            <td>Descriminação</td>
                            <td>Valor</td>
                            <td>Número de Parcelas</td>
                            <td>Mês Inicial</td>
                            <td>Remover</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.despesas.map((custo, index) => {
                            return (
                                <tr key={index}>
                                    <td>{custo.descriminacao}</td>
                                    <td>{this.props.formatNumber(custo.valor)}</td>
                                    <td>{custo.numero_parcelas}</td>
                                    <td>{custo.momento}</td>
                                    <td >
                                        <div className="pull-right">
                                            <div className="d-flex justify-content-between">
                                                <button className="btn btn-sm btn-danger"
                                                        onClick={(e) => {
                                                            this.props.removeDespesa(index);
                                                        }}
                                                >
                                                    <i className="fa fa-trash">

                                                    </i>
                                                </button>

                                                <button className="btn btn-sm btn-success"
                                                        onClick={(e) => {
                                                            this.props.setSelectDespesa(custo);
                                                        }}
                                                >
                                                    <i className="fa fa-edit">

                                                    </i>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>

                    <tfoot>
                    <tr>
                        <td colSpan="4">
                                <span className="pull-right">
                                    Total
                                </span>
                        </td>
                        <td>
                                <span className="pull-right">
                                    {this.props.formatNumber(total)}
                                </span>
                        </td>
                    </tr>
                    </tfoot>

                </table>
            </>
        );
    }
}
