import * as XLSX from 'xlsx';

const getTableData = (tableId) => {
  const headers = [];
  const subHeaders = [];
  const tableData = [];

  const table = document.querySelector(tableId);
  if (!table) {
    return [];
  }

  const headerRows = table.querySelectorAll('thead tr');
  if (headerRows.length > 0) {
    headerRows[0].childNodes.forEach((cell) => {
      if (cell.tagName === "TH") {
        headers.push({ v: cell.textContent || "", t: 's' });
      }
    });
  }
  if (headerRows.length > 1) {
    headerRows[1].childNodes.forEach((cell) => {
      if (cell.tagName === "TH") {
        subHeaders.push({ v: cell.textContent || "", t: 's' });
      }
    });
  }

  const rows = table.querySelectorAll('tbody tr');
  rows.forEach((row) => {
    const rowData = [];
    row.childNodes.forEach((cell) => {
      let cellText = cell.textContent.replaceAll(".", "");
      if (isNaN(cellText)) {
        rowData.push({ v: cellText });
      } else {
        rowData.push({ v: parseFloat(cellText), t: 'n', z: '#,##0' });
      }
    });
    tableData.push(rowData);
  });

  const rowsFooter = table.querySelectorAll('tfoot tr');
  rowsFooter.forEach((row) => {
    const rowData = [];
    row.childNodes.forEach((cell) => {
      let cellText = cell.textContent.replaceAll(".", "");
      if (isNaN(cellText)) {
        rowData.push({ v: cellText });
      } else {
        rowData.push({ v: parseFloat(cellText), t: 'n', z: '#,##0' });
      }
    });
    tableData.push(rowData);
  });

  const breakMarker = [];
  tableData.push(breakMarker);
  return [headers, subHeaders, ...tableData];
};

const generateExcelData = (rangeInicial, rangeFinal, wb, titulo) => {
  const data = [];

  for (let i = rangeInicial; i <= rangeFinal; i++) {
    let excelData = getTableData("#excel" + i);
    if (excelData.length > 0) {
      Array.prototype.push.apply(data, excelData);
    }
  }

  if (data.length > 0) {
    const ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, titulo);
  }
  return wb;
}

const exportData = () => {
  const wb = XLSX.utils.book_new();

  generateExcelData(1, 8, wb, "Lançamentos");
  generateExcelData(9, 16, wb, "Vendas");
  generateExcelData(17, 24, wb, "Oferta Final");
  generateExcelData(25, 31, wb, "IVV");
  generateExcelData(32, 35, wb, "Ticket Médio");
  generateExcelData(36, 39, wb, "Ticket Médio Metro");
  if(window.canViewTableAnaliseTemporalIncorporadoras){
    generateExcelData(40, 45, wb, "Lançamentos Por Incorporadora");
    generateExcelData(46, 51, wb, "Vendas Por Incorporadora");
  }

  XLSX.writeFile(wb, "relatorio_analitico.xlsx");
};

export default exportData;
