import ReactDOM from "react-dom";
import React from "react";
import Loader from "../Loader";
import {ToastContainer, toast} from 'react-toastify';
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import Select    from "react-select";
import TableAuditReportAnaliticGrid  from "./TableAuditReportAnaliticGrid";
import TableAuditReportGerencialGrid  from "./TableAuditReportGerencialGrid";

class FormReport extends React.Component{
    constructor(props) {
        super();

        this.state = {
            title : "Relatório de Auditoria",
            operatorsOptions : [],
            loading : false,
            data_inicial : null,
            data_final : null,
            operadores : [],
            errors: [],
            formato : 'json',
            data: {
                tipo : '',
                data : []
            },
        }
    }

    componentDidMount = async ()=> {

        console.log("Montando o componente FormReport");
        await Promise.all([
            this.loadOperators()
        ]);

    }

    loadOperators = async () => {
        try{
            let response = await axios.get(route('web.auditoria_tabelas.operadores'));

            let operadoresOptions = [{
                value: 'todos',
                label: 'Selecionar Todos'
            }, ...response.data];
            this.setState({
                ...this.state,
                operadoresOptions: operadoresOptions
            });
        }catch (e) {
            console.log(e);
        }
    }



    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }
    //
    submitForm = async (e) => {

        await this.setLoading(true);
        try {


            await this.setState({
                ...this.state, errors: [],
                data: {
                    tipo : '',
                    data : []
                }
            });




            let payload = {
                data_inicial: this.state.data_inicial ? new Date(this.state.data_inicial).toISOString().split('T')[0] : null,
                data_final: this.state.data_final ? new Date(this.state.data_final).toISOString().split('T')[0] : null,
                operadores: this.state.operadores.map((operator) => operator.value),
                tipo : this.state.tipo?.value,
                formato : this.state.formato
            };

            let aditionalParams = {};
            if( this.state.formato === 'excel'){
                aditionalParams = {
                    responseType: 'blob',
                };
            }

            console.log( payload )

            this.setState({loading: true});

            let response = await axios.post(route('web.auditoria_relatorio.form.post'), payload, aditionalParams );

            if (response.status === 200 && response.headers['content-type'] === 'application/json') {

                await this.setState({
                    ...this.state,
                    data: response.data
                });

            }

            if (response.status === 200 && response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Relatório de Auditoria.xlsx';
                link.click();

                toast.success('Relatório gerado com sucesso!');
            }

        } catch (e) {


            if (e.response && e.response.status === 422) {

                console.log( e.response.data.errors );

                //response é do tipo blob então o axios não consegue ler o json de erro automaticamente
                if( e.response.headers['content-type'] === 'application/json'){

                    if( e.response.data.errors){
                        await this.setState({
                            ...this.state,
                            errors: e.response.data.errors
                        });


                    }else{
                        let responseBody = await JSON.parse( await e.response.data.text());

                        console.log( responseBody );

                        if (responseBody.errors) {
                            await this.setState({
                                ...this.state,
                                errors: responseBody.errors
                            });
                        }
                    }


                    console.log( e.response.data.errors );

                }/*else if(this.state.formato === 'excel'){

                    alert('Não foi possível gerar o relatório. Tente novamente mais tarde.');

                }*/
            }

            console.log(e);
        } finally {
            this.setLoading(false);
        }
    }

    render() {
        return (<>
            {this.state.loading && <Loader/>}
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{width: "50%", zIndex: 9999999, fontWieght: 'bold'}}
            />

            <div className="card ">
                {this.loading && <Loader/>}
                <div className="card-header">
                    <h4 className="card-title">Relatório de Auditoria</h4>
                </div>
                <div className="card-body ">

                    <form method="post" className="form-horizontal">
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="status">Data Inicial</label>
                                <DatePicker
                                    locale="pt-BR"
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.data_inicial}
                                    onChange={async (e) => {
                                        await this.setState({
                                            ...this.state,
                                            data_inicial: e
                                        });
                                    }}
                                />


                                {this.state.errors['data_inicial'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.state.errors['data_inicial'][0]}
                                    </div>}

                            </div>

                            <div className="col-6">
                                <label htmlFor="status">Data Final</label>
                                <DatePicker
                                    locale="pt-BR"
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.data_final}
                                    onChange={async (e) => {
                                        await this.setState({
                                            ...this.state,
                                            data_final: e
                                        });
                                    }}
                                />

                                {this.state.errors['data_final'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.state.errors['data_final'][0]}
                                    </div>}

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label htmlFor="status">Operadores</label>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    name="operadores"
                                    options={this.state.operadoresOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Selecione os operadores"
                                    value={this.state.operadores}
                                    onChange={async (e) => {

                                        //selecionar todos os operadores
                                        if( e && e.length === 1 && e[0].value === 'todos') {

                                            let operadores = this.state.operadoresOptions.filter((operator) => operator.value !== 'todos');

                                            console.log('Operadores Filtrados', operadores);

                                            await this.setState({
                                                ...this.state,
                                                operadores: operadores
                                            });

                                            return;
                                        }

                                        await this.setState({
                                            ...this.state,
                                            operadores: e
                                        });



                                    }}></Select>

                                {this.state.errors['operadores'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.state.errors['operadores'][0]}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="tipo">Tipo do Relatório</label>
                                <Select
                                    options={[
                                        {value: 'Análitico', label: 'Análitico'},
                                        {value: 'Gerencial', label: 'Gerencial'},
                                    ]}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Selecione o tipo do relatório"
                                    value={this.state.tipo}
                                    onChange={async (e) => {
                                        await this.setState({
                                            ...this.state,
                                            tipo: e
                                        });
                                    }}></Select>


                                {this.state.errors['tipo'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.state.errors['tipo'][0]}
                                    </div>
                                }
                            </div>
                            <div className="col-6">

                            </div>

                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <button className="btn btn-success btn-block btn-round" onClick={async (e)=>{
                                    e.preventDefault();

                                    await this.setState({
                                        ...this.state,
                                        formato: 'json'
                                    })

                                    await this.submitForm();
                                }}>Visualizar</button>
                            </div>
                            <div className="col-6">
                                <a className="btn btn-primary btn-block btn-round"

                                        onClick={ async (e)=>{

                                    await this.setState({
                                        ...this.state,
                                        formato: 'excel'
                                    })
                                    await this.submitForm();
                                }}>
                                    <i className="fa fa-file-excel-o"></i> Gerar Excel
                                </a>
                            </div>
                        </div>


                    </form>

                </div>

                <div className="card-body">
                    { this.state.data.tipo === 'analitico' && <TableAuditReportAnaliticGrid data={this.state.data.data} /> }
                    { this.state.data.tipo === 'gerencial' && <TableAuditReportGerencialGrid data={this.state.data.data} /> }
                </div>
            </div>
        </>);
    }

}

if (document.getElementById('js-form-relatio-audit')) {
    ReactDOM.render( <FormReport/>, document.getElementById('js-form-relatio-audit'));
}
