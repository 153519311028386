import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Form from "./Form";
import Table from "./Table";
import Loader from "../Loader";
import Pagination from "../../util/pagination";
import "../../../../public/css/stylePagination.css";
import Modais from "./Modais";

class Index extends React.Component {
    state = {
        loading: false,
        showModalCreate: false,
        pagination: 1,
        maxPage: 1,
        buttons: [],
        regioes: [],
    };

    handlePageChange = (value) => {
        this.setState({
            pagination: value,
        });
        this.updateButtons();
        this.getRegioes();
    }

    setLoading = (loading) => {
        this.setState({
            loading: loading,
        });
    }

    getRegioes = async () => {
        this.setLoading(true);

        await this.setState({
            regioes: [],
        });

        Axios.get(route('regioes-administrativas-list', {
            page: this.state.pagination
        }))
            .then((response) => {
                this.setState({
                    regioes: response.data.data,
                    maxPage: response.data.last_page,
                });
                this.updateButtons();
            })
            .catch()
            .finally(() => {
                this.setLoading(false);
            });
    }

    updateButtons = () => {
        const paginationInstance = new Pagination({
            pagination: this.state.pagination,
            maxPage: this.state.maxPage,
            handlePageChange: this.handlePageChange,
        });

        let buttons = paginationInstance.loadButtonsPaginate();
        this.setState({
            buttons: buttons,
        });
    }

    closeShowModal = async (modal) => {
        await this.setState({
            ...this.state,
            [modal]: !this.state[modal]
        })
    }

    componentDidMount = () => {
        this.getRegioes();
    }

    render = () => {
        return (
            <>
                <Form showModal={this.closeShowModal} />
                <Table state={this.state}
                    getRegioes={this.getRegioes}
                />
                <div className="card text-center align-items-center">
                    <div className="card-body col-6 text-center align-items-center">
                        <div className="row d-flex align-items-center justify-content-center" style={{ justifyContent: "space-around" }}>
                            {this.state.buttons.map((button, index) => (
                                <div key={index}>
                                    {button}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <Modais
                    state={this.state}
                    closeModal={this.closeShowModal}
                    getRegioes={this.getRegioes}
                />
                {this.state.loading && <Loader />}
            </>
        );
    };
}

export default Index;

if (document.getElementById("regioes_administrativas")) {
    ReactDOM.render(<Index />, document.getElementById("regioes_administrativas"));
}
