import React, { Component } from "react";

class TablePadroesInfoComercial extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <table className="table table-striped table-condensed table-padroes" style={{border: `3px solid ${this.props.color}`}}>
                <thead>
                    <tr
                        className="text-center"
                        style={{
                            backgroundColor: '#5B7537',
                            color: "#ffffff",
                            fontWeight: "bold",
                        }}
                    >
                        <th
                            className="text-center"
                            style={{ color: "#ffffff", padding: "7px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>Padrão</span>
                        </th>
                        <th
                            className="text-center"
                            style={{ color: "#ffffff", padding: "7px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>
                                Faixa de Valor
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">Econômico</td>
                        <td class="text-center">Até 6.000 m² priv.</td>
                    </tr>
                    <tr>
                        <td class="text-center">Standard</td>
                        <td class="text-center">De 6.001 a 8.000 m² priv.</td>
                    </tr>
                    <tr>
                        <td class="text-center">Médio</td>
                        <td class="text-center">De 8.001 a 10.000 m² priv.</td>
                    </tr>
                    <tr>
                        <td class="text-center">Alto</td>
                        <td class="text-center">De 10.001 a 12.000 m² priv.</td>
                    </tr>
                    <tr>
                        <td class="text-center">Alto +</td>
                        <td class="text-center">Acima de 12.001 m² priv.</td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default TablePadroesInfoComercial;
