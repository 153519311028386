import React, { Component } from 'react';

class TablePrecoPadroes extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <table className="table table-striped table-condensed" style={{border: `3px solid ${this.props.color}`}}>
                <thead>
                    <tr className="text-center" style={{
                        backgroundColor: this.props.color,
                        //borderColor: this.props.color,
                        color: '#ffffff',
                        fontWeight: 'bold',
                        border: `3px solid ${this.props.color}`,


                    }}>
                        <td className="text-center" style={{ color: '#ffffff', fontWeight: 'bold', padding: '6px', fontSize: 13, textTransform:'uppercase' }} colSpan={3}>
                            Preço Médio Por Padrão - {this.props.title}
                        </td>


                    </tr>
                    <tr className="text-center" style={{
                        backgroundColor: this.props.color,
                        color: '#ffffff',
                        fontWeight: 'bold',
                        padding: '10px',
                        border: `3px solid ${this.props.color}`

                    }}>
                        <th className="text-center" style={{ color: '#ffffff', paddingTop: 3, paddingBottom: 3 }}>
                        Padrão
                        {this.props.title == "Vertical" && <button className="btn btn-sm" style={{backgroundColor: this.props.color,color: '#ffffff'}} onClick={this.props.showModalPadraoInfo}><i className="far fa-question-circle"></i></button>}
                        {this.props.title == "Comercial" && <button className="btn btn-sm" style={{backgroundColor: this.props.color,color: '#ffffff'}} onClick={this.props.showModalPadraoInfoComercial}><i className="far fa-question-circle"></i></button>}
                        {this.props.title == "Horizontal - Condomínio de Casas/Sobrados" && <button className="btn btn-sm" style={{backgroundColor: this.props.color,color: '#ffffff'}} onClick={this.props.showModalPadraoInfoCasas}><i className="far fa-question-circle"></i></button>}

                            </th>
                        <th className="text-center" style={{ color: '#ffffff',  paddingTop: 3, paddingBottom: 3 }}>Preço Médio</th>
                        <th className="text-center" style={{ color: '#ffffff',  paddingTop: 3, paddingBottom: 3 }}>Preço Médio Metro</th>

                    </tr>
                </thead>
                <tbody>
                    {this.props.padroes.map((padrao, index) => {
                        return (
                            <tr className="text-center" key={index * 15801}>
                                <td className="text-center">{padrao.padrao}</td>
                                <td className="text-center">{padrao.preco_medio}</td>
                                <td className="text-center">{padrao.preco_medio_metro}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

        );
    }
}

export default TablePrecoPadroes;
