import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class FormModalEvolucao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            evolucao: this.props.evolucao
        }
    }

    componentDidMount() {

    }


    render() {
        return (
            <div style={{zIndex: 999999 }}>
                <Modal size="lg" show={this.props.show} onHide={this.props.handleClose}
                       aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Title>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="text-center">Edição de Evolução</h4>
                                </div>
                            </div>
                        </div>
                    </Modal.Title>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">

                                    {this.props.evolucao.map(( evolucao )=>{
                                        return (
                                            <div className="col-12 col-sm-3 col-md-3 col-lg-3" key={evolucao.id}>
                                                <div className="form-group">
                                                    <label htmlFor="mes">Evolucão do Mês {evolucao.mes}</label>
                                                    <input
                                                        onChange={( event )=>{
                                                            event.persist();
                                                            this.props.onChange( evolucao, event.target.value )
                                                        }}
                                                        type="text" value={evolucao.evolucao} className="form-control"/>
                                                </div>
                                            </div>
                                        )
                                    })}

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <button  className="btn btn-block btn-success" onClick={this.props.save}>
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default FormModalEvolucao;
