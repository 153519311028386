import React from 'react';
import Modal from "react-bootstrap/Modal";


class ModalChangeShapeName extends React.Component{

    constructor(props){
        super(props);
    }

    render(){
        return(
            <Modal show={this.props.show} onHide={this.props.closeModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Alterar Nome da Forma {this.props.shape?.name} - Tipo: {this.props.shape?.type}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="panel-body">
                                    <div className="form-group">
                                        <label>Forma: {this.props.shape?.name}</label>
                                        <input type="text" className="form-control" placeholder="Nome da Forma" value={this.props.shape?.name}
                                               onChange={(e) => this.props.setShapeName(e.target.value )}/>
                                    </div>

                                    <div className="form-group">
                                        <button className="btn btn-primary btn-block" onClick={(e)=>{
                                            this.props.updateShapeName( );
                                        }}>Alterar</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        )
    }
}

export default ModalChangeShapeName;
