import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './styles.css';
import {Modal} from "react-bootstrap";
import Loader from "../Loader";
import ShimmerLoaderCards from "./ShimmerLoaderCards";
import ShimmerLoaderAnalisesCards from "./ShimmerLoaderAnalisesCards";

class GrupoViabilidadeAnalises extends Component {

    constructor(props) {
        super(props);
        const uuid = route().params.grupo;
        this.state = {
            grupo_uuid: uuid,
            showModalMembers: false,
            showModalDeleteGroup: false,
            showModalDeleteAnalise: false,
            analises: [],
            loading: false,
            password: '',
            errors: [],
            selectedAnalise: null,
        };
    }

    setLoading = (value) => {
        this.setState({
            loading: value
        });

    }

    componentDidMount = async () => {
        this.setLoading(true);
        await this.getGroup();

        this.setLoading(false);

    }

    closeModal = () => {
        this.setState({
            showModalMembers: false
        });
    }

    closeModalDeleteGroup = () => {
        this.setState({
            showModalDeleteGroup: false
        });
    }

    closeModalDeleteAnalise = () => {
        this.setState({
            showModalDeleteAnalise: false
        });
    }

    getGroup = async () => {

        let response = await axios.get(route('grupos.show', this.state.grupo_uuid));

        this.setState({
            grupo: response.data,
            analises: response.data.analises
        });
    }

    removerGrupo = async () => {
        try{

            this.setLoading(true);


            let response = await axios.post(route('grupos.destroy', this.state.grupo_uuid), {
                _method: "DELETE",
                password: this.state.password
            });

            this.closeModalDeleteGroup();

            if (response.status === 204) {
                window.location.href = route('web.viabilidade.index');
            }


        }catch (error) {
            if (error.response && error.response.status === 422) {
                this.setState({
                    errors: error.response.data.errors
                });
            }
            this.setLoading(false);
        }finally {
            this.setLoading(false);
        }


    }

    removerAnalise = async()=>{
        try{
            this.setLoading(true);

            let response = await axios.post(route('viabilidade.deletar_analise', this.state.selectedAnalise.uuid), {
                _method: "DELETE",
                password: this.state.password
            });

            this.closeModalDeleteAnalise();

            let analises = this.state.analises.filter((analise) => {
                return analise.uuid !== this.state.selectedAnalise.uuid;
            });

            this.setState({
                ...this.state,
                analises: [...analises]
            });


        }catch (error){
            if (error.response && error.response.status === 422) {
                this.setState({
                    errors: error.response.data.errors
                });
            }

        }finally {
            this.setLoading(false);
        }
    }

    render() {
        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-9 col-sm-9 col-lg-9">
                            <div className="card no-border-radius">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-12 col-md-9 col-sm-12 col-lg-9">
                                            <h5
                                                className="analises-main-title"
                                            >Análise de Viabilidade Plano Empresário</h5>
                                        </div>

                                        <div className="col-12 col-md-3 col-sm-12 col-lg-3">
                                            <a href={route('grupo.analises.create', this.state.grupo_uuid)}
                                               className="btn btn-success btn-sm btn-block float-right">
                                                <i className="fa fa-plus"></i>
                                                Criar Análise
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="row">

                                        {this.state.loading && (
                                            <ShimmerLoaderAnalisesCards number_of_cards={6} />
                                        )}

                                        {this.state.analises.map((analise, index) => {
                                            return (
                                                <div className="col-md-6 col-sm-12 col-12" key={index}
                                                     style={{backgroundColor: '#green'}}>
                                                    <div className="card card-analise no-border-radius">
                                                        <div className="card-header card-analise">
                                                            <h6 className="card-title-analise">{analise.nome}</h6>
                                                        </div>
                                                        <div className="card-body">
                                                            <p style={{color: "white"}}>
                                                                <strong>Atualizado em:</strong> &nbsp; {analise.updated_at_formatted} <br />
                                                                <strong> Criado por</strong> {analise.created_by?.name} <br />
                                                            </p>

                                                            <div className="d-flex justify-content-around">
                                                                <div style={{width: "50%"}}>

                                                                </div>
                                                                <div style={{width: "50%", textAlign: "right"}}>
                                                                    <button style={{padding: "7px 5px"}}
                                                                            onClick={() => this.setState({
                                                                                showModalDeleteAnalise: true,
                                                                                selectedAnalise: analise
                                                                            })}
                                                                       className="btn btn-sm btn-danger">
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                                <a
                                                                    style={{marginLeft: "5px", padding: "7px 5px"}}
                                                                    href={route('grupo.analises.create', {
                                                                        grupo: this.state.grupo_uuid,
                                                                        analise: analise.uuid
                                                                    })}
                                                                    className="btn btn-sm btn-success">
                                                                    <i className="fa fa-edit"></i>

                                                                </a>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-sm-3 col-lg-3">
                            <div className="card no-border-radius">
                                <div className="card-body">
                                    <strong>Grupo: </strong> {this.state.grupo?.nome} <br/>
                                    <strong>Criado por:</strong> {this.state.grupo?.owner?.name}
                                    <hr/>
                                    <button className="btn btn-sm btn-block btn-primary"
                                            onClick={() => this.setState({showModalMembers: true})}>
                                        <i className={"fa fa-users"}> </i> &nbsp;
                                        Ver membros
                                    </button>

                                    <a className="btn btn-sm btn-block btn-success"
                                       href={route('grupo.edit', this.state.grupo_uuid)}
                                    >
                                        <i className={"fa fa-edit"}> </i> &nbsp;
                                        Editar Grupo
                                    </a>

                                    <button className={"btn btn-sm btn-block btn-danger"}
                                            onClick={() => this.setState({
                                                showModalDeleteGroup: true
                                            })}
                                    >
                                        <i className={"fa fa-trash"}> </i> &nbsp;
                                        Remover Grupo
                                    </button>

                                    <a className="btn btn-sm btn-block btn-success"
                                       href={route('grupo.create')}
                                    >
                                        <i className={"fa fa-plus"}> </i> &nbsp;
                                        Criar Grupo
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div style={{zIndex: '9999'}}>
                    <Modal show={this.state.showModalMembers} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Membros do Grupo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="panel-body">
                                <ul className="list-group">
                                    {this.state.grupo?.members?.map((user, index) => {
                                            return (
                                                <li key={index} className="list-group-item">
                                                    {user.name}
                                                </li>
                                            )
                                        }
                                    )}
                                </ul>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.showModalDeleteAnalise} onHide={this.closeModalDeleteAnalise}>
                        <Modal.Header closeButton>
                        <Modal.Title>Remover Análise</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="panel-body">
                                <div className="alert alert-danger">
                                    <h4>Tem Certeza que deseja remover a Análise?</h4>
                                    <p>Essa operação não poderá ser desfeita.</p>
                                </div>

                                <div className="form-group" style={{padding: '5px'}}>
                                    <label htmlFor="">Digite sua senha para confirmar</label>
                                    <input type="password" onChange={(e) => {
                                        this.setState({
                                            password: e.target.value
                                        });
                                    }} className="form-control"/>
                                    {this.state.errors['password'] && (
                                        <span className="invalid-feedback" style={{display: "block"}}>
                                            {this.state.errors['password']}
                                        </span>

                                    )}
                                </div>

                                <div className="d-flex justify-content-center">
                                    <div style={{width: "70%", padding: "5px"}}>
                                        <button className={"btn btn-sm btn-block btn-success"}
                                                onClick={() => this.setState(
                                                    {showModalDeleteAnalise: false
                                                    })}
                                        >
                                            Cancelar
                                        </button>
                                    </div>

                                    <div style={{width: "30%", padding: "5px"}}>
                                        <button className={"btn btn-sm btn-block btn-danger"}
                                                onClick={this.removerAnalise}
                                        >
                                            Remover
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.showModalDeleteGroup} onHide={this.closeModalDeleteGroup}>
                        <Modal.Header closeButton>
                            <Modal.Title>Remover Grupo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="panel-body">
                                <div className="alert alert-danger">
                                    <h4>Tem Certeza que deseja remover o grupo?</h4>
                                    <p>Todos os dados vinculados ao grupo também serão removidos incluindo as
                                        análises.</p>
                                </div>

                                <div className="form-group" style={{padding: '5px'}}>
                                    <label htmlFor="">Digite sua senha para confirmar</label>
                                    <input type="password" onChange={(e) => {
                                        this.setState({
                                            password: e.target.value
                                        });
                                    }} className="form-control"/>
                                    {this.state.errors['password'] && (
                                        <span className="invalid-feedback" style={{display: "block"}}>
                                            {this.state.errors['password']}
                                        </span>

                                    )}
                                </div>

                                <div className="d-flex justify-content-center">
                                    <div style={{width: "70%", padding: "5px"}}>
                                        <button className={"btn btn-sm btn-block btn-success"}
                                                onClick={() => this.setState({showModalDeleteGroup: false})}
                                        >
                                            Cancelar
                                        </button>
                                    </div>

                                    <div style={{width: "30%", padding: "5px"}}>
                                        <button className={"btn btn-sm btn-block btn-danger"}
                                                onClick={this.removerGrupo}
                                        >
                                            Remover
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        );
    }

}

if (document.getElementById('js-viabilidade-grupo')) {
    ReactDOM.render(<GrupoViabilidadeAnalises/>, document.getElementById('js-viabilidade-grupo'));
}
