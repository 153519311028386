import React, { Component } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';

class CustomMarker extends Component {
    state = {
        showInfoWindow: false
    };
    handleMouseOver = e => {
        this.setState({
            showInfoWindow: true
        });
    };
    handleMouseExit = e => {
        this.setState({
            showInfoWindow: false
        });
    };
    render() {
        const { showInfoWindow } = this.state;
        const { info, empreendimento } = this.props;
        return (
            <Marker position={this.props.position}
                    icon={this.props.icon}
                    onClick={this.props.onClick}
                    onMouseOver=
                        {this.handleMouseOver} onMouseOut={this.handleMouseExit}>
                {showInfoWindow && (
                    <InfoWindow>
                        <div>
                            <h5>{empreendimento.nome}</h5>
                            <small>
                                <strong>Endereço: </strong>{empreendimento.endereco} {empreendimento.numero} <br />
                                <strong>Bairro: </strong>{empreendimento.bairro}
                            </small>
                        </div>
                    </InfoWindow>
                )}
            </Marker>
        );
    }
}
export default CustomMarker;
