import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button } from 'react-bootstrap';
import Loader from "./Loader";
import IntlCurrencyInput from "react-intl-currency-input"

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};


class HistoricUpdateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            historic : [],
            errors : [],
            showModalErrors : false,
            loading : false
         }
    }

    componentDidMount = async ()=> {

        this.setState({
            ...this.state,
            loading: true
        })

        await this.loadHistoric();


        this.setState({
            ...this.state,
            loading: false
        });

    }

    handleSalvar= async ()=>{
        await this.setState({
            ...this.state,
            loadding: true
        });

        await this.checkConsistency();

        if( this.state.errors.length === 0 ){



            let unidades = [];
            let distrato = [];
            let venda = [];
            let estoque = [];
            let preco = [];
            let base_id = [];
            let period = [];

            this.state.historic.map( ( base )=>{
                unidades.push( base.unidades || 0 );
                distrato.push( base.distrato || 0 );
                venda.push( base.venda || 0 );
                estoque.push( base.estoque );
                preco.push( base.preco );
                base_id.push( base.base_id );
                period.push( base.period );
            });

            let payload = {
                unidades,
                distrato,
                venda,
                estoque,
                preco,
                base_id,
                period
            };

            try {
                let response = await axios.post( route('update.historic',{
                    id : window.building_id
                }), payload );

                this.setState({
                    ...this.state,
                    loadding: false
                });

                if( response.status === 201 ){
                    window.location = route('building.edit', { building : window.building_id });
                }

            }catch ( e )
            {
                this.setState({
                    ...this.state,
                    loadding: false
                });

                console.log( e );
            }

            console.log( response );

        }

        this.setState({
            ...this.state,
            loadding: false
        });
    }

    loadHistoric = async() =>{

        let usarIvv = false;
        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);
        // debugger;
        console.log( urlParams );
        let usarIvvParam = urlParams.get('usar_ivv');

        if(  usarIvvParam !== undefined && usarIvvParam == "true" ){
            usarIvv = urlParams.get('usar_ivv');

            console.log('-----------------------------');
            console.log( urlParams.get('usar_ivv') );
            console.log('-----------------------------');
        }





        try{
            let rota = route('building.historic', window.building_id );

            if( usarIvv ){
                console.log('Tem que usar IVV')
                rota = route('building.historic', window.building_id ) + "?usar_ivv=true"
            }

            let historic = await axios.get( rota );
            console.log( rota );

            await this.setState({
                historic : historic.data
            });

        }catch(e){
            console.log(e);
        }

    }

    updateVenda = async (index, value ) => {

        let estoques = this.state.historic;
        let estoque = estoques[index];

        estoque.venda = parseInt(value) || 0;

        //alert( estoque.estoque );
        estoques[index] = estoque;

        await this.setState({
            historic: estoques
        });

        this.propagateUpdate( estoque.tipologia_id );
    };

    updateDistrato = async (index, value) => {
        let estoques = this.state.historic;
        let estoque = estoques[index];

        estoque.distrato = parseInt(value) || 0;

        //alert( estoque.estoque );
        estoques[index] = estoque;

        await this.setState({
            ...this.state,
            historic: estoques
        });

        this.propagateUpdate( estoque.tipologia_id );
    };

    propagateUpdate = async ( tipologiaId ) => {
        let estoques = this.state.historic;
        let initialIndex = 0;

        if( estoques[0].tipologia_id !== tipologiaId ){
            initialIndex = estoques.findIndex( function ( e ){
                return e.tipologia_id === tipologiaId
            });
        }

        for ( let x = initialIndex; x < estoques.length; x++) {
            let estoqueAnterior = estoques[x].unidades;

            if (x > initialIndex ) {
                estoqueAnterior = estoques[x - 1].estoque;
            }

            let currentEstoqueObject = estoques[x];
            if( currentEstoqueObject.tipologia_id === tipologiaId ){
                let calculatedEstoque =  estoqueAnterior - estoques[x].venda + estoques[x].distrato;
                currentEstoqueObject.estoque = calculatedEstoque;

                estoques[x] = currentEstoqueObject;
            }
        }

        await this.setState({
            ...this.state,
            historic: estoques
        });

    };

    checkConsistency = async ()=>{
        let historic = this.state.historic;
        let errors = [];

        for( let x = 0; x < historic.length; x++ ){

            let current = historic[x];

            let previous = current;
            if( x > 0 && historic[x-1].tipologia_id === current.tipologia_id ){
                previous = historic[x-1];
                let previousPrice = Number(previous.preco);

                let precoMaior = previousPrice * 1.30;
                let precoMenor = previousPrice * 0.70;
                if( Number(current.preco) >  precoMaior || Number(current.preco) < precoMenor ){
                    errors.push(`Período ${current.period} da Tipologia número ${current.tipologia_id} tem alteração de valor acima de 30%`)
                }

                console.log( x ,precoMaior, precoMenor );
            }








            if( current.estoque < 0 ){
                console.log( 'Venda Negativa');
                errors.push(`Período ${current.period} da Tipologia número ${current.tipologia_id} tem estoque negativo`)
            }

            if( current.estoque > current.unidades ){
                console.log( 'Venda Negativa');
                errors.push(`Período ${current.period} da Tipologia número ${current.tipologia_id} tem estoque maior que o número de unidades`)
            }

            if( current.venda < 0 ){
                console.log( 'Venda Negativa');
                errors.push(`Período ${current.period} da Tipologia número ${current.tipologia_id} tem venda negativo`)
            }

            if( current.venda > current.unidades ){
                console.log( 'Venda maior que unidades');
                errors.push(`Período ${current.period} da Tipologia número ${current.tipologia_id} tem vendas maior que o número de Unidades`)
            }

            if( current.distrato > current.unidades ){
                console.log( 'Distrato maior que unidades');
                errors.push(`Período ${current.period} da Tipologia número ${current.tipologia_id} tem distratos maior que o número de Unidades`)
            }

        }

        this.setState({
            ...this.state,
            errors: errors,
            showModalErrors : errors.length > 0
        })
    }

    updateValor = async (index, value ) => {
        let estoques = this.state.historic;
        let estoque = estoques[index];

        estoque.preco = parseFloat(value) || 0;

        //alert( estoque.estoque );
        estoques[index] = estoque;

        await this.setState({
            historic: estoques
        });

        this.propagateUpdate( estoque.tipologia_id );
    }

    handleCloseModalInfo = async () => {
        await this.setState({
            ...this.state,
            showModalErrors: false
        });
        return this.state.errors
    }

    handleCloseModalInfoAndUpdate = async () => {
        await this.setState({
            ...this.state,
            showModalErrors: false,
            loading: true
        });

        let unidades = [];
        let distrato = [];
        let venda = [];
        let estoque = [];
        let preco = [];
        let base_id = [];
        let period = [];

        this.state.historic.map((base) => {
            unidades.push(base.unidades || 0);
            distrato.push(base.distrato || 0);
            venda.push(base.venda || 0);
            estoque.push(base.estoque);
            preco.push(base.preco);
            base_id.push(base.base_id);
            period.push(base.period);
        });

        let payload = {
            unidades,
            distrato,
            venda,
            estoque,
            preco,
            base_id,
            period
        };

        try {
            let response = await axios.post(route('update.historic', {
                id: window.building_id
            }), payload);

            this.setState({
                ...this.state,
                loading: false
            });

            if (response.status === 201) {
                window.location = route('building.edit', { building: window.building_id });
            }

        } catch (e) {
            this.setState({
                ...this.state,
                loading: false
            });

            console.log(e);
        }

        console.log(response);

        this.setState({
            ...this.state,
            loading: false
        });
    }

    render() {
        if( this.state.loading){
            return  (
                <>
                    <div style={{position: "fixed", top: 0, right: 0, display: "flex", justifyContent : "center", alignItems: "center", height: "100%",width: "100%"}}>
                        <img src="/img/loader.gif" />
                    </div>
                </>
            )
        }
        return (
            <>
                <div>

                    <Modal show={this.state.showModalErrors} onHide={this.handleCloseModalInfo}
                           dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">Verifique as Incosistências</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ul className="list-group">
                                {this.state.errors.map( (error, index) => <li key={index} className="list-group-item list-group-item-danger">{error}</li>)}
                            </ul>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="btn-block" onClick={this.handleCloseModalInfo}>
                                Fechar
                            </Button>
                            <Button variant="danger" className="btn-block" onClick={this.handleCloseModalInfoAndUpdate}>
                                Salvar mesmo assim.
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <table className="table table-sm table-striped">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Área</th>
                            <th>Unidades</th>
                            <th>Período</th>
                            <th>Preço</th>
                            <th>Venda</th>
                            <th>Distrato</th>
                            <th>Estoque</th>
                        </tr>
                        </thead>

                        <tbody>
                        {this.state.historic.map(( base, index ) => {

                            let tipologiaLabel = `#${base.tipologia_id} - Tipologia ${base.type} de ${base.area} m2`;

                            if( base.dormitorios ){
                                tipologiaLabel += ` - ${base.dormitorios}`
                            }

                            let estoqueValidationClass = 'is-valid';
                            if( base.estoque < 0 || base.estoque > base.unidades ){
                                estoqueValidationClass = 'is-invalid';
                            }

                            let vendaValidationClass = 'is-valid';
                            if( base.venda < 0 || base.venda > base.unidades ){
                                vendaValidationClass = 'is-invalid';
                            }

                            let distratoValidationClass = 'is-valid';
                            if( base.distrato < 0 || base.distrato > base.unidades ){
                                distratoValidationClass = 'is-invalid';
                            }
                            let exibirCabecalho = false;

                            if( index == 0 || base.tipologia_id !== this.state.historic[index - 1 ].tipologia_id ){
                                exibirCabecalho = true;
                            }


                            return (
                                <>

                                    {exibirCabecalho &&
                                    <tr>
                                        <td style={{ display : 'table-cell', backgroundColor:'green'}} colSpan={8}><h3 className="text-center" style={{color:'#ffffff'}}>{tipologiaLabel}</h3></td>
                                    </tr>}

                                    <tr key={index}>
                                        <td>
                                            {tipologiaLabel}
                                        </td>
                                        <td>
                                            {base.area}
                                        </td>
                                        <td>
                                            {base.unidades}
                                        </td>
                                        <td>
                                            {base.period_formatted}
                                        </td>
                                        <td>
                                            <IntlCurrencyInput
                                                currency="BRL"
                                                config={currencyConfig}
                                                onChange={ (event, value, maskedValue)=>{
                                                    this.updateValor( index, value );
                                                }}
                                                style={{ padding: '3px' }}
                                                value={base.preco}
                                                className="valor form-control"

                                            />
                                           {/* <input className="form-control form-control-sm"
                                                   type="text"
                                                   onChange={(e)=>{
                                                       console.log( e );
                                                   }}
                                                   value={base.preco}/>*/}
                                        </td>
                                        <td>
                                            <input className={['form-control', 'form-control-sm', vendaValidationClass ].join(' ')}
                                                   type="number"
                                                   onChange={(e) => {
                                                       let value = e.target.value;
                                                       this.updateVenda(index, value );
                                                   }}
                                                   value={base.venda}/>
                                        </td>
                                        <td>
                                            <input className={['form-control', 'form-control-sm', distratoValidationClass ].join(' ')}
                                                   type="number"
                                                   onChange={(e) => {
                                                       let value = e.target.value;
                                                       this.updateDistrato(index, value);
                                                   }}
                                                   value={base.distrato || 0 }/>
                                        </td>
                                        <td>
                                            <input className={['form-control', 'form-control-sm', estoqueValidationClass ].join(' ')}
                                                   type="number" readOnly
                                                   onChange={( (e)=>{

                                                   })}
                                                   value={base.estoque} />

                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                        </tbody>
                    </table>
                    <button className="btn btn-primary btn-block" onClick={this.handleSalvar}>
                        Salvar &nbsp;
                        {this.state.loading && <>
                            <i className="fas fa-spinner fa-pulse" style={{color: 'green'}}></i>
                        </>}
                    </button>
                </div>
            </>
        );
    }
}

export default HistoricUpdateForm;


if (document.getElementById('historic-form')) {
    ReactDOM.render(<HistoricUpdateForm />, document.getElementById('historic-form'));
}
