import React, { Component } from "react";

class Card extends Component {
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <div className="card card-stats">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-attach-87 icon-color-lime"></i>
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="numbers">
                                                <p className="card-category">
                                                    Empreendimentos Ativos
                                                </p>
                                                <h4 className="card-title"> {this.props.data.data.countMediaQuery}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer ">
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-refresh"></i> Número
                                        total
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="card card-stats">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-attach-87 icon-color-lime"></i>
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="numbers">
                                                <p className="card-category">
                                                    Anexos no Período
                                                </p>
                                                <h4 className="card-title"> {this.props.data.data.countMediaQueryAtachments}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer ">
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-refresh"></i> Número
                                        total
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="card card-stats">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-attach-87 icon-color-lime"></i>
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="numbers">
                                                <p className="card-category">
                                                    Evidências
                                                </p>
                                                <h4 className="card-title"> {this.props.data.data.countEvidence}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer ">
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-refresh"></i> Número
                                        total
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="card card-stats">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-attach-87 icon-color-lime"></i>
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="numbers">
                                                <p className="card-category">
                                                    Memoriais Descritivos
                                                </p>
                                                <h4 className="card-title"> {this.props.data.data.countMemorial}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer ">
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-refresh"></i> Número
                                        total
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="card card-stats">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-attach-87 icon-color-lime"></i>
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="numbers">
                                                <p className="card-category">
                                                    Material
                                                </p>
                                                <h4 className="card-title"> {this.props.data.data.countMaterial}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer ">
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-refresh"></i> Número
                                        total
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="card card-stats">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-attach-87 icon-color-lime"></i>
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="numbers">
                                                <p className="card-category">
                                                    Tabelas
                                                </p>
                                                <h4 className="card-title"> {this.props.data.data.countTabela}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer ">
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-refresh"></i> Número
                                        total
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Card;
