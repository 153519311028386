import {React, Component}  from "react";
import { v4 as uuidv4 } from 'uuid';
import { InputNumber } from 'primereact/inputnumber';

class FormAdicionarUnidade extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nome: '',
            area_privativa: 0.00,
            quantidade: 0,
            uuid: '',
            preco: 0.00,
            preco_metro: 0.00,
            error: null
        }


    }

    componentDidMount() {

    }

    formIsValid = ()=>{
        if( this.state.nome === '' || this.state.area_privativa === '' || this.state.quantidade === '' ||  this.state.preco_metro === 0.00 ){
            return false;
        }

        //check negative values
        if( this.state.area_privativa < 0 || this.state.quantidade < 0 || this.state.preco_metro < 0.00 ){
            return false;
        }

        return true;
    }

    checkValid = () => {
        if( !this.formIsValid() ){
            this.setState({
                error: 'Dados Inválidos, preencha todos os campos corretamente.'
            });
        }
    }

    onAddUnit = async () => {

        await this.setState({
            error: null
        });


        let isValid = this.formIsValid();

        if( !isValid ){
            this.setState({
                error: 'Preencha todos os campos para adicionar uma unidade'
            });

            return;
        }


        this.props.onAddUnit({
            nome: this.state.nome,
            area_privativa: this.state.area_privativa,
            preco_metro: this.state.preco_metro,
            quantidade: this.state.quantidade,
            uuid: this.state.uuid ? this.state.uuid : uuidv4()
        });

        this.setState({
            nome: '',
            area_privativa: 0.00,
            quantidade: 0,
            uuid: '',
            preco: 0.00,
            preco_metro: 0.00
        });
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    static getDerivedStateFromProps(props, state) {
        if( props.selectedUnidade && props.selectedUnidade.uuid !== state.uuid ){
            return {
                nome: props.selectedUnidade.nome,
                area_privativa: props.selectedUnidade.area_privativa,
                preco: props.selectedUnidade.preco,
                preco_metro: props.selectedUnidade.preco_metro,
                quantidade: props.selectedUnidade.quantidade,
                uuid: props.selectedUnidade.uuid
            };
        }

        return null;
    }



    render() {
        return (
            <div className="add_unit">
                <hr />
                {this.state.error && <div className="alert alert-danger alert-dismissible">{this.state.error}</div>}
                <div className="d-flex justify-content-between align-items-center align-content-center" style={{marginBottom: '2px'}}>
                    <div>
                        <label htmlFor="name">Tipo de Unidade</label>
                        <input type="text"
                               onChange={this.onChange}
                               value={this.state.nome}
                               className="form-control"
                               id="tipo_unidade" name="nome"/>
                    </div>
                    <div className="ml-2">
                        <label htmlFor="name">Área Privativa</label>

                        <InputNumber
                            value={this.state.area_privativa}
                            onValueChange={(e)=>{
                                this.setState({
                                    ...this.state,
                                    area_privativa: e.value,
                                    preco: e.value * this.state.preco_metro
                                });
                            }}
                            inputClassName="form-control"
                            name="area_privativa"
                            id="area_privativa"
                            locale="pt-BR" minFractionDigits={2} />
                    </div>
                    <div className="ml-2">
                        <label htmlFor="name">Preço Metro</label>

                        <InputNumber
                            value={this.state.preco_metro}
                            onValueChange={(e)=>{
                                this.setState({
                                    ...this.state,
                                    preco_metro: e.value,
                                    preco: e.value * this.state.area_privativa
                                });
                            }}
                            inputClassName="form-control"
                            name="preco_metro"
                            id="preco_metro"
                            locale="pt-BR" minFractionDigits={2} />

                    </div>
                    <div className="ml-2">
                        <label htmlFor="name">Unidades</label>
                        <input type="number"
                               onChange={this.onChange}
                                 value={this.state.quantidade}
                               className="form-control"
                               id="numero_unidades" name="quantidade"/>
                    </div>
                    <div className="ml-2" style={{paddingTop: '26px'}}>
                        <button className="btn btn-success btn-sm" onClick={this.onAddUnit}>
                            <i className="fa fa-check"></i>
                        </button>
                    </div>
                </div>
                <hr />
            </div>

        );
    }
}

export default FormAdicionarUnidade;
