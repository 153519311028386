import React, {Component} from "react";
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export default class TablePerPattern extends Component {

    constructor(props) {
        super(props);


        this.state = {
            loading: false,
            labels: {
                "1_ano": "até 1 ano",
                "2_ano": "até 2 anos",
                "3_ano": "até 3 anos",
                "4_ano": "até 4 anos",
                "5_ano": "até 5+ anos",
            },
            info: {
                "Planta": 'Até 6 meses na planta',
                "Construção": 'De 7 a 36 meses em construção',
                "Pronto": 'A partir de 37 meses, pronto',
            },
            data: {
                "count_total_buildings": null,
                "count_buildings_by_pattern": [],
                "count_units_by_pattern": [],
                "count_bathrooms_by_pattern": [],
                "count_elevators_by_pattern": [],
                "supplies_list": [],
                "areas": [],
                "supplies_list_per_stage": {
                    "Planta": [],
                    "Construção": [],
                    "Pronto": [],
                },
                "supplies_list_per_time": {
                    "1_ano": {
                        "especial": null,
                        "economico": null,
                        "standard": null,
                        "medio": null,
                        "alto": null,
                        "luxo": null,
                        "super_luxo": null,
                        "total": null
                    },
                    "2_ano": {
                        "especial": null,
                        "economico": null,
                        "standard": null,
                        "medio": null,
                        "alto": null,
                        "luxo": null,
                        "super_luxo": null,
                        "total": null
                    },
                    "3_ano": {
                        "especial": null,
                        "economico": null,
                        "standard": null,
                        "medio": null,
                        "alto": null,
                        "luxo": null,
                        "super_luxo": null,
                        "total": null
                    },
                    "4_ano": {
                        "especial": null,
                        "economico": null,
                        "standard": null,
                        "medio": null,
                        "alto": null,
                        "luxo": null,
                        "super_luxo": null,
                        "total": null
                    },
                    "5_ano": {
                        "especial": null,
                        "economico": null,
                        "standard": null,
                        "medio": null,
                        "alto": null,
                        "luxo": null,
                        "super_luxo": null,
                        "total": null
                    },
                    "description": null,
                    "measure_unit": null
                }
            },

            padroesAndSlugs: [
                {
                    padrao: "Alto",
                    slug: "alto"
                },
                {
                    padrao: "Especial",
                    slug: "especial"
                },
                {
                    padrao: "Econômico",
                    slug: "economico"
                },
                {
                    padrao: "Standard",
                    slug: "standard"
                },
                {
                    padrao: "Médio",
                    slug: "medio"
                },
                {
                    padrao: "Luxo",
                    slug: "luxo"
                },
                {
                    padrao: "Super Luxo",
                    slug: "super_luxo"
                }

            ]
        }


    }

    getOnlyBuildingsIds = async () =>{
        let set = new Set();

        for (let i = 0; i < this.props.grandParentState.empreendimentos.length; i++) {
            set.add(this.props.grandParentState.empreendimentos[i].id_legado)
        }

        console.table( [...set]);

        return [...set]
    }

    componentDidMount = async () => {
        this.setState({
            ...this.state,
            loading: true,
        })


        let payload = await this.preparePayload(this.props.payload);



        try {
            let response = ''
            if (this.props.type.label === 'Por padrão') {
                response = await axios.post(route('web.supplies.reportSuppliesByPattern'), payload );
            }
            if (this.props.type.label === 'Por fase de obra') {
                response = await axios.post(route('web.supplies.reportSuppliesByStage'), payload  );
            }
            if (this.props.type.label === 'Por Incorporadora') {
                response = await axios.post(route('web.supplies.reportSuppliesByIncorporator'), payload );
            }

            if (response.status == 200) {
                console.log(response.data);

                await this.setState({
                    ...this.state,
                    data: response.data
                })
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({
                ...this.state,
                loading: false,
            })
        }
    }

    async preparePayload(payload) {
        let supplies = [];
        let cities = [];
        let neighborhoods = [];
        let pattern = [];
        let construction_status = [];
        let incorporators = [];

        payload.cities.forEach((item) => {
            cities.push(item.label);
        });

        payload.status = ['Ativo'];
        payload.buildings = [];

        payload.supplies.forEach((item) => {
            supplies.push(item.label);
        });

        payload.neighborhoods.forEach((item) => {
            neighborhoods.push(item.label);
        });

        payload.incorporators.forEach((item) => {
            incorporators.push(item.value);
        });

        if( Object.keys(payload.pattern).length > 0 ){
            pattern.push(payload.pattern.value);
        }

        if( Object.keys(payload.construction_status).length > 0 ){
            construction_status.push(payload.construction_status.value);
        }

        //caso tenha formas desenhadas no mapa usa os empreendimentos em exibição como filtro
        if( payload.there_are_shapes === true ){
            console.log("*********************************************************************************** antes")
            console.log(payload.buildings)
            payload.buildings = await this.getOnlyBuildingsIds();
            console.log(payload.buildings)
            console.log("***********************************************************************************")
        }else{
            payload.buildings = [];
        }

        payload.neighborhoods = neighborhoods;
        payload.pattern = pattern;
        payload.construction_status = construction_status;
        payload.supplies = supplies;
        payload.cities = cities;
        payload.incorporators = incorporators;

        return payload;
    }

    render() {

        let totalUnidades = 0;
        let totalBanheiros = 0;
        let totalElevadores = 0;
        let privateAreaTotal = 0;
        let equivalentAreaTotal = 0;
        this.state.data.count_units_by_pattern.forEach((item) => {
            totalUnidades += parseInt(item.unidades);
        });

        this.state.data.count_bathrooms_by_pattern.forEach((item) => {
            totalBanheiros += parseInt(item.count_bathrooms);
        });

        this.state.data.areas.forEach((item) => {
            privateAreaTotal += parseFloat(item.building_area);
            equivalentAreaTotal += parseFloat(item.building_area_equivalent);
        });

        this.state.data.count_elevators_by_pattern.forEach((item) => {
            totalElevadores += parseInt(item.elevators);
        });


        return (
            <>

                <div style={styles.parent}>

                    {this.state.loading && <>
                        <div style={styles.loader}>
                            <div style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                height: '100%',
                                width: '100%',
                                position: 'absolute',
                            }}>
                                <Loader
                                    type="TailSpin"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                    timeout={30000} // 30 seconds
                                />
                            </div>
                        </div>
                    </>}

                    <table className="table table-striped">
                        <thead>


                        <tr style={styles.tr_header}>
                            <td colSpan={2}></td>
                            <td>Total</td>
                            {this.state.padroesAndSlugs.map((padrao, index) => {
                                return <td style={styles.align_right} key={index}>{padrao.padrao}</td>
                            })}
                        </tr>

                        <tr style={styles.title}>
                            <td colSpan={10}>Empreendimentos</td>
                        </tr>

                        <tr>
                            <td>Empreendimentos</td>
                            <td>Un</td>
                            <td style={styles.align_right}>{this.state.data.count_total_buildings}</td>
                            {this.state.padroesAndSlugs.map((padrao, index) => {
                                let countBuildingsForPattern = this.state.data.count_buildings_by_pattern.find((item) => {
                                    return item.padrao_empreendimento === padrao.padrao
                                });

                                let count = countBuildingsForPattern?.buildings_count || 0;
                                return <td style={styles.align_right} key={`cb_${index}_`}>{Number(count).toLocaleString('pt-BR')}</td>
                            })}
                        </tr>

                        <tr>
                            <td>Unidades</td>
                            <td>Un</td>
                            <td style={styles.align_right}>{Number(totalUnidades).toLocaleString('pt-BR')}</td>
                            {this.state.padroesAndSlugs.map((padrao, index) => {
                                let countBuildingsForPattern = this.state.data.count_units_by_pattern.find((item) => {
                                    return item.padrao_empreendimento === padrao.padrao
                                });

                                let count = countBuildingsForPattern?.unidades || 0;
                                return <td style={styles.align_right} key={`cb_${index}_`}>{Number( count ).toLocaleString('pt-BR')}</td>
                            })}
                        </tr>
                        <tr>
                            <td>Elevadores</td>
                            <td>Un</td>
                            <td style={styles.align_right}>{Number(totalElevadores).toLocaleString('pt-BR')}</td>
                            {this.state.padroesAndSlugs.map((padrao, index) => {
                                let countBuildingsForPattern = this.state.data.count_elevators_by_pattern.find((item) => {
                                    return item.padrao_empreendimento === padrao.padrao
                                });

                                let count = countBuildingsForPattern?.elevators || 0;
                                return <td style={styles.align_right} key={`cb_${index}_`}>{Number( count ).toLocaleString('pt-BR')}</td>
                            })}
                        </tr>

                        <tr>
                            <td>Banheiros</td>
                            <td>Un</td>
                            <td style={styles.align_right}>{Number(totalBanheiros).toLocaleString('pt-BR')}</td>
                            {this.state.padroesAndSlugs.map((padrao, index) => {
                                let countBathroom = this.state.data.count_bathrooms_by_pattern.find((item) => {
                                    return item.padrao_empreendimento === padrao.padrao
                                });

                                let count = countBathroom?.count_bathrooms || 0;
                                return <td style={styles.align_right} key={`cb_${index}_`}>{Number( count ).toLocaleString('pt-BR')}</td>
                            })}
                        </tr>

                        <tr style={styles.title}>
                            <td colSpan={10}>Metragens</td>
                        </tr>

                        <tr>
                            <td>Área Privativa</td>
                            <td>m2</td>
                            <td style={styles.align_right}> {Math.round(privateAreaTotal).toLocaleString('pt-BR')}</td>

                            {this.state.padroesAndSlugs.map((padrao, index) => {
                                let countBuildingsForPattern = this.state.data.areas.find((item) => {
                                    return item.padrao_empreendimento === padrao.padrao
                                });

                                let sum = countBuildingsForPattern?.building_area || 0;
                                return <td style={styles.align_right}
                                    key={`cb_${index}_`}>{Math.round(sum).toLocaleString('pt-BR')}</td>
                            })}
                        </tr>
                        <tr>
                            <td>Área Total Equivalente</td>
                            <td>m2</td>
                            <td style={styles.align_right}>{Math.round(equivalentAreaTotal).toLocaleString('pt-BR')}</td>

                            {this.state.padroesAndSlugs.map((padrao, index) => {
                                let countBuildingsForPattern = this.state.data.areas.find((item) => {
                                    return item.padrao_empreendimento === padrao.padrao
                                });

                                let sum = countBuildingsForPattern?.building_area_equivalent || 0;
                                return <td style={styles.align_right}
                                    key={`cb_${index}_`}>{Math.round(sum).toLocaleString('pt-BR')}</td>
                            })}
                        </tr>

                        {this.props.type.label === 'Por fase de obra' &&
                            <>
                                <tr style={styles.title}>
                                    <td colSpan={10}>Fases da Obra</td>
                                </tr>

                                {["Planta", "Construção", "Pronto"].map((item, index) => {

                                    let total = 0;

                                    if (this.state.data.supplies_list_per_stage[item].length) {
                                        total = this.state.data.supplies_list_per_stage[item][0].total;
                                    }

                                    return (
                                        <tr key={`${item}_fase`}>
                                            <td style={ styles.info_td }>
                                                {item}
                                                <div className="info-mark" style={ styles.info_mark }>?</div>
                                                <div className="display-info">{this.state.info[item]}</div>
                                            </td>
                                            <td>{this.state.data.supplies_list_per_stage.measure_unit}</td>
                                            <td style={styles.align_right}>{Math.round(total).toLocaleString('pt-BR')}</td>

                                            {this.state.padroesAndSlugs.map((padrao, index) => {

                                                let data = 0;

                                                if (this.state.data.supplies_list_per_stage[item].length) {
                                                    data = this.state.data.supplies_list_per_stage[item][0][padrao.slug];
                                                }

                                                return <td style={styles.align_right} key={`slcb_${index}_`}>{Math.round(data).toLocaleString('pt-BR')}</td>
                                            })}
                                        </tr>
                                    );
                                })

                                }

                                <tr style={styles.title}>
                                    <td colSpan={10}>Tempo desde o Lançamento</td>
                                </tr>

                                {['1_ano', '2_ano', '3_ano', '4_ano', '5_ano'].map((item, index) => {

                                    let total = 0;

                                    if (this.state.data.supplies_list_per_time[item]) {
                                        total = this.state.data.supplies_list_per_time[item].total;
                                    }
                                    return (
                                        <tr key={`${item}_time`}>

                                            <td> {this.state.labels[item]}</td>
                                            <td>{this.state.data.supplies_list_per_time.measure_unit}</td>
                                            <td style={styles.align_right}>{Math.round(total).toLocaleString('pt-BR')}</td>

                                            {this.state.padroesAndSlugs.map((padrao, index) => {

                                                let data = 0;

                                                if (this.state.data.supplies_list_per_time[item]) {
                                                    data = this.state.data.supplies_list_per_time[item][padrao.slug];
                                                }

                                                return <td style={styles.align_right} key={`slcb_${index}_`}>{Math.round(data).toLocaleString('pt-BR')}</td>
                                            })}

                                        </tr>
                                    );
                                })}
                            </>
                        }

                        {this.props.type.label === 'Por Incorporadora' &&
                            <>
                                <tr style={styles.title}>
                                    <td colSpan={10}>Incorporadora</td>
                                </tr>
                                {this.state.data.supplies_list.map((item, index) => {
                                    return (
                                        <tr key={`supplies_${index}_`}>
                                            <td>{item.incorporadora}</td>
                                            <td>{item.measure_unit}</td>
                                            <td style={styles.align_right}>{Math.round(item.total).toLocaleString('pt-BR')}</td>
                                            {this.state.padroesAndSlugs.map((padrao, index) => {
                                                return <td style={styles.align_right}
                                                    key={`slcb_${index}_`}>{Math.round(item[padrao.slug]).toLocaleString('pt-BR')}</td>
                                            })}
                                        </tr>
                                    )
                                })}
                            </>
                        }

                        {this.props.type.label === 'Por padrão' &&
                            <>
                                <tr style={styles.title}>
                                    <td colSpan={10}>Materiais</td>
                                </tr>

                                {this.state.data.supplies_list.map((item, index) => {

                                    return (
                                        <tr key={`supplies_${index}_`}>
                                            <td>{item.description}</td>
                                            <td>{item.measure_unit}</td>
                                            <td style={styles.align_right}>{Math.round(item.total).toLocaleString('pt-BR')}</td>
                                            {this.state.padroesAndSlugs.map((padrao, index) => {
                                                return <td style={styles.align_right}
                                                    key={`slcb_${index}_`}>{Math.round(item[padrao.slug]).toLocaleString('pt-BR')}</td>
                                            })}
                                        </tr>
                                    )

                                })}
                            </>
                        }


                        </thead>

                    </table>
                </div>
            </>

        );
    }

}

const styles = {
    tr_header: {
        backgroundColor: "#2e3525",
        color: "#fff",
        fontWeight: "bold"
    },
    title: {
        backgroundColor: '#5b7537',
        color: '#fff',
        fontWeight: "bold"
    },
    parent : {
        position: 'relative',
        width: '100%',
        height: '100%'

    },
    loader : {
        position: 'absolute',
        display: 'flex',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        //transparent black background
        backgroundColor: 'rgba(0,0,0,0.5)',
        height: '100%',
        width: '100%',
    },
    align_right : {
        textAlign: 'right'
    },

    info_td:{
        display: 'flex',
    },

    info_mark: {
        display: 'flex',
        backgroundColor: '#5B7537',
        //width: 10,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 7,
        paddingRight: 7,
        borderRadius: 10,
        marginLeft: 10,
        color: '#FFFFFF',
        fontWeight: 'bold',
    }
}
