import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Axios from "axios";

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = async () => {};

    searchPortal = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/mapeamento/portal/json-options/" + inputQuery
            );
            console.log(response.data);
            return response.data;
        }
    };

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Portal</label>
                                <AsyncSelect
                                    cacheOptions
                                    isMulti
                                    value={this.props.portal}
                                    loadOptions={this.searchPortal}
                                    onChange={this.props.handlePortalChanged}
                                />
                            </div>

                            <div className="form-group col-6">
                                <label>Status</label>

                                <Select
                                    value={this.props.status}
                                    placeholder="Selecione"
                                    isMulti={false}
                                    onChange={this.props.handleStatusChanged}
                                    options={[
                                        { value: "", label: "Selecione" },
                                        { value: "Ativo", label: "Ativo" },
                                        { value: "Inativo", label: "Inativo" },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-success btn-block"
                                    onClick={this.props.handleSubmit}
                                >
                                    <i className="fas fa-search"></i> Buscar
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-block"
                                    style={{ backgroundColor: "#FFBB27" }}
                                    onClick={this.props.cleanState}
                                >
                                    <i className="far fa-file"></i> Limpar
                                </button>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
