import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import AsyncSelect from "react-select/async";
import Axios from "axios";

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            user_id: "",
            showModalUpdate: false,
            showModalNovoResponsavel: false,
            showReabrirMapeamento: false,
            showExtenderMapeamento: false,
            reabrirMapeamento: [],
            extenderMapeamento: [],
            responsavel: [],
            dataLimite: "",
        };
    }

    badgeFilter = (value) => {
        if (value == "Pendente") return "text-center badge badge-warning";
        if (value == "Concluído") return "text-center badge badge-success";
        if (value == "Em Andamento") return "text-center badge badge-info";
        if (value == "Inacabado") return "text-center badge badge-warning";
    };
    searchNewUser = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/mapeamento/users/json-options/" + inputQuery
            );
            return response.data;
        }
    };
    Update = async (e) => {
        await this.setState({
            ...this.state,
            id: e.id,
            user_id: e.user_id,
            showModalUpdate: e.user_id == null ? false : true,
        });

        if (this.state.user_id == null) {
            this.setNewResponsavel();
        }
    };
    handleNewUserChanged = async (e) => {
        await this.setState({
            ...this.state,
            user_id: e.value,
            responsavel: e,
        });
    };
    closeModalUpdate = async () => {
        await this.setState({
            ...this.state,
            showModalUpdate: false,
        });
    };
    closeModalNovoResponsavel = async () => {
        await this.setState({
            ...this.state,
            showModalNovoResponsavel: false,
        });
    };
    setNewResponsavel = async () => {
        await this.setState({
            ...this.state,
            showModalNovoResponsavel: true,
        });
    };
    setNewResponsavelSubmit = async () => {
        axios
            .post("mapeamento/update/" + this.state.id, {
                second_user_id: this.state.user_id,
                status: "Em Andamento",
            })
            .then((response) => {
                this.setState({
                    ...this.state,
                    responsavel: [],
                });
                this.closeModalUpdate();
                this.closeModalNovoResponsavel();
                this.props.getMapeamentos();
            });
    };

    verificaPrazo = (index) => {
        let date = new Date();
        let day = new Date(index).getUTCDate() + 1;
        let month = new Date(index).getMonth() + 1;
        let year = new Date(index).getFullYear();
        let fullDate = year + "-" + month + "-" + day;

        let indexDate = new Date(fullDate);
        return date < indexDate;
    };

    reabrirMapeamento = async (index) => {
        await this.setState({
            ...this.state,
            showReabrirMapeamento: true,
            reabrirMapeamento: index,
        });
    };
    extenderMapeamento = async (index) => {
        await this.setState({
            ...this.state,
            showExtenderMapeamento: true,
            extenderMapeamento: index,
        });
    };
    closeModalReabrirMapeamento = async () => {
        await this.setState({
            ...this.state,
            showReabrirMapeamento: false,
        });
    };
    closeModalExtenderMapeamento = async () => {
        await this.setState({
            ...this.state,
            showExtenderMapeamento: false,
        });
    };
    searchUser = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/mapeamento/users/json-options/" + inputQuery
            );
            return response.data;
        }
    };
    handleResponsavelChanged = async (e) => {
        await this.setState({
            ...this.state,
            responsavel: e,
        });
    };
    handleDataLimiteChanged = async (e) => {
        await this.setState({
            ...this.state,
            dataLimite: e.target.value,
        });
    };
    reabrir = async () => {
        await axios
            .post("/mapeamento/reabrir/" + this.state.reabrirMapeamento.id, {
                responsavel: this.state.responsavel,
                dataLimite: this.state.dataLimite,
            })
            .then((response) => {
                if (response.data.status == "Success") {
                    this.setState({
                        ...this.state,
                        responsavel: [],
                        dataLimite: "",
                    });
                    this.props.getMapeamentos();
                    this.closeModalReabrirMapeamento();
                }
            });
    };
    extender = async () => {
        await axios
            .post("/mapeamento/extender/" + this.state.extenderMapeamento.id, {
                dataLimite: this.state.dataLimite,
            })
            .then((response) => {
                if (response.data.status == "Success") {
                    this.setState({
                        ...this.state,
                        extenderMapeamento: [],
                        responsavel: [],
                        dataLimite: "",
                    });
                    this.props.getMapeamentos();
                    this.closeModalExtenderMapeamento();
                }
            });
    };
    progresso = (index) => {
        if (index.worker_status == 0) return;
        let value = (index.worker_status / index.total_link) * 100;
        return value.toFixed(2) + "%";
    };

    checkUser = (index) => {
        let canUpdate = false;
        if (index.user_id && index.user_id == this.props.auth_user) {
            canUpdate = true;
        }
        if (index.user_id && index.second_user_id == this.props.auth_user) {
            canUpdate = true;
        }

        console.log("canUpdate");
        console.log(canUpdate);
        return canUpdate;
    };

    getUserName = (index) => {
        let name = "";
        if (index.second_user_id == null && index.user) {
            name = index.user.name;
        }
        if (index.second_user_id != null && index.second_user) {
            name = index.second_user.name;
        }

        return name;
    };
    downloadReport = async(index) => {
        await axios
            .post("/mapeamento/produtividade", {
                id: index.id,
                tipo_relatorio: "gerencial_por_cidade",
            })
            .then((response) => {
                console.log(response.data.filePath);
                window.open(response.data.filePath)
            });
    }
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <table
                        id="datatables"
                        className="table table-striped table-condensed table-padroes"
                    >
                        <thead>
                            <tr>
                                <th
                                    className="text-center"
                                    onClick={() =>
                                        this.props.shortTable("status")
                                    }
                                >
                                    Status
                                </th>
                                <th
                                    className="text-center"
                                    onClick={() =>
                                        this.props.shortTable("type")
                                    }
                                >
                                    Tipo
                                </th>
                                <th
                                    className="text-center"
                                    onClick={() =>
                                        this.props.shortTable("city")
                                    }
                                >
                                    Cidade
                                </th>
                                <th
                                    className="text-center"
                                    onClick={() =>
                                        this.props.shortTable("analista")
                                    }
                                >
                                    Analista
                                </th>
                                <th
                                    className="text-center"
                                    onClick={() =>
                                        this.props.shortTable("period")
                                    }
                                >
                                    Período
                                </th>
                                <th
                                    className="text-center"
                                    onClick={() =>
                                        this.props.shortTable("user")
                                    }
                                >
                                    Responsável Atual
                                </th>
                                <th
                                    className="text-center"
                                    onClick={() =>
                                        this.props.shortTable("worker_status")
                                    }
                                >
                                    Progresso
                                </th>
                                <th
                                    className="text-center"
                                    onClick={() =>
                                        this.props.shortTable("data_criacao")
                                    }
                                >
                                    Data de Abertura
                                </th>
                                <th
                                    className="text-center"
                                    onClick={() =>
                                        this.props.shortTable("date_limit")
                                    }
                                >
                                    Prazo
                                </th>
                                <th
                                    className="text-center"
                                    onClick={() =>
                                        this.props.shortTable("completed_at")
                                    }
                                >
                                    Conclusão
                                </th>
                                <th className="text-center">Fontes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.mapeamentos.map((index, key) => {
                                return (
                                    <tr>
                                        <th className="text-center">
                                            <span
                                                className={this.badgeFilter(
                                                    index.status
                                                )}
                                            >
                                                {index.status}
                                            </span>
                                        </th>
                                        <th className="text-center">
                                            {index.type}
                                        </th>
                                        <th className="text-center">
                                            {index.city + " / " + index.state}
                                        </th>
                                        <th>
                                            {index.analista ??
                                                index.analistaperiodcreated}
                                        </th>
                                        <th className="text-center">
                                            {index.period}
                                        </th>
                                        <th className="text-center">
                                            {this.getUserName(index)}
                                        </th>
                                        <th>
                                            <div className="flex justify-content-between">
                                                <span>
                                                    Concluídos:{" "}
                                                    {index.worker_status}/
                                                    {index.total_link}{" "}
                                                    {this.progresso(index)}
                                                </span>
                                            </div>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{
                                                        width: this.progresso(
                                                            index
                                                        ),
                                                        backgroundColor:
                                                            "#527440",
                                                    }}
                                                    aria-valuenow={this.progresso(
                                                        index
                                                    )}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </th>
                                        <th className="text-center">
                                            {index.data_criacao}
                                        </th>
                                        <th className="text-center">
                                            {index.date_limit}
                                        </th>
                                        <th className="text-center">
                                            {index.completed_at}
                                        </th>
                                        <th className="text-center">
                                            {index.user_id &&
                                                index.user_id !=
                                                    this.props.auth_user &&
                                                window.canUpdateOwner &&
                                                index.status != "Concluído" &&
                                                index.second_user_id !=
                                                    this.props.auth_user && (
                                                    <button
                                                        className="btn btn-link btn-warning edit d-inline-block"
                                                        onClick={() =>
                                                            this.Update(index)
                                                        }
                                                    >
                                                        <i className="fa-solid fa-shuffle"></i>
                                                    </button>
                                                )}
                                            {this.checkUser(index) &&
                                                this.verificaPrazo(
                                                    index.date_limit_no_format
                                                ) && (
                                                    <>
                                                        <a
                                                            href={route(
                                                                "mapeamento_worker",
                                                                index.id
                                                            )}
                                                            title="Fontes"
                                                            className="btn btn-link btn-warning edit d-inline-block"
                                                        >
                                                            <i className="fas fa-tasks"></i>
                                                        </a>

                                                        <button
                                                            className="btn btn-link btn-warning edit d-inline-block"
                                                            onClick={() =>
                                                                this.Update(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <i className="fa-solid fa-shuffle"></i>
                                                        </button>
                                                    </>
                                                )}
                                            {!index.user_id &&
                                                this.verificaPrazo(
                                                    index.date_limit_no_format
                                                ) && (
                                                    <button
                                                        className="btn btn-link btn-warning edit d-inline-block"
                                                        onClick={() =>
                                                            this.Update(index)
                                                        }
                                                    >
                                                        <i className="fas fa-play"></i>
                                                    </button>
                                                )}
                                            {!this.verificaPrazo(
                                                index.date_limit_no_format
                                            ) &&
                                                index.type != "Reabertura" && (
                                                    <button
                                                        className="btn btn-link btn-warning edit d-inline-block"
                                                        onClick={() =>
                                                            this.reabrirMapeamento(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <i className="fa-solid fa-arrow-rotate-right"></i>
                                                    </button>
                                                )}
                                            {!this.verificaPrazo(
                                                index.date_limit_no_format
                                            ) && (
                                                <button
                                                    className="btn btn-link btn-warning edit d-inline-block"
                                                    onClick={() =>
                                                        this.extenderMapeamento(
                                                            index
                                                        )
                                                    }
                                                >
                                                    <i class="fa-solid fa-pen-to-square"></i>
                                                </button>
                                            )}
                                            {index.status == "Concluído" && <button className="btn btn-success btn-link" onClick={()=> this.downloadReport(index)}> <i className="fa-solid fa-download"></i></button>}
                                        </th>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <Modal
                        size="lg"
                        show={this.state.showModalUpdate}
                        onHide={this.closeModalUpdate}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Atualizar Responsável</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group col-12">
                                <label>Responsável</label>
                                <AsyncSelect
                                    cacheOptions
                                    isMulti={false}
                                    value={this.state.responsavel}
                                    loadOptions={this.searchNewUser}
                                    onChange={this.handleNewUserChanged}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-sm btn-success btn-block"
                                onClick={this.setNewResponsavelSubmit}
                            >
                                <i className="fas fa-search"></i> Atualizar
                            </button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        size="lg"
                        show={this.state.showModalNovoResponsavel}
                        onHide={this.closeModalNovoResponsavel}
                    >
                        <Modal.Header closeButton></Modal.Header>

                        <Modal.Title
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            Mapeamento
                        </Modal.Title>
                        <Modal.Body>
                            <div className="form-group col-12 text-center">
                                Tem certeza que gostaria de se vincular a este
                                mapeamento?
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-9"></div>
                            <div className="col-3">
                                <button
                                    className="btn btn-sm btn-success btn-block btn-round background-brain border-brain text-right"
                                    onClick={this.setNewResponsavelSubmit}
                                >
                                    Confirmar
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        size="lg"
                        show={this.state.showReabrirMapeamento}
                        onHide={this.closeModalReabrirMapeamento}
                    >
                        <Modal.Header closeButton></Modal.Header>

                        <Modal.Title
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            Reabrir Mapeamento
                        </Modal.Title>
                        <Modal.Body>
                            <div className="form-group col-12 text-center">
                                Tem certeza que gostaria de reabrir mapeamento?
                            </div>
                            <div className="form-group col-12">
                                <ul>
                                    <li>
                                        Localidade:{" "}
                                        {this.state.reabrirMapeamento.city} /{" "}
                                        {this.state.reabrirMapeamento.state}
                                    </li>
                                    <li>
                                        Periodo:{" "}
                                        {this.state.reabrirMapeamento.period}
                                    </li>
                                    <li>
                                        Responsável:{" "}
                                        {
                                            this.state.reabrirMapeamento.user
                                                ?.name
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Novo Responsável</label>
                                    <AsyncSelect
                                        cacheOptions
                                        value={this.state.responsavel}
                                        loadOptions={this.searchUser}
                                        onChange={this.handleResponsavelChanged}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Nova Data Limite</label>
                                    <input
                                        type="date"
                                        name="dataLimite"
                                        value={this.state.dataLimite}
                                        onChange={this.handleDataLimiteChanged}
                                        style={{ padding: "3px" }}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-9"></div>
                            <div className="col-3">
                                <button
                                    className="btn btn-sm btn-success btn-block btn-round background-brain border-brain text-right"
                                    onClick={this.reabrir}
                                >
                                    Confirmar
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        size="lg"
                        show={this.state.showExtenderMapeamento}
                        onHide={this.closeModalExtenderMapeamento}
                    >
                        <Modal.Header closeButton></Modal.Header>

                        <Modal.Title
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            Extender Mapeamento
                        </Modal.Title>
                        <Modal.Body>
                            <div className="form-group col-12 text-center">
                                Tem certeza que gostaria extender mapeamento?
                            </div>
                            <div className="form-group col-12">
                                <ul>
                                    <li>
                                        Localidade:{" "}
                                        {this.state.reabrirMapeamento.city} /{" "}
                                        {this.state.reabrirMapeamento.state}
                                    </li>
                                    <li>
                                        Periodo:{" "}
                                        {this.state.reabrirMapeamento.period}
                                    </li>
                                    <li>
                                        Responsável:{" "}
                                        {
                                            this.state.reabrirMapeamento.user
                                                ?.name
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Nova Data Limite</label>
                                    <input
                                        type="date"
                                        name="dataLimite"
                                        value={this.state.dataLimite}
                                        onChange={this.handleDataLimiteChanged}
                                        style={{ padding: "3px" }}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-9"></div>
                            <div className="col-3">
                                <button
                                    className="btn btn-sm btn-success btn-block btn-round background-brain border-brain text-right"
                                    onClick={this.extender}
                                >
                                    Confirmar
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default Table;
