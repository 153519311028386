import React from "react";
import Table from "./Table";
import Grafico from "./Grafico";

class TabLancamento extends React.Component {
    componentDidMount() { }

    filtroPorTipo = (rows, tipo) => {
        return rows.filter((index) => { return index.tipo == tipo });
    }

    validate = (data) => {
        return data != undefined && data != [] && data.length != 0;
    }

    render() {
        let exibirTabelas = true;
        let exibirGraficos = this.props.state.exibirGraficos;
        return (
            <>
                {this.validate(this.props.state.dataLancamentosIncorporadora)
                    && this.validate(this.props.state.dataLancamentosTipo)
                    &&
                    <>
                        {this.props.state.tipos.some(option => option.label === 'Vertical') &&
                            <>
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Incorporadora'
                                    titulo='Unidades Lançadas Vertical por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.unidadesLancadas, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.unidadesLancadas, "Vertical")}
                                    id="excel40"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Incorporadora'
                                    titulo='Unidades Lançadas Vertical por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.unidadesLancadas, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />
                                }
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Incorporadora'
                                    titulo='VGV Lançado Vertical Por Incorporadora '
                                    rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.vgv, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.vgv, "Vertical")}
                                    id="excel41"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Incorporadora'
                                    titulo='VGV Lançado Vertical Por Incorporadora '
                                    rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.vgv, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />
                                }
                            </>
                        }
                        {this.props.state.tipos.some(option => option.label === 'Horizontal') && <>
                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Incorporadora'
                                titulo='Unidades Lançadas Horizontal Por Incorporadora'
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.unidadesLancadas, "Horizontal")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.unidadesLancadas, "Horizontal")}
                                id="excel42"

                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Incorporadora'
                                titulo='Unidades Lançadas Horizontal Por Incorporadora'
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.unidadesLancadas, "Horizontal")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                            />}
                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Incorporadora'
                                titulo='VGV Lançado Horizontal Por Incorporadora Por Incorporadora '
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.vgv, "Horizontal")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.vgv, "Horizontal")}
                                id="excel43"

                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Incorporadora'
                                titulo='VGV Lançado Horizontal Por Incorporadora Por Incorporadora '
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.vgv, "Horizontal")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                            />}
                        </>}
                        {this.props.state.tipos.some(option => option.label === 'Comercial') && <>

                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Incorporadora'
                                titulo='Unidades Lançadas Comercial Por Incorporadora'
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.unidadesLancadas, "Comercial")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.unidadesLancadas, "Comercial")}
                                id="excel44"

                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Incorporadora'
                                titulo='Unidades Lançadas Comercial Por Incorporadora'
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.unidadesLancadas, "Comercial")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                            />}
                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Incorporadora'
                                titulo='VGV Lançado Comercial Por Incorporadora Por Incorporadora '
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.vgv, "Comercial")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.vgv, "Comercial")}
                                id="excel45"

                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Incorporadora'
                                titulo='VGV Lançado Comercial Por Incorporadora Por Incorporadora '
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosIncorporadora.vgv, "Comercial")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                            />}
                        </>}
                    </>
                }
            </>
        );
    }
}

export default TabLancamento;
