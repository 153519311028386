import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pt_BR from 'date-fns/locale/pt-BR';





class ModalHeatMap extends React.Component {


    constructor(props) {
        super(props);
        let dataInicial = new Date();
        dataInicial.setMonth(dataInicial.getMonth() - 3);
        this.state = {
            modalIsOpen: false,
            min: null,
            max: null,
            type : null,
            errorMessage: null,
            data_inicial: dataInicial,
            data_final: new Date()
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount = ()=> {

    }

    handleOptionsChange = async (selectedAreas) => {

    }


    submit = async ()=> {

    }

    openModal(area) {
        this.setState({
            modalIsOpen: true,
            area: area
        });
    }

    handleTipoChange = (event) => {
        //console.log(event);

        this.setState({
            ...this.state,
            type : event
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            area: null
        });
    }




    render() {



        return (
            <div style={{ zIndex: '9999'}}>
                <Modal show={this.props.show} onHide={this.props.closeModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Criar Mapa de Calor</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">

                                    {this.state.errorMessage &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>
                                    }
                                    <div className="panel-body">
                                        <div className="form-group">
                                            <label>Mapa de Calor Por:</label>
                                            <Select
                                                value={this.state.type}
                                                onChange={this.handleTipoChange}
                                                options={[
                                                    { value: 'oferta_final', label: 'Oferta Final' },
                                                    { value: 'ticket_medio', label: 'Ticket Médio' },
                                                    { value: 'oferta_inicial', label: 'Lançamentos' },
                                                    { value: 'vgv_final', label: 'VGV Oferta Final' }
                                                ]
                                            }
                                            />
                                        </div>
                                    </div>

                                        <div className="form-group row">
                                            {this.state.type?.value == 'oferta_inicial' && <>
                                                <div className="col-md-6 col-sm-6 col-lg-6">
                                                    <label>Lançamentos Data Inicial</label>
                                                    <DatePicker
                                                        className="form-control"
                                                        locale="pt-BR"
                                                        selected={this.state.data_inicial}
                                                        onChange={(date) =>{
                                                            this.setState({
                                                                ...this.state,
                                                                data_inicial: date
                                                            })
                                                        }}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                        showFullMonthYearPicker
                                                    />
                                                </div>
                                            <div className="col-md-6 col-sm-6 col-lg-6">
                                                <label>Lançamentos Data Final</label>
                                                <DatePicker
                                                    className="form-control"
                                                    locale="pt-BR"
                                                    selected={this.state.data_final}
                                                    onChange={(date) =>{
                                                        this.setState({
                                                            ...this.state,
                                                            data_final: date
                                                        })
                                                    }}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    showFullMonthYearPicker
                                                />
                                            </div></>}




                                            <div className="col-md-12 col-sm-12 col-lg-12" style={{marginTop: '10px'}}>
                                                <div style={{display : "flex", justifyContent: "space-between"}}>
                                                    <button className="btn btn-xs btn-success" onClick={()=>{
                                                        this.props.addHeatMap( this.state);
                                                        this.props.closeModal();
                                                    }}>
                                                        Criar Mapa de Calor
                                                    </button>
                                                </div>

                                            </div>
                                        </div>


                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {/*<ModalFooter>
                        <Button variant="secondary" onClick={this.props.closeModal}>
                            Fechar
                        </Button>
                        <Button variant="primary" onClick={this.props.closeModal}>

                        </Button>
                    </ModalFooter>*/}
                </Modal>
            </div>
        );
    }
}

export default ModalHeatMap;
