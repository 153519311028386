import React from 'react';
import Loader from "./Loader";
import { Modal } from 'react-bootstrap';

class ModalFinishTicket extends React.Component {

    constructor(props) {
        super();
        this.state = {
            mensagem: '',
            errors: [],
            loading: false,
        }
    }

    setLoading = async (loading) => {
        await this.setState({loading: loading});
    }

    finishTicket = async () => {

        try {
            await this.setLoading(true);

            let response = await axios.post(route('ticket.finish', this.props.ticket.id), {
                mensagem: this.state.mensagem
            });

            if (response.status == 200) {
                this.props.handleFinished();
            }

        } catch (e) {
            if (e.response && e.response.status === 422) {
                this.setState({errors: e.response.data.errors});
            }
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    render() {

        return (

            <>
                {this.state.loading && <Loader/>}
                <Modal show={this.props.show} onHide={this.props.handleCloseModal} size={'lg'}>
                    <Modal.Header closeButton>
                        <Modal.Title>Finalizar Ticket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{zIndex:999999}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="mensagem">Mensagem de Finalização </label>
                                                <textarea
                                                    className={`form-control ` + (this.state.errors.mensagem ? 'is-invalid' : '')}
                                                    name="mensagem"
                                                    onChange={(e) => {
                                                        this.setState({mensagem: e.target.value})
                                                    }}
                                                    id="exampleFormControlTextarea1" style={{height: "150px"}} rows="4"></textarea>
                                                    <p>
                                                        <span className={this.state.mensagem.length > 1000 ? 'badge badge-danger' : 'badge badge-success' }>{this.state.mensagem.length}</span>/1000
                                                    </p>
                                                    {this.state.errors['mensagem'] &&
                                                    <div className="invalid-feedback" style={{display: "block"}}>
                                                        {this.state.errors['mensagem']}
                                                    </div>}


                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-primary btn-block" onClick={this.finishTicket}>Finalizar Ticket</button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>

        );
    }
}

export default ModalFinishTicket;
