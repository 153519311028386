import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import TablePadroesInfo from "./TablePadroesInfo";

class ModalPadraoInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Modal
                size={'lg'}
                show={this.props.show}
                onHide={this.props.closeModal}
                // handleClose={}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Title>
                    <div className="text-center">
                    <label >Padrão do Imóvel</label>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <div className="text-justify">
                    <label for="">
                        &nbsp;&nbsp;&nbsp;&nbsp; Classificação do padrão de valor de um empreendimento
                        conforme o ticket médio de vendas de suas unidades em
                        termos de faixas de valor. Ressalte-se a especificidade
                        da assim chamada faixa “Especial” que contempla ao menos
                        50% de suas unidades com imóveis pequenos de estúdios,
                        lofts e 1 dormitório, não tendo uma classificação prévia
                        de preço, mas sim de tipologia. Isolou-se essa tipologia
                        por compreender que sua lógica de preço deve ser
                        avaliada em particular por ser um produto residencial
                        porém com “menor” característica familiar e mais de
                        investimento. O padrão Econômico terá como limite
                        superior o teto do programa Minha Casa Minha Vida. Segue tabela:
                    </label>
                    </div>


                    <TablePadroesInfo></TablePadroesInfo>
                </Modal.Body>

                <Modal.Footer>
                    <div className="col-8">
                    </div>
                    <div>
                    <button className={'btn btn-primary button-brain btn-round'} onClick={this.props.closeModal}>Fechar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalPadraoInfo;
