import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Loader from "../Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pt_BR from 'date-fns/locale/pt-BR';
import AsyncSelect from 'react-select/async';
import Axios from "axios";
import Select from "react-select";
registerLocale('pt-BR', pt_BR);


export default class UpdatePointsReportForm extends Component {

    constructor(props) {
        super();

        this.state = {
            report_data: {
                data : [],
                type : 'gerencial'
            },
            loading: false,
            errors: [],
            operadores: [],
            data_inicial: '',
            data_final: '',
            download: '',
            user: null,
            formato: 'json',
            type : {
                value : 'gerencial',
                label : 'Gerencial'
            },
        }
    }



    loadData = async () => {

        let requestExtraParams = {};

        try {

            await this.setState({
                ...this.state,
                errors: [],
            })

            await this.setLoading(true);

            let body = {
                ...this.state
            };

            if( this.state.operadores.length > 0 ){
                body.operadores = this.state.operadores.map( operador => operador.value );
            }

            body.tipo = this.state.type.value;

            if( this.state.data_inicial !=='' ){

                body.data_inicial = new Date(this.state.data_inicial).toLocaleDateString('pt-BR');
            }

            if (this.state.data_final !== '') {
                body.data_final = new Date(this.state.data_final).toLocaleDateString('pt-BR');
            }



            if( this.state.download !== '' || this.state.formato === 'excel' ){
                requestExtraParams.responseType = 'blob';
            }




            let response = await axios.post(route('web.pesos_atualizacao.report.post'),body, requestExtraParams);

            if (response.status === 200) {


                if( response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
                    const type = response.headers['content-type']
                    const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    //pega o nome do arquivo enviado na resposta
                    link.download = response.headers['content-disposition'].split('filename=')[1]
                    link.click()

                    this.setState({
                        ...this.state,
                         download: '',
                        formato: 'json'
                    });

                    return;
                }


                this.setState({
                    ...this.state,
                    report_data: response.data,
                    download: '',
                });
            }
        } catch (e) {
            if( e.response && e.response.status === 422 ){

                if(e.response.headers['content-type'] === 'application/json'){

                    let errors = e.response.data.errors;

                    if( requestExtraParams.responseType === 'blob'){
                        let responseBody = JSON.parse( await e.response.data.text());
                        errors = responseBody.errors;
                    }

                    this.setState({
                        ...this.state,
                        errors: errors
                    });
                }
            }

            console.log(e);
        }
        finally {
            await this.setLoading(false);
        }
    }

    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }

    loadAuthUser = async () => {
        try {
            let response = await axios.get(route('user.auth'));
            if (response.status === 200) {
                this.setState({
                    ...this.state,
                    user: response.data
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    loadOperators = async () => {

        //usuário só pode ver a própria pontuação
        if( this.state.user.permissions.includes('ver-pontuacao-propria') ){

            let options = [
                {
                    value: this.state.user.id,
                    label: this.state.user.name
                }
            ];

            this.setState({
                ...this.state,
                operadoresOptions: options,
                operadores: options
            })

            return ;
        }

        try{
            let response = await axios.get(route('web.auditoria_tabelas.operadores'));

            let operadoresOptions = [{
                value: 'todos',
                label: 'Selecionar Todos'
            }, ...response.data];

            this.setState({
                ...this.state,
                operadoresOptions: operadoresOptions
            });

        }catch (e) {
            console.log(e);
        }
    }

    componentDidMount = async () => {

        try {
            await this.loadAuthUser();
            await this.loadOperators();

        } catch (e) {
            console.log(e);
        }
    }






    render() {

        return (<>
            {this.state.loading && <Loader/>}
                <div className="card">
                    <div className="card-header">

                            <form action="">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="status">Data Inicial</label>
                                            <DatePicker
                                                locale="pt-BR"
                                                name="data_inicial"
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                autoComplete="off"
                                                selected={this.state.data_inicial}
                                                onChange={async (e) => {
                                                    await this.setState({
                                                        ...this.state,
                                                        data_inicial: e
                                                    });
                                                }}
                                            />
                                            {this.state.errors['data_inicial'] &&
                                                <div className="invalid-feedback" style={{display: "block"}}>
                                                    {this.state.errors['data_inicial'][0]}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="status">Data Final</label>
                                            <DatePicker
                                                locale="pt-BR"
                                                name="data_final"
                                                autoComplete="off"
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.data_final}
                                                onChange={async (e) => {
                                                    await this.setState({
                                                        ...this.state,
                                                            data_final: e
                                                    });
                                                }} //only when value has changed
                                            />
                                            {this.state.errors['data_final'] &&
                                                <div className="invalid-feedback" style={{display: "block"}}>
                                                    {this.state.errors['data_final'][0]}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label htmlFor="status">Operadores</label>
                                        <Select
                                            isMulti
                                            isReadOnly={this.state.user?.permissions.includes('ver-pontuacao-propria')}
                                            isDisabled={this.state.user?.permissions.includes('ver-pontuacao-propria')}
                                            closeMenuOnSelect={false}
                                            name="operadores"
                                            options={this.state.operadoresOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione os operadores"
                                            value={this.state.operadores}
                                            onChange={async (e) => {

                                                //selecionar todos os operadores
                                                if( e && e.length === 1 && e[0].value === 'todos') {

                                                    let operadores = this.state.operadoresOptions.filter((operator) => operator.value !== 'todos');


                                                    await this.setState({
                                                        ...this.state,
                                                        operadores: operadores
                                                    });

                                                    return;
                                                }

                                                await this.setState({
                                                    ...this.state,
                                                    operadores: e
                                                });



                                            }}></Select>

                                        {this.state.errors['operadores'] &&
                                            <div className="invalid-feedback" style={{display: "block"}}>
                                                {this.state.errors['operadores'][0]}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label htmlFor="status">Tipo de Relatório</label>
                                        <Select
                                            name="type"
                                            options={[
                                                {value: 'gerencial', label: 'Gerencial'},
                                                {value: 'analitico', label: 'Analítico'},
                                            ]}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o tipo de relatório"
                                            value={this.state.type}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state,
                                                    type: e
                                                });
                                            }}></Select>

                                        {this.state.errors['type'] &&
                                            <div className="invalid-feedback" style={{display: "block"}}>
                                                {this.state.errors['type'][0]}
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <button className={"btn btn-primary button-brain btn-round btn-block"} onClick={async () => {
                                            await this.loadData();
                                        }} type="button">Gerar Relatório
                                        </button>
                                    </div>
                                    <div className="col-6">

                                        <button className={"btn btn-secondary btn-block btn-round"} onClick={async () => {
                                            await this.setState({
                                                ...this.state,
                                               formato: 'excel',
                                            });
                                            await this.loadData();
                                        }} type="button">Download Excel
                                        </button>
                                    </div>
                                </div>
                            </form>

                    </div>
                    <div className="card-body">
                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr>
                                {this.state.report_data.type === 'gerencial' &&
                                    <>
                                        <th>Operador</th>
                                        <th>Pontos</th>
                                        <th>Dias Úteis</th>
                                        <th>Média Diária</th>
                                    </>
                                }

                                {this.state.report_data.type === 'analitico' &&
                                    <>
                                        <th>Nome</th>
                                        <th>Empreendimento</th>
                                        <th>Cidade</th>
                                        <th>Tipo do Empreendimento</th>
                                        <th>Status na Atualização</th>
                                        <th>Data</th>
                                        <th>Peso</th>
                                    </>
                                }

                            </tr>
                            </thead>
                            <tbody>
                            {this.state.report_data.data.map((data, index) => {
                                if( this.state.report_data.type === 'gerencial'){
                                    return (
                                        <tr key={index}>
                                            <td>{data.name}</td>
                                            <td>{Number(data.points).toLocaleString('pt-BR',{
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}</td>
                                            <td>{data.bussiness_days}</td>
                                            <td>{Number(data.daily_average).toLocaleString('pt-BR',{
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}</td>
                                        </tr>
                                    )
                                }

                                if( this.state.report_data.type === 'analitico'){
                                    return (
                                        <tr key={index}>
                                            <td>{data.name}</td>
                                            <td>{data.building_name}</td>
                                            <td>{data.city}</td>
                                            <td>{data.type}</td>
                                            <td>{data.building_status_on_update}</td>
                                            <td>{data.created_at_formatted}</td>
                                            <td>{data.weight}</td>
                                        </tr>
                                    )
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>);
    }
}


if (document.getElementById('js-form-pontuacao-report')) {
    ReactDOM.render(<UpdatePointsReportForm/>, document.getElementById('js-form-pontuacao-report'));
}
