import React, { Component } from "react";
import Select from "react-select";

class FormCreat extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-12">
                                <label>Link</label>
                                <input
                                    id="link_create"
                                    type="text"
                                    name="link"
                                    value={this.props.linkToCreate}
                                    onChange={
                                        this.props.handleLinkToCreateChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-12">
                                <label>Portal</label>
                                <input
                                    id="portal_create"
                                    type="text"
                                    name="portal"
                                    value={this.props.portalToCreate}
                                    onChange={
                                        this.props.handlePortalToCreateChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            {/* <div className="form-group col-6">
                                <label>Tipo</label>

                                <Select
                                    value={this.props.typeToCreate}
                                    placeholder="Selecione"
                                    isMulti={true}
                                    onChange={
                                        this.props.handleTypeToCreateChanged
                                    }
                                    options={[
                                        { value: "", label: "Selecione" },
                                        {
                                            value: "Horizontal",
                                            label: "Horizontal",
                                        },
                                        {
                                            value: "Vertical",
                                            label: "Vertical",
                                        },
                                        {
                                            value: "Comercial",
                                            label: "Comercial",
                                        },
                                        {
                                            value: "Hotel",
                                            label: "Hotel",
                                        },
                                    ]}
                                />
                            </div> */}
                            <div className="form-group col-12">
                                <label>Observação</label>
                                <input
                                    id="observacao_to_create"
                                    type="text"
                                    name="observacao"
                                    value={this.props.observacaoToCreate}
                                    onChange={
                                        this.props.handleObservacaoToCreateChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-check col-3">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.phoneToCreate}
                                        onChange={
                                            this.props
                                                .handlePhoneToCreateCreateChanged
                                        }
                                        type="checkbox"
                                        value="true"
                                    />
                                    <span className="form-check-sign"></span>
                                    Ligação
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormCreat;
