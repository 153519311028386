import React, { Component } from "react";

class LegendaUsers extends Component {
    render() {
        return (
            <>
                <div>
                    <span>
                        Ao selecionar um empreendimento no mapa, será calculada
                        a distância dos usuários até esse empreendimento.
                    </span>
                </div>
                <hr />
                <table className="table table-striped table-condensed legenda-mapa">
                    <thead>
                        <tr>
                            <th
                                colSpan="2"
                                className=""
                                style={{ paddingBottom: 7 }}
                            >
                                <span
                                    className="font-weight-bold text-center"
                                    style={{
                                        color: "rgb(0, 72, 14)",
                                        fontSize: 16,
                                    }}
                                >
                                    LEGENDA USUÁRIOS
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <img src="/img/markers/masc.png" alt="" />
                            </td>
                            <td>Usuário masculino</td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/img/markers/fem.png" alt="" />
                            </td>
                            <td>Usuário feminino</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="http://maps.google.com/mapfiles/ms/icons/purple-dot.png"
                                    alt=""
                                />
                            </td>
                            <td>Imóvel de interesse</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default LegendaUsers;
