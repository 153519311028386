import React from "react";
import Table from "./Table";
import Grafico from "./Grafico";


class TabTicketMedio extends React.Component {
    componentDidMount() { }

    filtroPorTipo = (rows, tipo) => {
        return rows.filter((index) => { if (index.tipo == tipo) { return index } });
    }

    validate = (data) => {
        return data != undefined && data != [] && data.length != 0;
    }


    render() {
        let exibirTabelas = true;
        let exibirGraficos = this.props.state.exibirGraficos;
        return (
            <>
                {this.props.state.tipos.some(option => option.label === 'Vertical')
                    && this.validate(this.props.state.dataTicketMedioTipologia)
                    && this.validate(this.props.state.dataTicketMedioTipo)
                    &&
                    <>
                        {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                            tipo_detalhamento='Tipologia'
                            titulo='Ticket Médio Vertical Por Tipologia'
                            rows={this.props.state.dataTicketMedioTipologia.ticketMedio}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioTipo.ticketMedio, "Vertical")}
                            id="excel32"
                        />}
                        {exibirGraficos && <Grafico
                            tipo_detalhamento='Tipologia'
                            titulo='Ticket Médio Vertical Por Tipologia'
                            rows={this.props.state.dataTicketMedioTipologia.ticketMedio}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioTipo.ticketMedio, "Vertical")}
                        />}
                    </>
                }
                {this.validate(this.props.state.dataTicketMedioPadrao)
                    && this.validate(this.props.state.dataTicketMedioTipo)
                    &&
                    <>
                        {this.props.state.tipos.some(option => option.label === 'Vertical') && <>
                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Padrão'
                                titulo='Ticket Médio Vertical por Padrão'
                                rows={this.filtroPorTipo(this.props.state.dataTicketMedioPadrao.ticketMedio, "Vertical")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                setLoading={this.setLoading}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioTipo.ticketMedio, "Vertical")}
                                id="excel33"


                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Padrão'
                                titulo='Ticket Médio Vertical por Padrão'
                                rows={this.filtroPorTipo(this.props.state.dataTicketMedioPadrao.ticketMedio, "Vertical")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                setLoading={this.setLoading}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioTipo.ticketMedio, "Vertical")}
                            />}
                        </>}

                        {this.props.state.tipos.some(option => option.label === 'Horizontal') && <>
                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Padrão'
                                titulo='Ticket Médio Horizontal Por Padrão'
                                rows={this.filtroPorTipo(this.props.state.dataTicketMedioPadrao.ticketMedio, "Horizontal")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                setLoading={this.setLoading}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioTipo.ticketMedio, "Horizontal")}
                                id="excel34"


                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Padrão'
                                titulo='Ticket Médio Horizontal Por Padrão'
                                rows={this.filtroPorTipo(this.props.state.dataTicketMedioPadrao.ticketMedio, "Horizontal")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                setLoading={this.setLoading}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioTipo.ticketMedio, "Horizontal")}
                            />}
                        </>}

                        {this.props.state.tipos.some(option => option.label === 'Comercial') && <>
                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Padrão'
                                titulo='Ticket Médio Comercial Por Padrão'
                                rows={this.filtroPorTipo(this.props.state.dataTicketMedioPadrao.ticketMedio, "Comercial")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                setLoading={this.setLoading}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioTipo.ticketMedio, "Comercial")}
                                id="excel35"


                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Padrão'
                                titulo='Ticket Médio Comercial Por Padrão'
                                rows={this.filtroPorTipo(this.props.state.dataTicketMedioPadrao.ticketMedio, "Comercial")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                setLoading={this.setLoading}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioTipo.ticketMedio, "Comercial")}
                            />}
                        </>}
                    </>
                }
            </>
        );
    }
}

export default TabTicketMedio;
