import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';

export default class ModalVersions extends Component {
    render() {
        return (
            <div style={{zIndex: 999999}}>
                <Modal show={this.props.show} onHide={this.props.handleClose}
                       aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Title>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="text-center">Versões</h4>
                                </div>
                            </div>
                        </div>
                    </Modal.Title>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">

                                <table className="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr style={ styles.titleRow}>
                                            <th>Versão</th>
                                            <th>Data Criação</th>
                                            <th>Carregar Versão</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.versions.map((version, index) => {
                                        return (
                                            <tr key={version.uuid}>
                                                <td>
                                                    <span className="badge badge-primary badge-pill">Versão {version.version}</span> &nbsp;
                                                </td>
                                                <td>
                                                    {version.created_at_formatted}
                                                </td>
                                                <td>
                                                    <div className="pull-right">
                                                        {this.props.showing_version !== version.uuid && <>
                                                            <a
                                                                style={{marginLeft: "5px", padding: "7px 5px"}}
                                                                onClick={ (event)=>{
                                                                    event.preventDefault();
                                                                    this.props.handleClose();
                                                                    this.props.loadData(version.uuid)
                                                                }}
                                                                className="btn btn-sm btn-success">
                                                                <i className="fa fa-edit"></i>

                                                            </a>
                                                        </>}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>

                                </table>


                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
const styles = {
    titleBox: {
        backgroundColor: '#5B7537', color: 'white', padding: '10px',
    },

    titleRow: {
        backgroundColor: '#5B7537', color: 'white', padding: '10px',
    }
}
