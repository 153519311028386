import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Axios from "axios";

class Form extends Component {
    state = this.props.state;

    render() {
        return (<>
            <button className="btn btn-link" onClick={() => this.props.showModal('showModalCreate')}>Criar Nova Região</button>
        </>);
    }
}

export default Form;
