import React from 'react';
import Loader from "./Loader";
import { Modal } from 'react-bootstrap';

class ModalAssignTicket extends React.Component {

    constructor(props) {
        super();
        this.state = {
            novo_responsavel: '',
            errors: [],
            loading: false,
            users: []
        }
    }

    setLoading = async (loading) => {
        await this.setState({loading: loading});
    }

    finishTicket = async () => {

        try {
            await this.setLoading(true);

            let response = await axios.post(route('ticket.assign', this.props.ticket.id), {
                novo_responsavel: this.state.novo_responsavel
            });

            if (response.status == 200) {
                this.props.handleAssigned();

            }

        } catch (e) {
            if (e.response && e.response.status === 422) {
                this.setState({errors: e.response.data.errors});
            }
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    getUsers = async () => {
        try {
            await this.setLoading(true);

            let response = await axios.get(route('users.suporte'));

            if (response.status == 200) {
                this.setState({users: response.data});
            }

        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    componentDidMount = async ()=>{
        await this.getUsers();
    }

    render() {

        return (

            <>
                {this.state.loading && <Loader/>}
                <Modal show={this.props.show} onHide={this.props.handleCloseModal} size={'lg'}>
                    <Modal.Header closeButton>
                        <Modal.Title>Atribuir Ticket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{zIndex:999999}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="mensagem">Atribuir Para</label>
                                                <select className="form-control" name="novo_responsavel" onChange={(e) => this.setState({novo_responsavel: e.target.value})}>
                                                    <option value="">Selecione</option>
                                                    {this.state.users.map((user, index) => {
                                                        return <option value={user.id}>{user.name}</option>
                                                    }
                                                    )}
                                                </select>

                                                {this.state.errors['novo_responsavel'] &&
                                                    <div className="invalid-feedback" style={{display: "block"}}>
                                                        {this.state.errors['novo_responsavel']}
                                                    </div>
                                                }


                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-primary btn-block" onClick={this.finishTicket}>Atribuir Ticket</button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>

        );
    }
}

export default ModalAssignTicket;
