import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Axios from "axios";

import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from "react-places-autocomplete";

import IntlCurrencyInput from "react-intl-currency-input";

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

class FormFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipos: [
                {value: 'Por padrão', label: 'Por padrão'},
                {value: 'Por fase de obra', label: 'Por fase de obra'},
                {value: 'Por Incorporadora', label: 'Por Incorporadora'},
            ],
        };
    }



    componentDidMount = async () => {
    };

    searchCidade = async (inputQuery) => {
        if (inputQuery.length >= 2) {

            let url = "/cidades/json-options/" + inputQuery;

            if( this.props.estadoSelecionado !== null ){

                url = "/cidades/json-options-by-state/" + this.props.estadoSelecionado.value +"/" + inputQuery;
            }

            const response = await axios.get(
                url
            );

            return response.data;
        }
    };

    handleChange = (address) => {
        this.setState({ ...this.state, address: address });
    };

    handleSelect = (address) => {
        console.log(address);

        this.setState({ ...this.state, address: address });

        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                console.log("Success", latLng);

                window.googleMap.panTo(latLng);

                this.props.handleCidadeChanged(latLng.lat, latLng.lng, address);
            })
            .catch((error) => console.error("Error", error));
    };

    handleValorMaximoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMaximoChanged(value);
    };

    handleValorMinimoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMinimoChanged(value);
    };

    handleValorMetroMaximoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMetroMaximoChanged(value);
    };

    handleValorMetroMinimoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMetroMinimoChanged(value);
    };


    handleValorParcelaMinimaChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorParcelaMinimaChanged(value);
    };

    render() {
        return (
            <div style={{ height: "100%" }}>
                <div className="card filtro-mapa">
                    <div className="card-body">
                        <div className="form-group">
                            <label className="label-estado">Estado</label>
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: 20,
                                    })
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#F2F2F2',
                                        primary: '#5B7537',
                                    },
                                })}
                                cacheOptions
                                placeholder="Selecione"
                                value={this.props.estado}
                                options={this.props.estadosOptions}
                                onChange={ this.props.handleEstadoChanged }
                            />

                        </div>
                        <div className="form-group">
                            <label className="label-fornecedor">Cidade</label>
                            <AsyncSelect
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: 20,
                                    })
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                    ...theme.colors,
                                    primary25: '#F2F2F2',
                                    primary: '#5B7537',
                                    },
                                })}
                                cacheOptions
                                placeholder="Selecione"
                                isMulti
                                value={this.props.cidade}
                                loadOptions={this.searchCidade}
                                onChange={ this.props.handleCidadeChanged }
                            />
                        </div>

                        { ( this.props.cidade !== 0 || this.props.estadoSelecionado?.value) &&
                            <div className="form-group">
                                <label className="label-fornecedor">Tipo de análise</label>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderRadius: 20,
                                        }),
                                        /* option: (defaultStyles, state) => ({
                                            ...defaultStyles,
                                            color: state.isSelected ? "#fff": '#000',
                                            backgroundColor: state.isSelected? "#5B7537": "#fff",
                                        }), */
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                        ...theme.colors,
                                        primary25: '#F2F2F2',
                                        primary: '#5B7537',
                                        },
                                    })}
                                    placeholder="Selecione"
                                    options={this.state.tipos}
                                    value={this.props.tipoAnalise}
                                    onChange={ this.props.handleAnaliseChanged }
                                    isMulti={false}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default FormFilters;
