import Axios from "axios";

class EmpreendimentosService {
    baseUrlEmpreendimentos = '/imoveis-json';


    extractValuesArray = (array) => {
        let newArray = [];

        array.forEach((element) => {
            newArray.push(element.value)
        });

        return newArray;
    }

    extractValuesArrayFromCidade = (array) => {
        let newArray = [];

        array.forEach((element) => {
            let elementParts = element.value.split('-');
            let cidade = elementParts[1].trim();
            newArray.push( cidade )
        });

        return newArray;
    }

    getEmpreendimentosWithinPolygon = async (path, state) => {
        let { tipo, tipo_negocio, area_maior_que, area_menor_que, quartos, valor_menor_que,
            valor_maior_que, valor_metro_maior_que,
            valor_metro_menor_que, cidade, estado, bairros
        } = state;

        status = this.extractValuesArray(status);
        let bairro = this.extractValuesArray(bairros);
        cidade = this.extractValuesArrayFromCidade( cidade )

        const requestBody = {
            tipo,
            tipo_negocio,
            cidade,
            estado,
            bairro,
            area_maior_que,
            area_menor_que,
            status,
            quartos,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            'polygon': path,
        }

        try {
            const empreendimentos = await axios.post(this.baseUrlEmpreendimentos,
                requestBody
            );

            return empreendimentos;

        } catch (error) {
            console.log('Erro ao Buscar Empreendimento');
            console.log('error');
        }

    }

    getEmpreendimentosWithinRadius = async (latitude, longitude, radius, state) => {
        let { tipo_empreendimento, padrao, area_maior_que, area_menor_que, quartos, status, valor_menor_que, valor_maior_que, valor_metro_maior_que, valor_metro_menor_que, cidade,
            estado, bairros, ano_lancamento, incorporadoras, nome_empreendimento,
            juros_minimo, juros_maximo, parcelas_minimo, parcelas_maximo,
            valor_parcela_maxima,valor_parcela_minima } = state;

        status = this.extractValuesArray(status);
        padrao = this.extractValuesArray(padrao);
        let bairro = this.extractValuesArray(bairros);
        let nome = this.extractValuesArray(nome_empreendimento);
        cidade = this.extractValuesArrayFromCidade(cidade);
        incorporadoras = this.extractValuesArray( incorporadoras );
        ano_lancamento = this.extractValuesArray( ano_lancamento )

        const requestBody = {
            tipo_empreendimento,
            cidade,
            estado,
            bairro,
            padrao,
            area_maior_que,
            area_menor_que,
            quartos,
            status,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            incorporadoras,
            ano_lancamento,
            'circle': {
                'radius': radius,
                'latitude': latitude,
                'longitude': longitude,
            },
            nome
        }

        try {
            const empreendimentos = await axios.post(this.baseUrlEmpreendimentos,
                requestBody
            );

            return empreendimentos;

        } catch (error) {
            console.log('Erro ao Buscar Empreendimentos');
            console.log(error);
        }
    }

    getEmpreendimento = async (id) => {
        try {
            const empreendimentoResponse = await axios.get(this.baseUrlEmpreendimento + id);

            return empreendimentoResponse.data;

        } catch (error) {
            console.log('error');
        }
    }

    getEmpreendimentoOnCidade = async (state) => {
        let { tipo_imovel, tipo_negocio ,padrao, area_maior_que, area_menor_que, quartos, valor_menor_que, valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que, cidade, estado, bairros
        } = state;

        cidade = this.extractValuesArrayFromCidade(cidade)
        let tipo = this.extractValuesArray( tipo_imovel );
        let bairro = this.extractValuesArray(bairros);

        const requestBody = {
            tipo : tipo,
            tipo_negocio,
            cidade,
            estado,
            bairro,
            padrao,
            area_maior_que,
            area_menor_que,
            quartos,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que

        }

        try {
            const empreendimentos = await axios.post(this.baseUrlEmpreendimentos,
                requestBody
            );

            return empreendimentos;

        } catch (error) {
            console.log('Erro ao Buscar Empreendimentos');
            console.log(error);
        }
    }


}

export default new EmpreendimentosService();
