import React, { Component, Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import ImageSlider from "./ImageSlider";
import { Transition } from "react-transition-group";
import * as PropTypes from "prop-types";
// import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AnaliseTemporalEmpreendimento from "./AnaliseTemporalEmpreendimento/AnaliseTemporalEmpreendimento";

moment.locale("pt-br");

class CardNegociacao extends Component {



    render() {
        return (
            <div>
                <h5 style={{ color: '#00480E', marginBottom: '10px' }}>Dados de Negociação</h5>
                <strong className="general-label">Percentual de Entrada: </strong> {this.props.empreendimento.porcentagem_entrada} %<br />
                <strong className="general-label">Número de Parcelas: </strong> {this.props.empreendimento.numero_parcelas} <br />
                <strong className="general-label">Valor de Desconto á vista: </strong> R$ {this.props.empreendimento.valor_desconto} <br />
                <strong className="general-label">Percentual de Desconto: </strong> {this.props.empreendimento.percentual_desconto} %<br />
                <strong className="general-label">Valor Parcela: </strong> R$ {this.props.empreendimento.valor_parcela} <br />
                <strong className="general-label">Taxa de Juros: </strong> {this.props.empreendimento.taxa_juros} %<br />
                <strong className="general-label">Indice de Correção de Juros: </strong> {this.props.empreendimento.indice_juros}<br />
                <strong className="general-label">Taxa Associativa: </strong> {this.props.empreendimento.taxa_associativa}
                <hr />
            </div>
        );
    }
}

CardNegociacao.propTypes = { empreendimento: PropTypes.shape({ this: PropTypes.any }) };

class ModalEmpreendimentos extends Component {
    state = {
        downloading: false,
        downloadProgress: 0
    }

    constructor(props) {
        super(props);

        this.state = {
            downloading: false,
            downloadProgress: 0
        }
    }
    render() {
        let periods = this.props.empreendimento.anexos.map((index) => {
            return index.created_at;
        });

        periods = periods.filter((element, index) => {
            return periods.indexOf(element) === index;
        });

        let periodsTabela = this.props.empreendimento.tabelas.map((index) => {
            return index.created_at;
        });

        periodsTabela = periodsTabela.filter((element, index) => {
            return periodsTabela.indexOf(element) === index;
        });

        let periodsMaterial = this.props.empreendimento.material.map((index) => {
            return index.created_at;
        });

        periodsMaterial = periodsMaterial.filter((element, index) => {
            return periodsMaterial.indexOf(element) === index;
        });

        let periodsMemorial = this.props.empreendimento.memorial.map((index) => {
            return index.created_at;
        });

        periodsMemorial = periodsMemorial.filter((element, index) => {
            return periodsMemorial.indexOf(element) === index;
        });

        let statusAditionalClassName = this.props.empreendimento.status === 'Comercialização' ? 'badge-success' : 'badge-danger';

        let nome_empreendimento = this.props.empreendimento.nome;

        return (
            <Modal
                size="lg"
                dialogClassName="dialogWidth"
                style={{ zIndex: 9999999 }}
                show={this.props.show}
                onHide={this.props.closeModalEmpreendimento}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-6">
                                    <h3 className="card-title h-title"><i className="far fa-building"></i> &nbsp; {this.props.empreendimento.nome}</h3>
                                    <small>{this.props.empreendimento.tipo_empreendimento} - {this.props.empreendimento.padrao}</small><br />
                                    <span className={'badge ' + statusAditionalClassName}>
                                        <strong>
                                            <small>
                                                Status: {this.props.empreendimento.status}
                                            </small>
                                        </strong>
                                    </span>
                                </div>
                                <div className="col-6">
                                    {this.props.empreendimento.retrofit == 1 && <>
                                        <img
                                            className=""
                                            src={"/img/selo_retrofit.png"}
                                            alt="slider image"
                                            style={{
                                                width: "80px",
                                                height: "80px",
                                                float: "right",
                                            }}
                                        />
                                    </>}
                                </div>
                            </div>

                            <hr />
                            <strong className="general-label">Database: </strong>&nbsp;{this.props.empreendimento.database}
                            <br />
                            {this.props.empreendimento.estagio && <>
                                <strong>Estágio: </strong>&nbsp;{this.props.empreendimento.estagio}
                            </>}
                            <br />
                            <a target="_blank" className="btn btn-sm btn-primary button-brain btn-round" href={"/contato-infos?empreendimento=" + this.props.empreendimento.id_legado}>
                                Informar Dados Faltantes e ou incorretos
                            </a>

                        </div>
                        <div className="card-body" style={{ paddingLeft: 0, color: '#03260a' }}>
                            <Tabs defaultActiveKey="empreendimento"
                                id="uncontrolled-tab-example"
                                className="mb-3">
                                <Tab eventKey="empreendimento" title="Empreendimento" tabClassName="nav-tab-item">
                                    {this.props.empreendimento.fotos.length > 0 &&
                                        <ImageSlider images={this.props.empreendimento.fotos} />}
                                    <i className="nc-icon nc-pin-3"></i> &nbsp;
                                    {this.props.empreendimento.endereco}, {this.props.empreendimento.numero} {this.props.empreendimento.bairro}, {this.props.empreendimento.cidade} - {this.props.empreendimento.estado}
                                    <hr />
                                    <strong
                                        className="general-label">Construtora: </strong>{this.props.empreendimento.construtora}
                                    <br />
                                    <table className="table table-condensed table-striped table-bordered tabela-emp">
                                        <thead>
                                            <tr>
                                                <th>Incorporadora</th>
                                                <th>Participação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.empreendimento.incorporadora.map((incorporator, index) => {
                                                return (
                                                    <tr key={index * 554554}>
                                                        <td>{incorporator.incorporadora}</td>
                                                        <td>{incorporator.participacao}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <br />
                                    <i className="fa fa-calendar general-label" aria-hidden="true"></i> &nbsp;
                                    <strong className="general-label">Data de
                                        Lançamento: </strong>{this.props.empreendimento.lancamento} <br />
                                    <strong className="general-label">Oferta
                                        Lançada: </strong>{this.props.empreendimento.oferta_inicial} <br />
                                    <strong className="general-label">Oferta
                                        Final: </strong>{this.props.empreendimento.oferta_final} <br />
                                    <strong className="general-label">Disponibilidade sobre Oferta
                                        Lançada: </strong>{Number(100 - this.props.empreendimento.porcentagem_vendida).toFixed(2)} %<br />
                                    <strong className="general-label">Porcentagem
                                        Vendida: </strong>{this.props.empreendimento.porcentagem_vendida} %<br />
                                    <hr />
                                    <strong className="general-label">VGV
                                        Lançado: </strong>{this.props.empreendimento.valor_geral_vendas_lancado} <br />
                                    <strong className="general-label">VGV
                                        Final: </strong>{this.props.empreendimento.valor_geral_vendas_final} <br />
                                    <strong className="general-label">Preço/m²
                                        médio: </strong>{this.props.empreendimento.valor_medio_metro} <br />
                                    <strong className="general-label">Ticket
                                        Médio: </strong>{this.props.empreendimento.valor_medio_unidade} <br />
                                    {this.props.empreendimento.ivv !== undefined && <>
                                        <strong className="general-label">IVV: </strong>{this.props.empreendimento.ivv} <br />
                                    </>}
                                    <hr />
                                </Tab>

                                {this.props.empreendimento.tipo_empreendimento == 'Horizontal' &&
                                    <Tab eventKey="dados_negociacao" title="Dados de Negociação"
                                        tabClassName="nav-tab-item">
                                        <CardNegociacao empreendimento={this.props.empreendimento} />
                                    </Tab>
                                }

                                <Tab eventKey="unidades" title="Unidades" tabClassName="nav-tab-item">
                                    <h5 style={{ color: '#00480E' }}>UNIDADES</h5>
                                    <table className="table table-condensed table-striped table-bordered tabela-emp"
                                        style={{ whiteSpace: 'nowrap' }}>
                                        <thead>
                                            <tr className="text-center" style={{
                                                fontSize: 12,
                                                textTransform: 'uppercase',
                                                fontWeight: 'bold',
                                                backgroundColor: '#00480E',
                                                color: 'white'
                                            }}>
                                                <td style={{ padding: 7, fontWeight: 'bold' }}>Á. Privativa</td>
                                                <td style={{ fontWeight: 'bold' }}>Quartos</td>
                                                <td style={{ fontWeight: 'bold' }}>Vagas Garagem</td>
                                                <td style={{ fontWeight: 'bold' }}>Tipologia</td>
                                                <td style={{ fontWeight: 'bold' }}>Valor</td>
                                                <td style={{ fontWeight: 'bold' }}>Valor m<sup>2</sup></td>
                                                <td style={{ fontWeight: 'bold' }}>Of. Inicial</td>
                                                <td style={{ fontWeight: 'bold' }}>Of. Final</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.empreendimento.unidades.map((unidade, index) => {
                                                return (
                                                    <tr className="text-center" key={index * 785471578}>
                                                        <td>{unidade.area_privativa} m<sup>2</sup></td>
                                                        <td>{unidade.quartos}</td>
                                                        <td>{unidade.garagem}</td>
                                                        <td>{unidade.tipologia}</td>
                                                        <td>{unidade.valor}</td>
                                                        <td>{unidade.valor_metro} &nbsp; </td>
                                                        <td>{unidade.oferta_inicial}</td>
                                                        <td style={{ display: 'table-cell' }}>{unidade.oferta_final}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </Tab>
                                {this.props.empreendimento.tipo_empreendimento != "Horizontal" &&
                                    <tab eventKey="ficha" title="Ficha" tabClassName="nav-tab-item">
                                        <h5 style={{ color: '#00480E' }}>Ficha</h5>
                                        <div>
                                            <strong
                                                className="general-label">Torres: </strong> {this.props.empreendimento.torres ?? 0}
                                            <br />
                                            <strong
                                                className="general-label">Elevadores: </strong>{this.props.empreendimento.elevador ?? 0}
                                            <br />
                                            {this.props.empreendimento.areas.map((area, index) => {
                                                if (area.area == "Banheiros 1Q" || area.area == "Banheiros 2Q" || area.area == "Banheiros 3Q" || area.area == "Banheiros 4Q") {
                                                    return (
                                                        <strong key={`span-areas-${index}`} className="general-label">Possui {area.area}<br /></strong>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </tab>}
                                <tab eventKey="areas" title="Áreas de Lazer" tabClassName="nav-tab-item">
                                    <h5 style={{ color: '#00480E' }}>Áreas de Lazer</h5>
                                    <div>
                                        <ul>
                                            {this.props.empreendimento.areas.map((area, index) => {
                                                if (area.area != "Banheiros 1Q" || area.area != "Banheiros 2Q" || area.area != "Banheiros 3Q" || area.area != "Banheiros 4Q") {
                                                    return (<li key={`areaslazer-${index}`}>{area.area}</li>)
                                                }
                                            })}
                                        </ul>
                                    </div>
                                </tab>
                                {window.isInterno == true && this.props.empreendimento.anexos.length > 0 &&
                                    <tab eventKey="anexos" title="Evidências" tabClassName="nav-tab-item">
                                        <h5 style={{ color: '#00480E' }}>Evidências</h5>
                                        <div>
                                            {
                                                periods.map((period, index) => {
                                                    return (
                                                        <Fragment key={`evidencias-${period}-${index}`}>
                                                            <p>Evidências adicionadas no mês {period}</p>
                                                            <ul className="list-group">
                                                                {this.props.empreendimento.anexos.map(function (anexo, index) {

                                                                    if (anexo.created_at == period) {
                                                                        return (
                                                                            <li className="list-group-item" key={`anexos-evidencias-${index}`}>
                                                                                <div className="pull-left">
                                                                                    {anexo.name} <br />
                                                                                </div>
                                                                                <div className="pull-right">
                                                                                    <a className="btn btn-success btn-sm"
                                                                                        href={anexo.url} target="_blank">
                                                                                        <i className="fa fa-file-download"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    }
                                                                })}
                                                            </ul>
                                                            <hr />
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </tab>}

                                {window.verMemorial == true && this.props.empreendimento.memorial.length > 0 &&
                                    <tab eventKey="memorial" title="Memorial Descritivo" tabClassName="nav-tab-item">
                                        <h5 style={{ color: '#00480E' }}>Memoriais</h5>
                                        <div>
                                            {
                                                periodsMaterial.map((period, index) => {
                                                    return (
                                                        <Fragment key={`material-period-${period}-${index}`}>
                                                            <p>Memoriais Descritivos adicionados no mês {period}</p>
                                                            <ul className="list-group">
                                                                {this.props.empreendimento.memorial.map(function (anexo, index) {

                                                                    if (anexo.created_at == period) {
                                                                        return (
                                                                            <li className="list-group-item" key={`memorial-${index}`}>
                                                                                <div className="pull-left">
                                                                                    {anexo.name} <br />
                                                                                </div>
                                                                                <div className="pull-right">
                                                                                    <a className="btn btn-success btn-sm"
                                                                                        href={anexo.url} target="_blank">
                                                                                        <i className="fa fa-file-download"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    }
                                                                })}
                                                            </ul>
                                                            <hr />
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </tab>}
                                {window.verMaterial == true && this.props.empreendimento.material.length > 0 &&
                                    <tab eventKey="material" title="Materiais" tabClassName="nav-tab-item">
                                        <h5 style={{ color: '#00480E' }}>Materiais</h5>
                                        <div>
                                            {
                                                periodsMaterial.map((period, index) => {
                                                    return (
                                                        <Fragment key={`material2-periodo-${period}-${index}`}>
                                                            <p>Materiais adicionados no mês {period}</p>
                                                            <ul className="list-group">
                                                                {this.props.empreendimento.material.map(function (anexo, index) {

                                                                    if (anexo.created_at == period) {
                                                                        return (
                                                                            <li className="list-group-item" key={`material-${index}`}>
                                                                                <div className="pull-left">
                                                                                    {/* {anexo.name}  */}
                                                                                    Material - {nome_empreendimento}
                                                                                    <br />
                                                                                </div>
                                                                                <div className="pull-right">
                                                                                    <a className="btn btn-success btn-sm"
                                                                                        href={anexo.url} target="_blank">
                                                                                        <i className="fa fa-file-download"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    }
                                                                })}
                                                            </ul>
                                                            <hr />
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </tab>}
                                {window.isClient == true && this.props.empreendimento.tabelas.length > 0 &&
                                    <tab eventKey="tabelas" title="Tabelas" tabClassName="nav-tab-item">
                                        <h5 style={{ color: '#00480E' }}>Tabelas</h5>
                                        <div>
                                            {
                                                periodsTabela.map((period) => {
                                                    return (
                                                        <Fragment key={`tabelas-periodo-${period}`}>

                                                            <ul className="list-group">
                                                                {this.props.empreendimento.tabelas.map(function (anexo, index) {

                                                                    if (anexo.created_at == period) {
                                                                        return (
                                                                            <li className="list-group-item" key={`tabela-anexo-${index}`}>
                                                                                <div className="pull-left">
                                                                                    {nome_empreendimento} - {anexo.period} <br />
                                                                                </div>
                                                                                <div className="pull-right">
                                                                                    <a className="btn btn-success btn-sm"
                                                                                        href={anexo.url} target="_blank">
                                                                                        <i className="fa fa-file-download"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    }
                                                                })}
                                                            </ul>
                                                            <hr />
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </tab>}
                                <Tab eventKey="analise_temporal" title="Análise Temporal" tabClassName="nav-tab-item">
                                    <AnaliseTemporalEmpreendimento empreendimento={this.props.empreendimento} />
                                </Tab>
                            </Tabs>
                            <hr />
                            {this.state.downloading && <>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{
                                        width: this.state.downloadProgress + '%'
                                    }} aria-valuenow={this.state.downloadProgress}
                                        aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <button onClick={this.handleDownloadClick} className="btn btn-success btn-round"
                        disabled={this.state.downloading}>
                        Download &nbsp;
                        <i className="fa fa-file-pdf-o"></i>
                        {this.state.downloading && <>
                            <i className="fa fa-spinner fa-spin"></i>
                        </>}
                    </button>


                    <Button
                        variant="primary"
                        className="btn btn-sm btn-primary button-brain btn-round"
                        onClick={this.props.togglePainelEmpreendimento}
                    >
                        Fechar
                    </Button>


                </Modal.Footer>
            </Modal>
        );
    }

    handleDownloadClick = async (event) => {
        event.preventDefault();

        try {

            await this.setState({
                ...this.state,
                downloading: true,
                downloadProgress: 0
            })

            const response = await fetch(this.props.empreendimento.pdf);


            if (!response?.body) {
                return;
            }

            const contentLength = response.headers.get('x-filesize');
            const totalLength = typeof contentLength === 'string' && parseInt(contentLength);

            const reader = response.body.getReader();
            const chunks = [];


            let receivedLength = 0;


            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                chunks.push(value);

                receivedLength += value.length;


                let step = receivedLength / totalLength * 100;

                await this.setState({
                    ...this.state,
                    downloadProgress: step
                })
            }

            const blob = new Blob(chunks);


            const fileNameFromResponse = response.headers.get('Content-Disposition').split('filename=')[1];

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');

            a.href = url;
            a.download = fileNameFromResponse;


            a.addEventListener('click', () => {
                setTimeout(() => {
                    URL.revokeObjectURL(url);
                    a.removeEventListener('click', () => { });
                }, 10000)
            }, false);

            a.click();
        } catch (e) {
            console.log(e);
        } finally {
            await this.setState({
                ...this.state,
                downloading: false,
            })
        }
    }
}

export default ModalEmpreendimentos;
