import React, { Component } from 'react';
import moment from 'moment';
import ImageSlider from './ImageSlider';
import { Transition } from 'react-transition-group';
import * as PropTypes from "prop-types";
moment.locale('pt-br');

class CardNegociacao extends Component {
    render() {
        return (
            <div>
                <h5 style={{ color: '#00480E', marginBottom: '10px' }}>Dados de Negociação</h5>
                <strong>Percentual de Entrada: </strong> {this.props.empreendimento.porcentagem_entrada} %<br />
                <strong>Número de Parcelas: </strong> {this.props.empreendimento.numero_parcelas} <br />
                <strong>Valor de Desconto á vista: </strong> R$ {this.props.empreendimento.valor_desconto} <br />
                <strong>Percentual de Desconto: </strong> {this.props.empreendimento.percentual_desconto} %<br />
                <strong>Valor Parcela: </strong> R$ {this.props.empreendimento.valor_parcela} <br />
                <strong>Taxa de Juros: </strong> {this.props.empreendimento.taxa_juros} %<br />
                <strong>Indice de Correção de Juros: </strong> {this.props.empreendimento.indice_juros}<hr />
            </div>
        );
    }
}

CardNegociacao.propTypes = {empreendimento: PropTypes.shape({this: PropTypes.any})};

class EmpreendimentoCard extends Component {



    render() {
        let statusAditionalClassName = this.props.empreendimento.status === 'Comercialização' ? 'badge-success' : 'badge-danger';
        const transitionStyles = {
            entering: { opacity: 1 },
            entered: { opacity: 1 },
            exiting: { opacity: 0 },
            exited: { opacity: 0 },
        };
        return (
            <>
                <div style={{ display: (this.props.visible == true) ? 'block' : 'none' }}>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"><i className="far fa-building"></i> &nbsp; {this.props.empreendimento.nome}</h3>
                            <small>{this.props.empreendimento.tipo_empreendimento} - {this.props.empreendimento.padrao}</small><br />
                            <span className={'badge ' + statusAditionalClassName}>
                                <strong>
                                    <small>
                                        Status: {this.props.empreendimento.status}
                                    </small>
                                </strong>
                            </span>
                            <hr />
                            <strong>Database: </strong>&nbsp;{this.props.empreendimento.database}
                            <br />
                            <br />
                            <a target="_blank" className="btn btn-sm btn-primary" href={"/contato-infos?empreendimento=" + this.props.empreendimento.id_legado }>
                                Informar Dados Faltantes e ou incorretos
                            </a>

                        </div>
                        <div className="card-body" style={{ paddingLeft: 0, color: '#03260a' }}>

                            {this.props.empreendimento.fotos.length > 0 && <ImageSlider images={this.props.empreendimento.fotos} />}

                            <hr />
                            <i className="nc-icon nc-pin-3"></i> &nbsp;
                            {this.props.empreendimento.endereco}, {this.props.empreendimento.numero} {this.props.empreendimento.bairro}, {this.props.empreendimento.cidade} - {this.props.empreendimento.estado}
                            <hr />
                            <strong>Construtora: </strong>{this.props.empreendimento.construtora} <br />

                                <table className="table table-condensed table-striped table-bordered tabela-emp">
                                    <thead>
                                        <tr>
                                            <th>Incorporadora</th>
                                            <th>Participação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.empreendimento.incorporadora.map( (incorporator, index) => {
                                        return (
                                            <tr key={index*554554} >
                                                <td>
                                                    {incorporator.incorporadora}
                                                </td>
                                                <td>
                                                    {incorporator.participacao}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            <br />
                            <i className="fa fa-calendar" aria-hidden="true"></i> &nbsp;
                            <strong>Data de Lançamento: </strong>{this.props.empreendimento.lancamento} <br />
                            <strong>Oferta Lançada: </strong>{this.props.empreendimento.oferta_inicial} <br />
                            <strong>Oferta Final: </strong>{this.props.empreendimento.oferta_final} <br />
                            <strong>Disponibilidade sobre Oferta Lançada: </strong>{Number(100 - this.props.empreendimento.porcentagem_vendida).toFixed(2)} %<br />
                            <strong>Porcentagem Vendida: </strong>{this.props.empreendimento.porcentagem_vendida} %<br />
                            <hr />
                            <strong>VGV Lançado: </strong>{this.props.empreendimento.valor_geral_vendas_lancado} <br />
                            <strong>VGV Final: </strong>{this.props.empreendimento.valor_geral_vendas_final} <br />
                            <strong>Preço/m² médio: </strong>{this.props.empreendimento.valor_medio_metro} <br />
                            <strong>Ticket Médio: </strong>{this.props.empreendimento.valor_medio_unidade} <br />
                            { this.props.empreendimento.ivv !== undefined && <>
                                <strong>IVV: </strong>{this.props.empreendimento.ivv} <br />
                            </>}
                            <hr />



                            {this.props.empreendimento.tipo_empreendimento == 'Horizontal' && <CardNegociacao empreendimento={this.props.empreendimento} />}
                            <h5 style={{ color: '#00480E' }}>UNIDADES</h5>
                            <table className="table table-condensed table-striped table-bordered tabela-emp" style={{ whiteSpace: 'nowrap' }}>
                                <thead>
                                    <tr className="text-center" style={{ fontSize: 12, textTransform: 'uppercase', fontWeight: 'bold', backgroundColor: '#00480E', color: 'white' }}>
                                        <td style={{ padding: 7, fontWeight: 'bold' }}>Á. Privativa</td>
                                        <td style={{ fontWeight: 'bold' }}>Quartos</td>
                                        <td style={{ fontWeight: 'bold' }}>Vagas Garagem</td>
                                        <td style={{ fontWeight: 'bold' }}>Tipologia</td>
                                        <td style={{ fontWeight: 'bold' }}>Valor</td>
                                        <td style={{ fontWeight: 'bold' }}>Valor m<sup>2</sup></td>
                                        <td style={{ fontWeight: 'bold' }}>Of. Inicial</td>
                                        <td style={{ fontWeight: 'bold' }}>Of. Final</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.empreendimento.unidades.map((unidade, index) => {
                                        return (
                                            <tr className="text-center" key={index*785471578}>
                                                <td>{unidade.area_privativa} m<sup>2</sup></td>
                                                <td>{unidade.quartos}</td>
                                                <td>{unidade.garagem}</td>
                                                <td>{unidade.tipologia}</td>
                                                <td>{unidade.valor}</td>
                                                <td>{unidade.valor_metro} &nbsp; </td>
                                                <td>{unidade.oferta_inicial}</td>
                                                <td style={{ display: 'table-cell' }}>{unidade.oferta_final}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="card-footer">
                            <button className="btn btn-sm btn-primary" onClick={this.props.handleHideClicked}>
                                Esconder Painel do Empreendimento
                            </button>
                        </div> */}
                    </div>
                </div>
            </>
        );
    }
}

export default EmpreendimentoCard;
