import React from 'react';
import {Modal} from 'react-bootstrap';
import './estilos.css';
import IsochroneService from "./IsochroneService";
import {Dialog} from "primereact/dialog";

import 'primeicons/primeicons.css';
import {Slider} from "primereact/slider";
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import {defaultPolygonOptions} from "../mapsDefaultOptions";
import {InputNumber} from "primereact/inputnumber";

const MAX_POLYGONS = 3;

class ModalRaioPorDeslocamento extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tipo_deslocamento: 'driving',
            tempos: [0],
            coresOptions: [
                { name: '#0e53ce', value: '#0e53ce'},
                { name: '#095609', value: '#095609'},
                { name: '#5d3d2a', value: '#5d3d2a'},
                { name: '#DC143C', value: '#DC143C'},
                { name: '#6007a9', value: '#6007a9'},
                { name: '#bd0f72', value: '#bd0f72'}
            ],
            cor: null,
            loading: false,
            labels:{
                'driving': 'De Carro',
                'bicycling': 'De Bicicleta',
                'walking': 'A Pé'
            },
            configOptions: [
                {
                    tempo: 0,
                    cor: null
                }
            ]
        };
    }

    handleSubmit = () => {
        let service = new IsochroneService();

        let {tipo_deslocamento, configOptions } = this.state;

        this.setState({
            ...this.state,
            loading: true
        })

        for (let i = 0; i < configOptions.length; i++) {
            let option = configOptions[i];
            service.compute({
                lat: this.props.center.lat,
                lng: this.props.center.lng,
                mode: this.state.tipo_deslocamento,
                type: 'duration',
                value: option.tempo * 60,
                slices: 16,
                cycles: 10,
                precision: 5,
                callback: async (status, positions) => {
                    if (status === 'OK') {

                        const polygon = new google.maps.Polygon({
                            ...defaultPolygonOptions,
                            paths: positions,
                            fillColor: 'transparent',
                            color: option.cor,
                            draggable: false,
                            strokeColor: option.cor,
                            strokeWeight: 4,
                            editable: false
                        });

                        let name = `Deslocamento ${this.state.labels[this.state.tipo_deslocamento]} em ${option.tempo} minutos`;

                        polygon.set('name', name);
                        let map = window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
                        polygon.setMap(map);

                        //no último poligono, fecha o modal e recarrega os empreendimentos
                        if (i === configOptions.length - 1) {
                            await this.setState({
                                ...this.state,
                                loading: false
                            })

                            this.props.handleClose();

                            await this.props.onPolygonComplete(polygon, true );


                        }else {
                            await this.props.onPolygonComplete(polygon, false);
                        }
                    }
                }
            });

        }


    }

    justifyOptions = [
        {icon: 'fa fa-car', value: 'driving', title: 'De Carro'},
        {icon: 'fa fa-bicycle', value: 'bicycling', title: 'De Bicicleta'},
        {icon: 'fa fa-person-walking', value: 'walking', title: 'A Pé'},
    ]

    justifyTemplate(option) {
        return <i title={option.title} className={option.icon}></i>;
    }

    colorTemplate(option) {
        return (
            <div className="country-item" style={{backgroundColor: option.value}}>
                <div>&nbsp;</div>
            </div>
        );
    }

    selectedOptionTemplate(option, props) {

        if (option) {
            return (
                <div className="country-item country-item-value" style={{backgroundColor: option.value}}>
                    <div>&nbsp;</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    handleColorChanged = ( e )=>{

        let options = this.state.configOptions;

        options[e.index].cor = e.value;

        this.setState({
            ...this.state,
            configOptions: [...options]
        })
    }

    handleTempoChanged = ( e )=>{

        let options = this.state.configOptions;

        options[e.index].tempo = e.value;

        this.setState({
            ...this.state,
            configOptions: [...options]
        })
    }


    render() {
        return (

            <Dialog
                header="Mapa Por Tempo de Deslocamento"
                visible={this.props.show}
                style={{width: '35vw', marginTop: '50px'}}
                onHide={this.props.handleClose}
                blockScroll={true}
            >
                <div className="form-group">
                    <SelectButton value={this.state.tipo_deslocamento}
                                  options={this.justifyOptions}
                                  onChange={(e) => this.setState({tipo_deslocamento: e.value})}
                                  itemTemplate={this.justifyTemplate}
                    />
                </div>

                {this.state.configOptions.map(( option, index) => {

                    let {tempo, cor} = option;
                    return (<React.Fragment key={index}>
                        <div className="form-group row">
                            <div className="col-5">
                                {index === 0 && <label htmlFor="">Tempo de Deslocamento (Em minutos):</label>}
                                <InputNumber
                                    style={{width: '100%'}}
                                    min={0}
                                    max={180}
                                    value={tempo} onChange={(e) => {
                                    this.handleTempoChanged({value: e.value, index})
                                }}/>
                                <Slider value={tempo}
                                        min={0}
                                        max={180}
                                        onChange={(e) => {
                                            this.handleTempoChanged({value: e.value, index})
                                        }}
                                />
                            </div>
                            <div className="col-5">
                                {index === 0 && <label htmlFor="raio">Cor de Preenchimento</label>}
                                <Dropdown
                                    style={{width: '100%'}}
                                    value={cor}
                                    options={this.state.coresOptions}
                                    optionLabel={"name"}
                                    onChange={(e)=>{
                                        this.handleColorChanged({value: e.value, index})
                                    }}
                                    itemTemplate={this.colorTemplate}
                                    valueTemplate={this.selectedOptionTemplate}
                                    placeholder="Selecione a Cor"
                                />
                            </div>

                            <div className="col-2">
                                {index > 0 && <>
                                    <button className="btn btn-sm btn-danger"
                                            onClick={()=>{
                                                let options = this.state.configOptions;
                                                options.splice(index, 1);
                                                this.setState({
                                                    ...this.state,
                                                    configOptions: [...options]
                                                })
                                            }}
                                            title={"Remover"}>
                                        <i className="fa fa-trash"/>
                                    </button>
                                    &nbsp;
                                </>}
                                {this.state.configOptions.length < MAX_POLYGONS && <>
                                    <button className="btn btn-sm btn-success"
                                            onClick={()=>{
                                                this.setState({
                                                    ...this.state,
                                                    configOptions: [...this.state.configOptions, {tempo: 0, cor: null}]
                                                })
                                            }}
                                            title={"Adicionar"}>
                                        <i className="fa fa-plus"/>
                                    </button>
                                </>}
                            </div>

                        </div>

                    </React.Fragment>);
                })}

                <div className="form-group">
                    <button className="btn btn-default btn-sm btn-block" disabled={this.state.loading}
                            onClick={this.handleSubmit}>
                        {this.state.loading && <i className="fa fa-spin fa-spinner"/>}
                        Desenhar
                    </button>
                </div>

            </Dialog>
        );
    }
}

export default ModalRaioPorDeslocamento;
