import React, {Component} from 'react';
import {InfoWindow, Marker} from 'react-google-maps';

class CenterMark extends Component {
    state = {
        showInfoWindow: false,
        animation: null
    };

    handleMouseOver = e => {
        this.setState({
            showInfoWindow: true
        });
    };

    getAnimation = () => {
        return this.state.animation;
    }

    setAnimation = (animation) => {
        this.setState({
            ...this.state,
            animation: animation
        });
    }

    handleMouseExit = e => {
        this.setState({
            showInfoWindow: false
        });
    };

    componentDidMount() {

    }

    render() {
        const {showInfoWindow} = this.state;

        return (
            <Marker position={this.props.position}
                //icon={this.props.icon}
                    onClick={() => {

                        this.props.onClick();


                    }}
                    onMouseOver={this.handleMouseOver}
                    onMouseOut={this.handleMouseExit}>
                        {showInfoWindow && (
                            <InfoWindow>
                                <div>
                                    <h5>Desenhar Raio Por deslocamento</h5>
                                    <button onClick={this.props.onClick}>Raio Por Deslocamento</button>
                                </div>
                            </InfoWindow>
                        )}
            </Marker>
        );
    }
}

export default CenterMark;
