import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Loader from "../Loader";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt_BR from 'date-fns/locale/pt-BR';
import ModalAudit from "./ModalAudit";

registerLocale('pt-BR', pt_BR);


export default class TableAuditLotsBasesIndex extends Component {

    constructor(props) {
        super();

        this.state = {
            bases: [],
            audit_lot: [],
            base_current_editing: null,
            loading: false,
            errors: [],
            show_modal: false,
            pagination: {
                total: 0,
                per_page: 20,
                current_page: 1,
                last_page: 1,
                from: 1,
                to: 0
            },
            filters: {
                cidade: '',
                data_inicial: '',
                data_final: '',
                separado_por: null
            },
            tables_links : []
        }
    }


    /**
     * Carrega os dados das bases do lote de auditoria
     * @returns {Promise<void>}
     */
    loadData = async () => {
        try {
            await this.setLoading(true);

            //filter
            let urlParams = {
                page: this.state.pagination.current_page,
                id : route().params.id
            }

            let response = await axios.get(route('web.auditoria_tabelas.bases-json', urlParams));


            if (response.status === 200) {

                this.setState({
                    ...this.state,
                    bases: response.data.bases,
                    audit_lot: response.data.audit_lot,
                    pagination: {
                        total: response.data.total,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        last_page: response.data.last_page,
                        from: response.data.from,
                        to: response.data.to
                    }, filters: {
                        ...this.state.filters,
                    }
                });
            }
        } catch (e) {
            if (e.response && e.response.status === 422) {
                this.setState({errors: e.response.data.errors});

            }
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }


    /**
     * Obtém os links dos arquivos de tabelas da base sendo auditada
     * @returns {Promise<void>}
     */
    loadTableLinks = async () => {

        let response = await axios.get( route('web.auditoria_tabelas.linksTabelas', {
            id : route().params.id,
            baseId : this.state.base_current_editing.id
        }) );

        if( response.status === 200 ){

            this.setState({
                ...this.state,
                tables_links : response.data
            })
        }
    }

    componentDidMount = async () => {


        try {
            await Promise.all([
                this.loadData(),
            ])
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Altera a página atual da paginação
     * @param page
     * @returns {Promise<void>}
     */
    alterPage = async (page) => {
        await this.setState({
            ...this.state,
            pagination: {
                ...this.state.pagination,
                current_page: page
            }
        });

        await this.loadData();
    }

    /**
     * Fecha ou abre o modal de auditoria
     */
    toggleModal() {
        this.setState({
            ...this.state,
            show_modal: !this.state.show_modal
        });
    }

    /**
     * Handler para atualizar o status da auditoria
     * @returns {Promise<void>}
     */
    handleUpdateAuditStatusSubmit = async ( data ) => {

        try{
            const response = await axios.put(route('web.auditoria_tabelas.updateResult', {
                id: this.state.audit_lot.id,
                baseId : this.state.base_current_editing.id
            }), {
                'audit_result': data.audit_result,
                'followup': data.followup
            });

            if (response.status === 200) {
                this.toggleModal();
                this.loadData();

                this.setState({
                    ...this.state,
                    base_current_editing: null
                });
            }
        }catch (e) {
            if( e.response && e.response.status === 422 ){
                this.setState({errors: e.response.data.errors});
            }

        }
    }

    /**
     * Handler para atualizar os dados da base em auditoria
     * @param e
     * @returns {Promise<void>}
     */
    handleChangeResultAudit = async (e) => {
        let value = e.target.value;

        await this.setState({
            ...this.state,
            base_current_editing: {
                ...this.state.base_current_editing,
                pivot: {
                    ...this.state.base_current_editing.pivot,
                    audit_result: value
                }
            }
        });
    }

    /**
     * Handler para atualizar o followup da base em auditoria
     * @param e
     * @returns {Promise<void>}
     */
    handleChangeFollowup = async (e) => {
        let value = e.target.value;

        await this.setState({
            ...this.state,
            base_current_editing: {
                ...this.state.base_current_editing,
                pivot: {
                    ...this.state.base_current_editing.pivot,
                    followup: value
                }
            }
        });
    }


    render() {


        return (<>
            {this.state.loading && <Loader/>}

            {this.state.show_modal && <ModalAudit
                base={this.state.base_current_editing}
                handleSubmit={this.handleUpdateAuditStatusSubmit}
                errors={this.state.errors}
                handleChangeResultAudit={this.handleChangeResultAudit}
                show={this.state.show_modal}
                handleClose={()=>{this.toggleModal(); }}
                tables_links={this.state.tables_links}
                handleChangeFollowup={this.handleChangeFollowup}
            />
            }



            <div className="card">
                <div className="card-header">



                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-6">
                            <h4> Bases Com Tabelas para Auditar</h4>
                        </div>
                        <div className="col-6">
                            <a href={route('web.auditoria_tabelas.index')} className="btn btn-success float-right">
                                Voltar para Auditoria de Tabelas
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '25px'}}>
                        <div className="col-12">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Empreendimento</th>
                                        <th>Tipo Empreendimento</th>
                                        <th>Status do Empreendimento</th>
                                        <th>Período</th>
                                        <th>Status da Auditoria</th>
                                        <th>Resultado da Auditoria</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.bases.map((base, index) => {
                                    const periodDate = new Date(base.period);
                                    return (
                                        <tr key={index}>
                                            <td>{base.building.name}</td>
                                            <td>{base.building.type}</td>
                                            <td>{base.status}</td>
                                            <td>{base.period_formatted}</td>
                                            <td>{base.pivot.audit_status}</td>
                                            <td>{base.pivot.audit_result || 'Não Auditado'}</td>

                                            <td >
                                                <a href="" className={'btn btn-link'}
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        await this.setState({
                                                            ...this.state,
                                                            base_current_editing: base
                                                        });
                                                        await this.loadTableLinks();
                                                        this.toggleModal();
                                                    }}
                                                >
                                                    <i className="fa fa-eye"></i>&nbsp;
                                                    Auditar
                                                </a>
                                            </td>
                                        </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">

                        <div className="col-12">
                            <nav aria-label="navigation" style={{float: "right"}}>
                                <ul className="pagination">
                                    {this.state.pagination.current_page > 1 &&
                                        <li className="page-item">
                                            <a className="page-link" href="#">Anterior</a>
                                        </li>
                                    }

                                    {this.state.pagination.last_page > 1 && [...Array(this.state.pagination.last_page)].map((page, index) => {
                                        let pageNumber = index + 1;
                                        return (

                                            <li className={(pageNumber === this.state.pagination.current_page) ? 'page-item active' : 'page-item '}
                                                key={index}>
                                                <a
                                                    className={`page-link`}
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.alterPage(pageNumber);
                                                    }}>
                                                    {pageNumber}
                                                </a>
                                            </li>
                                        )
                                    })}

                                    {this.state.pagination.current_page < this.state.pagination.last_page &&
                                        <li className="page-item">
                                            <a className="page-link" href="#" onClick={(e) => {
                                                e.preventDefault();
                                                this.alterPage(this.state.pagination.current_page + 1);
                                            }}>
                                                Próximo
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
        </>);
    }


}


if (document.getElementById('js-list-audit-lots-bases')) {
    ReactDOM.render(<TableAuditLotsBasesIndex/>, document.getElementById('js-list-audit-lots-bases'));
}
