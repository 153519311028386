import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import TablePadroesInfoComercial from "./TablePadroesInfoComercial";

class ModalPadraoInfoCasas extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Modal
                size={'lg'}
                show={this.props.show}
                onHide={this.props.closeModal}
                // handleClose={}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Title>
                    <div className="text-center">
                        <label >Padrão do Imóvel</label>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <div className="text-justify">
                        <label for="">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            Classificação do padrão de valor de um empreendimento conforme o ticket médio de vendas de suas unidades em termos de faixas de valor. O padrão Econômico terá como limite superior o teto do programa Minha Casa Minha Vida. Segue tabela:
                        </label>
                    </div>


                    <table className="table table-striped table-condensed table-padroes">
                        <thead>
                            <tr
                                className="text-center"
                                style={{
                                    backgroundColor: '#5B7537',
                                    color: "#ffffff",
                                    fontWeight: "bold",
                                }}
                            >
                                <th
                                    className="text-center"
                                    style={{ color: "#ffffff", padding: "7px" }}
                                >
                                    <span style={{ fontWeight: "bold" }}>Padrão</span>
                                </th>
                                <th
                                    className="text-center"
                                    style={{ color: "#ffffff", padding: "7px" }}
                                >
                                    <span style={{ fontWeight: "bold" }}>
                                        Faixa de Valor
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-center">
                                <td className="text-center">Econômico</td>
                                <td className="text-center">
                                    Teto Minha Casa Minha Vida
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td className="text-center">Standard</td>
                                <td className="text-center">
                                    de Teto Minha Casa Minha Vida até 700.000
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td className="text-center">Médio</td>
                                <td className="text-center">
                                    de 700.001 até 1.000.000
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td className="text-center">Alto</td>
                                <td className="text-center">
                                    de 1.000.001 até 1.500.000
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td className="text-center">Luxo</td>
                                <td className="text-center">
                                    de 1.500.001 até 3.000.000
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td className="text-center">Super Luxo</td>
                                <td className="text-center">Acima de 3.000.001</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>

                <Modal.Footer>
                    <div className="col-8">
                    </div>
                    <div>
                        <button className={'btn btn-primary button-brain btn-round'} onClick={this.props.closeModal}>Fechar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalPadraoInfoCasas;
