import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Loader from "./Loader";
import { ToastContainer, toast } from 'react-toastify';
import Axios from "axios";
import AsyncSelect from "react-select/async";
import EnderecoEnprendimentoPartialForm from "./TicketsSupport/EnderecoEnprendimentoPartialForm";
import cep from "cep-promise";
import IntlCurrencyInput from "react-intl-currency-input";
import EstoqueValoresForm from "./TicketsSupport/EstoqueValoresForm";

export default class FormTicketSuport extends Component {

    constructor(props) {
        super();

        this.state = {
            nome: '',
            building_id: '',
            assunto: '',
            cidade: '',
            mensagem: '',
            anexos: [],
            errors: [],
            loading: false,
            assuntos: ['Sugerir Inclusão de Empreendimento não Cadastrado', 'Informações de Empreendimento faltantes e ou incorretas', 'Outros - Descreva no campo Mensagem'],
            observacoes: {
                "Informações de Empreendimento faltantes e ou incorretas": ['Localização Incorreta', 'Estoque e/ou valor incorretos', 'Informações Gerais Incorretas'],
                "Outros - Descreva no campo Mensagem": ['Envio de Material', 'Dúvida', 'Erro no Sistema']
            },
            observacao: '',
            bairro: '',
            cep: '',
            endereco: '',
            numero: '',
            complemento: '',
            longitude: '',
            latitude: '',
            cidade_empreendimento: '',
            estado_empreendimento: '',
            tipologias: [],
        }
    }

    searchEmpreendimentos = async (inputQuery) => {
        if (inputQuery.length > 1) {
            try {

                let params = {
                    term: inputQuery
                };

                if (this.state.cidade !== '') {

                    let parts = this.state.cidade.value.split('-');

                    params.cidade = parts[0];
                    params.estado = parts[1];
                }

                let endpoint = route('search-buildings', params);

                let empreendimentos = await axios.get(endpoint);

                return empreendimentos.data;
            } catch (error) {
                console.log(error);
            }
        }
    }

    searchCidade = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get('/cidades/json-options-with-state-on-values/' + inputQuery);
            return response.data;

        }
    }

    updateTipologias = async (tipologias) => {
        await this.setState({
            ...this.state,
            tipologias: tipologias
        }
        );
    }

    submitForm = async (e) => {
        e.preventDefault();

        try {
            await this.setLoading(true);
            await this.setState({
                ...this.state, errors: [],
            });

            const data = new FormData();

            Object.keys(this.state).forEach(key => {
                if (key !== 'errors' && key !== 'loading' && key !== 'assuntos' && key !== 'anexos') {
                    if (this.state[key] !== null && this.state[key] !== undefined && this.state[key] !== '') {
                        let value = this.state[key];

                        if (key === 'tipologias') {
                            value = JSON.stringify(this.state[key]);
                        }

                        data.append(key, value);

                    }
                }
            });

            for (const key in Object.keys(this.state.anexos)) {
                data.append('anexos[]', this.state.anexos[key]);
            }

            if (this.state.cidade !== '') {
                let parts = this.state.cidade.value.split('-');
                data.append('cidade', parts[0].trim());
                data.append('estado', parts[1].trim());
            }

            if (this.state.building_id !== '') {
                data.delete('nome');
                data.append('empreendimento', this.state.building_id);
            }

            this.setState({ loading: true });

            let response = await axios.post(route('ticket.store'), data);

            if (response.status === 201) {

                toast.success(`Ticket ${response.data.id} Criado com sucesso!`);


                setTimeout(() => {
                    window.location.href = route('tickets.index');
                }, 2000);
            }

        } catch (e) {

            if (e.response && e.response.status === 422) {
                this.setState({ errors: e.response.data.errors });
            }

            console.log(e);
        } finally {
            this.setLoading(false);
        }


    }

    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }


    componentDidMount = async () => {

        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('assunto') && urlParams.get('assunto') === 'sugerir_inclusao') {
            await this.setState({
                ...this.state,
                assunto: 'Sugerir Inclusão de Empreendimento não Cadastrado'
            });
        }

        if (urlParams.has('empreendimento')) {
            try {
                await this.setLoading(true);

                let response = await axios.get(route('building.show', urlParams.get('empreendimento')));

                if (response.status === 200) {
                    this.setState({
                        ...this.state,
                        nome: response.data.building.name,
                        building_id: response.data.building.id,
                        assunto: 'Informações de Empreendimento faltantes e ou incorretas',
                        cidade: {
                            label: response.data.building.city?.city + ' - ' + response.data.building.city?.state,
                            value: response.data.building.city?.city + '-' + response.data.building.city?.state
                        },
                        empreendimento: {
                            value: response.data.building.id,
                            label: response.data.building.name + ' - ' + response.data.building.city?.city + ' - ' + response.data.building.city?.state
                        },
                        bairro: response.data.building.neighborhood,
                        cep: response.data.building.zip_code,
                        endereco: response.data.building.address,
                        numero: response.data.building.number,
                        complemento: response.data.building.complement,
                        longitude: response.data.building.longitude,
                        latitude: response.data.building.latitude,
                        cidade_empreendimento: response.data.building.city?.city,
                        estado_empreendimento: response.data.building.city?.state,
                        tipologias: response.data.tipologias
                    });
                }
            } catch (e) {
                console.log(e);
            } finally {
                await this.setLoading(false);
            }
        }
    }

    onChangefile = async (e) => {
        this.setState({
            ...this.state, anexos: e.target.files
        });
    }

    onChangeNome = async (e) => {
        this.setState({
            ...this.state, nome: e.target.value
        });
    }

    onChangeAssunto = (e) => {
        this.setState({
            ...this.state, assunto: e.target.value
        });
    }

    onChangeObservacao = (e) => {
        this.setState({
            ...this.state, observacao: e.target.value
        });
    }

    onChangeCidade = (e) => {
        this.setState({
            ...this.state, cidade: e.target.value
        });
    }

    onChangeMensagem = (e) => {
        this.setState({
            ...this.state, mensagem: e.target.value
        });
    }

    onChangeLongitude = async (e) => {
        this.setState({
            ...this.state, longitude: e.target.value
        });

        this.centerMap();
    }

    onChangeLatitude = async (e) => {
        await this.setState({
            ...this.state, latitude: e.target.value
        });

        this.centerMap();
    }

    centerMap = () => {
        let location = {
            lat: parseFloat(this.state.latitude),
            lng: parseFloat(this.state.longitude)
        };

        window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setCenter(location);

        window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(18);

        window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.panTo(
            location
        );

    }

    onChangeEndereco = (e) => {
        this.setState({
            ...this.state, endereco: e.target.value
        });
    }

    onChangeBairro = (e) => {
        this.setState({
            ...this.state, bairro: e.target.value
        });
    }

    onChangeComplemento = (e) => {
        this.setState({
            ...this.state, complemento: e.target.value
        });
    }

    onChangeCep = async (e) => {

        let cepInput = e.target.value.replace(/\D/g, '');

        await this.setState({
            ...this.state, cep: cepInput
        });

        try {
            if (cepInput.length >= 8) {
                let cepResponse = await cep(cepInput);
                console.log(cepResponse);

                if (cepResponse) {
                    this.setState({
                        ...this.state,
                        endereco: cepResponse.street,
                        bairro: cepResponse.neighborhood,
                        cidade_empreendimento: cepResponse.city,
                        estado_empreendimento: cepResponse.state

                    });
                }
            }


        } catch (e) {
            console.log(e);
        }


    }

    getCoordinatesFromAddress = (e) => {

        let address = this.state.endereco + ', ' + this.state.numero + ', ' + this.state.bairro + ', ' + this.state.cidade_empreendimento + ', ' + this.state.estado_empreendimento + ', ' + this.state.cep;
        const endpoint = `https://apicrawley.hqssolucoes.com.br/coordenadas?end=${encodeURI(address)}`;

        fetch(endpoint).then(async (response) => response.json())
            .then(async (json) => {
                console.log(json);
                let latitude = json.latitude;
                let longitude = json.longitude;

                await this.setState({
                    ...this.state,
                    latitude: latitude,
                    longitude: longitude
                })

                this.centerMap();
            });
    }


    onChangeCidadeEmpreendimento = (e) => {
        this.setState({
            ...this.state, cidade_empreendimento: e.target.value
        });
    }

    onChangeEstadoEmpreendimento = (e) => {
        this.setState({
            ...this.state, estado_empreendimento: e.target.value
        });
    }

    onChangeNumero = (e) => {
        this.setState({
            ...this.state, numero: e.target.value
        });
    }

    setLocation = async (markerDragEvent) => {
        await this.setState({
            ...this.state,
            latitude: markerDragEvent.latLng.lat(),
            longitude: markerDragEvent.latLng.lng()
        });

        this.centerMap();
    }

    onChangeEmpreendimento = async (e) => {
        this.setState({
            ...this.state,
            empreendimento: e,
            building_id: e.value
        });

        let buildingId = e.value;

        if (buildingId !== '') {

            let response = await axios.get(route('building.show', buildingId));

            if (response.status === 200) {

                this.setState({
                    ...this.state,
                    bairro: (response.data.building.neighborhood) ? response.data.building.neighborhood : null,
                    cidade_empreendimento: (response.data.building?.city.city) ? response.data.building?.city.city : null,
                    estado_empreendimento: (response.data.building?.city.state) ? response.data.building?.city.state : null,
                    endereco: (response.data.building.address) ? response.data.building.address : null,
                    numero: (response.data.building.number) ? response.data.building.number : null,
                    complemento: (response.data.building.complement) ? response.data.building.complement : null,
                    cep: response.data.building.zip_code ?? null,
                    latitude: parseFloat(response.data.building.latitude),
                    longitude: parseFloat(response.data.building.longitude),
                    tipologias: response.data.tipologias

                });
            }
        }
    }


    render() {
        return (<>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{ width: "50%", zIndex: 9999999, fontWieght: 'bold' }}
            />

            <div className="card ">
                {this.loading && <Loader />}
                <div className="card-header">
                    {/* <h4 className="card-title">Contato</h4> */}
                </div>
                <div className="card-body ">

                    <form method="post" className="form-horizontal">
                        <div className="form-group">
                            <label htmlFor="assunto">Assunto</label>
                            <select name="assunto" id="assunto" onChange={this.onChangeAssunto}
                                value={this.state.assunto}
                                className={`form-control ` + (this.state.errors.assunto ? 'is-invalid' : '')}>
                                <option value="">Selecione</option>
                                {this.state.assuntos.map((assunto, key) => {
                                    return <option key={key} value={assunto}>{assunto}</option>
                                })}
                            </select>

                            {this.state.errors['assunto'] &&
                                <div className="invalid-feedback" style={{ display: "block" }}>
                                    {this.state.errors['assunto'][0]}
                                </div>}

                        </div>
                        {(this.state.assunto === 'Informações de Empreendimento faltantes e ou incorretas'
                            || this.state.assunto === 'Outros - Descreva no campo Mensagem') &&
                            <React.Fragment>
                                <div className="form-group">
                                    <label htmlFor="observacao">Observação</label>
                                    <select name="observacao" id="assunto" onChange={this.onChangeObservacao}
                                        value={this.state.observacao}
                                        className={`form-control ` + (this.state.errors.observacao ? 'is-invalid' : '')}>
                                        <option value="">Selecione</option>
                                        {this.state.observacoes[this.state.assunto].map((observacao, key) => {
                                            return <option key={key} value={observacao}>{observacao}</option>
                                        })}
                                    </select>

                                    {this.state.errors['observacao'] &&
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {this.state.errors['observacao'][0]}
                                        </div>
                                    }
                                </div>

                            </React.Fragment>}
                        <div className="form-group">
                            <label htmlFor="">Cidade</label>
                            <AsyncSelect
                                cacheOptions
                                value={this.state.cidade}
                                loadOptions={this.searchCidade}
                                onChange={async (e) => {
                                    await this.setState({
                                        ...this.state,

                                        cidade: e

                                    });
                                }}
                            />
                            {this.state.errors['cidade'] &&
                                <div className="invalid-feedback" style={{ display: "block" }}>
                                    {this.state.errors['cidade']}
                                </div>}
                        </div>
                        {
                            this.state.assunto === 'Sugerir Inclusão de Empreendimento não Cadastrado' &&
                            <div className="form-group">
                                <label htmlFor="nome">Nome do Empreendimento</label>
                                <input type="text"
                                    className={`form-control ` + (this.state.errors.nome ? 'is-invalid' : '')}
                                    name="nome"
                                    onChange={this.onChangeNome}
                                    id="nome" placeholder="Nome do Empreendimento"
                                    value={this.state.nome} />

                                {this.state.errors['nome'] &&
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {this.state.errors['nome']}
                                    </div>}
                            </div>
                        }
                        {
                            this.state.assunto !== 'Sugerir Inclusão de Empreendimento não Cadastrado' &&
                            <div className="form-group">
                                <label htmlFor="">Empreendimento</label>
                                <AsyncSelect
                                    cacheOptions
                                    value={this.state.empreendimento}
                                    defaultOptions
                                    loadOptions={this.searchEmpreendimentos}
                                    onChange={this.onChangeEmpreendimento}


                                />

                                {this.state.errors['empreendimento'] &&
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {this.state.errors['empreendimento']}
                                    </div>}
                            </div>
                        }

                        {this.state.observacao === 'Localização Incorreta' && <React.Fragment>
                            <EnderecoEnprendimentoPartialForm
                                onChangeEndereco={this.onChangeEndereco}
                                onChangeBairro={this.onChangeBairro}
                                onChangeComplemento={this.onChangeComplemento}
                                onChangeCep={this.onChangeCep}
                                onChangeCidadeEmpreendimento={this.onChangeCidadeEmpreendimento}
                                onChangeEstadoEmpreendimento={this.onChangeEstadoEmpreendimento}
                                onChangeNumero={this.onChangeNumero}
                                onChangeLatitude={this.onChangeLatitude}
                                onChangeLongitude={this.onChangeLongitude}
                                setLocation={this.setLocation}
                                getCoordinatesFromAddress={this.getCoordinatesFromAddress}
                                state={this.state}
                            />

                        </React.Fragment>}

                        {this.state.observacao === 'Estoque e/ou valor incorretos' &&
                            <EstoqueValoresForm state={this.state}
                                updateTipologias={this.updateTipologias}
                            />}


                        <div className="form-group">
                            <label htmlFor="mensagem">Mensagem</label>
                            <textarea className={`form-control ` + (this.state.errors.mensagem ? 'is-invalid' : '')}
                                onChange={this.onChangeMensagem}
                                value={this.state.mensagem}
                                name="mensagem" style={{ height: '200px' }}
                                id="mensagem"></textarea>
                            {this.state.errors['mensagem'] &&
                                <div className="invalid-feedback" style={{ display: "block" }}>
                                    {this.state.errors['mensagem'][0]}
                                </div>}
                            <small className="form-text text-muted">Forneça o detalhamento da informação. Caso
                                esteja
                                sugerindo a inserção de Empreendimento forneça os dados de Endereço do
                                Empreendimento</small>
                        </div>
                        <div className="form-group">
                            <input type="file" name="anexos[]" onChange={this.onChangefile} size="2" id="anexos"
                                multiple="multiple" />
                            {this.state.errors['anexos'] &&
                                <div className="invalid-feedback" style={{ display: "block" }}>
                                    {this.state.errors['anexos'][0]}
                                </div>}
                        </div>
                        <button className="btn btn-primary button-brain btn-round" onClick={this.submitForm}>Enviar</button>
                    </form>

                </div>
            </div>
        </>);
    }
}

if (document.getElementById('js-form-ticket')) {
    ReactDOM.render(<FormTicketSuport />, document.getElementById('js-form-ticket'));
}
