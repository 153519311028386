import React, { Component } from "react";
import Axios from "axios";
class Table extends Component {
    state = {

    }
    destroy = async (id) => {
        await Axios.post(route('destroy-regiao-administrativa-by-id', id),
            { method: "DELETE" }
        ).then((response) => {
            this.props.getRegioes()
        }).catch().finally()
    }
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <table id="datatables" className="table table-striped table-condensed table-padroes">
                        <thead>
                            <tr>
                                <th className="text-center">Região</th>
                                <th className="text-center">Criado Por</th>
                                <th className="text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.state.regioes.map((index, key) => {
                                return (
                                    <tr key={key} id={index.id}>
                                        <td>{index.name}</td>
                                        <td>{index.user?.name}</td>
                                        <td>
                                            <div className="row">
                                                <div className="col-2 ">
                                                    <a href={"regiao-administrativa/" + index.id}>
                                                        <i className="fa-solid fa-pen"></i>
                                                    </a>
                                                </div>
                                                <div className="col-2 ">
                                                    <a className="btn btn-sm btn-danger" onClick={() => this.destroy(index.id)}>
                                                        <i className="fa-solid fa-times"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}

export default Table;
