import React from 'react';
import {Dialog} from 'primereact/dialog';
import {ColorPicker} from 'primereact/colorpicker';
import Slider from '@material-ui/core/Slider';
import {InputSwitch} from 'primereact/inputswitch';


class ShapesAttributesEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shape: null,
            options: {
                fillColor: 'transparent',
                fillOpacity: 0.5,
                strokeColor: '#000000',
                strokeWeight: 3,
                draggable: false,
                clickable: true,
                editable: true,
                zIndex: 1,
                radius: 0
            }
        };
    }

    componentDidMount() {
        this.setState({
            shape: this.props.shape,
            options: {
                fillColor: this.props.shape?.fillColor || 'transparent',
                fillOpacity: this.props.shape?.fillOpacity || 0.5,
                strokeColor: this.props.shape?.strokeColor || '#000000',
                strokeWeight: this.props.shape?.strokeWeight || 3,
                draggable: this.props.shape?.draggable,
                clickable: this.props.shape?.clickable,
                editable: this.props.shape?.editable,
                radius: this.props.shape?.radius
            }
        });
    }

    onChangeCorPreenchhimento = (e) => {

        let hexColor = `#${e.value}`;

        this.setState({
            ...this.state,
            options: {
                ...this.state.options,
                fillColor: hexColor
            }
        });
    }

    onChangeCorBorda = (e) => {
        let hexColor = `#${e.value}`;

        this.setState({
            ...this.state,
            options: {
                ...this.state.options,
                strokeColor: hexColor
            }
        });


    }

    onChangeEspessuraBorda = (e, newValue ) => {

        this.setState({
            ...this.state,
            options: {
                ...this.state.options,
                strokeWeight: newValue
            }
        });
    }

    onChangeOpacidadePreenchimento = (e, newValue ) => {
        this.setState({
            ...this.state,
            options: {
                ...this.state.options,
                fillOpacity: newValue
            }
        });
    }

    onChangeArrastavel = (e) => {
        let value = e.value;

        this.setState({
            ...this.state,
            options: {
                ...this.state.options,
                draggable: value
            }
        });
    }

    onChangeEditavel = (e) => {

        let value = e.value;

        this.setState({
            ...this.state,
            options: {
                ...this.state.options,
                editable: value
            }
        });
    }


    onChangeName = (e) => {
        let shape = this.state.shape;

        shape.name = e.target.value;

        this.setState({
            shape: shape
        });
    }


    static getDerivedStateFromProps(props, state) {
        if (props.shape !== state.shape) {
            return {
                shape: props.shape,
                options: {
                    fillColor: props.shape?.fillColor,
                    fillOpacity: props.shape?.fillOpacity,
                    strokeColor: props.shape?.strokeColor,
                    strokeWeight: props.shape?.strokeWeight,
                    draggable: props.shape?.draggable,
                    clickable: props.shape?.clickable,
                    editable: props.shape?.editable,
                    radius: props.shape?.radius
                }
            };
        }
        return null;
    }

    onChangeRadius = (e) => {
        this.setState({
            ...this.state,
            options: {
                ...this.state.options,
                radius: parseFloat(e.target.value)
            }
        });
    }

    submit = () => {
        this.state.shape.setOptions(this.state.options);

        if(this.state.shape.type === 'Círculo') {
            this.state.shape.setRadius(this.state.options.radius);
        }

        this.props.updateShape(this.state.shape);
    }


    render() {
    return (
      <div>
          <Dialog header="Editar Forma" visible={this.props.show} style={{width: '40vw', marginTop: '10px'}} modal onHide={this.props.onHide}>
              <div className="row">
                  <div className="col-12">
                      <div className="form-group">
                          <label htmlFor="shapeName">Rótulo</label><br />
                          <input type="text" id="shapeName"
                                 className="form-control"
                                 value={this.state.shape?.name}
                                 onChange={this.onChangeName}/>
                      </div>
                      <div className="form-group">
                          <div className="d-flex">
                              <div style={{width: '50%'}}>
                                  <label htmlFor="shapeColor">Cor de Preenchimento</label><br/>
                                  <ColorPicker
                                      value={this.state.options.fillColor}
                                      onChange={this.onChangeCorPreenchhimento}/>
                              </div>
                              <div style={{width: '50%'}}>
                                  <label htmlFor="shapeColor">Cor da Borda</label> <br/>
                                  <ColorPicker
                                      value={this.state.options?.strokeColor}
                                      onChange={this.onChangeCorBorda}
                                  />
                              </div>

                          </div>
                      </div>

                      <div className="form-group">
                          <label htmlFor="shapeColor">Opacidade do Preenchimento</label><br/>

                          <Slider
                              value={this.state.options?.fillOpacity}
                              aria-labelledby="discrete-slider-small-steps"
                              step={0.1}
                              marks
                              min={0.1}
                              max={1}
                              valueLabelDisplay="on"
                              onChange={this.onChangeOpacidadePreenchimento}
                          />

                      </div>

                      <div className="form-group">
                          <label htmlFor="shapeColor">Espessura da Borda</label> <br />
                            <Slider
                                    min={1} max={10}
                                    value={this.state.options?.strokeWeight}
                                    onChange={this.onChangeEspessuraBorda}
                                    valueLabelDisplay="on"
                                    aria-labelledby="discrete-slider-small-steps"
                                    step={1}
                                    marks
                            />
                      </div>

                      {this.state.shape?.type === 'Círculo' && <>
                          <div className="form-group">
                              <label htmlFor="shapeRadius">Raio</label>
                              <input type="number" id="shapeRadius"
                                     className="form-control"
                                     value={this.state.options?.radius}
                                     onChange={this.onChangeRadius}/>
                          </div>
                      </>}

                      <div className="form-group">
                          <div className="d-flex">
                              <div style={{width: '50%'}}>
                                  <label htmlFor="shapeColor">Arrastável</label> <br />
                                  <InputSwitch
                                      checked={this.state.options.draggable}
                                      onChange={this.onChangeArrastavel}
                                  />
                              </div>
                              <div style={{width: '50%'}}>
                                  <div className="form-group">
                                      <label htmlFor="shapeColor">Editável</label> <br />
                                      <InputSwitch checked={this.state.options.editable}
                                                   onChange={this.onChangeEditavel}/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-12">
                      <button className="btn btn-sm btn-primary btn-block" onClick={this.submit}>Aplicar</button>
                  </div>
              </div>
          </Dialog>
      </div>
    );
    }
}

export default ShapesAttributesEditor;
