import React, {Fragment} from 'react';
import { getPercentageFromTotal, formatPercentage } from '../../../util/helpers';
import './estilos.css';

const colunas = {
    'apartamentos' : 'Apartamentos',
    'indice_verticalizacao' : 'Indice de Verticalização',
    'total' : 'Total de Moradias',
}

class IndiceVerticalizacao extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            headers: [],
            config: {
                dados:{},
                title: '',
                data_label: ''
            },
            downloading: false
        }
    }

    static getDerivedStateFromProps(props, state) {

        let dataFormatter = props.formatter;

        console.log("Formatter");
        console.log( dataFormatter );

        return {
            data: dataFormatter?.dataCollection,
            headers: dataFormatter?.headers,
            config: dataFormatter?.config,
        }
    }

    exportExcel = async() => {
        try{

            this.setState({
                downloading: true
            });

            let dadosKeys = Object.keys(this.state.config?.dados);
            let collectionKeys = Object.keys(this.state.data);

            let payload = {
                config: this.state.config,
                headers: this.state.headers,
                dadosKeys: dadosKeys,
                collectionKeys: collectionKeys,
                data: this.state.data
            }

            let response = await axios.post( route('web.export.dados.sociodemografia'), payload, {
                responseType: 'blob'
            });

            let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download =  response.headers['content-disposition'].split('filename=')[1];
            a.click();
            window.URL.revokeObjectURL(url);

        }catch (e) {
            console.log(e);
        }finally {
            this.setState({
                downloading: false
            });
        }

    }


    formatNumber = (number) => {
        return new Intl.NumberFormat('pt-BR').format(Number(number));
    }

    /**
     * * Alta verticalização: acima de 60%
     * * Média verticalização: de 40% a 60%
     * * Baixa verticalização: de 1% a 40%
     * * Sem verticalização: abaixo de 1%
     * @param numeroApartamentos
     * @param totalMoradias
     */
    getIndiceVerticalizacao = ( numeroApartamentos, totalMoradias )=>{
        let indice = Number(numeroApartamentos) / Number(totalMoradias) * 100;

        if( indice > 60 ){
            return 'Alta';
        }

        if( indice >= 40 && indice <= 60 ){
            return 'Média';
        }

        if( indice >= 1 && indice < 40 ){
            return 'Baixa';
        }

        return 'Sem Verticalização';
    }

    render() {

        let dadosKeys = Object.keys(this.state.config?.dados);
        let collectionKeys = Object.keys(this.state.data);

        return (
            <div style={{overflowX: 'scroll'}}>
                <h1 style={{marginBottom: '15px'}}>{this.state?.config?.title}</h1>
                <table className="table table-striped" style={{borderBottom: '1px solid #ccc'}}>
                    <thead>
                    <tr style={{
                        backgroundColor: '#5B7537',
                        borderColor: '#5B7537',
                        color: 'white',
                    }}>
                        <th style={{color: 'white', fontWeight: 'bold'}} scope="col" className="text-center">
                            {this.state.config?.data_label}
                        </th>

                        {this.state.headers.map((header, index) => {

                            return (
                                <th key={`header-faixa-etaria-${index}`}
                                    style={{color: 'white', fontWeight: 'bold'}} scope="col" className="text-center">
                                    {header}
                                </th>
                            );
                        })}

                    </tr>

                    </thead>
                    <tbody>
                    {dadosKeys.map((key, index) => {

                        let dado = this.state.config?.dados?.[key];


                        return (

                            <Fragment key={`row-${key}-${index}`}>
                                <tr style={{fontWeight: 'bold'}} scope="col" className="text-center">
                                    <td>{dado}</td>

                                    {collectionKeys.map((collectionKey, index) => {
                                        let dadoValue = this.formatNumber(this.state.data[collectionKey]?.items?.[0]?.[key]);

                                        if (key === 'indice_verticalizacao') {
                                            let apartamentos = this.state.data[collectionKey]?.items?.[0]?.['apartamentos'];
                                            let total = this.state.data[collectionKey]?.items?.[0]?.['total'];
                                            dadoValue = this.getIndiceVerticalizacao(apartamentos, total);
                                        }

                                        return (
                                            <Fragment key={collectionKey}>
                                                <td className="text-right">
                                                    {dadoValue}
                                                </td>
                                            </Fragment>
                                        );

                                    })}
                                </tr>
                            </Fragment>
                        );
                    })}
                    <tr style={{fontWeight: 'bold', borderTopWidth: '4px', backgroundColor: '#ccc'}} scope="col"
                        className="text-center">
                        <td style={{fontWeight: 'bold'}}>TOTAL DE MORADIAS</td>
                        {collectionKeys.map((collectionKey, index) => {
                            return (
                                <Fragment key={collectionKey}>
                                    <td className="text-right" style={{fontWeight: 'bold'}}>
                                        {this.formatNumber(this.state.data[collectionKey]?.items?.[0]?.['total'])}
                                    </td>
                                </Fragment>
                            );
                        })}
                    </tr>
                    </tbody>
                </table>
                <div style={{marginTop: '20px', height: '40px'}}>
                    <button className="btn btn-default pull-right" onClick={this.exportExcel}>
                        Exportar Excel &nbsp;
                        <i className="fas fa-file-excel"></i>
                        &nbsp;
                        {this.state.downloading && <i className="fas fa-spinner fa-spin"></i>}

                    </button>
                </div>

                <hr/>


                <div className="alert alert-info">
                    <p>Indice de Verticalização = Número de Apartamentos / Número total de Moradias.</p>
                    <p>Alta verticalização: acima de 60%</p>
                    <p>Média verticalização: de 40% a 60%</p>
                    <p>Baixa verticalização: de 1% a 40%</p>
                    <p>Sem verticalização: abaixo de 1%</p>

                </div>
            </div>
        );
    }
}

export default IndiceVerticalizacao;
