import Axios from "axios";

class GeolocationService {

    reverse = async (latitude, longitude, place ) => {
        try {

            const url = `/geolocation/reverse/${latitude}/${longitude}`;
            const response = await axios.get(url);
            return response.data;


        } catch (error) {

            console.log('Opa Caiu um erro aqui');
            let url = route('geocoder-from-place-info');

            let response = await axios.post(url, place[0] );

            if( response.status === 200 ){
                return  response.data;
            }
        }
    }

    location = async (address) => {

        const endpoint = `https://apicrawley.hqssolucoes.com.br/coordenadas?end=${address}`;

        let response = await axios.get( endpoint );

        console.log( response );
        return response.data;
    }



    getCityBoundariesPolygon = async (cidade, estado) => {
        let url = `https://nominatim.openstreetmap.org/search.php?q=${cidade},${estado}&county=brazil&polygon_geojson=1&format=json`;

        try {
            let response = await axios.get(url)

            console.log(response.data[0].geojson);

            let coordinates = response.data[0].geojson.coordinates[0];
            let responseCoordinates = [];

            console.log(coordinates.length);

            for (let x = 0; x < coordinates.length; x++) {
                let current = coordinates[x];

                let returnPosition = [parseFloat(current[1]), parseFloat(current[0])];

                responseCoordinates.push(returnPosition);

            }

            return responseCoordinates;

        } catch (error) {
            console.log(error);
        }
    }

}

export default new GeolocationService();
