import React, { Component } from "react";
import Select from "react-select";
import MultiSelect from "react-multi-select-component";
import Legenda from "./Legenda";
import AsyncSelect from "react-select/async";
import Axios from "axios";

import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from "react-places-autocomplete";

import IntlCurrencyInput from "react-intl-currency-input";

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

class FormFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            estados: [
                { value: "AC", label: "Acre" },
                { value: "AL", label: "Alagoas" },
                { value: "AP", label: "Amapá" },
                { value: "AM", label: "Amazonas" },
                { value: "BA", label: "Bahia" },
                { value: "CE", label: "Ceará" },
                { value: "DF", label: "Distrito Federal" },
                { value: "ES", label: "Espírito Santo" },
                { value: "GO", label: "Goías" },
                { value: "MA", label: "Maranhão" },
                { value: "MT", label: "Mato Grosso" },
                { value: "MS", label: "Mato Grosso do Sul" },
                { value: "MG", label: "Minas Gerais" },
                { value: "PA", label: "Pará" },
                { value: "PB", label: "Paraíba" },
                { value: "PR", label: "Paraná" },
                { value: "PE", label: "Pernambuco" },
                { value: "PI", label: "Piauí" },
                { value: "RJ", label: "Rio de Janeiro" },
                { value: "RN", label: "Rio Grande do Norte" },
                { value: "RS", label: "Rio Grande do Sul" },
                { value: "RO", label: "Rondônia" },
                { value: "RR", label: "Roraíma" },
                { value: "SC", label: "Santa Catarina" },
                { value: "SP", label: "São Paulo" },
                { value: "SE", label: "Sergipe" },
                { value: "TO", label: "Tocantins" },
            ],
            cidades: [],
            address: "",
            types: [],
        };
    }

    loadTypes = async () => {
        try {
            let response = await axios.get(route("polos-geradores-tipos"));

            if (response.status == 200) {
                let types = response.data.types;

                let options = [];

                types.forEach((type) => {
                    options.push({ value: type, label: type });
                });

                this.setState({ types: options });
            }
        } catch (e) {
            console.log(e);
        } finally {
        }
    };

    componentDidMount = async () => {
        this.props.getProjects();
        this.loadTypes();
    };

    searchCidade = async (inputQuery) => {
        let state = this.props.estadosSelecionados != null
        ? this.props.estadosSelecionados.value 
        : null;
        
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/cidades/json-options-by-state/" + state + "/" + inputQuery
            );
            return response.data;
        }
    }

    handleChange = (address) => {
        this.setState({ ...this.state, address: address });
    };

    handleSelect = (address) => {

        this.setState({ ...this.state, address: address });

        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {

                window.googleMap.panTo(latLng);

                this.props.handleCidadeChanged(latLng.lat, latLng.lng, address);
            })
            .catch((error) => console.error("Error", error));
    };

    refreshIconButton = () => {
        return (
            <>
                <i className="fas fa-sync-alt"></i> Atualizar
            </>
        );
    };

    buscarIconButton = () => {
        return (
            <>
                <i className="fas fa-search"></i> Buscar
            </>
        );
    };

    handleValorMaximoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMaximoChanged(value);
    };

    handleValorMinimoChange = async (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMinimoChanged(value);
    };

    handleValorMetroMaximoChange = async (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMetroMaximoChanged(value);
    };

    handleValorMetroMinimoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMetroMinimoChanged(value);
    };


    handleValorParcelaMinimaChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorParcelaMinimaChanged(value);
    };

    render() {
        return (
            <div
                style={{
                    overflowX: "hidden",
                    height: "90vh",
                }}
            >
                <div className="card filtro-mapa">
                    <div className="card-body">
                        <div className="form-group">
                            <label>Carregar Projeto</label>
                            <Select
                                placeholder="Selecione"
                                options={this.props.projeto_name}
                                onChange={this.props.handleProjetoChanged}
                                isMulti={false}
                            />
                        </div>
                        {/* <div className="form-group">
                            <label>Nome</label>
                            <AsyncSelect
                                cacheOptions
                                isMulti
                                value={this.props.nome_empreendimento}
                                defaultOptions
                                loadOptions={this.props.searchEmpreendimentos}
                                onChange={
                                    this.props.handleNomeEmpreendimentoChanged
                                }
                            />
                        </div> */}
                        <div className="form-group">
                            <label>Tipo Negócio</label>

                            <Select
                                value={this.props.tipo_negocio}
                                placeholder="Selecione"
                                isMulti={true}
                                onChange={this.props.handleStatusChanged}
                                options={[
                                    {
                                        value: "Todos",
                                        label: "Selecionar Todos",
                                    },
                                    {
                                        value: "Venda",
                                        label: "Revenda",
                                    },
                                    {
                                        value: "Aluguel",
                                        label: "Locação",
                                    },
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <label>Tipo Empreendimento</label>

                            <Select
                                value={this.props.tipo}
                                placeholder="Selecione"
                                isMulti={true}
                                onChange={this.props.handleTipoEmpreendimentoChanged}
                                options={[
                                    {
                                        value: "Todos",
                                        label: "Selecionar Todos",
                                    },
                                    {
                                        value: "Apartamento",
                                        label: "Apartamento",
                                    },
                                    {
                                        value: "Apartamento Duplex",
                                        label: "Apartamento Duplex",
                                    },
                                    {
                                        value: "Apartamento Garden",
                                        label: "Apartamento Garden",
                                    },
                                    {
                                        value: "Edifício residencial",
                                        label: "Edifício residencial",
                                    },
                                    {
                                        value: "Flat",
                                        label: "Flat",
                                    },
                                    {
                                        value: "Duplex",
                                        label: "Duplex",
                                    },
                                    {
                                        value: "Triplex",
                                        label: "Triplex",
                                    },
                                    {
                                        value: "Garden",
                                        label: "Garden",
                                    },
                                    {
                                        value: "Cobertura",
                                        label: "Cobertura",
                                    },
                                    {
                                        value: "Hotel/Motel/Pousada",
                                        label: "Hotel/Motel/Pousada",
                                    },
                                    {
                                        value: "Kitinet",
                                        label: "Kitinet",
                                    },
                                    {
                                        value: "Kitnet/Conjugado",
                                        label: "Kitnet/Conjugado",
                                    },
                                    {
                                        value: "Loft",
                                        label: "Loft",
                                    },
                                    {
                                        value: "Studio",
                                        label: "Studio",
                                    },
                                    {
                                        value: "Vila/Rua",
                                        label: "Vila/Rua",
                                    },
                                    {
                                        value: "Casa",
                                        label: "Casa",
                                    },
                                    {
                                        value: "Casa em condomínio fechado",
                                        label: "Casa em condomínio fechado",
                                    },
                                    {
                                        value: "Condominio",
                                        label: "Condominio",
                                    },
                                    {
                                        value: "Casa Padrão",
                                        label: "Casa Padrão",
                                    },
                                    {
                                        value: "Casa de Vila",
                                        label: "Casa de Vila",
                                    },
                                    {
                                        value: "Casa de Condomínio",
                                        label: "Casa de Condomínio",
                                    },
                                    {
                                        value: "Casa Residencial",
                                        label: "Casa Residencial",
                                    },
                                    {
                                        value: "Residência",
                                        label: "Residência",
                                    },
                                    {
                                        value: "Térrea",
                                        label: "Térrea",
                                    },
                                    {
                                        value: "Sobrado",
                                        label: "Sobrado",
                                    },
                                    {
                                        value: "Chácara",
                                        label: "Chácara",
                                    },
                                    {
                                        value: "Fazenda",
                                        label: "Fazenda",
                                    },
                                    {
                                        value: "Fazenda/Sítio/Chácara",
                                        label: "Fazenda/Sítio/Chácara",
                                    },
                                    {
                                        value: "Haras",
                                        label: "Haras",
                                    },
                                    {
                                        value: "Terreno",
                                        label: "Terreno",
                                    },
                                    {
                                        value: "Lote/Terreno",
                                        label: "Lote/Terreno",
                                    },
                                    {
                                        value: "Loteamento/Condomínio",
                                        label: "Loteamento/Condomínio",
                                    },
                                    {
                                        value: "Escritório",
                                        label: "Escritório",
                                    },
                                    {
                                        value: "Loja",
                                        label: "Loja",
                                    },
                                    {
                                        value: "Imóvel comercial",
                                        label: "Imóvel comercial",
                                    },
                                    {
                                        value: "Galeria",
                                        label: "Galeria",
                                    },
                                    {
                                        value: "Prédio/Edifício inteiro",
                                        label: "Prédio/Edifício inteiro",
                                    },
                                    {
                                        value: "Shopping",
                                        label: "Shopping",
                                    },
                                    {
                                        value: "Consultório",
                                        label: "Consultório",
                                    },
                                    {
                                        value: "Loja/Salão",
                                        label: "Loja/Salão",
                                    },
                                    {
                                        value: "Centro comercial",
                                        label: "Centro comercial",
                                    },
                                    {
                                        value: "Andar/Laje corporativa",
                                        label: "Andar/Laje corporativa",
                                    },
                                    {
                                        value: "Área Comercial",
                                        label: "Área Comercial",
                                    },
                                    {
                                        value: "Ponto comercial/Loja/Box",
                                        label: "Ponto comercial/Loja/Box",
                                    },
                                    {
                                        value: "Conjunto Comercial/Sala",
                                        label: "Conjunto Comercial/Sala",
                                    },
                                    {
                                        value: "Sala/Conjunto",
                                        label: "Sala/Conjunto",
                                    },
                                    {
                                        value: "Casa Comercial",
                                        label: "Casa Comercial",
                                    },
                                    {
                                        value: "Edifício Comercial",
                                        label: "Edifício Comercial",
                                    },
                                    {
                                        value: "Galpão Comercial",
                                        label: "Galpão Comercial",
                                    },
                                    {
                                        value: "Prédio Inteiro",
                                        label: "Prédio Inteiro",
                                    },
                                    {
                                        value: "Prédio/Edificio",
                                        label: "Prédio/Edificio",
                                    },
                                    {
                                        value: "Prédio/Edificio inteiro",
                                        label: "Prédio/Edificio inteiro",
                                    },
                                    {
                                        value: "Prédio Comercial",
                                        label: "Prédio Comercial",
                                    },
                                    {
                                        value: "Edifício Comercial",
                                        label: "Edifício Comercial",
                                    },
                                    {
                                        value: "Residência Comercial",
                                        label: "Residência Comercial",
                                    },
                                    {
                                        value: "Sala Comercial",
                                        label: "Sala Comercial",
                                    },
                                    {
                                        value: "Galpão/Depósito/Armazém",
                                        label: "Galpão/Depósito/Armazém",
                                    },
                                    {
                                        value: "Galpão",
                                        label: "Galpão",
                                    },
                                    {
                                        value: "Garagem",
                                        label: "Garagem",
                                    },
                                ]}
                            />
                        </div>
                        {/* <div className="form-check checkbox-inline">
                            <input 
                                className="form-check-input"
                                name="check_estado"
                                id="check_estado"
                                onChange={ async (e)=>{
                                    await this.props.setBuscaPorEstado(e);

                                    await this.props.handleCidadeChanged(null);
                                }}
                                checked={this.props.busca_por_estado}
                                type="checkbox" 
                                value="true" 
                            />
                            <span className="slider"></span>
                            <label for="check_estado" className="form-check-label margin-check">
                                Busca Por Estado
                            </label>
                        </div> */}
                        {/* {this.props.busca_por_estado && ( */}
                            {/* // <> */}
                                <div className="form-group">
                                    <label>Estado</label>
                                    <Select
                                        cacheOptions
                                        options={this.state.estados}
                                        placeholder="Selecione"
                                        value={this.props.estadosSelecionados}
                                        onChange={
                                            this.props.handleEstadosChanged
                                        }
                                    />
                                </div>
                            {/* </> */}
                        {/* // )} */}
                        {/* {!this.props.busca_por_estado && ( */}
                            {/* // <> */}
                                <div className="form-group">
                                    <label>Cidade</label>
                                    <AsyncSelect
                                        cacheOptions
                                        placeholder="Selecione"
                                        isMulti
                                        value={this.props.cidade}
                                        loadOptions={this.searchCidade}
                                        onChange={
                                            this.props.handleCidadeChanged
                                        }
                                    />
                                </div>
                            {/* </> */}
                        {/* // )} */}
                        {/* {!this.props.busca_por_estado && ( */}
                            {/* // <> */}
                                <div className="form-group">
                                    <label>Bairros</label>

                                    <Select
                                        value={this.props.bairros}
                                        placeholder="Selecione"
                                        isMulti={true}
                                        onChange={
                                            this.props.handleBairrosChanged
                                        }
                                        options={this.props.bairrosOptions}
                                    />
                                </div>
                            {/* </> */}
                        {/* // )} */}

                        <div className="form-group row">
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Área Mínima</label>
                                <input
                                    type="number"
                                    name="area_minima"
                                    value={this.props.area_minima}
                                    onChange={
                                        this.props.handleAreaMinimaChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Área Máxima</label>
                                <input
                                    type="number"
                                    name="area_maxima"
                                    value={this.props.area_maxima}
                                    onChange={
                                        this.props.handleAreaMaximaChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Valor Mínimo</label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    value={this.props.valor_maior_que}
                                    onChange={this.handleValorMinimoChange}
                                    style={{ padding: "3px" }}
                                    className={this.props.showAlertCity ? "form-control is-invalid" : "form-control "}
                                />
                                <span className="invalid-feedback" role="alert">
                                                <strong>Campo requerido</strong>
                                </span>
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Valor Máximo</label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    onChange={this.handleValorMaximoChange}
                                    style={{ padding: "3px" }}
                                    className={this.props.showAlertCity ? "form-control is-invalid" : "form-control "}
                                    value={this.props.valor_menor_que}
                                />
                                 <span className="invalid-feedback" role="alert">
                                                <strong>Campo requerido</strong>
                                </span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>
                                    Valor R$/m<sup>2</sup> Mínimo
                                </label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    onChange={this.handleValorMetroMinimoChange}
                                    style={{ padding: "3px" }}
                                    value={this.props.valor_metro_maior_que}
                                    className="valor form-control"
                                />
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>
                                    Valor R$/m<sup>2</sup> Máximo
                                </label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    onChange={this.handleValorMetroMaximoChange}
                                    style={{ padding: "3px" }}
                                    value={this.props.valor_metro_menor_que}
                                    className="valor form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Quartos</label>
                            <br />
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.quartos.includes(1)}
                                        onChange={
                                            this.props.handleQuartosChanged
                                        }
                                        type="checkbox"
                                        value="1"
                                    />
                                    <span className="form-check-sign"></span>1
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        onChange={
                                            this.props.handleQuartosChanged
                                        }
                                        checked={this.props.quartos.includes(2)}
                                        type="checkbox"
                                        value="2"
                                    />
                                    <span className="form-check-sign"></span>2
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.quartos.includes(3)}
                                        onChange={
                                            this.props.handleQuartosChanged
                                        }
                                        type="checkbox"
                                        value="3"
                                    />
                                    <span className="form-check-sign"></span>3
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.quartos.includes(4)}
                                        onChange={
                                            this.props.handleQuartosChanged
                                        }
                                        type="checkbox"
                                        value="4"
                                    />
                                    <span className="form-check-sign"></span>4
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Vagas</label>
                            <br />
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.vagas.includes(1)}
                                        onChange={
                                            this.props.handleVagasChanged
                                        }
                                        type="checkbox"
                                        value="1"
                                    />
                                    <span className="form-check-sign"></span>1
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        onChange={
                                            this.props.handleVagasChanged
                                        }
                                        checked={this.props.vagas.includes(2)}
                                        type="checkbox"
                                        value="2"
                                    />
                                    <span className="form-check-sign"></span>2
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.vagas.includes(3)}
                                        onChange={
                                            this.props.handleVagasChanged
                                        }
                                        type="checkbox"
                                        value="3"
                                    />
                                    <span className="form-check-sign"></span>3
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.vagas.includes(4)}
                                        onChange={
                                            this.props.handleVagasChanged
                                        }
                                        type="checkbox"
                                        value="4"
                                    />
                                    <span className="form-check-sign"></span>4+
                                </label>
                            </div>
                        </div>
                        {/* <div className="form-check checkbox-inline">
                            <label className="form-check-label">
                                <input
                                    className="form-check-input"
                                    checked={this.props.showMarkers}
                                    onChange={(e) => {
                                        let checked = e.target.checked;
                                        this.props.setShowMarkers(checked);
                                    }}
                                    type="checkbox"
                                    value="true"
                                />
                                <span className="form-check-sign"></span>
                                Mostrar Empreendimentos
                            </label>
                        </div> */}
                        {/* {window.authenticatedUserCanQueryPolos == true && (
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.showPolosGeradores}
                                        onChange={(e) => {
                                            let checked = e.target.checked;
                                            this.props.setShowPolosGeradores(
                                                checked
                                            );
                                        }}
                                        type="checkbox"
                                        value="true"
                                    />
                                    <span className="form-check-sign"></span>
                                    Mostrar Polos Geradores
                                </label>
                            </div>
                        )}
                        {this.props.showPolosGeradores && (
                            <>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <Select
                                            isMulti
                                            value={
                                                this.props
                                                    .polos_geradores_types_selected
                                            }
                                            onChange={
                                                this.props
                                                    .setPolosGeradoresTypes
                                            }
                                            name="tipos"
                                            options={this.state.types}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                            </>
                        )} */}
                    </div>
                    <div
                        className="card-footer btn-filtro-mobile"
                        style={{
                            position: "sticky",
                            bottom: "14px",
                            backgroundColor: "#FFF",
                            padding: "5px 0",
                            boxShadow: "0, 0, 3px, rgba(0,0,0, .2",
                        }}
                    >
                        <div className="row">
                            <div className="col-4 tira-margin-right btn-limpar">
                                <button
                                    className="btn btn-sm btn-primary button-brain btn-round btn-block"
                                    style={{
                                        marginTop: "15px",
                                        backgroundColor: "#FFBB27",
                                        borderColor: "#FFBB27",
                                    }}
                                    onClick={this.props.cleanState}
                                >
                                    <i className="far fa-file"></i> Limpar
                                </button>
                            </div>
                            <div className="col-4 tira-margin-right btn-salvar">
                                <button
                                    className="btn btn-sm btn-block background-brain border-brain btn-round"
                                    style={{ marginTop: "15px" }}
                                    onClick={this.props.handleSubmit}
                                >
                                    {this.props.overlays.length > 0
                                        ? this.refreshIconButton()
                                        : this.buscarIconButton()}
                                </button>
                            </div>
                            {/* <div className="col-4 tira-margin-left btn-salvar-estado">
                                <button
                                    className="btn btn-sm btn-primary btn-block"
                                    style={{ marginTop: "12px" }}
                                    onClick={this.props.saveState}
                                >
                                    Salvar KML
                                </button>
                            </div> */}
                            <div className="col-6 tira-margin-left btn-salvar-estado">
                                <button
                                    className="btn btn-sm btn-primary button-brain btn-round btn-block"
                                    style={{ marginTop: "12px" }}
                                    onClick={this.props.openModalWork}
                                >
                                    Salvar Trabalho
                                </button>
                            </div>
                            <div className="col-6 tira-margin-left btn-salvar-estado">
                                {this.props.projeto != "" && (
                                    <>
                                        <button
                                            className="btn btn-sm btn-primary button-brain btn-round btn-block"
                                            style={{ marginTop: "12px" }}
                                            onClick={this.props.deleteWork}
                                        >
                                            Deletar Trabalho Carregado
                                        </button>
                                    </>
                                )}
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>

                {this.props.showLegenda && (
                    <Legenda ranges={this.props.ranges} />
                )}
            </div>
        );
    }
}

export default FormFilters;
