import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Axios from "axios";

class ModalComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipo_de_mapeamento: { value: "", label: "Selecione" },
            error: false,
        };
    }

    concluirMapeamento = async () => {
        if (this.state.tipo_de_mapeamento.value == "") {
            return this.handleChange({
                name: "error",
                value: true,
            });
        }

        axios
            .post("/mapeamento/concluir/" + this.props.id, {
                tipo_de_mapeamento: this.state.tipo_de_mapeamento.value,
            })
            .then((response) => {
                if (response.data.status === "Success") {
                    window.location.href = "/mapeamento";
                }
                this.props.onHide()
            });
    };

    handleChange = async (e) => {
        await this.setState({
            ...this.state,
            [e.name]: e.value,
        });
    };

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <Modal size="lg" show={true} onHide={this.props.onHide}>
                        <Modal.Header closeButton>
                            <Modal.Title>Concluir Mapeamento</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group col-12">
                                <label>Tipo de Mapeamento</label>
                                <Select
                                    value={this.state.tipo_de_mapeamento}
                                    placeholder={this.state.tipo_de_mapeamento}
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.handleChange({
                                            name: "tipo_de_mapeamento",
                                            value: value,
                                        })
                                    }
                                    options={[
                                        {
                                            value: "",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "Completo",
                                            label: "Completo",
                                        },
                                        {
                                            value: "Parcial",
                                            label: "Parcial",
                                        },
                                        {
                                            value: "Não Mapeado",
                                            label: "Não Mapeado",
                                        },
                                    ]}
                                />

                                {this.state.error && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        Selecione uma opção
                                    </div>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-sm btn-success btn-block"
                                onClick={this.concluirMapeamento}
                            >
                                <i className="fas fa-search"></i> Concluir
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default ModalComplete;
