import React, { Component } from 'react';

class TablePadroes extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var totalEmpreendimentos = 0;
        var ofertaLancada = 0;
        var ofertaFinal = 0;

        return (
            <table className="table table-striped table-condensed table-padroes" style={{border: `3px solid ${this.props.color}`}}>
                <thead>
                    <tr className="text-center" style={{
                        backgroundColor: this.props.color,
                        color: '#ffffff',
                        fontWeight: 'bold',
                    }} >
                        <td colSpan="8">
                            <span style={{ fontWeight: "bold" }}>
                                {this.props.title}
                            </span>
                        </td>
                    </tr>
                    <tr className="text-center" style={{
                        backgroundColor: this.props.color,
                        color: '#ffffff',
                        fontWeight: 'bold',
                    }}>

                        <th className="text-center" style={{ color: '#ffffff', padding: '7px', }}>
                            <span style={{ fontWeight: "bold" }}>
                                Padrão
                            {this.props.title == "Vertical" && <button className="btn btn-sm" style={{backgroundColor: this.props.color,color: '#ffffff'}} onClick={this.props.showModalPadraoInfo}><i className="far fa-question-circle"></i></button>}
                            {this.props.title == "Comercial" && <button className="btn btn-sm" style={{backgroundColor: this.props.color,color: '#ffffff'}} onClick={this.props.showModalPadraoInfoComercial}><i className="far fa-question-circle"></i></button>}
                            {this.props.title == "Horizontal - Condomínio de Casas/Sobrados" && <button className="btn btn-sm" style={{backgroundColor: this.props.color,color: '#ffffff'}} onClick={this.props.showModalPadraoInfoCasas}><i className="far fa-question-circle"></i></button>}
                            </span></th>
                        <th className="text-center" style={{ color: '#ffffff', padding: '7px', }}>
                            <span style={{ fontWeight: "bold" }}>
                                Nº Emp
                            </span></th>
                        <th className="text-center" style={{ color: '#ffffff', padding: '7px', }}>
                            <span style={{ fontWeight: "bold" }}>
                                (%)
                            </span></th>
                        <th className="text-center" style={{ color: '#ffffff', padding: '7px', }}>
                            <span style={{ fontWeight: "bold" }}>
                                Oferta Lançada
                            </span>
                        </th>
                        <th className="text-center" style={{ color: '#ffffff', padding: '7px', }}>
                            <span style={{ fontWeight: "bold" }}>
                                (%)
                            </span>
                        </th>
                        <th className="text-center" style={{ color: '#ffffff' }}>
                            <span style={{ fontWeight: "bold" }}>
                                Oferta Final
                            </span>
                        </th>
                        <th className="text-center" style={{ color: '#ffffff' }}>
                            <span style={{ fontWeight: "bold" }}>
                                (%)
                            </span>
                        </th>
                        <th className="text-center" style={{ color: '#ffffff' }}>
                            <span style={{ fontWeight: "bold" }}>
                                Dispo. s/O.I
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.padroes.map((padrao, index) => {

                        totalEmpreendimentos += parseInt(padrao.count);
                        ofertaLancada += parseInt(padrao.oferta_inicial);
                        ofertaFinal += parseInt( padrao.oferta_final );

                        return (
                            <tr className="text-center" key={index * 100 + padrao}>
                                <td className="text-center">{padrao.padrao}</td>
                                <td className="text-center">{ padrao.count }</td>
                                <td className="text-center">{Number(padrao.porcentagem_empreendimentos).toFixed(1).toLocaleString().replace('.',',')}</td>
                                <td className="text-center">{Number( padrao.oferta_inicial).toLocaleString()}</td>
                                <td className="text-center">{Number( padrao.oferta_inicial_porcentagem).toFixed(1).toLocaleString().replace('.',',')}</td>
                                <td className="text-center">{Number( padrao.oferta_final).toLocaleString()}</td>
                                <td className="text-center">{Number( padrao.oferta_final_porcentagem).toFixed(1).toLocaleString().replace('.',',')}</td>
                                <td className="text-center">{Number( padrao.oferta_final_porcentagem_sob_oferta_inicial).toFixed(1).toLocaleString().replace('.',',')}</td>
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td className="text-center">Total</td>
                        <td className="text-center">{totalEmpreendimentos.toLocaleString('pt-BR')}</td>
                        <td className="text-center">100%</td>
                        <td className="text-center">{ofertaLancada.toLocaleString('pt-BR')}</td>
                        <td className="text-center">100%</td>
                        <td className="text-center">{ofertaFinal.toLocaleString('pt-BR')}</td>
                        <td className="text-center">100%</td>
                        <td className="text-center">{Number((ofertaFinal / ofertaLancada) * 100).toFixed(1).toLocaleString('pt-BR').replace('.', ',')}</td>
                    </tr>
                </tfoot>
            </table>

        );
    }
}

export default TablePadroes;
