export default [
    '#1e78b4',
    '#194d72',
    '#b1d954',
    '#5B7537',
    '#FFA500',
    '#4682B4',
    '#EEE8AA',
    // '#8B008B',
    '#7B68EE',
    '#40E0D0',
    '#FFD700',
    // 'blue', 'green', 'red', 'yellow', 'black'
];