import React, { Component } from "react";
import exportToExcel from "./Excel";
class TableAnalitico extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.data;
    }

    sumTotal = (data) => {
        return (
            data.VerticalAtivo +
            data.VerticalFuturo +
            data.VerticalLançado +
            data.HorizontalAtivo +
            data.HorizontalFuturo +
            data.HorizontalLançado +
            data.ComercialAtivo +
            data.ComercialFuturo +
            data.ComercialLançado
        );
    };

    exportData = () => {
        const tableData = [];
        const headers = [];
        // Obtendo cabeçalhos da tabela
        const headerRows = document.querySelectorAll("#datatables thead th");
        headerRows.forEach((header) => headers.push(header.textContent));

        // Obtendo dados da tabela
        const rows = document.querySelectorAll("#datatables tbody tr");
        rows.forEach((row) => {
            const rowData = [];
            row.childNodes.forEach((cell) => {
                rowData.push(cell.textContent);
            });
            tableData.push(rowData);
        });

        // Preparando dados para exportação
        const exportData = [headers, ...tableData];

        // Exportando para Excel
        exportToExcel(exportData, "relatorio_analitico");
    };

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <button
                        onClick={this.exportData}
                        className="btn btn-sucess btn-link"
                    >
                        Exportar para Excel
                    </button>
                    <table
                        id="datatables"
                        className="table table-striped table-condensed table-padroes"
                    >
                        <thead style={{
                                    backgroundColor: "rgb(91, 117, 55)",
                                    width: "88px",
                                    height: "50px",
                                }}>
                            <tr>
                                <th className="text-center" style={{color: "white"}}>Assistente</th>
                                <th className="text-center" style={{color: "white"}}>Produtividade Total</th>
                                <th className="text-center" style={{color: "white"}}>Empreendimentos Cadastrados</th>
                                <th className="text-center" style={{color: "white"}}>Sites Visitados</th>
                                <th className="text-center" style={{color: "white"}}>Fotos</th>
                                <th className="text-center" style={{color: "white"}}>Hubs</th>
                                
                                <th className="text-center" style={{color: "white"}}>Auditoria Vertical Ativo</th>
                                <th className="text-center" style={{color: "white"}}>Auditoria Vertical Futuro</th>
                                <th className="text-center" style={{color: "white"}}>Auditoria Vertical Lançado</th>

                                <th className="text-center" style={{color: "white"}}>Auditoria Horizontal Ativo</th>
                                <th className="text-center" style={{color: "white"}}>Auditoria Horizontal Futuro</th>
                                <th className="text-center" style={{color: "white"}}>Auditoria Horizontal Lançado</th>

                                <th className="text-center" style={{color: "white"}}>Auditoria Comercial  Ativo</th>
                                <th className="text-center" style={{color: "white"}}>Auditoria Comercial  Futuro</th>
                                <th className="text-center" style={{color: "white"}}>Auditoria Comercial  Lançado</th>

                                <th className="text-center" style={{color: "white"}}>Total Auditoria</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {Object.keys(this.state.data).map((key) => {
                                {var total = this.sumTotal(this.state.data[key])}
                                return (
                                            <tr key={`${key}`}>
                                                <td>{key}</td>
                                                <td>{Number(this.state.data[key].total) + Number(total)}</td>
                                                <td>{this.state.data[key].empreendimentos_cadastrados}</td>
                                                <td>{this.state.data[key].sites_visitados}</td>
                                                <td>{this.state.data[key].fotos_analisadas}</td>
                                                <td>{this.state.data[key].hub}</td>

                                                <td>{this.state.data[key].VerticalAtivo}</td>
                                                <td>{this.state.data[key].VerticalFuturo}</td>
                                                <td>{this.state.data[key].VerticalLançado}</td>

                                                <td>{this.state.data[key].HorizontalAtivo}</td>
                                                <td>{this.state.data[key].HorizontalFuturo}</td>
                                                <td>{this.state.data[key].HorizontalLançado}</td>

                                                <td>{this.state.data[key].ComercialAtivo}</td>
                                                <td>{this.state.data[key].ComercialFuturo}</td>
                                                <td>{this.state.data[key].ComercialLançado}</td>
                                                <td>{total}</td>
                                            </tr>
                                );
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default TableAnalitico;
