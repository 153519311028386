import React from "react";
import ReactDOM from "react-dom";
import Form from "./Form";
import FormCreate from "./FormCreate";
import Table from "./Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";

class Index extends React.Component {
    state = {
        links: [],
        city: [],
        portal: [],
        status: "",
        showModalCreate: false,

        linkToCreate: "",
        observacaoToCreate: "",
        portalToCreate: "",
        typeToCreate: ["Vertical"],
        phoneToCreate: false,
    };

    componentDidMount = async () => {
        this.getLinks();
    };

    getLinks = async () => {
        try {
            await this.setState({
                ...this.state,
                links: [],
            });

            let city_id = window.location.href.split("/")[4];

            let links = await axios.get(
                route("mapeamento-links-lista", city_id)
            );

            await this.setState({
                ...this.state,
                links: links.data.links,
                city: links.data.city,
            });
        } catch (e) {
            console.log(e);
        }
    };

    handlePortalChanged = async (e) => {
        let portal = e.map((index, key) => {
            return index.value;
        });

        await this.setState({
            ...this.state,
            portal: portal,
        });
    };

    handleStatusChanged = async (e) => {
        await this.setState({
            ...this.state,
            status: e.value,
        });
    };

    handleSubmitForm = async () => {
        try {
            await this.setState({
                ...this.state,
                links: [],
            });

            let links = await axios.get(
                "lista/" +
                    this.state.city.id +
                    "?status=" +
                    this.state.status +
                    "&portal=" +
                    this.state.portal
            );

            await this.setState({
                ...this.state,
                links: links.data.links,
            });
        } catch (e) {
            console.log(e);
        }
    };

    cleanState = async () => {
        try {
            await this.setState({
                ...this.state,
                links: [],
                portal: [],
                status: "",
            });

            this.getLinks();
        } catch (e) {
            console.log(e);
        }
    };

    openModalCreate = async () => {
        await this.setState({
            ...this.state,
            showModalCreate: true,
        });
    };

    closeModalCreate = async () => {
        await this.setState({
            ...this.state,
            showModalCreate: false,
        });
    };

    handleLinkToCreateChanged = async (e) => {
        await this.setState({
            ...this.state,
            linkToCreate: e.target.value,
        });
    };
    handleObservacaoToCreateChanged = async (e) => {
        await this.setState({
            ...this.state,
            observacaoToCreate: e.target.value,
        });
    };
    handlePortalToCreateChanged = async (e) => {
        await this.setState({
            ...this.state,
            portalToCreate: e.target.value,
        });
    };
    handleTypeToCreateChanged = async (e) => {
        let tipo = e.map((index, key) => {
            return index.value;
        });

        await this.setState({
            ...this.state,
            typeToCreate: tipo,
        });
    };
    handlePhoneToCreateCreateChanged = async () => {
        await this.setState({
            ...this.state,
            phoneToCreate: this.state.phoneToCreate == false ? true : false,
        });
    };

    saveNewLink = async () => {
        if (this.state.linkToCreate == "") {
            document.getElementById("link_create").classList.add("is-invalid");
        }
        if (this.state.portalToCreate == "") {
            document
                .getElementById("portal_create")
                .classList.add("is-invalid");
        }

        await axios
            .post("store", {
                link: this.state.linkToCreate,
                observacao: this.state.observacaoToCreate,
                portal: this.state.portalToCreate,
                type: this.state.typeToCreate,
                city_id: this.state.city.id,
                phone_number: this.state.phoneToCreate,
            })
            .then(
                (response) => {
                    if (response.data.status === "Success") {
                        this.setState({
                            ...this.state,
                            linkToCreate: "",
                            observacaoToCreate: "",
                            portalToCreate: "",
                            typeToCreate: ["Vertical"],
                            phoneToCreate: false,
                        });
                        this.getLinks();
                        this.closeModalCreate();
                    }
                },
                (error) => {}
            );
    };

    render = () => {
        return (
            <>
                <Form
                    handleSubmit={this.handleSubmitForm}
                    cleanState={this.cleanState}
                    handlePortalChanged={this.handlePortalChanged}
                    handleStatusChanged={this.handleStatusChanged}
                />

                <div className="row">
                    <div className="col-8">
                        <h3>
                            Cidade: {this.state.city.city} /{" "}
                            {this.state.city.state}
                        </h3>
                    </div>
                    <div className="col-4 text-right">
                        <button
                            className="btn btn-info"
                            onClick={this.openModalCreate}
                        >
                            Adicionar novo Link
                        </button>
                        <Modal
                            size="lg"
                            show={this.state.showModalCreate}
                            onHide={this.closeModalCreate}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Adicionar Link</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormCreate
                                    handleSubmit={this.handleSubmitForm}
                                    handleLinkToCreateChanged={
                                        this.handleLinkToCreateChanged
                                    }
                                    handleObservacaoToCreateChanged={
                                        this.handleObservacaoToCreateChanged
                                    }
                                    handlePortalToCreateChanged={
                                        this.handlePortalToCreateChanged
                                    }
                                    handleTypeToCreateChanged={
                                        this.handleTypeToCreateChanged
                                    }
                                    handlePhoneToCreateCreateChanged={
                                        this.handlePhoneToCreateCreateChanged
                                    }
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-success"
                                    onClick={this.saveNewLink}
                                >
                                    Salvar
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>

                <Tabs
                    defaultActiveKey="vertical"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="vertical" title="Links">
                        <Table
                            links={this.state.links}
                            getLinks={this.getLinks}
                            type={"Vertical"}
                        />
                    </Tab>
                    {/* <Tab eventKey="comercial" title="Comercial">
                        <Table
                            links={this.state.links}
                            getLinks={this.getLinks}
                            type={"Comercial"}
                        />
                    </Tab>
                    <Tab eventKey="horizontal" title="Horizontal">
                        <Table
                            links={this.state.links}
                            getLinks={this.getLinks}
                            type={"Horizontal"}
                        />
                    </Tab>
                    <Tab eventKey="Hotel" title="Hotel">
                        <Table
                            links={this.state.links}
                            getLinks={this.getLinks}
                            type={"Hotel"}
                        />
                    </Tab> */}
                </Tabs>
            </>
        );
    };
}

export default Index;

if (document.getElementById("mapeamento_links")) {
    ReactDOM.render(<Index />, document.getElementById("mapeamento_links"));
}
