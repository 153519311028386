import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import Axios from "axios";
import exportToExcel from "./Excel";

class TableGerencial extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.data;
    }

    weekDay = (dataString) => {
        const days = [
            "Domingo",
            "Segunda-feira",
            "Terça-feira",
            "Quarta-feira",
            "Quinta-feira",
            "Sexta-feira",
            "Sábado",
        ];

        const data = new Date(`${dataString}T00:00:00Z`);

        data.setUTCHours(
            data.getUTCHours() + new Date().getTimezoneOffset() / 60
        );

        const day = data.getDay();
        return days[day];
    };
    exportData = () => {
        // Antes de chamar a função exportToExcel, prepare os dados da tabela
        const tableData = [];
        const headerRow = [];

        // Obtendo dados da primeira linha (cabeçalho) da tabela
        const headerCells = document.querySelectorAll(
            "#datatables thead tr:first-child th"
        );
        headerCells.forEach((cell) => {
            headerRow.push(cell.textContent);
        });

        // Adicionando cabeçalho à matriz de dados
        tableData.push(headerRow);

        // Obtendo dados da tabela
        const dataRows = document.querySelectorAll("#datatables tbody tr");
        dataRows.forEach((row) => {
            const rowData = [];
            row.childNodes.forEach((cell) => {
                rowData.push(cell.textContent);
            });
            // Adicionando linha de dados à matriz de dados
            tableData.push(rowData);
        });

        // Chamando a função exportToExcel com os dados da primeira tabela
        exportToExcel(tableData, "relatorio_gerencial");
    };

    dateFormat = (date) => {
        const partes = date.split('-');
        const dataFormatada = `${partes[2]}/${partes[1]}/${partes[0]}`;
        return dataFormatada;
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <button
                        onClick={this.exportData}
                        className="btn btn-sucess btn-link"
                    >
                        Exportar para Excel
                    </button>
                    <table
                        id="datatables"
                        className="table table-striped table-condensed table-padroes"
                    >
                        <thead style={{backgroundColor: "#e6e6e6",width: "88px", height: "50px"}}>

                            <tr>

                                <th className="text-center" style={{ fontWeight: 'bold', color: "#58595B"}}>Operador</th>
                                {this.state.periods.map((period) => {
                                    return (
                                        <>
                                            <th key={period} className="text-center" style={{ fontWeight: 'bold' , color: "#58595B"}}>
                                                {this.dateFormat(period)}
                                            </th>
                                        </>
                                    );
                                })}
                                <th className="text-center" style={{ fontWeight: 'bold' , color: "#58595B"}}>Total</th>
                            </tr>

                            <tr>

                                <th className="text-center" style={{fontSize: "10px", fontWeight: 'bold' , color: "#58595B"}}>Dia da Semana</th>
                                {this.state.periods.map((period) => {
                                    return (
                                        <>
                                            <th key={period} className="text-center" style={{fontSize: "10px", fontWeight: 'bold', color: "#58595B"}}>
                                                {this.weekDay(period)}
                                            </th>
                                        </>
                                    );
                                })}
                                <th className="text-center" style={{fontSize: "10px", fontWeight: 'bold' , color: "#58595B"}}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.data).map((key) => {
                                let total = 0;
                                return (
                                    <tr key={key}>
                                        <td className="text-center">{key}</td>
                                        {this.state.periods.map((period) => {
                                            const value =
                                                parseInt(
                                                    this.state.data[key][
                                                        period
                                                    ],
                                                    10
                                                ) || 0;
                                            total += value;
                                            return (
                                                <td
                                                    key={`${key}-${period}`}
                                                    className="text-center"
                                                >
                                                    {value}
                                                </td>
                                            );
                                        })}
                                        <td>{total}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default TableGerencial;
