import React from 'react';

class TableAuditReportAnaliticGrid extends React.Component {
    render() {
        return (
            <div>
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Operador</th>
                            <th>Período</th>
                            <th>Tabela</th>
                            <th>Parceria</th>
                            <th>Saldo</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.props.data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.operador}</td>
                            <td>{item.periodo}</td>
                            <td>{item.saldo_por_tabela}</td>
                            <td>{item.saldo_por_parceria}</td>
                            <td>{item.saldo}</td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableAuditReportAnaliticGrid;
