export default 
	[
		{
			"hexa": "#6B98FF",
			"nome" : "Azul",
			"marker": "blue"
		},
		{
			"hexa": "#CB9D7C",
			"nome" : "Marrom",
			"marker": "brown"
		},
		
		{
			"hexa" : "#F28805",
			"nome" : "Laranja",
			"marker": "orange"
		},
		{
			"hexa" : "#BCE3FF",
			"nome" : "Azul Pastel",
			"marker": "paleblue"
		},
		{
			"hexa" : "#FDABFF",
			"nome" : "Rosa",
			"marker": "pink"
		},
		{
			"hexa" : "#BC88F7",
			"nome" : "Roxo",
			"marker": "purple"
		},
		{
			"hexa" : "#01BF00",
			"nome" : "Verde Escuro",
			"marker": "darkgreen"
		},
		{
			"hexa" : "#FFFFFF",
			"nome" : "Branco",
			"marker": "white"
		},		
		{
			"hexa" : "#2E1E00",
			"nome" : "Marron Escuro",
			"marker": "darkbrown"
		},
		{
			"hexa" : "#00FFEB",
			"nome" : "Azul Claro",
			"marker": "lightblue"
		},
		{
			"hexa" : "#A42E00",
			"nome" : "Laranja Escuro",
			"marker": "darkorange"
		},
		{
			"hexa" : "#9B9400",
			"nome" : "Amarelo Escuro",
			"marker": "darkyellow"
		},
	];