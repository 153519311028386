import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Form from "./Form";
import Table from "./Table";
import Card from "./Card";
import Loader from "../Loader";

class Index extends React.Component {
    state = {
        period: "",
        loading: false,
        data: [],
    };

    componentDidMount = async () => {
        this.getAtachments();
    };

    getAtachments = async () => {
        await this.setState({
            data: [],
            loading: true,
        });

        await axios
            .get("/buildings-atachments-get?" + "period=" + this.state.period)
            .then((response) => {
                this.setState({
                    data: response.data,
                    loading: false
                });
            })
            .catch((error) => {
                return alert("Erro");
            });
    };

    handleChanged = async (event) => {
        await this.setState({
            [event.name]: event.value,
        });

        this.getAtachments();
    };

    render = () => {
        return (
            <>

                <Card data={this.state} />
                <Form state={this.state} handleChanged={this.handleChanged} />
                {this.state.loading && <Loader />}
                <Table data={this.state} />
            </>
        );
    };
}

export default Index;

if (document.getElementById("buildings_atachments")) {
    ReactDOM.render(<Index />, document.getElementById("buildings_atachments"));
}
