import React, { Component } from "react";
import lodash, { add } from "lodash";

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    removeDuplicates = (users) => {
        let uniqueProperties = lodash.uniqBy(users, function (a) {
            return a.property_of_interest;
        });

        return uniqueProperties;
    };

    render() {
        const uniqueProperties = this.removeDuplicates(
            this.props.selectedArea.users
        );

        return (
            <table className="table table-striped table-condensed table-padroes">
                <thead>
                    <tr
                        className="text-center"
                        style={{
                            backgroundColor: this.props.color,
                            color: "#ffffff",
                            fontWeight: "bold",
                        }}
                    >
                        <td colSpan="8">
                            <span style={{ fontWeight: "bold" }}>
                                {this.props.title}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Nome</td>
                    </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                    {uniqueProperties.map((property) => (
                        <tr>
                            <th>{property.property_of_interest}</th>
                        </tr>
                    ))}
                </tfoot>
            </table>
        );
    }
}

export default Table;
