import React, { Component } from 'react';

class TableQuartosOferta extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <table className="table table-striped table-condensed" style={{ marginBottom: 50, border: `3px solid ${this.props.color}` }}>
                <thead>
                    <tr className="text-center" style={{
                        backgroundColor: this.props.color,
                        color: '#ffffff'

                    }}>
                        <td colSpan="6">
                            VERTICAL
                        </td>
                    </tr>

                    <tr className="text-center" style={{
                        backgroundColor: this.props.color,
                        color: '#ffffff'

                    }}>
                        <th className="text-center" style={{ color: '#ffffff' }}>Tipo Imóvel</th>
                        <th className="text-center" style={{ color: '#ffffff' }}>Oferta Lançada</th>
                        <th className="text-center" style={{ color: '#ffffff' }}>(%)</th>
                        <th className="text-center" style={{ color: '#ffffff' }}>Oferta Final</th>
                        <th className="text-center" style={{ color: '#ffffff' }}>(%)</th>
                        <th className="text-center" style={{ color: '#ffffff' }}>Dispo.s/O.L</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.tipologias.unidades.map((tipologia, index) => {
                        return (
                            <tr className="text-center" key={index * 10574}>
                                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                                    {tipologia.quartos} DORMITÓRIOS</td>
                                <td className="text-center">{tipologia.oferta_inicial}</td>
                                <td className="text-center">{Number(tipologia.porcentagem_oferta_inicial).toFixed(1).toLocaleString().replace('.',',')}</td>
                                <td className="text-center">{tipologia.oferta_final}</td>
                                <td className="text-center">{Number(tipologia.porcentagem_oferta_final).toFixed(1).toLocaleString().replace('.',',')}</td>
                                <td className="text-center">{Number(tipologia.porcentagem_oferta_final_sob_oferta_lancada).toFixed(1).toLocaleString().replace('.',',')}</td>
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td>&nbsp;</td>
                        <td>{this.props.tipologias.oferta_inicial_total}</td>
                        <td>100%</td>
                        <td>{this.props.tipologias.oferta_final_total}</td>
                        <td>100%</td>
                        <td>{Number(this.props.tipologias.oferta_final_total.replace('.', '') / this.props.tipologias.oferta_inicial_total.replace('.', '') * 100).toFixed(1).toLocaleString().replace('.', ',')}</td>
                    </tr>
                </tfoot>
            </table>
        );
    }
}

export default TableQuartosOferta;
