import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Loader from "../Loader";
import {ToastContainer, toast} from 'react-toastify';
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import Select    from "react-select";

export default class FormTableAuditLot extends Component {

    constructor(props) {
        super();

        this.state = {
            data_inicial: null,
            data_final: null,
            cidade: null,
            errors: [],
            loading: false,
            operadores: [],
            operadoresOptions: [],
            percentage : 5,
        }
    }


    /**
     * Faz busca de cidades enquanto o usuário digita algo no campo de busca
     * @param inputQuery
     * @returns {Promise<any>}
     */
    searchCidade = async (inputQuery) => {

        if (inputQuery.length >= 2) {
            const response = await axios.get( route('web.util.city.autocompleteWithIdsAsValues', {
                search : inputQuery
            }));

            return response.data;

        }
    }


    /**
     * Envia os dados do formulário para o servidor e salva o Lote de Auditoria
     * @param e
     * @returns {Promise<void>}
     */
    submitForm = async (e) => {
        e.preventDefault();

        await this.setLoading(true);

        try {


            await this.setState({
                ...this.state, errors: [],
            });




            let payload = {
                start_date: this.state.data_inicial ? new Date(this.state.data_inicial).toISOString().split('T')[0] : null,
                end_date: this.state.data_final ? new Date(this.state.data_final).toISOString().split('T')[0] : null,
                city: this.state.cidade?.value ?? null,
                operators: this.state.operadores.map((operator) => operator.value),
                percentage: this.state.percentage,
                period: this.state.periodo ? new Date( this.state.periodo).toISOString().split('T')[0] : null,

            };



            let response = await axios.post(route('web.auditoria_tabelas.store'), payload );

            if (response.status === 201) {

                toast.success(`Lote de Auditoria ${response.data.audit_lot.id} Criado com sucesso!`);


                setTimeout(() => {
                    window.location.href = route('web.auditoria_tabelas.index');
                }, 2000);
            }

        } catch (e) {

            if (e.response && e.response.status === 422) {
                this.setState({errors: e.response.data.errors});
            }

            console.log(e);
        } finally {
            this.setLoading(false);
        }


    }

    /**
     * Exibe ou não o indicativo de atividade de carregamento (Loader, Spinner)
     * @param loading
     * @returns {Promise<void>}
     */
    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }


    /**
     * Executa ações após o componente ser montado
     * @returns {Promise<void>}
     */
    componentDidMount = async () => {

        try{
            let response = await axios.get(route('web.auditoria_tabelas.operadores'));

            let operadoresOptions = [{
                value: 'todos',
                label: 'Selecionar Todos'
            }, ...response.data];
            this.setState({
                ...this.state,
                operadoresOptions: operadoresOptions
            });
        }catch (e) {
            console.log(e);
        }
    }



    render() {
        return (<>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{width: "50%", zIndex: 9999999, fontWieght: 'bold'}}
            />

            <div className="card ">
                {this.state.loading && <Loader/>}
                <div className="card-header">
                    <h4 className="card-title">Criar Lote de Auditoria</h4>
                </div>
                <div className="card-body ">

                    <form method="post" className="form-horizontal">
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="status">Data Inicial</label>
                                <DatePicker
                                    locale="pt-BR"
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.data_inicial}
                                    onChange={async (e) => {
                                        await this.setState({
                                            ...this.state,
                                            data_inicial: e
                                        });
                                    }}
                                />


                                {this.state.errors['start_date'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.state.errors['start_date'][0]}
                                    </div>}

                            </div>

                            <div className="col-6">
                                <label htmlFor="status">Data Final</label>
                                <DatePicker
                                    locale="pt-BR"
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.data_final}
                                    onChange={async (e) => {
                                        await this.setState({
                                            ...this.state,
                                            data_final: e
                                        });
                                    }}
                                />

                                {this.state.errors['end_date'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.state.errors['end_date'][0]}
                                    </div>}

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label htmlFor="status">Operadores</label>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    name="operadores"
                                    options={this.state.operadoresOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Selecione os operadores"
                                    value={this.state.operadores}
                                    onChange={async (e) => {

                                        //selecionar todos os operadores
                                        if( e && e.length === 1 && e[0].value === 'todos') {

                                            let operadores = this.state.operadoresOptions.filter((operator) => operator.value !== 'todos');

                                            console.log('Operadores Filtrados', operadores);

                                            await this.setState({
                                                ...this.state,
                                                operadores: operadores
                                            });

                                            return;
                                        }

                                        await this.setState({
                                            ...this.state,
                                            operadores: e
                                        });



                                    }}></Select>

                                {this.state.errors['operators'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.state.errors['operators'][0]}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="status">Período</label>
                                <DatePicker
                                    locale="pt-BR"
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.periodo}
                                    onChange={async (e) => {
                                        await this.setState({
                                            ...this.state,
                                            periodo: e
                                        });
                                    }}
                                />

                                {this.state.errors['period'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.state.errors['period'][0]}
                                    </div>
                                }
                            </div>
                            <div className="col-6">
                                <label htmlFor="status">Porcentagem Por Operador</label>
                                <input type="number" className="form-control"
                                       value={this.state.percentage}
                                       onChange={async (e) => {
                                           await this.setState({
                                               ...this.state,
                                               percentage: e.target.value
                                           });
                                       }}
                                       name="percentage" id="qtd_bases" />
                                {this.state.errors['percentage'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.state.errors['percentage'][0]}
                                    </div>
                                }
                            </div>

                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label htmlFor="">Cidade</label>
                                <AsyncSelect
                                    cacheOptions
                                    value={this.state.cidade}
                                    loadOptions={this.searchCidade}
                                    placeholder="Digite o nome da cidade"
                                    onChange={async (e) => {
                                        await this.setState({
                                            ...this.state,
                                            cidade: e

                                        });
                                    }}
                                />
                                {this.state.errors['city'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.state.errors['city'][0]}
                                    </div>}
                            </div>
                        </div>

                        <button className="btn btn-primary" onClick={this.submitForm}>Criar Lote</button>
                    </form>

                </div>
            </div>
        </>);
    }
}

if (document.getElementById('js-form-table-audit-lot')) {
    ReactDOM.render(<FormTableAuditLot/>, document.getElementById('js-form-table-audit-lot'));
}
