import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Table from "./Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AsyncSelect from "react-select/async";
import ModalComplete from "./ModalComplete";
class Index extends React.Component {
    state = {
        city: [],
        links: [],
        mapFisico: [],
        mapeamento: [],
        user: [],
        portalShow: [],
        portal: [],
        countBuildings: {
            horizontal : 0,
            vertical : 0,
            comercial : 0,
            hotel : 0
        },
        showModalConclusao: false,
    };

    componentDidMount = async () => {
        this.getLinks();
    };

    searchPortal = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/mapeamento/portal/json-options/" + inputQuery
            );
            return response.data;
        }
    };

    handlePortalChanged = async (e) => {
        let portal = e.map((index, key) => {
            return index.value;
        });

        await this.setState({
            ...this.state,
            portalShow: e,
            portal: portal,
        });


    };

    getLinks = async () => {
        try {
            let mapeamento_id = window.location.href.split("/").pop();

            let links = await axios.get(
                "/mapeamento-worker/lista/" + mapeamento_id
            );

            await this.setState({
                ...this.state,
                links: links.data.links,
                mapFisico: links.data.mapeamentoFisico,
                city: links.data.city,
                mapeamento: links.data.mapeamento,
                user: links.data.user,
                countBuildings: links.data.buildingsCounts
            });
        } catch (e) {}
    };

    handleSubmitForm = async () => {
        console.log(this.state.portal);
        try {

            let mapeamento_id = window.location.href.split("/").pop();

            await this.setState({
                ...this.state,
                links: [],
            });

            let links = await axios.get(
                "/mapeamento-worker/lista/" +
                    mapeamento_id +
                    "?portal=" +
                    this.state.portal
            );

            await this.setState({
                ...this.state,
                links: links.data.links,
            });
        } catch (e) {
            console.log(e);
        }
    };

    cleanState = async () => {
        try {
            await this.setState({
                ...this.state,
                links: [],
                portal: [],
                portalShow: [],
            });

            this.getLinks();
        } catch (e) {
            console.log(e);
        }
    };

    concluirMapeamento = async () => {
        // axios
        //     .post("/mapeamento/concluir/" + this.state.mapeamento.id)
        //     .then((response) => {
        //         if (response.data.status === "Success") {
        //             window.location.href = "/mapeamento";
        //         }
        //     });

        await this.setState({
            ...this.state,
            showModalConclusao: !this.state.showModalConclusao
        })
        console.log(this.state.showModalConclusao);
    };

    render = () => {
        let totalCadastrados = parseInt(this.state.countBuildings.vertical) + parseInt(this.state.countBuildings.horizontal) + parseInt(this.state.countBuildings.comercial) + parseInt(this.state.countBuildings.hotel);
        return (
            <>
                <div className="content">
                    <div className="container-fluid">
                        <div className="card">

                        {this.state.showModalConclusao && <ModalComplete id={this.state.mapeamento.id} onHide={this.concluirMapeamento}/>}

                            <div className="card-header">
                                <div className="row">
                                    <div className="col-8 text-left">
                                        <h3 className="mb-0">Mapeamento</h3>
                                    </div>
                                    <div className="col-4 text-right">
                                        <a
                                            href="/mapeamento"
                                            className="btn btn-warning"
                                        >
                                            VOLTAR PARA LISTA
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-title">
                                <div
                                    className="row"
                                    style={{ marginLeft: "15px" }}
                                >
                                    <div className="col-4">
                                        <h3>
                                            {this.state.city.city +
                                                " / " +
                                                this.state.city.state}
                                        </h3>
                                        <h4>
                                            {
                                                this.state.mapeamento
                                                    .period_formated
                                            }
                                        </h4>
                                    </div>
                                    <div className="col-8">
                                        <div style={{ float: "right" }}>
                                            <a
                                                className="btn btn-success btn-link"
                                                href={
                                                    "/mapeamento-links/" +
                                                    this.state.mapeamento
                                                        .city_id
                                                }
                                                target="_blank"
                                            >
                                                + Adicionar novo Site
                                            </a>
                                            <button
                                                className="btn btn-success"
                                                onClick={
                                                    this.concluirMapeamento
                                                }
                                            >
                                                Concluir mapeamento
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <label>Portal</label>
                                            <AsyncSelect
                                                cacheOptions
                                                isMulti
                                                value={this.state.portalShow}
                                                loadOptions={this.searchPortal}
                                                onChange={
                                                    this.handlePortalChanged
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-6">
                                            <button
                                                className="btn btn-sm btn-success btn-block"
                                                onClick={
                                                    this.handleSubmitForm
                                                }
                                            >
                                                <i className="fas fa-search"></i>{" "}
                                                Buscar
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button
                                                className="btn btn-sm btn-block"
                                                style={{
                                                    backgroundColor: "#FFBB27",
                                                }}
                                                onClick={this.cleanState}
                                            >
                                                <i className="far fa-file"></i>{" "}
                                                Limpar
                                            </button>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <Tabs
                                    defaultActiveKey="vertical"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="vertical" title="Links">
                                        <Table
                                            links={this.state.links}
                                            mapFisico={this.state.mapFisico}
                                            getLinks={this.getLinks}
                                            type={"Vertical"}
                                            city={this.state.city}
                                            mapeamento={this.state.mapeamento}
                                            user={this.state.user}
                                            cadastrados={totalCadastrados}
                                        />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default Index;

if (document.getElementById("mapeamento_worker")) {
    ReactDOM.render(<Index />, document.getElementById("mapeamento_worker"));
}
