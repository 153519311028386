import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Form from "./Form";
import Table from "./Table";
import "../../../../../public/css/stylePagination.css";
class Index extends React.Component {
    state = {
        produtividade: [],
        responsavel: [],
        cidades: [],
        pagination: 1,
        maxPage: 1,
        buttons: [],
        period: "",
    };

    componentDidMount = async () => {
        this.getProdutividade();
    };

    getProdutividade = async () => {
        let url = "/mapeamento/painel/gerencial/list?";
        await Axios.get(
            `${url}responsavel=${this.state.responsavel}&cidades=${this.state.cidades}&period=${this.state.period}&page=${this.state.pagination}`
        ).then((response) => {
            this.setState({
                produtividade: response.data.produtividade.data,
                maxPage: response.data.produtividade.last_page,
            });

            this.loadButtonsPaginate();
        });
    };

    handlePageChange = async (value) => {
        await this.setState({
            ...this.state,
            pagination: value,
        });

        return this.getProdutividade();
    };

    loadButtonsPaginate = async () => {
        const buttons = [];

        buttons.push(
            <button
                key="first"
                disabled={this.state.pagination === 1}
                className={
                    1 === this.state.pagination
                        ? "p-pagination-btn-active"
                        : "p-pagination-btn"
                }
                onClick={() => this.handlePageChange(1)}
            >
                1
            </button>
        );

        let startPage = Math.max(2, this.state.pagination - 1);
        let endPage = Math.min(
            this.state.maxPage - 1,
            this.state.pagination + 1
        );

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    className={
                        i === this.state.pagination
                            ? "p-pagination-btn-active"
                            : "p-pagination-btn"
                    }
                    onClick={() => this.handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }

        if (this.state.maxPage > 1) {
            buttons.push(
                <button
                    key="last"
                    onClick={() => this.handlePageChange(this.state.maxPage)}
                    className={
                        this.state.maxPage === this.state.pagination
                            ? "p-pagination-btn-active"
                            : "p-pagination-btn"
                    }
                    disabled={this.state.pagination === this.state.maxPage}
                >
                    {this.state.maxPage}
                </button>
            );
        }

        await this.setState({
            ...this.state,
            buttons: buttons,
        });
    };

    clearState = async () => {
        await this.setState({
            ...this.state,
            period: "",
            responsavel: [],
            cidades: [],
            pagination: 1,
        });
        console.log(this.state);

        await this.getProdutividade();
    };

    handleChanged = async (event) => {
        if (Array.isArray(event.value)) {
            event.value = event.value.map((index) => {
                return index.value;
            });
        }

        await this.setState({
            ...this.state,
            [event.name]: event.value,
        });
    };

    render = () => {
        var canUseFilter =
            window.user_id == 257 || window.user_id == 630 ? true : false;

        return (
            <>
                {canUseFilter && (
                    <Form
                        state={this.state}
                        handleChanged={this.handleChanged}
                        clearState={this.clearState}
                        reloadProdutividade={this.getProdutividade}
                    />
                )}

                <Table
                    produtividade={this.state.produtividade}
                    reloadProdutividade={this.getProdutividade}
                />
                <div className="card text-center align-items-center">
                    <div className="card-body col-6 text-center align-items-center">
                        <div className="row d-flex align-items-center justify-content-center" style={{ justifyContent: "space-around" }}>
                            {this.state.buttons.map((button, index) => (
                                <div key={index} >
                                    {button}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default Index;

if (document.getElementById("mapeamento_painel")) {
    ReactDOM.render(<Index />, document.getElementById("mapeamento_painel"));
}
