import React, {Component} from "react";
import ReactDOM from "react-dom";
import Form from "./Form";
import Table from "./Table";


class Index extends Component {
    state = {
        user_type: [],
        age: [],
        genre: "",
        marital_status: "",
        children: "",
        income: 0,

        data: [],
        excelFile: '',
    };

    componentDidMount = async () => {
        this.getData();
        this.getExcelFile();
    };

    handleChanged = async (event) => {
        if (Array.isArray(event.value) && event.name != "age") {
            event.value = event.value.map((index) => {
                return index.value;
            });
        }

        await this.setState({
            [event.name]: event.value,
        });
    };

    getData = async () => {
        await this.setState({
            data: [],
        });

        axios
            .get(
                "/geo-referenciamento/list?" +
                    "user_type=" +
                    this.state.user_type +
                    "&age=" +
                    this.state.age +
                    "&genre=" +
                    this.state.genre +
                    "&marital_status=" +
                    this.state.marital_status +
                    "&children=" +
                    this.state.children
            )
            .then((response) => {
                this.setState({
                    data: response.data,
                });
            })
            .catch((error) => {});
    };

    getExcelFile = async () =>{
        await this.setState({
            excelFile: '',
        })

        axios
            .get(
                "/geo-referenciamento/excelFile"
            )
            .then((response) => {
                this.setState({
                    excelFile: response.data,
                });
            })
            .catch((error) => {});
    }

    render () {
        return (
            <>
                <Form
                    handleChanged={this.handleChanged}
                    getData={this.getData}
                    state={this.state}
                />
                <Table data={this.state.data} getData={this.getData} />
            </>
        );
    };
}



if (document.getElementById("geo_referenciamento")) {
    ReactDOM.render(<Index />, document.getElementById("geo_referenciamento"));
}

export default Index;
