import React,{ Component } from "react";

class Form extends Component {
    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-4">
                                <label>Período</label>
                                <input
                                    type="month"
                                    name="period"
                                    value={this.props.state.period}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "period",
                                            value: value.target.value,
                                        })
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
