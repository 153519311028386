const detalhes = [
    {
        label: 'Lançamentos', options: [
            { label: 'Unidades Lançadas', value: 'unidades_lancadas' },
            { label: 'VGV Unidades Lançadas', value: 'vgv_unidades_lancadas' }]
    },
    {
        label: 'Vendas', options: [
            { label: 'Unidades Vendidas Líquido', value: 'unidades_vendidas_liquido' },
            { label: 'VGV Unidades Vendidas Líquido', value: 'vgv_unidades_vendidas_liquido' },
            { label: 'IVV - Indice de Velocidade de Vendas', value: 'ivv' }]
    },
    {
        label: "Preços", options: [
            { label: "Preço Médio", value: "preco_medio" },
            { label: "Preço Metro Médio", value: "preco_metro_medio" },
        ]
    },
    {
        label: "Oferta Final", options: [
            { label: "Oferta Final", value: "oferta_final" },
            { label: "VGV Oferta Final", value: "vgv_oferta_final" },
        ]
    }]

module.exports = detalhes;