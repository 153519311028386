import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Form from "./Form";
import Loader from "../../Loader";

class Index extends React.Component {
    state = {
        responsavel: [],
        cidades: [],
        status: ["Concluído", "Em Andamento"],
        period: "",
        date_limit_initial: "",
        date_limit_final: "",
        created_at_initial: "",
        created_at_final: "",
        file: "",
        fileAnalitico: "",
        tipo_mapeamento: "Selecione",
        tipo_empreendimento: "Selecione",
        tipo_relatorio: "Selecione",
        type: "Selecione",
        showLoading: false,
    };

    componentDidMount = async () => {};

    handleResponsavelChanged = async (e) => {
        let responsavel = e.map((index, key) => {
            return index.value;
        });

        await this.setState({
            ...this.state,
            responsavel: responsavel,
        });
    };
    handleStatusChanged = async (e) => {
        if(e === null){
            return  await this.setState({
                ...this.state,
                status: ["Concluído", "Em Andamento"],
            });
        }

        let status = e.map((index, key) => {
            return index.value;
        });

        await this.setState({
            ...this.state,
            status: status,
        });

        console.log(this.state.status);
    };
    handleCidadeChanged = async (e) => {
        let cidades = e.map((index, key) => {
            return index.label;
        });

        await this.setState({
            ...this.state,
            cidades: cidades,
        });
    };
    handlePeriodChanged = async (e) => {
        await this.setState({
            ...this.state,
            period: e.target.value,
        });
    };
    handleDateLimitInitialChanged = async (e) => {
        await this.setState({
            ...this.state,
            date_limit_initial: e.target.value,
        });
    };
    handleDateLimitFinalChanged = async (e) => {
        await this.setState({
            ...this.state,
            date_limit_final: e.target.value,
        });
    };
    handleCreatedAtInitialChanged = async (e) => {
        await this.setState({
            ...this.state,
            created_at_initial: e.target.value,
        });
    };
    handleCreatedAtFinalChanged = async (e) => {
        await this.setState({
            ...this.state,
            created_at_final: e.target.value,
        });
    };

    handleTipoMapeamentoChanged = async (e) => {
        await this.setState({
            ...this.state,
            tipo_mapeamento: e.value,
        });
    };
    handleTipoEmpreendimentoChanged = async (e) => {
        await this.setState({
            ...this.state,
            tipo_empreendimento: e.value,
        });
    };
    handleTipoRelatorioChanged = async (e) => {
        await this.setState({
            ...this.state,
            tipo_relatorio: e.value,
        });
    };
    handleTypeChanged = async (e) => {
        await this.setState({
            ...this.state,
            type: e.value,
        });
    };

    generateRelatorio = async () => {
        await this.setState({
            ...this.state,
            file: "",
            fileAnalitico: "",
            showLoading: true,
        });

        await axios
            .post("/mapeamento/produtividade", {
                responsavel: this.state.responsavel,
                cidades: this.state.cidades,
                period: this.state.period,
                status: this.state.status,
                date_limit_initial: this.state.date_limit_initial,
                date_limit_final: this.state.date_limit_final,
                created_at_initial: this.state.created_at_initial,
                created_at_final: this.state.created_at_final,
                tipo_mapeamento: this.state.tipo_mapeamento,
                tipo_empreendimento: this.state.tipo_empreendimento,
                tipo_relatorio: this.state.tipo_relatorio,
                type: this.state.type,
            })
            .then((response) => {
                console.log(response.data);
                this.setState({
                    ...this.state,
                    file: response.data.filePath ?? "",
                    fileAnalitico: response.data.filePathAnalitico ?? "",
                    showLoading: false,
                });
            });
    };

    clearState = async () => {
        this.setState({
            ...this.state,
            mapeamentos: [],
            status: "Selecione",
            responsavel: [],
            cidades: [],
            period: "",
            date_limit_initial: "",
            date_limit_final: "",
            created_at_initial: "",
            created_at_final: "",
            file: "",
            tipo_mapeamento: "Selecione",
            tipo_empreendimento: "Selecione",
            type: "Selecione",
        });
    };

    render = () => {
        return (
            <>
                <Form
                    responsavel={this.state.responsavel}
                    cidades={this.state.cidades}
                    period={this.state.period}
                    date_limit_initial={this.state.date_limit_initial}
                    date_limit_final={this.state.date_limit_final}
                    created_at_initial={this.state.created_at_initial}
                    created_at_final={this.state.created_at_final}
                    tipo_mapeamento={this.state.tipo_mapeamento}
                    tipo_empreendimento={this.state.tipo_empreendimento}
                    tipo_relatorio={this.state.tipo_relatorio}
                    handleResponsavelChanged={this.handleResponsavelChanged}
                    handleStatusChanged={this.handleStatusChanged}
                    handleCidadeChanged={this.handleCidadeChanged}
                    handlePeriodChanged={this.handlePeriodChanged}
                    handleDateLimitInitialChanged={
                        this.handleDateLimitInitialChanged
                    }
                    handleDateLimitFinalChanged={
                        this.handleDateLimitFinalChanged
                    }
                    handleCreatedAtInitialChanged={
                        this.handleCreatedAtInitialChanged
                    }
                    handleCreatedAtFinalChanged={
                        this.handleCreatedAtFinalChanged
                    }
                    handleTipoMapeamentoChanged={
                        this.handleTipoMapeamentoChanged
                    }
                    handleTipoEmpreendimentoChanged={
                        this.handleTipoEmpreendimentoChanged
                    }
                    handleTipoRelatorioChanged={this.handleTipoRelatorioChanged}
                    handleTypeChanged={this.handleTypeChanged}
                    clearState={this.clearState}
                    generateRelatorio={this.generateRelatorio}
                />

                <div className="row">
                    {this.state.showLoading && <Loader />}
                    <div className="col-6">
                        {this.state.file != "" && (
                            <a
                                href={this.state.file}
                                download
                                className="btn btn-link btn-success"
                            >
                                Baixar Produtividade de Mapeamento
                            </a>
                        )}
                        {this.state.fileAnalitico != "" && (
                            <a
                                href={this.state.fileAnalitico}
                                download
                                className="btn btn-link btn-success btn-round"
                            >
                                Baixar Relatório Analítico
                            </a>
                        )}
                    </div>
                </div>
            </>
        );
    };
}

export default Index;

if (document.getElementById("mapeamento_produtividade")) {
    ReactDOM.render(
        <Index />,
        document.getElementById("mapeamento_produtividade")
    );
}
