import React, { Component } from 'react';

class TableVGV extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {

        let somaVgv = 0;
        return (
            <table className="table table-striped table-condensed table-padroes" style={{border: `3px solid ${this.props.color}`}}>
                <thead>
                    <tr className="text-center" style={{
                        backgroundColor: this.props.color,
                        color: '#ffffff',
                        fontWeight: 'bold',
                    }} >
                        <td colSpan="2">
                            <span style={{ fontWeight: "bold" }}>
                               VGV {this.props.type} Por Padrão -  {this.props.title}
                            </span>
                            <span style={{ fontWeight: "bold" }}>
                               {" "}Valores em mil R$
                            </span>
                        </td>
                    </tr>
                    <tr className="text-center" style={{
                        backgroundColor: this.props.color,
                        color: '#ffffff',
                        fontWeight: 'bold',
                    }}>

                        <th className="text-center" style={{ color: '#ffffff', padding: '7px', }}>
                        <span style={{ fontWeight: "bold" }}>
                                Padrão
                            </span>
                        {this.props.title == "Vertical" && <button className="btn btn-sm" style={{backgroundColor: this.props.color,color: '#ffffff'}} onClick={this.props.showModalPadraoInfo}><i className="far fa-question-circle"></i></button>}
                        {this.props.title == "Comercial" && <button className="btn btn-sm" style={{backgroundColor: this.props.color,color: '#ffffff'}} onClick={this.props.showModalPadraoInfoComercial}><i className="far fa-question-circle"></i></button>}
                        {this.props.title == "Horizontal - Condomínio de Casas/Sobrados" && <button className="btn btn-sm" style={{backgroundColor: this.props.color,color: '#ffffff'}} onClick={this.props.showModalPadraoInfoCasas}><i className="far fa-question-circle"></i></button>}

                        </th>
                        <th className="text-center" style={{ color: '#ffffff', padding: '7px', }}>
                            <span style={{ fontWeight: "bold" }}>
                               VGV
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.padroes.map((padrao, index) => {
                        let vgv = this.props.type == "Lançado" ? padrao.vgvLancado : padrao.vgv;
                        somaVgv += Number(vgv.replaceAll('.', '').replaceAll(',', '.') )
                        return (
                            <tr className="text-center" key={index * 78414}>
                                <td className="text-center">{padrao.padrao}</td>
                                <td className="text-center">{ vgv }</td>
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            Total
                        </td>
                        <td>
                            {somaVgv.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace('R$', '')}
                        </td>
                    </tr>
                </tfoot>
            </table>

        );
    }
}

export default TableVGV;
