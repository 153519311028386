import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import Axios from "axios";

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cidades: [],
            responsavel: [],
        };
    }

    searchCidade = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/cidades/json-options/" + inputQuery
            );
            return response.data;
        }
    };
    searchUser = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/mapeamento/users/json-options/" + inputQuery
            );
            return response.data;
        }
    };
    handleResponsavelChanged = async (e) => {
        await this.setState({
            ...this.state,
            responsavel: e,
        });

        this.props.handleChanged({
            value: this.state.responsavel,
            name: "responsavel",
        });
    };

    clearState = async () => {
        await this.setState({
            ...this.state,
            responsavel: [],
            cidades: [],
            period: "",
        });

        await this.props.clearState();
    };

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Responsável</label>
                                <AsyncSelect
                                    cacheOptions
                                    isMulti
                                    value={this.state.responsavel}
                                    loadOptions={this.searchUser}
                                    onChange={this.handleResponsavelChanged}
                                />
                            </div>
                            {/* <div className="form-group col-4">
                                <label>Cidade</label>
                                <AsyncSelect
                                    cacheOptions
                                    isMulti
                                    value={this.props.cidade}
                                    loadOptions={this.searchCidade}
                                    onChange={this.props.handleCidadeChanged}
                                />
                            </div> */}
                            <div className="form-group col-6">
                                <label>Período</label>
                                <input
                                    type="month"
                                    name="period"
                                    value={this.props.state.period}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            value: value.target.value,
                                            name: "period",
                                        })
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-success btn-block btn-round background-brain border-brain"
                                    onClick={() =>
                                        this.props.reloadProdutividade()
                                    }
                                >
                                    <i className="fas fa-search"></i>
                                    Buscar
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-block btn-round background-brain border-brain"
                                    style={{ backgroundColor: "#FFBB27" }}
                                    onClick={this.clearState}
                                >
                                    <i className="far fa-file"></i> Limpar
                                </button>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
