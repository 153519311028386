import * as XLSX from 'xlsx';

function exportToExcel(tableData, fileName) {
  // Importando XLSX diretamente
  
  const worksheet = XLSX.utils.aoa_to_sheet(tableData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

export default exportToExcel;
