import React from 'react';
import {Dialog} from "primereact/dialog";
import {MultiSelect} from 'primereact/multiselect';


class ModalSalvarProjeto extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCountries: [],
            countries : [
                {name: 'Australia', code: 'AU'},
                {name: 'Brazil', code: 'BR'},
                {name: 'China', code: 'CN'},
                {name: 'Egypt', code: 'EG'},
                {name: 'France', code: 'FR'},
                {name: 'Germany', code: 'DE'},
                {name: 'India', code: 'IN'},
                {name: 'Japan', code: 'JP'},
                {name: 'Spain', code: 'ES'},
                {name: 'United States', code: 'US'}
            ],
            users: []
        }
    }

    userTemplate = (option) => {
        return (
            <div className="country-item">
                <img alt={option.name} style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '10px'
                }} className="rounded-circle" src={option.image} />
                <div>{option.name}</div>
            </div>
        );
    }

    selectedUsersTemplate = (option) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <img alt={option.name}  style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '10px'
                    }} className="rounded-circle"  src={option.image} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return "Selecione";
    }

    componentDidMount = async ()=> {
        await this.loadUsers();
    }

    loadUsers = async ()=> {
        try{
            let response = await axios.get( route('company.users.index') );

            let users = response.data.map((user) => {
                return {
                    name: user.name,
                    image: user.profile_picture_url,
                    id: user.id
                }
            })

            this.setState({users: users});
        }catch (e) {
            console.log(e);
        }
    }

    render() {
        return (

            <Dialog
                header="Salvar Projeto"
                visible={this.props.show}
                style={{width: '35vw', marginTop: '50px'}}
                onHide={this.props.handleClose}
                blockScroll={true}
            >
                <div className="form-group">
                    <label>Nome do Projeto</label>
                    <input
                        type="text"
                        name="project_name"
                        value={this.props.project_name}
                        onChange={this.props.handleNameProjectChanged}
                        style={{padding: "3px"}}
                        className="form-control"
                    />
                    {this.props.errors.project_name && <div className="text-danger">{this.props.errors.project_name}</div>}
                </div>

                <div className="form-group">
                    <label>Compartilhar com:</label>
                    <MultiSelect
                        filter
                        style={{width: '100%'}}
                        value={this.props.share_users}
                        options={this.state.users}
                        onChange={(e) => {
                            this.props.handleShareUsersChanged(e.value);
                        }}
                        selectedItemsLabel="{0} usuários selecionados"
                        optionLabel="name" placeholder="Select Countries"
                        className="multiselect-custom"
                        itemTemplate={this.userTemplate}
                        selectedItemTemplate={this.selectedUsersTemplate}
                    />
                </div>


                <div className="form-group">
                    <button className="btn btn-default btn-sm btn-block" disabled={this.props.loading}
                            onClick={this.props.saveProject}>
                        {this.props.loading && <i className="fa fa-spin fa-spinner"/>}
                        Salvar Projeto
                    </button>
                </div>

            </Dialog>
        );
    }
}

export default ModalSalvarProjeto;
