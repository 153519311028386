import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "../../Loader";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import "../../../../../public/css/stylePagination.css";

class Index extends React.Component {
    state = {
        loading: false,
        regiao: [],
        estados: [],
        cidades: [],
        id: window.location.href.split('administrativa/')[1],
    };

    setLoading = (loading) => {
        this.setState({
            loading: loading,
        });
    }

    searchCidade = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/cidades/json-options/" + inputQuery
            );
            return response.data;
        }
    };

    searchEstado = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/estado/json-options/" + inputQuery
            );
            return response.data;
        }
    };

    getCidades = async () => {
        this.setLoading(true);

        await this.setState({
            regiao: [],
            cidades: [],
            estados: [],
        });

        await Axios.post(route('regiao-administrativa-by-id', this.state.id))
            .then((response) => {
                this.setState({
                    regiao: response.data,
                });
            })
            .catch()
            .finally(() => {
                this.setLoading(false);
            });
    }

    submit = async () => {

        if(this.state.cidades.length == 0 ||this.state.cidades == [] )
            {
                return alert("Selecione ao menos uma cidade.")
            }
        await Axios.post(route('syncCidades-by-id', this.state.id), {
            cidades: this.state.cidades.map((index) => index.label),
            estado: this.state.estados.value
        }).then((response) => {
            this.getCidades();
        }).catch()
            .finally();
    }

    detach = async (id) => {
        await Axios.post(route('syncCidades-by-id', this.state.id), {
            id: id,
            detach: true,
        }).then((response) => {
            this.getCidades();
        }).catch()
            .finally();
    }

    componentDidMount = () => {
        this.getCidades();
    }

    render = () => {
        return (
            <>
                <div className="card">
                    <div className="row col-12">
                        <div className="text-center col-12">
                            <h4>Selecione as cidades para vincular à região administrativa</h4>
                        </div>
                        <div className="form-group col-6">
                            <label>Estado</label>
                            <AsyncSelect
                                cacheOptions
                                isMulti={false}
                                value={this.state.estados}
                                loadOptions={this.searchEstado}
                                onChange={async (e) => {
                                    let value = e;

                                    if (e === null) {
                                        value = [];
                                    }

                                    await this.setState({
                                        ...this.state, estados: value
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group col-6">
                            <label>Cidade</label>
                            <AsyncSelect
                                cacheOptions
                                isMulti
                                value={this.state.cidades}
                                loadOptions={this.searchCidade}
                                onChange={async (e) => {
                                    let value = e;

                                    if (e === null) {
                                        value = [];
                                    }

                                    if (value.some(item => item.value === 'Todos')) {
                                        value = [];
                                        this.state.cidadesOptions.map((index) => {
                                            if (index.options != undefined) {
                                                console.log(index.options);
                                                value = value.concat(index.options)
                                            }
                                        })
                                    }
                                    await this.setState({
                                        ...this.state, cidades: value
                                    });

                                }}
                            />
                        </div>
                        <div className="form-group col-12">
                            <button className="btn btn-success btn-sm" onClick={this.submit}>Salvar</button>
                            <button className="btn btn-warning btn-sm" onClick={() => window.location = (route("regioes-administrativas"))}> Voltar</button>

                        </div>
                    </div >
                    <div className="card-body">
                        <table id="datatables" className="table table-striped table-condensed table-padroes">
                            <thead>
                                <tr>
                                    <th className="text-center">Cidade</th>
                                    <th className="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.regiao.cities?.map((index, key) => {
                                    console.log(index)
                                    return (
                                        <tr key={key}>
                                            <td>{index.city}</td>
                                            <td className="text-center">
                                                <button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => this.detach(index.id)}>
                                                    x
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.state.loading && <Loader />}
            </>
        );
    };
}

export default Index;

if (document.getElementById("regioes_administrativas_cidades")) {
    ReactDOM.render(<Index />, document.getElementById("regioes_administrativas_cidades"));
}
