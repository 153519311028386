import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import FormUpdate from "./FormUpdate";

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalUpdate: false,
            link_id: "",
            link: "",
            observacao: "",
            portal: "",
            status: "",
            type: "",
            phone_number: false,
        };
    }

    filterByType = (value) => {
        return value.type == this.props.type;
    };
    openModalUpdate = async (index) => {
        await this.setState({
            ...this.state,
            link_id: index.id,
            link: index.link,
            observacao: index.observacao,
            portal: index.portal,
            status: index.status,
            type: index.type,
            phone_number: index.phone_number == true ? true : false,
            showModalUpdate: true,
        });

        console.log(this.state.phone_number);
    };
    handleLinkChanged = async (e) => {
        await this.setState({
            ...this.state,
            link: e.target.value,
        });
    };
    handleObservacaoChanged = async (e) => {
        await this.setState({
            ...this.state,
            observacao: e.target.value,
        });
    };
    handlePortalChanged = async (e) => {
        await this.setState({
            ...this.state,
            portal: e.target.value,
        });
    };
    handleTypeChanged = async (e) => {
        await this.setState({
            ...this.state,
            type: e.value,
        });
    };
    handlePhoneNumberChanged = async (e) => {
        await this.setState({
            ...this.state,
            phone_number: this.state.phone_number == false ? true : false,
        });
    };
    closeModalUpdate = async () => {
        await this.setState({
            ...this.state,
            showModalUpdate: false,
        });
    };
    updateLink = async () => {
        await axios
            .post("update/" + this.state.link_id, {
                link: this.state.link,
                observacao: this.state.observacao,
                portal: this.state.portal,
                type: this.state.type,
                phone_number: this.state.phone_number,
            })
            .then(
                (response) => {
                    if (response.data.status === "Success") {
                        this.setState({
                            ...this.state,
                            link: "",
                            observacao: "",
                            portal: "",
                            type: "",
                            phone_number: false ,
                        });
                        this.props.getLinks();
                        this.closeModalUpdate();
                    }
                },
                (error) => {}
            );
    };
    inativarOuAtivarLink = async () => {
        await axios
            .post("update/" + this.state.link_id, {
                link: this.state.link,
                portal: this.state.portal,
                type: this.state.type,
                status: this.state.status == "Inativo" ? "Ativo" : "Inativo",
            })
            .then(
                (response) => {
                    if (response.data.status === "Success") {
                        this.setState({
                            ...this.state,
                            link: "",
                            portal: "",
                            type: "",
                        });
                        this.props.getLinks();
                        this.closeModalUpdate();
                    }
                },
                (error) => {}
            );
    };
    deletarLink = async (id) => {
        await axios
            .post("destroy/" + id)
            .then(
                (response) => {
                    if (response.data.status === "Success") {
                        this.props.getLinks();
                    }
                },
                (error) => {}
            );
    };

    filterByStatusAtivo = (value) => {
        return value.status == "Ativo";
    };
    filterByStatusInativo = (value) => {
        return value.status == "Inativo";
    };

    render() {
        let links = this.props.links;
        // let links = this.props.links.filter(this.filterByType);
        let ativos = links.filter(this.filterByStatusAtivo);
        let inativos = links.filter(this.filterByStatusInativo);

        return (
            <div className="card">
                <span>
                    Total de Links: {links.length} - Ativos: {ativos.length} -
                    Inativos {inativos.length}
                </span>
                <div
                    className="card-body"
                >
                    <table className="table table-striped table-condensed table-padroes">
                        <thead>
                            <tr className="text-center">
                                <th className="text-center">Portal</th>
                                <th className="text-center">Link</th>
                                <th className="text-center">Observação</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {links.map((index, key) => {
                                return (
                                    <tr>
                                        <td className="col-2">
                                            {index.portal} {index.phone_number == true && <span className="badge badge-info">Ligação</span>}
                                        </td>
                                        <td className="col-4">
                                            <a
                                                href={index.link}
                                                target="_blank"
                                            >
                                                {index.link.substring(0, 80)}
                                            </a>
                                        </td>
                                        <td className="col-2">
                                            {index.observacao}
                                        </td>
                                        <td className="col-2 text-center">
                                            {index.status}
                                        </td>
                                        <td className="text-center">
                                            <button
                                                className="btn btn-success btn-sm"
                                                onClick={() =>
                                                    this.openModalUpdate(index)
                                                }
                                            >
                                                <i className="fa fa-edit"></i>
                                            </button>
                                            <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() =>
                                                    this.deletarLink(index.id)
                                                }
                                            >
                                                <i className="fa fa-times"></i>
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <Modal
                        size="lg"
                        show={this.state.showModalUpdate}
                        onHide={this.closeModalUpdate}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Atualizar Link</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormUpdate
                                handleSubmit={this.updateLink}
                                handlePortalChanged={this.handlePortalChanged}
                                handleLinkChanged={this.handleLinkChanged}
                                handleObservacaoChanged={this.handleObservacaoChanged}
                                handleTypeChanged={this.handleTypeChanged}
                                handlePhoneNumberChanged={this.handlePhoneNumberChanged}
                                link={this.state.link}
                                observacao={this.state.observacao}
                                portal={this.state.portal}
                                type={this.state.type}
                                phone_number={this.state.phone_number}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-success"
                                onClick={this.updateLink}
                            >
                                Atualizar
                            </button>
                            <button
                                className="btn btn-danger"
                                onClick={this.inativarOuAtivarLink}
                            >
                                {this.state.status == "Inativo"
                                    ? "Ativar"
                                    : "Inativar"}
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default Table;
