import Axios from "axios";
import React, { Component } from "react";

export default class TableDescPadroes extends Component{

    constructor(props) {
        super(props);

        this.state = {
            data: [],

            padroesAndSlugs: [
                {
                    padrao: "Sigla",
                    slug: "sigla"
                },
                {
                    padrao: "Padrão",
                    slug: "padrao"
                },
                {
                    padrao: "Faixa de valor",
                    slug: "faixa"
                },
                {
                    padrao: "Coeficiente",
                    slug: "coeficiente"
                },
                {
                    padrao: "Projeto",
                    slug: "projeto"
                },
                {
                    padrao: "Nome e descrição",
                    slug: "nome_desc"
                },
                {
                    padrao: "Quartos",
                    slug: "quartos"
                },

            ]
        }


    }

    componentDidMount = async ()=> {
        try{
            let response = await Axios.get( route('web.supplies.patternDescription', {per_page: 200}));

            if(response.status == 200){
                console.log(response.data);

                await this.setState({
                    ...this.state,
                    data: response.data.data
                })
            }
        }catch (e) {
            console.log(e);
        }
    }

    /* download = async () => {
        try{
            
            let response = await Axios.get( route('web.supplies.patternDescription', {format: 'excel', per_page: 200}, {responseType: blob}));            

            if (response.status == 200) {
                if( response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
                    const type = response.headers['content-type']
                    const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'descricao_padrao.xlsx'
                    link.click()
                    
                }
            }
        }catch (e) {
            console.log(e);
        } 
    } */

    render(){

        return(
            <div>
                <div className="table-btn-div">
                    {/* <button
                        className="btn btn-sm btn-round btn-filter btn-download"
                        style={{
                        marginTop: "15px",
                        backgroundColor: "#5B7537",
                        borderColor: "#5B7537",
                        }}
                        type="button"
                        onClick="location.href='https://brain.hqssolucoes.com.br/insumos/descricao_padrao.xlsx'"
                    >
                        <i class="fa fa-download"></i>
                    </button> */}
                    <a
                        className="btn btn-sm btn-round btn-filter btn-download"
                        style={{
                            marginTop: "15px",
                            backgroundColor: "#5B7537",
                            borderColor: "#5B7537",
                        }}
                        role="button"
                        href="https://brain.hqssolucoes.com.br/insumos/descricao_padrao.xlsx"
                        download
                    >
                        <i class="fa fa-download"></i>
                    </a>
                </div>
                <table className="table table-striped">
                    <thead>

                        <tr style={styles.tr_subtitle}>
                            {this.state.padroesAndSlugs.map((padrao, index) => {
                                return <td key={index}>{padrao.padrao}</td>
                            })}
                        </tr>
                    </thead>

                    {this.state.data.map( (item, index) => {

                        return (
                            <tr key={`supplies_${index}_`}>
                                <td>{item.acronym}</td>
                                <td>{item.pattern}</td>
                                <td>{item.brain_pattern}</td>
                                <td>{item.coefficient}</td>
                                <td>{item.project}</td>
                                <td>{item.description}</td>
                                <td>{item.bedrooms}</td>
                            </tr>
                        )

                    })}

                </table>
            </div>
        );
    }

}

const styles = {
  tr_header: {
    backgroundColor: "#2e3525",
    color: "#fff",
    fontWeight: "bold"
  },
  tr_title: {
    backgroundColor: "#5b7537",
    color: "#fff",
    fontWeight: "bold"
  },
  tr_subtitle: {
    backgroundColor: "#7ca83e",
    color: "#fff",
    fontWeight: "bold"
  },
}
