import React from 'react';
import ReactDOM from 'react-dom';
import geolocationService from "../../services/geolocationService";
import ModalResults from "./ModalResults";
import Loader from "../Loader";

const API_KEY = process.env.MIX_GOOGLE_MAPS_API_KEY;


class ColetaPolosGeradores extends React.Component {


    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.state = {
            paginationData: {},
            form: {
                cidade: '',
                estado: '',
                endereco: '',
                latitude: '',
                longitude: '',
                cidade_toda: false,
            },
            loading: false,
            errors: {},
            show_modal_results: false,
            busca_selecionada: null
        }


    }

    salvarNovaColeta = async () => {
        try {
            this.setLoading(true);
            const response = await axios.post(route('web.coleta_polos_geradores.store'), this.state.form);

            if (response.status === 201) {
                this.setState({
                    ...this.state,
                    form: {
                        cidade: '',
                        estado: '',
                        endereco: '',
                        latitude: '',
                        longitude: '',
                        cidade_toda: false
                    }
                });

                this.inputRef.current.value = '';

                await this.loadColetas();
            }

        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 422) {
                this.setState({
                    errors: error.response.data.errors
                });

            }
        } finally {
            this.setLoading(false);
        }
    }

    onSearchComplete = async (e) => {
        try {
            this.setLoading(true);
            const place = this.autocomplete.getPlaces()[0];

            console.log(place);

            let latitude = place.geometry.location.lat();
            let longitude = place.geometry.location.lng();

            const cityData = await geolocationService.reverse(latitude, longitude, place);

            this.setState({
                ...this.state,
                form: {
                    ...this.state.form,
                    cidade: cityData.cidade,
                    estado: cityData.uf,
                    endereco: place.formatted_address,
                    latitude: latitude,
                    longitude: longitude
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setLoading(false);
        }
    }

    componentDidMount = async () => {
        await this.loadColetas();

        this.autocomplete = new window.google.maps.places.SearchBox(this.inputRef.current, {
            fields: ['geometry', 'formatted_address'],
            types: ['address'],
        });

        this.autocomplete.addListener('places_changed', this.onSearchComplete);

    }

    componentWillUnmount() {
        this.autocomplete.unbindAll();
    }

    showModalResult = () => {
        this.setState({
            show_modal_results: true
        });
    }

    setLoading = (loading) => {
        this.setState({
            loading: loading
        });
    }

    loadColetas = async (url = null) => {
        try {
            this.setLoading(true);

            if (!url) {
                url = route('web.coleta_polos_geradores.index-json');
            }

            let response = await axios.get(url);


            this.setState({
                paginationData: response.data
            });

        } catch (error) {
            console.log(error);
        } finally {
            console.log('Finalizou a requisição');
            this.setLoading(false);
        }
    }

    onChangeCidadeToda = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                cidade_toda: e.target.checked
            }
        });
    }


    render() {
        return (
            <div>

                <ModalResults
                    visible={this.state.show_modal_results}
                    onHide={() => this.setState({show_modal_results: false})}
                    busca={this.state.busca_selecionada}
                />


                <div className="card">
                    <div className="card-body">
                        {this.state.loading && <Loader/>}

                        <>
                            <h5 className="card-title">Coleta de Polos Geradores</h5>
                            <form action="">
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label htmlFor="nome">Endereço</label>
                                        <input
                                            type="text" className="form-control"
                                            id="endereco" name="endereco"
                                            ref={this.inputRef}
                                        />
                                        {this.state.errors.endereco &&
                                            <div
                                                className="invalid-feedback d-flex">{this.state.errors.endereco[0]}</div>}
                                        {this.state.errors.latitude &&
                                            <div
                                                className="invalid-feedback d-flex">{this.state.errors.latitude[0]}</div>}
                                        {this.state.errors.longitude &&
                                            <div
                                                className="invalid-feedback d-flex">{this.state.errors.longitude[0]}</div>}
                                        {this.state.errors.cidade &&
                                            <div
                                                className="invalid-feedback d-flex">{this.state.errors.cidade[0]}</div>}

                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="cidade_toda">
                                            <input
                                                type="checkbox"
                                                id="cidade_toda" name="cidade_toda"
                                                value={this.state.form.cidade_toda}
                                                checked={this.state.form.cidade_toda}
                                                onChange={this.onChangeCidadeToda}
                                            />
                                            &nbsp; Cidade Toda
                                        </label>
                                    </div>

                                    <div className="col-12">
                                        <button type="button" className="btn btn-primary btn-block"
                                                onClick={this.salvarNovaColeta}>Buscar
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <hr/>

                            <div className="table-responsive">
                                <table className="table table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Cidade</th>
                                        <th>Estado</th>
                                        <th>Endereço</th>
                                        <th>Latitude</th>
                                        <th>Longitude</th>
                                        <th>Cidade Toda</th>
                                        <th>Solicitante</th>
                                        <th>Ação</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.paginationData?.data?.map((coleta, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {coleta.status === 'Pendente' && <>
                                                        <span className="badge badge-warning">{coleta.status}</span>
                                                    </>}

                                                    {coleta.status === 'Enviado' && <>
                                                        <span className="badge badge-success">{coleta.status}</span>
                                                    </>}

                                                </td>
                                                <td>{coleta.cidade}</td>
                                                <td>{coleta.estado}</td>
                                                <td>{coleta.endereco}</td>
                                                <td>{coleta.latitude}</td>
                                                <td>{coleta.longitude}</td>
                                                <td>
                                                    {coleta.executar_cidade && <>
                                                        <span className="badge badge-success">Sim</span>
                                                    </>}

                                                    {!coleta.executar_cidade && <>
                                                        <span className="badge badge-danger">Não</span>
                                                    </>}


                                                </td>
                                                <td>{coleta.user?.name}</td>

                                                <td>
                                                    <a href="#"
                                                       title="Ver Resultados"
                                                       onClick={(e) => {
                                                        e.preventDefault();
                                                        this.showModalResult();
                                                        this.setState({
                                                            busca_selecionada: coleta
                                                        });
                                                    }}>
                                                        <i className="fa fa-list"></i>

                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    </tbody>
                                </table>
                            </div>
                            {this.state.paginationData?.links && <>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        {this.state.paginationData.links.map((link, index) => {
                                            return (link.url != null && (

                                                    <React.Fragment key={index}>
                                                        <li className={`page-item ${link.active ? 'active' : ''}`}>
                                                            <a className="page-link" href="#" onClick={async () => {
                                                                await this.loadColetas(link.url);
                                                            }}>
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: link.label}}></div>
                                                            </a>
                                                        </li>
                                                    </React.Fragment>
                                                )
                                            );
                                        })}

                                    </ul>
                                </nav>
                            </>}</>
                    </div>
                </div>
            </div>
        );
    }

}

if (document.getElementById('app-coleta-polos-geradores')) {
    ReactDOM.render(<ColetaPolosGeradores/>, document.getElementById('app-coleta-polos-geradores'));
}
