import React, { Component } from "react";
import Select from "react-select";
import IntlCurrencyInput from "react-intl-currency-input";

class FormCreate extends Component {
    getAges = () => {
        let ages = [];
        for (let i = 18; i < 99; i++) {
            ages.push({ value: i, label: i + " Anos" });
        }

        return ages;
    };

    handleChanged = async (event, value, maskedValue) => {
        this.props.handleChanged({
            name: "income",
            value: value,
        });
    };

    render() {
        const currencyConfig = {
            locale: "pt-BR",
            formats: {
                number: {
                    BRL: {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    },
                },
            },
        };

        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Tipo do Cliente</label>
                                <Select
                                    value={this.props.state.user_type}
                                    placeholder={this.props.state.user_type}
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "user_type",
                                            value: value.value,
                                        })
                                    }
                                    name="userType"
                                    options={[
                                        {
                                            value: "",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "lead",
                                            label: "Lead",
                                        },
                                        {
                                            value: "visitante",
                                            label: "Visitante",
                                        },
                                        {
                                            value: "cliente",
                                            label: "Cliente",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>CEP</label>
                                <input
                                    type="text"
                                    value={this.props.state.cep}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "cep",
                                            value: value.target.value,
                                        })
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>Idade</label>
                                <Select
                                    value={this.props.state.age}
                                    placeholder={this.props.state.age}
                                    isMulti={false}
                                    options={this.getAges()}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "age",
                                            value: value.value,
                                        })
                                    }
                                    name="age"
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>Gênero</label>
                                <Select
                                    value={this.props.state.genre}
                                    placeholder={this.props.state.genre}
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "genre",
                                            value: value.value,
                                        })
                                    }
                                    name="genre"
                                    options={[
                                        {
                                            value: "",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "masculino",
                                            label: "Masculino",
                                        },
                                        {
                                            value: "feminino",
                                            label: "Feminino",
                                        },
                                        {
                                            value: "transgênero",
                                            label: "Transgênero",
                                        },
                                        {
                                            value: "gênero neutro",
                                            label: "Gênero neutro",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>Estado Civil</label>
                                <Select
                                    value={this.props.state.marital_status}
                                    placeholder={
                                        this.props.state.marital_status
                                    }
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "marital_status",
                                            value: value.value,
                                        })
                                    }
                                    name="marital_status"
                                    options={[
                                        {
                                            value: "",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "solteiro",
                                            label: "Solteiro(a)",
                                        },
                                        {
                                            value: "casado",
                                            label: "Casado(a)",
                                        },
                                        {
                                            value: "separado",
                                            label: "Separado(a)",
                                        },
                                        {
                                            value: "divorciado",
                                            label: "Divorciado(a)",
                                        },
                                        {
                                            value: "viúvo",
                                            label: "Viúvo(a)",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>Filhos</label>
                                <Select
                                    value={this.props.state.children}
                                    placeholder={this.props.state.children}
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "children",
                                            value: value.value,
                                        })
                                    }
                                    name="children"
                                    options={[
                                        {
                                            value: "",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "true",
                                            label: "Sim",
                                        },
                                        {
                                            value: "false",
                                            label: "Não",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>Renda</label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    value={this.props.state.income}
                                    name="income"
                                    onChange={this.handleChanged}
                                    style={{ padding: "3px" }}
                                    className="valor form-control"
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>Imóvel de Interesse</label>
                                <input
                                    type="text"
                                    value={
                                        this.props.state.property_of_interest
                                    }
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "property_of_interest",
                                            value: value.target.value,
                                        })
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>Tipologia de Interesse</label>
                                <input
                                    type="text"
                                    value={
                                        this.props.state.typology_of_interest
                                    }
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "typology_of_interest",
                                            value: value.target.value,
                                        })
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>Fonte Livre</label>
                                <input
                                    type="text"
                                    value={this.props.state.text}
                                    onChange={(value) =>
                                        this.props.handleChanged({
                                            name: "text",
                                            value: value.target.value,
                                        })
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormCreate;
