import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {ModalFooter} from "react-bootstrap";
import Select from 'react-select';
class ModalCompareArea extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            area: null,
            selectedAreas: [],
            areasValues : []
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount = ()=> {

    }

    handleOptionsChange = async (selectedAreas) => {
        console.log(selectedAreas);

        let areasValues = [];

        selectedAreas.forEach(area => {
            areasValues.push(area.value);
        });


        await this.setState({
            selectedAres: selectedAreas,
            areasValues: areasValues
        });
    }

    submit = async ()=> {
        let values = [];

        this.state.selectedAreas.forEach(area => {
            values.push(area.value);
        });



        await axios.get( route('compare.areas', {areas: values}));

        this.closeModal();
    }

    openModal(area) {
        this.setState({
            modalIsOpen: true,
            area: area
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            area: null
        });
    }


    render() {

        let options = [];

        this.props.selectedAreas.map( (area)=>{
            let value = `${area.type.split(' ')}-${area.name}`;
            if ( area.meta_data?.cache_key !== undefined )
            {
                value += `@${area.meta_data.cache_key}`;
            }
            let areaName = '';

            if ( area.name !== undefined )
            {
                areaName = area.name;
            }

            options.push({
                value: value,
                label: area.type.split(' ')[0] + ' ' + areaName
            });
        })

        return (
            <div style={{ zIndex: '9999'}}>
                <Modal show={this.props.show} onHide={this.props.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Selecione as áreas que quer comparar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="panel-body">
                                        <form action={route('compare.areas')} method="get" target={'_blank'}>
                                            <Select
                                                isMulti={true}
                                                onChange={this.handleOptionsChange}
                                                options={options} />
                                            <hr/>
                                            <input type="hidden" name='areas' value={this.state.areasValues}/>
                                            <button className={'btn btn-primary'} type={'submit'}>Comparar</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {/*<ModalFooter>
                        <Button variant="secondary" onClick={this.props.closeModal}>
                            Fechar
                        </Button>
                        <Button variant="primary" onClick={this.props.closeModal}>

                        </Button>
                    </ModalFooter>*/}
                </Modal>
            </div>
        );
    }
}

export default ModalCompareArea;
