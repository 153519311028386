import React, { Component } from "react";

class InfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div
                className="card area-info"
                style={{
                    borderStyle: "solid",
                    borderColor: this.props.color,
                    borderWidth: "2px",
                    fontSize: 16,
                }}
            >
                <div
                    className="card-header"
                    style={{
                        color: "#517E37",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        fontSize: 18,
                    }}
                >
                    {this.props.type.split(" ")[0]}{" "}
                    {this.props.selectedArea.name} <br />
                </div>
                <div
                    className="card-body"
                    style={{ backgroundColor: "#ffffff", fontSize: "15" }}
                >
                    Tipo da Busca: {this.props.type}
                    <br />
                    {this.props.selectedArea.radius !== undefined && (
                        <>
                            Raio do Círculo:{" "}
                            {Number(this.props.selectedArea.radius).toFixed(2)}
                        </>
                    )}
                    <hr />
                    {this.props.selectedArea.users != undefined && (
                        <>
                            <strong>Total de Usuários Localizados:</strong>{" "}
                            {this.props.selectedArea.users.length ?? 0} <br />
                        </>
                    )}
                    {this.props.selectedArea.buildings != undefined && (
                        <>
                            <strong>Total de Imóveis Localizados:</strong>{" "}
                            {this.props.selectedArea.buildings.length ?? 0}{" "}
                            <br />
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default InfoCard;
