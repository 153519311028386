import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import TablePadroesInfoComercial from "./TablePadroesInfoComercial";

class ModalPadraoInfoComercial extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Modal
                size={'lg'}
                show={this.props.show}
                onHide={this.props.closeModal}
                // handleClose={}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Title>
                    <div className="text-center">
                        <label >Padrão do Imóvel</label>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <div className="text-justify">
                        <label for="">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            A classificação dos padrões em empreendimentos comerciais é feita
                            a partir da faixa de valor do m² privativo, sendo assim:
                        </label>
                    </div>


                    <TablePadroesInfoComercial/>
                </Modal.Body>

                <Modal.Footer>
                    <div className="col-8">
                    </div>
                    <div>
                        <button className={'btn btn-primary button-brain btn-round'} onClick={this.props.closeModal}>Fechar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalPadraoInfoComercial;
