import React, { Component } from 'react';


class HeatMapLegend extends Component {

    render() {

        let legends = {
            oferta_final : 'Oferta Final',
            ticket_medio : 'Ticket Médio',
            oferta_inicial: 'Lançamentos',
            vgv_final : 'VGV Oferta Final',
        };

        let legend = legends[this.props.weightType];

        return (
            <>

                   <table className="table table-striped table-condensed legenda-mapa" style={{backgroundColor: '#ffffff'}}>
                          <thead>
                            <tr>
                                <th scope="col" colSpan="2" style={{textAlign: 'center'}}>
                                    <span className="font-weight-bold text-center" style={{color: 'rgb(0, 72, 14)', fontSize: 16}}>
                                        Mapa de Calor - {legend}
                                    </span>
                                </th>
                            </tr>
                          </thead>
                       <tbody>
                       {this.props.config.map((item, index) => (


                           <tr key={index}>
                                    <td>
                                        <img src={item.icon} alt=""/>
                                    </td>
                                    <td>

                                        {item.max !== undefined && <>
                                            De {item.min} até {item.max}
                                        </>}
                                        {item.max === undefined && <>
                                            Acima de {item.min}
                                        </>}
                                    </td>
                                </tr>

                            ))}
                            </tbody>
                   </table>



            </>
        );




    }
}

export default HeatMapLegend;
