import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';


class ModalComparePolosGeradores extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            area: null,
            selectedAreas: [],
            areasValues : [],
            options: []
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount = ()=> {
        this.getAreas();
    }

    handleOptionsChange = async (selectedAreas) => {

        await this.setState({
            areasValues: selectedAreas
        });
    }



    openModal(area) {
        this.setState({
            modalIsOpen: true,
            area: area
        });

        this.getAreas();
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            area: null
        });
    }


    submit = async ( e )=> {
        e.preventDefault();
        let payload = [];

        this.state.areasValues.forEach((area)=>{
            payload.push(area.value);
        });


        await axios.post( route('polos-geradores-compare'), { areas : payload }, {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'polos_geradores.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
            console.log(error);
        });




    }


    render() {

        let options = this.getAreas();


        return (
            <div style={{ zIndex: '9999'}}>
                <Modal show={this.props.show} onHide={this.props.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Selecione as áreas que quer comparar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="panel-body">

                                        <Select
                                            isMulti={true}
                                            onChange={this.handleOptionsChange}
                                            value={this.state.areasValues}
                                            options={options} />
                                        <hr/>
                                        <button className={'btn btn-primary'} type={'submit'}
                                            onClick={this.submit}>Comparar</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

    getAreas() {
        let options = [];


        this.props.selectedAreas.forEach((area)=>{
            if( area.polos_geradores_cache_key === '' || area.polos_geradores_cache_key === undefined ){
                return;
            }


            let areaName = '';

            if ( area.name !== undefined )
            {
                areaName = area.name;
            }


            options.push({
                value: {
                    cache_key: area.polos_geradores_cache_key,
                    label: area.type.split(' ')[0] + ' ' + areaName
                },
                label: area.type.split(' ')[0] + ' ' + areaName
            });

        });

        return options;
    }
}

export default ModalComparePolosGeradores;
