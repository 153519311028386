import React from "react";
import Table from "./Table";
import Grafico from "./Grafico";

class TabLancamento extends React.Component {
    componentDidMount() { }

    filtroPorTipo = (rows, tipo) => {
        return rows.filter((index) => { return index.tipo == tipo });
    }

    validate = (data) => {
        return data != undefined && data != [] && data.length != 0;
    }

    render() {
        let exibirTabelas = true;
        let exibirGraficos = this.props.state.exibirGraficos;
        return (
            <>
                {this.props.state.tipos.some(option => option.label === 'Vertical')
                    && this.validate(this.props.state.dataLancamentosTipologia)
                    && this.validate(this.props.state.dataLancamentosTipo)
                    &&
                    <>
                        {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                            tipo_detalhamento='Tipologia'
                            titulo='Unidades Lançadas por Tipologia'
                            rows={this.props.state.dataLancamentosTipologia.unidadesLancadas}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.unidadesLancadas, "Vertical")}
                            id="excel1"
                        />}
                        {exibirGraficos && <Grafico
                            tipo_detalhamento='Tipologia'
                            titulo='Unidades Lançadas por Tipologia'
                            rows={this.props.state.dataLancamentosTipologia.unidadesLancadas}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}

                        />}
                        {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                            tipo_detalhamento='Tipologia'
                            titulo='VGV Lançado Por Tipologia '
                            rows={this.props.state.dataLancamentosTipologia.vgv}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.vgv, "Vertical")}
                            id="excel2"
                        />}

                        {exibirGraficos && <Grafico
                            tipo_detalhamento='Tipologia'
                            titulo='VGV Lançado Por Tipologia '
                            rows={this.props.state.dataLancamentosTipologia.vgv}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                        />}

                    </>
                }
                { this.validate(this.props.state.dataLancamentosPadrao)
                    && this.validate(this.props.state.dataLancamentosTipo)
                    &&
                    <>
                        {this.props.state.tipos.some(option => option.label === 'Vertical') &&
                            <>
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Padrão'
                                    titulo='Unidades Lançadas Vertical por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.unidadesLancadas, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.unidadesLancadas, "Vertical")}
                                    id="excel3"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Padrão'
                                    titulo='Unidades Lançadas Vertical por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.unidadesLancadas, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />
                                }
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Padrão'
                                    titulo='VGV Lançado Vertical Por Padrão '
                                    rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.vgv, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.vgv, "Vertical")}
                                    id="excel4"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Padrão'
                                    titulo='VGV Lançado Vertical Por Padrão '
                                    rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.vgv, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />
                                }
                            </>
                        }
                        {this.props.state.tipos.some(option => option.label === 'Horizontal') && <>
                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Padrão'
                                titulo='Unidades Lançadas Horizontal'
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.unidadesLancadas, "Horizontal")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.unidadesLancadas, "Horizontal")}
                                id="excel5"

                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Padrão'
                                titulo='Unidades Lançadas Horizontal'
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.unidadesLancadas, "Horizontal")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                            />}
                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Padrão'
                                titulo='VGV Lançado Horizontal Por Padrão '
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.vgv, "Horizontal")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.vgv, "Horizontal")}
                                id="excel6"

                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Padrão'
                                titulo='VGV Lançado Horizontal Por Padrão '
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.vgv, "Horizontal")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                            />}
                        </>}
                        {this.props.state.tipos.some(option => option.label === 'Comercial') && <>

                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Padrão'
                                titulo='Unidades Lançadas Comercial'
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.unidadesLancadas, "Comercial")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.unidadesLancadas, "Comercial")}
                                id="excel7"

                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Padrão'
                                titulo='Unidades Lançadas Comercial'
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.unidadesLancadas, "Comercial")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                            />}
                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Padrão'
                                titulo='VGV Lançado Comercial Por Padrão '
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.vgv, "Comercial")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                totais={this.filtroPorTipo(this.props.state.dataLancamentosTipo.vgv, "Comercial")}
                                id="excel8"

                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Padrão'
                                titulo='VGV Lançado Comercial Por Padrão '
                                rows={this.filtroPorTipo(this.props.state.dataLancamentosPadrao.vgv, "Comercial")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                            />}
                        </>}
                    </>
                }
            </>
        );
    }
}

export default TabLancamento;
