import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Axios from "axios";

class Modais extends Component {
    state = {
        name: ""
    }

    create = async () => {
        Axios.post(route('regioes-administrativas'), {
            name: this.state.name
        }).then((response) => {
            this.props.closeModal("showModalCreate");
            this.props.getRegioes();
        }).catch(() => { })
            .finally(() => { })
    }
    render() {
        return (<>
            <Modal
                size="md"
                show={this.props.state.showModalCreate}
                onHide={() => this.props.closeModal("showModalCreate")}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Região Administrativa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="form-group col-12">
                            <label>Nome da Região</label>
                            <input
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={(e) => this.setState({
                                    ...this.state,
                                    name: e.target.value
                                })}
                                style={{ padding: "3px" }}
                                className="form-control"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success" onClick={() => this.create()}>Criar</button>
                </Modal.Footer>
            </Modal>
        </>);
    }
}

export default Modais;
