const replaceMarkersRevendaLocacao = async( empreendimentos )=>{
    return new Promise( (resolve, reject)=>{
        let newRevenda = [];

        let tiposAgregadosApartamentos = [
            "Apartamento", "Apartamento Duplex", "Apartamento Garden", "Flat", "Cobertura", "Kitinet", "Loft", "Studio"
        ];

        let tipoCasaAgregado = [
            "Casa","Casa de Condomínio", "Casa Residencial", "Residência", "Sobrado"
        ];

        let tipoComercialAgregado = [
            "Loja", "Ponto comercial/Loja/Box", "Loja Shopping/ Ct Comercial", "Loja/Salão", "Área Comercial",
            "Edifício Comercial", "Galpão Comercial", "Prédio Comercial", "Residência Comercial", "Conjunto Comercial/Sala",
            "Casa Comercial", "Prédio Inteiro", "Sala/Conjunto", "Sala Comercial", "Prédio/Edificio inteiro"
        ];

        let tipoRuralAgregado = ["Chácara", "Fazenda" ,"Haras"];

        let tipoIndustrialAgregado = ["Galpão", "Garagem"];

        let tipoTerrenoAgregado = ["Terreno", "Loteamento/Condomínio", "Lote/Terreno"];

        for( let x = 0; x < empreendimentos.length; x++ ){

            let empreendimento = empreendimentos[x];

            if( tiposAgregadosApartamentos.includes( empreendimento.tipo ) ){
                if( empreendimento.tipo_negocio.toLowerCase() === 'venda'){
                    empreendimento.customMarker = `/img/markers/apartamentos.png`;
                }else{
                    empreendimento.customMarker = `/img/markers/apartamentos_aluguel.png`;
                }
            }

            if( tipoCasaAgregado.includes( empreendimento.tipo ) ){
                if (empreendimento.tipo_negocio.toLowerCase() === "venda" ) {
                    empreendimento.customMarker = `/img/markers/residencial.png`;
                } else {
                    empreendimento.customMarker = `/img/markers/residencial_aluguel.png`;
                }
            }

            if( tipoRuralAgregado.includes( empreendimento.tipo ) ){
                if (empreendimento.tipo_negocio.toLowerCase() === "venda" ) {
                    empreendimento.customMarker = `/img/markers/rural.png`;
                } else {
                    empreendimento.customMarker = `/img/markers/rural_aluguel.png`;
                }
            }

            if( tipoIndustrialAgregado.includes( empreendimento.tipo ) ){
                if (empreendimento.tipo_negocio.toLowerCase() === "venda" ) {
                    empreendimento.customMarker = `/img/markers/industrial-garagem.png`;
                } else {
                    empreendimento.customMarker = `/img/markers/industrial-garagem_aluguel.png`;
                }
            }

            if( tipoComercialAgregado.includes( empreendimento.tipo ) ){
                if (empreendimento.tipo_negocio.toLowerCase() === "venda" ) {
                    empreendimento.customMarker = `/img/markers/comercial.png`;
                } else {
                    empreendimento.customMarker = `/img/markers/comercial_aluguel.png`;
                }
            }

            if( tipoTerrenoAgregado.includes( empreendimento.tipo ) ){
                if (empreendimento.tipo_negocio.toLowerCase() === "venda" ) {
                    empreendimento.customMarker = `/img/markers/lotes-terrenos.png`;
                } else {
                    empreendimento.customMarker = `/img/markers/lotes-terrenos_aluguel.png`;
                }
            }

            newRevenda.push( empreendimento );

        }

        resolve( newRevenda );

    });


}

export {
    replaceMarkersRevendaLocacao
}
