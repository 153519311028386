window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    //window.Popper = require('popper.js').default;
    //window.$ = window.jQuery = require('jquery');

    //require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
window.Noty = require('noty');
window.clipboard = require('clipboard');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';



/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

//import Echo from 'laravel-echo';

/*window.Pusher = require('pusher-js');

window.Echo = new Echo({
     broadcaster: 'pusher',
     key: process.env.MIX_PUSHER_APP_KEY,
     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
     encrypted: true,
     wsHost: window.location.hostname,
     wsPort: 6001,
     forceTLS: false,
     disableStats: true,
});

window.Echo.private('bases-assigned').listen( 'BasesWereAssigned',e => {
    //alert('Opá');
    var message = `<div class="alert alert-success text-center font-weight-bold" style="border-radius: 10px; opacity: 0.9">
        Distribuiçao de Atualizaçao do Periodo ${e.periodo} da cidade de ${e.cidade} foi finalizada.
    </div>`;
    var notyAlert = new Noty({
        text: message,
        type: 'info',
        layout: 'topRight',
        //timeout : 5000,
        theme : 'bootstrap-v4'
        });
    notyAlert.show();
});*/

import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: process.env.VITE_SENTRY_DSN_PUBLIC,
});
