import React, { Component } from "react";

class Table extends Component {
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <table
                        id="datatables"
                        className="table table-striped table-condensed table-padroes"
                    >
                        <thead>
                            <tr>
                                <th>Empreendimento</th>
                                <th>Total Ativo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.data.data.mediaQueryMonth !=
                                undefined &&
                                this.props.data.data.mediaQueryMonth.map(
                                    (index, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{index.name}</td>
                                                <td>{index.countModel}</td>
                                            </tr>
                                        );
                                    }
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Table;
