import React, { Component } from 'react';
import ModalSetRadiusFromMarker from './ModalSetRadiusFromMarker';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { Circle } from 'react-google-maps';

class ToolsBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: true
          }
    }

    alert = ()=>{
        alert('Opa');
    }

    render() {
        return (
            <div style={{position: 'absolute', bottom: '145px', zIndex : 9999}}>
                <div style={{marginLeft: '50px'}} className="">
                    <div className="btn-group" role="group" aria-label="Basic example" style={{boxShadow: '0 0 3px rgba(0,0,0,.2'}}>

                    {
                        this.props.overlays.map((overlay, index ) => {
                           if( overlay.type !== 'cidade' ){
                               return <React.Fragment key={overlay.id}>
                                   <button style={{ backgroundColor: overlay.color, border: 'none' }}
                                   key={index * 145245} className="btn btn-light"
                                   title={overlay.type} onClick={() => {

                                       this.props.removeOverlay(overlay.id);
                                   }}
                                   >
                                       <i className="far fa-trash-alt"  ></i>
                                   </button>
                                   {(overlay.type == 'Círculo' || overlay.type == 'Círculo Manual') && <>
                                       <OverlayTrigger
                                           trigger="click"
                                           placement="top"
                                           overlay={
                                               <Popover>
                                                   <Popover.Title as="h3">Editar Raio do Círculo</Popover.Title>
                                                   <Popover.Content>
                                                       <input type="number" className="form-control form-control-sm" value={overlay.radius}
                                                       onChange={ (e)=>{

                                                           this.props.onChangeInputRadius(overlay.id, e.target.value);

                                                       }} />
                                                    </Popover.Content>
                                               </Popover>
                                           }>
                                           <button style={{ backgroundColor: overlay.color, border: 'none' }} key={index * 145245}
                                               className="btn btn-light" title={overlay.type} onClick={() => {


                                               }}
                                           >
                                               <i className="far fa-edit"  ></i>
                                           </button>
                                       </OverlayTrigger>



                                   </>}
                               </React.Fragment>
                           }
                        })


                        }

                        {this.props.heatMap && <>

                            <button style={{backgroundColor: "darkred", border: 'none'}}
                                    key={121212121212121 * 145245} className="btn btn-light"
                                    title="Remover Mapa de Calor" onClick={() => {

                                this.props.removeHeatMap();
                            }}
                            >
                                <i className="fa fa-trash" style={{color: 'white'}}></i>
                            </button>

                            <button style={{backgroundColor: "green", border: 'none'}}
                                    key={12121212121212451 * 145245} className="btn btn-light"
                                    title={this.props.showMarkers == true ? 'Esconder Marcadores' : 'Mostrar Marcadores'} onClick={() => {

                                this.props.toggleShowMarkers();
                            }}
                            >
                                { this.props.showMarkers == false ? <i className="fa fa-map-marker-alt" style={{color: 'coral'}}></i> :
                                    <i className="fa-solid fa-xmark" style={{color: 'white'}}></i>
                                }
                            </button>
                        </>
                        }
                    </div>

                </div>
            </div>
         );
    }
}

export default ToolsBar;
