import {Component, React} from 'react';
import './styles.css';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import InnerLoading from "../Viabilidade/InnerLoading";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);


let reportsServiceBaseUrl = process.env.MIX_BASE_URL_REPORTS;
class AnaliseTemporalEmpreendimento extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                tipologias: [],
                bases: []
            },
            empreendimento: {
                periodos_da_cidade: []
            },
            periodos_da_cidade: [],
            user: {},
            loading: false,
            opcoes_informacao:[
                {
                    label: "Estoque",
                    value: "stock"
                },{
                    label: "Venda Líquida",
                    value: "venda_liquida"
                },{
                    label: "Ticket Médio M²",
                    value: "price_private_area"
                },{
                    label: "Ticket Médio",
                    value: "price"
                }
            ],
            opcao_selecionada: {
                label: "Estoque",
                value: "stock"
            },
            agrupamentos: ['Mensal', 'Trimestral'],
            agrupamento_selecionado: 'Mensal'
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            empreendimento: props.empreendimento,
        };
    }


    loadAuthUser = async () => {
        try {

            let response = await axios.get(route('user.auth'));

            if (response.status === 200) {
                this.setState({
                    ...this.state, user: response.data
                });
            }

        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount = async () => {
        await this.loadAuthUser();

        let agrupamento_selecionado = 'Mensal';

        if(parseInt(this.props.empreendimento?.city?.follow_up_period) === 3)
        {
            agrupamento_selecionado = 'Trimestral';
        }

        this.setState({
            ...this.state,
            agrupamento_selecionado: agrupamento_selecionado,
            periodos_da_cidade: this.props.empreendimento.periodos_da_cidade
        });


        await this.getData();
    }

    getData = async () => {
        try{

            this.setState({
                ...this.state,
                loading: true
            })

            let response = await axios.get( route('web.analise_temporal_empreendimento.get', this.state.empreendimento.id_legado ) );

            if( response.status === 200 ){
                this.setState({
                    ...this.state,
                    data: {
                        tipologias: response.data.tipologias,
                        bases : response.data.bases,
                    }
                });
            }

        }catch (e) {
            console.log(e);
        }finally {
            this.setState({
                ...this.state,
                loading: false
            })
        }
    }

    formatPeriodo = (periodo) => {
        if( this.state.agrupamento_selecionado === 'Mensal' ){
            let mes = periodo.split('-')[1];
            let ano = periodo.split('-')[0];
            return `${mes}/${ano}`;
        }

        if( this.state.agrupamento_selecionado === 'Trimestral'){
            //return quarter example 1T/2021
            let mes = periodo.split('-')[1];
            let ano = periodo.split('-')[0];

            if( mes === '01' || mes === '02' || mes === '03' ){
                return `1T/${ano}`;
            }

            if( mes === '04' || mes === '05' || mes === '06' ){
                return `2T/${ano}`;
            }

            if( mes === '07' || mes === '08' || mes === '09' ){
                return `3T/${ano}`;
            }

            if( mes === '10' || mes === '11' || mes === '12' ){
                return `4T/${ano}`;
            }

        }
    }

    formatCurrency = (value) => {

        if( !value ){
            return;
        }

        let formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 0

        });

        return formatter.format(value);
    }

    getCategories = ()=>{
        return this.state.periodos_da_cidade.map( periodo => {
            return this.formatPeriodo(periodo);
        });
    }

    getHighChartsOptions = ()=>{

        let categories = this.getCategories();

        let dado = this.state.opcao_selecionada.label;

        let series = this.getSeries();

        return {
            chart: {
                type: 'line',
                plotBackgroundImage: 'https://geobrain.com.br/img/thumb-geobrain-mark.png',
            },
            title: {
                text: 'Análise Temporal'
            },
            subtitle: {
                text: this.state.opcao_selecionada.label
            },
            xAxis: {
                categories: categories
            },
            yAxis: {
                title: {
                    text: dado
                }
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            series: series
        };
    }

    onChangeAgrupamento = async (e) => {
        let agrupamento = e.target.value;
        let periodosDaCidade = this.state.empreendimento.periodos_da_cidade;

        if( agrupamento === 'Trimestral' ){
            periodosDaCidade = this.filterPeriodosTrimestrais(periodosDaCidade);
        }

        await this.setState({
            ...this.state,
            agrupamento_selecionado: agrupamento,
            periodos_da_cidade: [...periodosDaCidade]
        });

        console.log( agrupamento);
        console.log( periodosDaCidade );
    }


    render() {
        return (
            <div className="card-body">

                <div className="form-group row">
                    <div className="col-6">
                        <label htmlFor="informacao">Informação</label>
                        <select className="form-control"
                                id="informacao"
                                value={this.state.opcao_selecionada.value}
                                onChange={(e) => {

                                    let informacao = e.target.value;

                                    let opcao_selecionada = this.state.opcoes_informacao.find(opcao => {
                                        return opcao.value === informacao;
                                    });

                                    this.setState({
                                        ...this.state,
                                        opcao_selecionada: opcao_selecionada
                                    })
                                }}>
                            {this.state.opcoes_informacao.map(option => {
                                return <option value={option.value}>{option.label}</option>
                            })}</select>
                    </div>
                    <div className="col-6">
                        {this.state?.empreendimento?.city?.follow_up_period === 1 && <>
                            <label htmlFor="agrupamento">Agrupamento</label>
                            <select className="form-control"
                                    id="agrupamento"
                                    value={this.state.agrupamento_selecionado}
                                    onChange={this.onChangeAgrupamento}>
                                {this.state.agrupamentos.map(option => {
                                    return <option value={option}>{option}</option>
                                })}</select>
                        </>}
                    </div>
                </div>

                {!this.state.loading && <>
                    <HighchartsReact
                        options={this.getHighChartsOptions()}
                        highcharts={Highcharts}
                    />
                </>}
                {this.state.loading && <>
                    <InnerLoading/>
                </>}

            </div>
        );
    }

    getSeries() {
        let series = [];

        this.state.data.tipologias.map(tipologia => {
            let data = [];
            this.state.periodos_da_cidade.map(periodo => {

                let key = this.state.opcao_selecionada.value;

                if( this.state.agrupamento_selecionado === 'Trimestral' && key === 'venda_liquida' ){
                    key = 'venda_liquida_trimestre';
                }

                let base = this.state?.data?.bases[tipologia.id][periodo]?.[0]?.[key] ?? 0;

                data.push(Number(base));

            });

            series.push({
                name: `${tipologia.number_bedroom} dorms - ${tipologia.private_area}m²`,
                data: data
            });

        })

        console.log( series );

        return series;
    }

    filterPeriodosTrimestrais(periodosDaCidade) {
        return periodosDaCidade.filter( periodo => {
            //retornar apenas os últimos meses de cada trimestre
            let mes = periodo.split('-')[1];

            if( mes === '03' || mes === '06' || mes === '09' || mes === '12' ){
                return true;
            }

        });
    }
}

export default AnaliseTemporalEmpreendimento;

