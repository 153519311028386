import React from 'react';

class TableAuditReportAnaliticGrid extends React.Component {
    render() {
        return (
            <div>
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Operador</th>
                            <th>Empreendimento</th>
                            <th>Número de Empreendimentos</th>
                            <th>Incorporadora</th>
                            <th>Com Fonte de Informação</th>
                            <th>Tipo</th>
                            <th>Data</th>
                            <th>Status da Auditoria</th>
                            <th>Followup da Auditoria</th>
                            <th>Data da Auditoria</th>
                            <th>Saldo</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.props.data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.operador}</td>
                            <td>{item.empreendimento}</td>
                            <td>{item.numero_empreendimentos}</td>
                            <td>{item.incorporadoras}</td>
                            <td>{item.possue_fonte}</td>
                            <td>{item.tipo}</td>
                            <td>{item.created_at}</td>
                            <td>{item.audit_status}</td>
                            <td>{item.followup}</td>
                            <td>{item.audit_date}</td>
                            <td>{item.saldo}</td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableAuditReportAnaliticGrid;
