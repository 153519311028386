import React, {Component} from 'react';

function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
}

class RendaIbgeLegend extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log('RendaIbgeLegend mounted');

        console.log(this.props.colorRanges);
    }

    formatData = (data) => {
        let dataType = this.props.mapa_coropetico_current_showing.config.dado_type;

        let formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        let doubleFormatter = new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 2
        });

        if( dataType === 'currency' ){
            return formatter.format(data);
        }

        if( dataType === 'percentage' ){
            return formatter.format(data).replace("R$", "") + '%';
        }

        if( dataType === 'double' ){
            return doubleFormatter.format(data);
        }

        return data;
    }

    render() {

        let colorRanges = this.props.colorRanges;

        return (<>
                <table className="table table-striped table-condensed legenda-mapa">
                    <thead>
                    <tr>
                        <th scope="col" colSpan="2" style={{textAlign: 'center'}}>

                            <span className="font-weight-bold text-center"
                                  style={{color: 'rgb(0, 72, 14)', fontSize: 16}}>
                                        {this.props.mapa_coropetico_current_showing?.config?.title}
                            </span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {colorRanges?.map((item, index) => (<tr key={index} style={styles.legend}>
                    <td style={{...styles.legend, backgroundColor:  hexToRGB(item.color, '0.9')}}>
                                <div style={{width: '13px', height: '13px'}}></div>
                            </td>
                            <td style={styles.legend}>
                                {item.max !== Infinity && <>
                                    {this.formatData(item.min)}&nbsp;- {this.formatData(item.max)}
                                </>}
                                {item.max === Infinity && <>
                                    Acima de {this.formatData(item.min)}
                                </>}
                            </td>
                        </tr>))}
                    </tbody>
                </table>

                <hr />
                <button className="btn btn-default btn-sm btn-block" onClick={this.props.openModalLegendas}>
                    Customizar Faixas
                </button>
        </>);

    }
}

const styles = {
    legend: {
        padding: '5px',
        margin: '4px',
    }
}


export default RendaIbgeLegend;
