import React, { Component } from "react";
import Select from "react-select";
import MultiSelect from "react-multi-select-component";
import Legenda from "./Legenda";
import LegendaUsers from "./LegendaUsers";
import AsyncSelect from "react-select/async";
import Axios from "axios";
class FormFilters extends Component {
    componentDidMount = async () => {};

    searchIncorporators = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/incorporadoras-json?incorporadora=" + inputQuery
            );
            return response.data;
        }
    };

    searchCity = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/cidades/json-options/" + inputQuery
            );
            return response.data;
        }
    };

    refreshIconButton = () => {
        return (
            <>
                <i className="fas fa-sync-alt"></i> Atualizar
            </>
        );
    };

    buscarIconButton = () => {
        return (
            <>
                <i className="fas fa-search"></i> Buscar
            </>
        );
    };

    render() {
        return (
            <div
                style={{
                    overflowX: "hidden",
                    height: "90vh",
                }}
            >
                <div className="card filtro-mapa">
                    <div className="card-body">
                        <div className="form-group">
                            <label>Incorporadora</label>
                            <AsyncSelect
                                value={
                                    this.props.state.incorporators
                                        ? this.props.state.incorporators.map(
                                              (item) => ({
                                                  value: item,
                                                  label: item,
                                              })
                                          )
                                        : []
                                }
                                cacheOptions
                                defaultOptions
                                isMulti={true}
                                loadOptions={this.searchIncorporators}
                                onChange={(value) =>
                                    this.props.handleChanged({
                                        name: "incorporators",
                                        value: value,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group">
                            <label>Nome do Empreendimento</label>
                            <AsyncSelect
                                cacheOptions
                                isMulti
                                value={
                                    this.props.state.property_of_interest
                                        ? this.props.state.property_of_interest.map(
                                              (item) => ({
                                                  value: item,
                                                  label: item,
                                              })
                                          )
                                        : []
                                }
                                defaultOptions
                                loadOptions={this.props.searchBuildings}
                                onChange={(value) =>
                                    this.props.handleChanged({
                                        name: "property_of_interest",
                                        value: value,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group">
                            <label>Status</label>

                            <Select
                                placeholder="Selecione"
                                isMulti={true}
                                value={
                                    this.props.state.status
                                        ? this.props.state.status.map(
                                              (item) => ({
                                                  value: item,
                                                  label: item,
                                              })
                                          )
                                        : []
                                }
                                onChange={(value) =>
                                    this.props.handleChanged({
                                        name: "status",
                                        value: value,
                                    })
                                }
                                options={[
                                    {
                                        value: "Comercialização",
                                        label: "Comercialização",
                                    },
                                    { value: "Esgotado", label: "Esgotado" },
                                    { value: "Futuro", label: "Futuro" },
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <label>Cidade</label>
                            <AsyncSelect
                                value={
                                    this.props.state.city
                                        ? this.props.state.city.map((item) => ({
                                              value: item,
                                              label: item,
                                          }))
                                        : []
                                }
                                cacheOptions
                                placeholder="Selecione"
                                isMulti={true}
                                loadOptions={this.searchCity}
                                onChange={(value) => {
                                    this.props.handleChanged({
                                        name: "city",
                                        value: value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Tipo Usuário</label>

                            <Select
                                isMulti={true}
                                value={
                                    this.props.state.user_type
                                        ? this.props.state.user_type.map(
                                              (item) => ({
                                                  value: item,
                                                  label: item,
                                              })
                                          )
                                        : []
                                }
                                ref={(ref) => (this.userTypeSelectRef = ref)}
                                onChange={(value) =>
                                    this.props.handleChanged({
                                        name: "user_type",
                                        value: value,
                                    })
                                }
                                name="userType"
                                options={[
                                    { value: "", label: "Selecione" },
                                    { value: "lead", label: "Lead" },
                                    { value: "visitante", label: "Visitante" },
                                    { value: "cliente", label: "Cliente" },
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <label>Quartos</label>
                            <br />
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.state.bedrooms.includes(
                                            1
                                        )}
                                        onChange={
                                            this.props.handleBedroomsChanged
                                        }
                                        type="checkbox"
                                        value="1"
                                    />
                                    <span className="form-check-sign"></span>1
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        onChange={
                                            this.props.handleBedroomsChanged
                                        }
                                        checked={this.props.state.bedrooms.includes(
                                            2
                                        )}
                                        type="checkbox"
                                        value="2"
                                    />
                                    <span className="form-check-sign"></span>2
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.state.bedrooms.includes(
                                            3
                                        )}
                                        onChange={
                                            this.props.handleBedroomsChanged
                                        }
                                        type="checkbox"
                                        value="3"
                                    />
                                    <span className="form-check-sign"></span>3
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.state.bedrooms.includes(
                                            4
                                        )}
                                        onChange={
                                            this.props.handleBedroomsChanged
                                        }
                                        type="checkbox"
                                        value="4"
                                    />
                                    <span className="form-check-sign"></span>4
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        checked={this.props.state.showBuildings}
                                        onChange={(value) =>
                                            this.props.handleChanged({
                                                name: "showBuildings",
                                                value: !this.props.state
                                                    .showBuildings,
                                            })
                                        }
                                        type="checkbox"
                                    />
                                    <span className="form-check-sign"></span>
                                    Exibir Empreendimentos
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer btn-filtro-mobile">
                        <div className="row">
                            <div className="col-6 tira-margin-right">
                                <button
                                    className="btn btn-sm btn-success btn-block background-brain border-brain btn-round"
                                    style={{ marginTop: "15px" }}
                                    onClick={this.props.handleSubmit}
                                >
                                    {this.props.state.overlays.length > 0
                                        ? this.refreshIconButton()
                                        : this.buscarIconButton()}
                                </button>
                            </div>
                            <div className="col-6 tira-margin-right">
                                <button
                                    className="btn btn-sm btn-block btn-primary button-brain btn-round"
                                    style={{ marginTop: "15px" }}
                                    onClick={this.props.resetForm}
                                >
                                    <i className="far fa-file"></i> Limpar
                                </button>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>

                {this.props.state.showLegenda && (
                    <>
                        <LegendaUsers />
                        <Legenda ranges={this.props.state.ranges} />
                    </>
                )}
            </div>
        );
    }
}

export default FormFilters;
