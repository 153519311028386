import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Axios from "axios";

class Form extends Component {
    constructor(props) {
        super(props);
    }
    searchUser = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                `/mapeamento/users/json-options/${inputQuery}?role=Mapeamento` 
            );
            return response.data;
        }
    };

    render() {
        var canUseFilter = window.isGestor;

        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Período Inicial</label>
                                <input
                                    type="date"
                                    name="periodBeginAt"
                                    value={
                                        this.props.state.currentForm.periodBeginAt
                                    }
                                    onChange={(value) =>
                                        this.props.onChange({
                                            name: "periodBeginAt",
                                            value: value.target.value,
                                        })
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>Período Final</label>
                                <input
                                    type="date"
                                    name="periodEndAt"
                                    value={this.props.state.currentForm.periodEndAt}
                                    onChange={(value) =>
                                        this.props.onChange({
                                            name: "periodEndAt",
                                            value: value.target.value,
                                        })
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>Tipo Relatório</label>
                                <Select
                                    value={this.props.state.currentForm.type}
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.props.onChange({
                                            name: "type",
                                            value: value,
                                        })
                                    }
                                    options={[
                                        {
                                            value: "",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "Analítico",
                                            label: "Analítico",
                                        },
                                        {
                                            value: "Gerencial",
                                            label: "Gerencial",
                                        },
                                        {
                                            value: "Analítico Por Peso",
                                            label: "Analítico Por Peso",
                                        },
                                        {
                                            value: "Gerencial Por Peso",
                                            label: "Gerencial Por Peso",
                                        },
                                    ]}
                                />
                            </div>
                            {canUseFilter && (<div className="form-group col-6">
                                <label>Assistente</label>
                                <AsyncSelect
                                    cacheOptions
                                    isMulti
                                    value={this.props.state.currentForm.users}
                                    loadOptions={this.searchUser}
                                    onChange={(value) =>
                                        this.props.onChange({
                                            name: "users",
                                            value: value ?? [],
                                        })
                                    }
                                />
                            </div>)}

                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-success btn-block btn-round background-brain border-brain"
                                    onClick={this.props.submit}
                                >
                                    <i className="fas fa-search"></i> Buscar
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-block btn-round"
                                    style={{ backgroundColor: "#FFBB27" }}
                                    onClick={this.props.clearState}
                                >
                                    <i className="far fa-file"></i> Limpar
                                </button>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
