const getBrowserGeolocation = async ()=>{
    let pos =  { lat: -15.721387, lng: -48.0774442 };
  
    if( window.navigator.geolocation ){
      window.navigator.geolocation.getCurrentPosition( (position)=> {
          pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
          };
          
      });
    }
    return pos;
  }

export default getBrowserGeolocation;