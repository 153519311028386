import Axios from "axios";

class EmpreendimentosServiceGeo {
    baseUrlEmpreendimentos = "/empreendimentos/json";
    baseUrlEmpreendimento = "/empreendimento/json/";

    extractValuesArray = (array) => {
        let newArray = [];

        array.forEach((element) => {
            newArray.push(element.value);
        });

        return newArray;
    };

    getEmpreendimentosWithinPolygon = async (path, state) => {
        let {
            tipo_empreendimento,
            bedrooms,
            status,
            incorporators,
            property_of_interest,
        } = state;

        let nome = property_of_interest;
        let incorporadoras = incorporators;
        let quartos = bedrooms;
        // incorporadoras = this.extractValuesArray(incorporadoras);

        const requestBody = {
            tipo_empreendimento,
            status,
            quartos,
            polygon: path,
            incorporadoras,
            nome,
        };

        try {
            const empreendimentos = await axios.post(
                this.baseUrlEmpreendimentos,
                requestBody
            );

            return empreendimentos;
        } catch (error) {
            console.log("Erro ao Buscar Empreendimento");
            console.log("error");
        }
    };

    getEmpreendimentosWithinRadius = async (
        latitude,
        longitude,
        radius,
        state
    ) => {
        let { padrao, bedrooms, status, incorporators, property_of_interest } =
            state;

        let nome = property_of_interest;
        let incorporadoras = incorporators;
        let quartos = bedrooms;

        const requestBody = {
            padrao,
            quartos,
            status,
            incorporadoras,
            circle: {
                radius: radius,
                latitude: latitude,
                longitude: longitude,
            },
            nome,
        };

        try {
            const empreendimentos = await axios.post(
                this.baseUrlEmpreendimentos,
                requestBody
            );

            return empreendimentos;
        } catch (error) {
            console.log("Erro ao Buscar Empreendimentos");
            console.log(error);
        }
    };

    getEmpreendimento = async (id) => {
        try {
            const empreendimentoResponse = await axios.get(
                this.baseUrlEmpreendimento + id
            );

            return empreendimentoResponse.data;
        } catch (error) {
            console.log("error");
        }
    };

    getEmpreendimentoOnCidade = async (state) => {
        let {
            tipo_empreendimento,
            city,
            bedrooms,
            incorporators,
            property_of_interest,
            status,
        } = state;

        let nome = property_of_interest;
        let cidade = city;
        let incorporadoras = incorporators;
        let quartos = bedrooms;

        // incorporadoras = this.extractValuesArray(incorporadoras);

        const requestBody = {
            tipo_empreendimento,
            cidade,
            incorporadoras,
            nome,
            status,
        };
        try {
            const empreendimentos = await axios.post(
                this.baseUrlEmpreendimentos,
                requestBody
            );

            return empreendimentos;
        } catch (error) {
            console.log("Erro ao Buscar Empreendimentos");
            console.log(error);
        }
    };
}

export default new EmpreendimentosServiceGeo();
