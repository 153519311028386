import React, { Component } from "react";
import { render } from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import mapDataBrasil from "./mapDataBrasil";
import { deviceDetect } from "react-device-detect";

require("highcharts/modules/map")(Highcharts);
class Mapa extends Component {
    constructor(state) {
        super(state);
        this.state = {};
    }

    componentDidMount = async () => {};

    render() {
        const data = this.props.data.map((index) => {
            return { code: index.state, value: Math.round(index.value) };
        });

        const mapOptions = {
            title: {
                text: this.props.type,
            },
            subTitle: {
                text: this.props.period,
            },
            colorAxis: {
                min: -100,
                max: 100,
                minColor: "#ff0000",
                maxColor: "#00ff04",
            },

            series: [
                {
                    mapData: mapDataBrasil,
                    name: "Brasil",
                    data: data,
                    joinBy: ["postal-code", "code"],
                    dataLabels: {
                        enabled: true,
                        format: "{point.code}",
                    },
                    tooltip: {
                        pointFormat: "{point.name}: {point.value}%",
                    },
                    states: {
                        hover: {
                            color: "#BADA55",
                        },
                    },
                },
            ],
        };

        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <HighchartsReact
                                options={mapOptions}
                                constructorType={"mapChart"}
                                highcharts={Highcharts}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Mapa;
