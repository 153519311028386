import Loader from "./Loader";
import {Modal} from "react-bootstrap";
import React from "react";
import Select from 'react-select';


class ModalPolosGeradores extends React.Component {


    constructor() {
        super();

        this.state = {
            types: [],
            errors: [],
            loading: false,
        }
    }

    setLoading = async (loading) => {
        await this.setState({loading: loading});
    }





    componentDidMount = async()=> {
    }

    render() {

        return (

            <>
                {this.state.loading && <Loader/>}
                <Modal show={this.props.show} onHide={this.props.handleCloseModal} style={{zIndex: 9999999999}} size={'lg'}
                       dialogClassName="dialogWidth"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Polos Geradores na Área</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{zIndex: 999999}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="pull-right">
                                        <a href={route('polos-geradores.export', {key: this.props.cacheKey})} target={'_blank'} className="btn btn-success btn-sm">
                                            <i className="fa fa-file-excel"></i> Exportar
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">

                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Grupos</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Polos Geradores</a>
                                        </li>

                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div style={{maxHeight: '80vh', overflowX: "scroll", overflowY: "sroll"}}>

                                                <table className="table table-striped table-bordered" style={{maxHeight: '80vh'}}>
                                                    <thead>
                                                    <tr>
                                                        <th>&nbsp;</th>
                                                        <th>Nome</th>
                                                        <th>Categoria</th>
                                                        <th>Sub-Categoria</th>
                                                        <th>Endereço</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.props.polos.map((polo, key) => {
                                                            return (<tr key={key}>
                                                                <td>
                                                                    <img src={polo.image} width={'20px'} height={'20px'} />
                                                                </td>
                                                                <td>
                                                                    <div style={{display: "flex", alignContent: "space-between", flexWrap: "wrap"}}>
                                                                        <div style={{flex: "50%"}}>
                                                                            {polo.title}
                                                                        </div>
                                                                        <div>
                                                                            {polo.in_shopping === true && <span className="badge badge-info">Loja em Shopping</span>}
                                                                        </div>
                                                                    </div>

                                                                </td>
                                                                <td>{polo.main_category || polo.categories[0]}</td>
                                                                <td>{polo.sub_category}</td>
                                                                <td>{polo.formatted_address.replace( polo.title+',', '')}</td>
                                                            </tr>)
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                           <table className="table table-bordered table-striped">
                                                  <thead>
                                                    <tr>
                                                        <th>Grupo</th>
                                                        <th>Quantidade</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                        {this.props.polos_geradores_agrupados.map((polo, key) => {
                                                            console.log( polo );
                                                            return (<tr key={key}>
                                                                <td>{polo.grupo}</td>
                                                                <td>{polo.count}</td>
                                                            </tr>)
                                                        })}

                                                    </tbody>
                                           </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>

        );
    }

}

export default ModalPolosGeradores;
