const convertPolygonToGeojson = (polygon) => {

    if (polygon.getBounds !== undefined) {
        let bounds = polygon.getBounds();
        let northEast = bounds.getNorthEast();
        let southWest = bounds.getSouthWest();

        let northWest = [northEast.lat(), southWest.lng()];
        let southEast = [southWest.lat(), northEast.lng()];

        let path = [
            [northEast.lng(), northEast.lat()],
            [northWest[1], northWest[0]],
            [southWest.lng(), southWest.lat()],
            [southEast[1], southEast[0]],
            [northEast.lng(), northEast.lat()]
            ,];

        path.push(path[0]);

        return {
            "type": "Polygon",
            "coordinates": [path]
        };
    }

    let path = [];

    for (var i = 0; i < polygon.getPath().getLength(); i++) {
        let coordinate = polygon.getPath().getAt(i).toUrlValue(10).split(',');

        path.push(
            [
                Number(coordinate[1]),
                Number(coordinate[0])
            ]
        )
    }

    path.push(path[0]);

    return {
        "type": "Polygon",
        "coordinates": [path]
    };
}
//Pega caminho do poligno
const getPolygonPath = (polygon) => {

    if (polygon.getBounds !== undefined) {
        let bounds = polygon.getBounds();
        let northEast = bounds.getNorthEast();
        let southWest = bounds.getSouthWest();

        let northWest = [northEast.lat(), southWest.lng()];
        let southEast = [southWest.lat(), northEast.lng()];

        let path = [
            [northEast.lat(), northEast.lng()],
            [northWest[0], northWest[1]],
            [southWest.lat(), southWest.lng()],
            [southEast[0], southEast[1]],
            [northEast.lat(), northEast.lng()],
        ];

        return path;
    }

    let path = [];

    for (var i = 0; i < polygon.getPath().getLength(); i++) {
        let coordinate = polygon.getPath().getAt(i).toUrlValue(10).split(',');
        path.push([Number(coordinate[0]), Number(coordinate[1])]);
    }

    return path;
}


export {
    convertPolygonToGeojson,
    getPolygonPath
}
