import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Form from "./Form";
import Table from "./Table";
import Card from "./Card";

class Index extends React.Component {
    state = {
        mapeamentos: [],
        status: "Selecione",
        responsavel: [],
        cidades: [],
        estados: [],
        period: "",
        date_limit_initial: "",
        date_limit_final: "",
        created_at_initial: "",
        created_at_final: "",
        auth_user_id: "",
        reverse: false,
    };

    componentDidMount = async () => {
        this.getMapeamentos();
    };
    handleStatusChanged = async (e) => {
        await this.setState({
            ...this.state,
            status: e.value,
        });
    };
    handleResponsavelChanged = async (e) => {
        if (e == null) {
            return await this.setState({
                ...this.state,
                responsavel: [],
            });
        }

        let responsavel = e.map((index, key) => {
            return index.value;
        });

        await this.setState({
            ...this.state,
            responsavel: responsavel,
        });
    };
    handleCidadeChanged = async (e) => {
        if (e == null) {
            return await this.setState({
                ...this.state,
                cidades: [],
            });
        }

        let cidades = e.map((index, key) => {
            return index.label;
        });

        await this.setState({
            ...this.state,
            cidades: cidades,
        });
    };
    handleEstadoChanged = async (e) => {
        if (e == null) {
            return await this.setState({
                ...this.state,
                estados: [],
            });
        }
        let estados = e.map((index, key) => {
            return index.value;
        });

        await this.setState({
            ...this.state,
            estados: estados,
        });
    };
    handlePeriodChanged = async (e) => {
        await this.setState({
            ...this.state,
            period: e.target.value,
        });
    };
    handleDateLimitInitialChanged = async (e) => {
        await this.setState({
            ...this.state,
            date_limit_initial: e.target.value,
        });
    };
    handleDateLimitFinalChanged = async (e) => {
        await this.setState({
            ...this.state,
            date_limit_final: e.target.value,
        });
    };
    handleCreatedAtInitialChanged = async (e) => {
        await this.setState({
            ...this.state,
            created_at_initial: e.target.value,
        });
    };
    handleCreatedAtFinalChanged = async (e) => {
        await this.setState({
            ...this.state,
            created_at_final: e.target.value,
        });
    };
    getMapeamentos = async () => {
        this.setState({
            ...this.state,
            mapeamentos: [],
        });

        let mapeamentos = await axios.get(
            "mapeamento/get?status=" +
                this.state.status +
                "&responsavel=" +
                this.state.responsavel +
                "&estados=" +
                this.state.estados +
                "&cidades=" +
                this.state.cidades +
                "&period=" +
                this.state.period +
                "&date_limit_initial=" +
                this.state.date_limit_initial +
                "&date_limit_final=" +
                this.state.date_limit_final +
                "&created_at_initial=" +
                this.state.created_at_initial +
                "&created_at_final=" +
                this.state.created_at_final
        );

        this.setState({
            ...this.state,
            mapeamentos: mapeamentos.data.mapeamentos,
            auth_user_id: mapeamentos.data.auth_user_id,
        });
    };
    clearState = async () => {
        this.setState({
            ...this.state,
            mapeamentos: [],
            status: "Selecione",
            responsavel: [],
            cidades: [],
            period: "",
            date_limit_initial: "",
            date_limit_final: "",
            created_at_initial: "",
            created_at_final: "",
        });

        let mapeamentos = await axios.get("mapeamento/get");

        this.setState({
            ...this.state,
            mapeamentos: mapeamentos.data.mapeamentos,
        });
    };

    shortTable = async (typeOfSort) => {
        let mapeamentos;
        let reverse = this.state.reverse;

        if (reverse == false) {
            if (typeOfSort === "user") {
                mapeamentos = [...this.state.mapeamentos].sort((a, b) => {
                    if (a[typeOfSort] != null && b[typeOfSort] != null) {
                        return a[typeOfSort].name.toLowerCase() <
                            b[typeOfSort].name.toLowerCase()
                            ? -1
                            : 1;
                    }
                    if (a[typeOfSort] == null && b[typeOfSort] != null) {
                        return "zzzzzzzz" < b[typeOfSort].name.toLowerCase()
                            ? -1
                            : 1;
                    }
                    if (a[typeOfSort] != null && b[typeOfSort] == null) {
                        return a[typeOfSort].name.toLowerCase() < "zzzzzzzz"
                            ? -1
                            : 1;
                    }
                });
            } else if (typeOfSort === "worker_status") {
                mapeamentos = [...this.state.mapeamentos].sort((a, b) => {
                    if (a.value == 0) {
                        return;
                    }
                    if (b.value == 0) {
                        return;
                    }
                    return (a.worker_status / a.total_link) * 100 <
                        (b.worker_status / b.total_link) * 100
                        ? -1
                        : 1;
                });
            } else {
                mapeamentos = [...this.state.mapeamentos].sort((a, b) =>
                    a[typeOfSort] < b[typeOfSort] ? -1 : 1
                );
            }

            reverse = true;
        } else {
            if (typeOfSort === "user") {
                mapeamentos = [...this.state.mapeamentos].sort((a, b) => {
                    if (a[typeOfSort] != null && b[typeOfSort] != null) {
                        return a[typeOfSort].name < b[typeOfSort].name ? 1 : -1;
                    }
                    if (a[typeOfSort] == null && b[typeOfSort] != null) {
                        return "zzzzzzzz" < b[typeOfSort] ? 1 : -1;
                    }
                    if (a[typeOfSort] != null && b[typeOfSort] == null) {
                        return a[typeOfSort] < "zzzzzzzz" ? 1 : -1;
                    }
                });
            } else if (typeOfSort === "worker_status") {
                mapeamentos = [...this.state.mapeamentos].sort((a, b) => {
                    if (a.value == 0) {
                        return;
                    }
                    if (b.value == 0) {
                        return;
                    }
                    return (a.worker_status / a.total_link) * 100 <
                        (b.worker_status / b.total_link) * 100
                        ? 1
                        : -1;
                });
            } else {
                mapeamentos = [...this.state.mapeamentos].sort((a, b) =>
                    a[typeOfSort] < b[typeOfSort] ? 1 : -1
                );
            }

            reverse = false;
        }

        await this.setState({
            ...this.state,
            mapeamentos: mapeamentos,
            reverse: reverse,
        });
    };

    render = () => {
        return (
            <>
                <Card/>
                <Form
                    mapeamentos={this.state.mapeamentos}
                    getMapeamentos={this.getMapeamentos}
                    status={this.state.status}
                    responsavel={this.state.responsavel}
                    cidades={this.state.cidades}
                    period={this.state.period}
                    date_limit_initial={this.state.date_limit_initial}
                    date_limit_final={this.state.date_limit_final}
                    created_at_initial={this.state.created_at_initial}
                    created_at_final={this.state.created_at_final}
                    handleStatusChanged={this.handleStatusChanged}
                    handleResponsavelChanged={this.handleResponsavelChanged}
                    handleCidadeChanged={this.handleCidadeChanged}
                    handleEstadoChanged={this.handleEstadoChanged}
                    handlePeriodChanged={this.handlePeriodChanged}
                    handleDateLimitInitialChanged={
                        this.handleDateLimitInitialChanged
                    }
                    handleDateLimitFinalChanged={
                        this.handleDateLimitFinalChanged
                    }
                    handleCreatedAtInitialChanged={
                        this.handleCreatedAtInitialChanged
                    }
                    handleCreatedAtFinalChanged={
                        this.handleCreatedAtFinalChanged
                    }
                    clearState={this.clearState}
                />
               <Table
                    shortTable={this.shortTable}
                    auth_user={this.state.auth_user_id}
                    mapeamentos={this.state.mapeamentos}
                    getMapeamentos={this.getMapeamentos}
                />
            </>
        );
    };
}

export default Index;

if (document.getElementById("mapeamento")) {
    ReactDOM.render(<Index />, document.getElementById("mapeamento"));
}
