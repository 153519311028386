import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Form from "./Form";
import Grafico from "./Grafico";
import Mapa from "./Mapa";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Loader from "../Loader";

class Index extends React.Component {
    state = {
        period: "",
        firstPeriod: "",
        lastPeriod: "",
        type: [],
        category: [],
        patterns: [],
        region: [],
        state: [],
        city: [],
        neighborhoods: [],
        group_by: "",
        variation: "",
        show: "",
        search: "",

        sales: [],
        salesVariation: [],
        salesState: [],
        units: [],
        unitsState: [],
        unitsVariation: [],
        offer: [],
        offerVariation: [],
        offerState: [],
        prices: [],
        pricesVariation: [],
        pricesState: [],
        periodFormated: "",
        showComparations: false,
        showGraf: false,
        showMap: false,
        loading: false,
    };

    handleChanged = async (event) => {
        await this.setState({
            ...this.state,
            [event.name]:
                event.name == "period" ? event.value : event.value.value,
        });
        console.log(this.state);
    };

    handleChangedArray = async (event) => {
        if (event.value === null) {
            return await this.setState({
                ...this.state,
                [event.name]: [],
            });
        }

        let value = event.value.map((index) => {
            return index.value;
        });

        await this.setState({
            ...this.state,
            [event.name]: value,
        });
    };

    className = () => {
        if (this.state.showComparations == true) {
            return "col-12";
        }
        return "col-12";
    };
    classNameDivPdf = () => {
        if (this.state.showComparations == true) {
            return "row col-12";
        }
        return "row col-12";
    };

    clearInfos = async () => {
        return await this.setState({
            showGraf: false,
            showMap: false,
            loading: true,
            showComparations: false,
            sales: [],
            salesVariation: [],
            salesState: [],
            units: [],
            unitsState: [],
            unitsVariation: [],
            offer: [],
            offerVariation: [],
            offerState: [],
            prices: [],
            pricesVariation: [],
            pricesState: [],
            periodFormated: [],
        });
    };

    post = async () => {
        if (this.state.period == null || this.state.period == "") {
            return alert("Selecione o perído!");
        }
        if (this.state.category == null || this.state.category.length == 0) {
            return alert("Selecione uma categoria!");
        }

        await this.clearInfos();

        let showMap = true;
        let showGraf = true;

        if (this.state.show != "" && this.state.show != true) {
            if (this.state.show != "ambos") {
                showMap = this.state.show == "mapa" ? true : false;
                showGraf = this.state.show == "grafico" ? true : false;
            }
        }

        await axios
            .post("/mapeamento-comparativo", this.state)
            .then((response) => {
                this.setState({
                    ...this.state,
                    sales: response.data.sales,
                    salesVariation: response.data.salesVariation,
                    salesState: response.data.salesState,
                    units: response.data.units,
                    unitsState: response.data.unitsState,
                    unitsVariation: response.data.unitsVariation,
                    offer: response.data.offer,
                    offerVariation: response.data.offerVariation,
                    offerState: response.data.offerState,
                    prices: response.data.prices,
                    pricesVariation: response.data.pricesVariation,
                    pricesState: response.data.pricesState,
                    periodFormated: response.data.period,
                    showComparations: response.data.showComparations ?? false,
                    showGraf: showGraf,
                    showMap: showMap,
                    firstPeriod: response.data.firstPeriod,
                    lastPeriod: response.data.lastPeriod,
                    search: response.data.data.search,
                    loading: false,
                    show: true,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    clearState = async () => {
        this.setState({
            ...this.state,
            period: "",
            firstPeriod: "",
            lastPeriod: "",
            type: [],
            category: [],
            patterns: [],
            region: [],
            state: [],
            city: [],
            neighborhoods: [],
            group_by: "",
            variation: "",
            periodFormated: "",
            sales: [],
            salesVariation: [],
            salesState: [],
            units: [],
            unitsState: [],
            unitsVariation: [],
            offer: [],
            offerVariation: [],
            offerState: [],
            prices: [],
            pricesVariation: [],
            pricesState: [],
            showGraf: false,
            showMap: false,
            show: false,
        });
    };

    printDocument = async () => {
        const pdf = new jsPDF({
            orientation: "landscape",
        });
        const salesPdf = await html2canvas(
            document.getElementById("salesDiv"),
            {
                quality: 4,
                scale: 5,
            }
        ).then((canvas) => {
            return canvas.toDataURL("sales/png");
        });
        const offerPdf = await html2canvas(
            document.getElementById("offerDiv"),
            {
                quality: 4,
                scale: 5,
            }
        ).then((canvas) => {
            return canvas.toDataURL("offerPdf/png");
        });
        const unitsPdf = await html2canvas(
            document.getElementById("unitsDiv"),
            {
                quality: 4,
                scale: 5,
            }
        ).then((canvas) => {
            return canvas.toDataURL("unitsPdf/png");
        });
        const pricesPdf = await html2canvas(
            document.getElementById("pricesDiv"),
            {
                quality: 4,
                scale: 5,
            }
        ).then((canvas) => {
            return canvas.toDataURL("preicesPdf/png");
        });
        const salesStatePdf = await html2canvas(
            document.getElementById("salesStateDiv"),
            {
                quality: 4,
            }
        ).then((canvas) => {
            return canvas.toDataURL("salesStateDiv/png");
        });
        const offerStatePdf = await html2canvas(
            document.getElementById("offerStateDiv"),
            {
                quality: 4,
            }
        ).then((canvas) => {
            return canvas.toDataURL("offerStateDiv/png");
        });
        const unitsStatePdf = await html2canvas(
            document.getElementById("unitsStateDiv"),
            {
                quality: 4,
            }
        ).then((canvas) => {
            return canvas.toDataURL("unitsStateDiv/png");
        });
        const pricesStatePdf = await html2canvas(
            document.getElementById("pricesStateDiv"),
            {
                quality: 4,
            }
        ).then((canvas) => {
            return canvas.toDataURL("pricesStateDiv/png");
        });
        const pageHeight =
            pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        const pageWidth =
            pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        const width = pageWidth / 2;
        pdf.setFontSize(40);
        pdf.text("Unidades Vendidas", width, pageHeight - 180, {
            align: "center",
        });
        pdf.addImage(salesPdf, "PNG", 15, 50, 250, 100, "", "SLOW");
        pdf.addPage();
        pdf.text("Ofertas", width, pageHeight - 180, {
            align: "center",
        });
        pdf.addImage(offerPdf, "PNG", 15, 50, 250, 100, "", "SLOW");
        pdf.addPage();
        pdf.text("Unidades Lançadas", width, pageHeight - 180, {
            align: "center",
        });
        pdf.addImage(unitsPdf, "PNG", 15, 50, 250, 100, "", "SLOW");
        pdf.addPage();
        pdf.text("Média dos Preços", width, pageHeight - 180, {
            align: "center",
        });
        pdf.addImage(pricesPdf, "PNG", 15, 50, 250, 100, "", "SLOW");
        pdf.addPage();
        pdf.text("Vendas Por Região", width, pageHeight - 180, {
            align: "center",
        });
        pdf.addImage(salesStatePdf, "PNG", 75, 50);
        pdf.addPage();
        pdf.text("Ofertas Por Região", width, pageHeight - 180, {
            align: "center",
        });
        pdf.addImage(offerStatePdf, "PNG", 75, 50);
        pdf.addPage();
        pdf.text("Lançamentos Por Região", width, pageHeight - 180, {
            align: "center",
        });
        pdf.addImage(unitsStatePdf, "PNG", 75, 50);
        pdf.addPage();
        pdf.text("Preços por Região", width, pageHeight - 180, {
            align: "center",
        });
        pdf.addImage(pricesStatePdf, "PNG", 75, 50);
        pdf.save("mapa_comparativo.pdf");
    };

    render = () => {
        return (
            <>
                <Form
                    handlePeriodChanged={this.handlePeriodChanged}
                    handleChanged={this.handleChanged}
                    handleChangedArray={this.handleChangedArray}
                    post={this.post}
                    clearState={this.clearState}
                    region={this.state.region}
                    state={this.state.state}
                    city={this.state.city}
                />
                {this.state.loading && <Loader />}
                {this.state.show && (
                    <div>
                        <button
                            onClick={this.printDocument}
                            className="btn btn-link btn-success"
                        >
                            Baixar Arquivo
                        </button>
                        {this.state.showGraf && (
                            <div className="row">
                                {this.state.sales != undefined && (
                                    <div
                                        className={this.classNameDivPdf()}
                                        id="salesDiv"
                                    >
                                        <div className={this.className()}>
                                            <Grafico
                                                data={this.state.sales}
                                                type={"Unidades Vendidas"}
                                                period={this.state.firstPeriod}
                                                search={this.state.search}
                                            />
                                        </div>
                                        {this.state.salesVariation !=
                                            undefined &&
                                            this.state.salesVariation.length >
                                                0 && (
                                                <div className="col-12">
                                                    <Grafico
                                                        data={
                                                            this.state
                                                                .salesVariation
                                                        }
                                                        dataForCompare={
                                                            this.state.sales
                                                        }
                                                        type={
                                                            "Variação Unidades Vendidas"
                                                        }
                                                        period={
                                                            this.state
                                                                .lastPeriod
                                                        }
                                                        search={
                                                            this.state.search
                                                        }
                                                    />
                                                </div>
                                            )}
                                    </div>
                                )}
                                {this.state.offer != undefined && (
                                    <div
                                        className={this.classNameDivPdf()}
                                        id="offerDiv"
                                    >
                                        <div className={this.className()}>
                                            <Grafico
                                                data={this.state.offer}
                                                type={
                                                    "Unidades em Oferta Final"
                                                }
                                                period={this.state.firstPeriod}
                                                search={this.state.search}
                                            />
                                        </div>
                                        {this.state.offerVariation !=
                                            undefined &&
                                            this.state.offerVariation.length >
                                                0 && (
                                                <div className="col-12">
                                                    <Grafico
                                                        data={
                                                            this.state
                                                                .offerVariation
                                                        }
                                                        dataForCompare={
                                                            this.state.offer
                                                        }
                                                        type={
                                                            "Variação Unidades em Oferta Final"
                                                        }
                                                        period={
                                                            this.state
                                                                .lastPeriod
                                                        }
                                                        search={
                                                            this.state.search
                                                        }
                                                    />
                                                </div>
                                            )}
                                    </div>
                                )}
                                {this.state.units != undefined && (
                                    <div
                                        className={this.classNameDivPdf()}
                                        id="unitsDiv"
                                    >
                                        <div className={this.className()}>
                                            <Grafico
                                                data={this.state.units}
                                                type={"Unidades Lançadas"}
                                                period={this.state.firstPeriod}
                                                search={this.state.search}
                                            />
                                        </div>
                                        {this.state.unitsVariation !=
                                            undefined &&
                                            this.state.unitsVariation.length >
                                                0 && (
                                                <div className="col-12">
                                                    <Grafico
                                                        data={
                                                            this.state
                                                                .unitsVariation
                                                        }
                                                        dataForCompare={
                                                            this.state.units
                                                        }
                                                        type={
                                                            "Variação Unidades Lançadas"
                                                        }
                                                        period={
                                                            this.state
                                                                .lastPeriod
                                                        }
                                                        search={
                                                            this.state.search
                                                        }
                                                    />
                                                </div>
                                            )}
                                    </div>
                                )}
                                {this.state.prices != undefined && (
                                    <div
                                        className={this.classNameDivPdf()}
                                        id="pricesDiv"
                                    >
                                        <div className={this.className()}>
                                            <Grafico
                                                data={this.state.prices}
                                                type={"Média dos Preços"}
                                                period={this.state.firstPeriod}
                                                search={this.state.search}
                                            />
                                        </div>
                                        {this.state.pricesVariation !=
                                            undefined &&
                                            this.state.pricesVariation.length >
                                                0 && (
                                                <div className="col-12">
                                                    <Grafico
                                                        data={
                                                            this.state
                                                                .pricesVariation
                                                        }
                                                        dataForCompare={
                                                            this.state.prices
                                                        }
                                                        type={
                                                            "Variação da Média dos Preços"
                                                        }
                                                        period={
                                                            this.state
                                                                .lastPeriod
                                                        }
                                                        search={
                                                            this.state.search
                                                        }
                                                    />
                                                </div>
                                            )}
                                    </div>
                                )}
                            </div>
                        )}
                        {this.state.showMap && (
                            <div className="row">
                                {this.state.salesState != undefined && (
                                    <div className="col-12" id="salesStateDiv">
                                        <Mapa
                                            data={this.state.salesState}
                                            type={"Unidades Vendidas"}
                                            period={this.state.period}
                                        />
                                    </div>
                                )}

                                {this.state.offerState != undefined && (
                                    <div className="col-12" id="offerStateDiv">
                                        <Mapa
                                            data={this.state.offerState}
                                            type={"Unidades em Oferta Final"}
                                            period={this.state.period}
                                        />
                                    </div>
                                )}

                                {this.state.unitsState != undefined && (
                                    <div className="col-12" id="unitsStateDiv">
                                        <Mapa
                                            data={this.state.unitsState}
                                            type={"Unidades Lançadas"}
                                            period={this.state.period}
                                        />
                                    </div>
                                )}

                                {this.state.pricesState != undefined && (
                                    <div className="col-12" id="pricesStateDiv">
                                        <Mapa
                                            data={this.state.pricesState}
                                            type={"Média dos Preços"}
                                            period={this.state.period}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    };
}

export default Index;

if (document.getElementById("mapas_comparativos")) {
    ReactDOM.render(<Index />, document.getElementById("mapas_comparativos"));
}
