import React from 'react';
import ReactDOM from 'react-dom';
import AnunciosMap from './AnunciosMap';




class AppAnuncios extends React.Component {

    render = () => {
        return (
            <>
                <AnunciosMap />
            </>
        );
    }
}

export default AppAnuncios;

if (document.getElementById('mapa_anuncios')) {
    ReactDOM.render(<AppAnuncios />, document.getElementById('mapa_anuncios'));
}
