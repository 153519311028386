import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Loader from "./Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pt_BR from 'date-fns/locale/pt-BR';
import AsyncSelect from 'react-select/async';
import Axios from "axios";
registerLocale('pt-BR', pt_BR);


export default class TicketsList extends Component {

    constructor(props) {
        super();

        this.state = {
            tickets: [],
            loading: false,
            pagination: {
                total: 0,
                per_page: 20,
                current_page: 1,
                last_page: 1,
                from: 1,
                to: 0
            },
            filters: {
                status: '',
                assunto: '',
                name: '',
                cidade: '',
                cliente : '',
                empreendimento: '',
                data_inicial: '',
                data_final: '',
                download: '',
            },
            assuntos: [
                'Sugerir Inclusão de Empreendimento não Cadastrado',
                'Informações de Empreendimento faltantes e ou incorretas',
                'Outros - Descreva no campo Mensagem'
            ],
            status: ['Aberto', 'Em Atendimento', 'Finalizado'],
            user: null,
        }
    }


    searchCidade = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get('/cidades/json-options-with-state-on-values/' + inputQuery);
            return  response.data;

        }
    }

    searchCliente = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get('/search-clients/' + inputQuery);
            return  response.data;

        }
    }


    loadData = async () => {
        try {
            await this.setLoading(true);

            //filter
            let urlParams = {
                page: this.state.pagination.current_page,
            }

            if(this.state.filters.status.length > 0){
                urlParams.status = this.state.filters.status;
            }

            if(this.state.filters.assunto.length > 0){
                urlParams.assunto = this.state.filters.assunto;
            }

            if(this.state.filters.name.length > 0){
                urlParams.nome = this.state.filters.name;
            }

            if(this.state.filters.cidade !== '' && this.state.filters.cidade !== null){
                let parts = this.state.filters.cidade.value.split('-');
                urlParams.cidade = parts[0].trim();
                urlParams.estado = parts[1].trim();
            }

            if( this.state.filters.data_inicial !=='' ){

                urlParams.data_inicial = new Date(this.state.filters.data_inicial).toLocaleDateString('pt-BR');
            }

            if (this.state.filters.data_final !== '') {
                urlParams.data_final = new Date(this.state.filters.data_final).toLocaleDateString('pt-BR');
            }

            if (this.state.filters.cliente !== '' && this.state.filters.cliente !== null) {
                urlParams.cliente = this.state.filters.cliente.value;
            }

            if (this.state.filters.empreendimento !== '' && this.state.filters.empreendimento !== null) {
                urlParams.empreendimento = this.state.filters.empreendimento.value;
            }

            let requestExtraParams = {};

            if( this.state.filters.download !== ''){
                urlParams.download = true;
                requestExtraParams.responseType = 'blob';

            }


            let response = await axios.get(route('tickets.index.data', urlParams ), requestExtraParams);

            if (response.status == 200) {


                if( response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
                    const type = response.headers['content-type']
                    const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'tickets.xlsx'
                    link.click()

                    this.setState({
                        ...this.state,
                         filters: {
                            ...this.state.filters,
                            download: ''
                        }
                    });

                    return;
                }


                this.setState({
                    ...this.state,
                    tickets: response.data.data,
                    pagination: {
                        total: response.data.total,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        last_page: response.data.last_page,
                        from: response.data.from,
                        to: response.data.to
                    }, filters: {
                        ...this.state.filters,
                        download: ''
                    }
                });
            }
        } catch (e) {
            if( e.response && e.response.status === 422){
                this.setState({errors: e.response.data.errors});

            }
            console.log(e);
        }
        finally {
            await this.setLoading(false);
        }
    }

    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }

    loadAuthUser = async () => {
        try {
            let response = await axios.get(route('user.auth'));
            if (response.status == 200) {
                this.setState({
                    ...this.state,
                    user: response.data
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount = async () => {

        try {
            await this.loadData();
            await this.loadAuthUser();
        } catch (e) {
            console.log(e);
        }
    }

    alterPage = async (page) => {
        await this.setState({
            ...this.state,
            pagination: {
                ...this.state.pagination,
                current_page: page
            }
        });

        await this.loadData();
    }

    searchEmpreendimentos = async (inputQuery) => {
        if ( inputQuery.length > 1 ) {
            try {

                let params = {
                    term : inputQuery
                };

                if( this.state.filters.cidade != ''){

                    let parts = this.state.filters.cidade.value.split('-');

                    params.cidade = parts[0];
                    params.estado = parts[1];
                }

                let endpoint = route('search-buildings', params );

                let empreendimentos = await axios.get( endpoint );

                return empreendimentos.data;
            } catch (error) {
                console.log(error);
            }
        }
    }

    handleNomeChanged = async (e) => {
        await this.setState({
            ...this.state,
            filters: {
                ...this.state.filters,
                name: e.target.value
            }
        });

    }

    render() {

        let canManageTickets = this.state.user?.can_gerenciar_tickets;

        return (<>
            {this.state.loading && <Loader/>}
                <div className="card">
                    <div className="card-header">
                        {canManageTickets &&
                            <form action="">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="status">Data Abertura Inicial</label>
                                            <DatePicker
                                                locale="pt-BR"
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.filters.data_inicial}
                                                onChange={async (e) => {
                                                    await this.setState({
                                                        ...this.state,
                                                        filters: {
                                                            ...this.state.filters,
                                                            data_inicial: e
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="status">Data Abertura Final</label>
                                            <DatePicker
                                                locale="pt-BR"
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.filters.data_final}
                                                onChange={async (e) => {
                                                    await this.setState({
                                                        ...this.state,
                                                        filters: {
                                                            ...this.state.filters,
                                                            data_final: e
                                                        }
                                                    });
                                                }} //only when value has changed
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">Status</label>
                                            <select name="status" id="status" className="form-control"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            ...this.state,
                                                            filters: {
                                                                ...this.state.filters,
                                                                status: e.target.value
                                                            }
                                                        });
                                                    }}>
                                                <option value="">Selecione</option>
                                                {this.state.status.map((status, index) => {
                                                    return <option key={index} value={status}>{status}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">Assunto</label>
                                            <select onChange={(e) => {
                                                this.setState({
                                                    ...this.state,
                                                    filters: {
                                                        ...this.state.filters,
                                                        assunto: e.target.value
                                                    }
                                                })
                                            }} name="assunto" className={'form-control'}
                                                    value={this.state.filters.assunto} id="">
                                                <option value="">Selecione</option>
                                                {this.state.assuntos.map((assunto, key) => {
                                                    return <option key={key} value={assunto}>{assunto}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">Empreendimento</label>
                                            <AsyncSelect
                                                cacheOptions
                                                isClearable
                                                placeholder="Digite para buscar..."
                                                value={this.state.filters.empreendimento}
                                                noOptionsMessage={() => 'Nenhum Resultado'}
                                                defaultOptions
                                                loadOptions={this.searchEmpreendimentos}
                                                onChange={(e) => {
                                                    this.setState({
                                                        ...this.state,
                                                        filters: {
                                                            ...this.state.filters,
                                                            empreendimento: e
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">Cidade</label>
                                            <AsyncSelect
                                                cacheOptions
                                                isClearable
                                                noOptionsMessage={() => 'Nenhum Resultado'}
                                                placeholder="Digite para buscar..."
                                                value={this.state.filters.cidade}
                                                loadOptions={this.searchCidade}
                                                onChange={async (e) => {
                                                    await this.setState({
                                                        ...this.state,
                                                        filters: {
                                                            ...this.state.filters,
                                                            cidade: e
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">Cliente</label>
                                            <AsyncSelect
                                                cacheOptions
                                                isClearable
                                                placeholder="Digite para buscar..."
                                                noOptionsMessage={() => 'Nenhum Resultado'}
                                                value={this.state.filters.cliente}
                                                loadOptions={this.searchCliente}
                                                onChange={async (e) => {
                                                    await this.setState({
                                                        ...this.state,
                                                        filters: {
                                                            ...this.state.filters,
                                                            cliente: e
                                                        }
                                                    });
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="nome">Nome de Empreendimento Sugerido</label>
                                            <input type="text" onChange={this.handleNomeChanged} className="form-control" id="nome" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <button className={"btn btn-primary button-brain btn-round btn-block"} onClick={async () => {
                                            await this.setState({
                                                ...this.state,
                                                pagination: {
                                                    ...this.pagination,
                                                    current_page: 1
                                                }
                                            });
                                            this.loadData();
                                        }} type="button">Filtrar
                                        </button>
                                    </div>
                                    <div className="col-6">

                                        <button className={"btn btn-secondary btn-block btn-round"} onClick={async () => {
                                            await this.setState({
                                                ...this.state,
                                                pagination: {
                                                    ...this.pagination,
                                                    current_page: 1
                                                },
                                                filters: {
                                                    ...this.state.filters,
                                                    download: true
                                                }
                                            });
                                            this.loadData();
                                        }} type="button">Download Excel
                                        </button>
                                    </div>
                                </div>
                            </form>

                        }
                    </div>
                    <div className="card-body">
                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Status</th>
                                <th>Assunto</th>
                                <th>Cliente</th>
                                <th>Data de Criação</th>
                                <th>Data de Finalização</th>
                                <th>Ações</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.tickets.map((ticket, index) => {
                                const dataCriacao = new Date(ticket.created_at).toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'});
                                let dataFinalizacao = '';
                                if( ticket.finished_at ){
                                    dataFinalizacao = new Date(ticket.finished_at).toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'});
                                }
                                return(
                                    <tr key={index}>
                                        <td>{ticket.id}</td>
                                        <td>
                                            {ticket.status == "Aberto" && <span className="badge badge-success">{ticket.status}</span>}
                                            {ticket.status == "Finalizado" && <span className="badge badge-danger">{ticket.status}</span>}
                                            {ticket.status == "Em Atendimento" && <span className="badge badge-warning">{ticket.status}</span>}

                                        </td>
                                        <td>{ticket.subject}</td>
                                        <td>{ticket.name}</td>
                                        <td>{dataCriacao}</td>
                                        <td>{dataFinalizacao}</td>
                                        <td>
                                            <a href={route('ticket.show', ticket.id)} className={'btn btn-link'}>
                                                <i className="fa fa-eye"></i>&nbsp;
                                                Ver
                                            </a>
                                        </td>
                                    </tr>)
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className="card-footer">
                        <div className="row">

                            <div className="col-12">
                                <nav aria-label="navigation" style={{ float: "right"}}>
                                    <ul className="pagination">
                                        {this.state.pagination.current_page > 1 &&
                                            <li className="page-item">
                                                <a className="page-link" href="#">Anterior</a>
                                            </li>
                                        }

                                        {this.state.pagination.last_page > 1 && [...Array(this.state.pagination.last_page)].map((page, index) => {
                                            let pageNumber = index + 1;
                                            return (

                                                <li className={( pageNumber === this.state.pagination.current_page) ? 'page-item active' : 'page-item ' } key={index}>
                                                    <a
                                                        className={`page-link`}
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.alterPage(pageNumber);
                                                        }}>
                                                        {pageNumber}
                                                    </a>
                                                </li>
                                            )
                                        })}

                                        {this.state.pagination.current_page < this.state.pagination.last_page &&
                                            <li className="page-item">
                                                <a className="page-link" href="#" onClick={(e)=>{
                                                    e.preventDefault();
                                                    this.alterPage(this.state.pagination.current_page + 1);
                                                }}>
                                                    Próximo
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
}


if (document.getElementById('js-list-tickets')) {
    ReactDOM.render(<TicketsList/>, document.getElementById('js-list-tickets'));
}
