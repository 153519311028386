import Axios from "axios";
import React, { Component } from "react";
import Markers from "./Markers";

class Legenda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: [],
        };
    }

    componentDidMount = async () => {
        await this.loadMarkers();
    };

    loadMarkers = async () => {
        let markers = localStorage.getItem("markers");

        if (markers) {
            markers = JSON.parse(markers);
            this.setState({
                ...this.state,
                markers: markers,
            });

            return;
        }

        let response = await axios
            .get(route("building-markers"))
            .catch((error) => {
                console.log(error);
            });


        if (response.status === 200) {
            this.setState({
                ...this.state,
                markers: response.data,
            });
        }
    };
    render() {
        const labels = {
            area_media: "Área média",
            ticket_medio_metro: "Ticket Médio R$/m²",
            total_unidades: "Oferta Inicial",
            oferta_final: "Estoque",
        };

        return (
            <>
                <table className="table table-striped table-condensed legenda-mapa">
                    <thead>
                        <tr>
                            <th
                                colSpan="2"
                                className=""
                                style={{ paddingBottom: 7 }}
                            >
                                <span
                                    className="font-weight-bold text-center"
                                    style={{
                                        color: "rgb(0, 72, 14)",
                                        fontSize: 16,
                                    }}
                                >
                                    LEGENDA
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.ranges.map((range) => {
                            let hexaBackground = Markers.find((marker) => {
                                return marker.marker === range.marker;
                            }).hexa;

                            return (
                                <tr key={range.id}>
                                    <td>
                                        <div
                                            style={{
                                                backgroundColor: hexaBackground,
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "50%",
                                                borderColor: "#cccccc",
                                                borderWidth: "1px",
                                                borderStyle: "solid",
                                            }}
                                        ></div>
                                    </td>
                                    <td>
                                        {labels[range.type]} A pártir de{" "}
                                        {Number(range.min).toLocaleString()}
                                        {(() => {
                                            if (range.max === 99999999) {
                                                return null;
                                            }
                                            return (
                                                " Até " +
                                                Number(
                                                    range.max
                                                ).toLocaleString()
                                            );
                                        })()}
                                    </td>
                                </tr>
                            );
                        })}

                        {this.state.markers.map((marker) => {
                            let randomNumber =
                                Math.floor(Math.random() * (999999 - 100000)) +
                                100000;
                            return (
                                <tr
                                    key={`${marker.status}.${marker.tipo}.${marker.status}.${randomNumber}`}
                                >
                                    <td>
                                        <img src={marker.icon} alt="" />
                                    </td>
                                    <td>
                                        {`${marker.tipo} ${marker.padrao} ${marker.status}`}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    }
}

export default Legenda;
