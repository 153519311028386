import React from "react";
import { Marker } from "react-google-maps";
import SimpleMap from "../SimpleMap/SimpleMap";

/*const Map = withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={17}
        defaultCenter={ props.center}
        onMapMounted={ ref => {
            window.googleMap = ref;
        }}
    >
        { props.latitude != "" && props.longitude != "" && <Marker
            draggable={true}
            onDragEnd={props.setLocation}
            position={{ lat: props.latitude, lng: props.longitude }} />}
    </GoogleMap>
)*/
export default class EnderecoEnprendimentoPartialForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            latitude: 0,
            longitude: 0,
        }


    }
    componentDidMount() {
    }




    render() {
        const estados = [
            'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO',
        ];



        return (<React.Fragment>
            <div className="card">
                <div className="card-header">
                    Informe o endereço do empreendimento
                </div>
                <div className="card-body">
                    <div className='form-group'>
                        <div className="row">
                            <div className="col-6 col-sm-12 col-xs-12">
                                <label htmlFor={'cep'}> CEP </label>
                                <input type="text"
                                       className={`form-control ` + (this.props.state.errors.cep ? 'is-invalid' : '')}
                                       name="cep"
                                       id="cep"
                                       onChange={this.props.onChangeCep}
                                       placeholder="CEP"
                                       value={this.props.state.cep}/>

                                {this.props.state.errors['cep'] && <div className="invalid-feedback" style={{display: "block"}}>
                                    {this.props.state.errors['cep']}
                                </div>}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-8 col-xs-12">
                                <label htmlFor="endereco">Endereço</label>
                                <input type="text"
                                       className={`form-control ` + (this.props.state.errors.endereco ? 'is-invalid' : '')}
                                       name="endereco"
                                       id="endereco"
                                       onChange={this.props.onChangeEndereco}
                                       placeholder="Endereço"
                                       value={this.props.state.endereco}/>
                                {this.props.state.errors['endereco'] && <div className="invalid-feedback" style={{display: "block"}}>
                                    {this.props.state.errors['endereco']}
                                </div>}
                            </div>

                            <div className="col-4 col-xs-12">
                                <label htmlFor="numero">Número</label>
                                <input type="text"
                                       className={`form-control ` + (this.props.state.errors.numero ? 'is-invalid' : '')}
                                       name="numero"
                                       id="numero"
                                       onChange={this.props.onChangeNumero}
                                       placeholder="Número"
                                       value={this.props.state.numero}
                                />

                                {this.props.state.errors['numero'] && <div className="invalid-feedback" style={{display: "block"}}>
                                    {this.props.state.errors['numero']}
                                </div>}
                            </div>


                        </div>






                        <div className="row">
                            <div className="col-6 col-xs-12">
                                <label htmlFor="bairro">Bairro</label>
                                <input type="text"
                                       className={`form-control ` + (this.props.state.errors.bairro ? 'is-invalid' : '')}
                                       name="bairro"
                                       id="bairro"
                                       onChange={this.props.onChangeBairro}
                                       placeholder="Bairro"
                                       value={this.props.state.bairro}/>

                                {this.props.state.errors['bairro'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.props.state.errors['bairro']}
                                    </div>}
                            </div>

                            <div className="col-6 col-xs-12">
                                <label htmlFor="complemento">Complemento</label>
                                <input type="text"
                                       className={`form-control ` + (this.props.state.errors.complemento ? 'is-invalid' : '')}
                                       name="complemento"
                                       id="complemento"
                                       onChange={this.props.onChangeComplemento}
                                       placeholder="Complemento"
                                       value={this.props.state.complemento}/>
                                {this.props.state.errors['complemento'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.props.state.errors['complemento']}
                                    </div>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-12">
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text"
                                       className={`form-control ` + (this.props.state.errors.cidade_empreendimento ? 'is-invalid' : '')}
                                       name="cidade_empreendimento"
                                       id="cidade_empreendimento"
                                       onChange={this.props.onChangeCidadeEmpreendimento}
                                       placeholder="Cidade"
                                       value={this.props.state.cidade_empreendimento}/>
                                {this.props.state.errors['cidade_empreendimento'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.props.state.errors['cidade_empreendimento']}
                                    </div>}
                            </div>

                            <div className="col-6 col-xs-12">
                                <label htmlFor="estado">Estado</label>
                                <select className={`form-control ` + (this.props.state.errors.estado_empreendimento ? 'is-invalid' : '')}
                                        name="estado_empreendimento"
                                        id="estado_empreendimento"
                                        onChange={this.props.onChangeEstadoEmpreendimento}
                                        value={this.props.state.estado_empreendimento}>
                                    <option value="">Selecione</option>
                                    {estados.map((estado, index) => {
                                        return <option key={index} value={estado}>{estado}</option>
                                    })}
                                </select>
                                {this.props.state.errors['estado_empreendimento'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.props.state.errors['estado_empreendimento']}
                                    </div>}
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-12">
                                <label htmlFor="latitude">Latitude</label>
                                <input type="text"
                                       className={`form-control ` + (this.props.state.errors.latitude ? 'is-invalid' : '')}
                                       name="latitude"
                                       id="latitude"
                                       onChange={this.props.onChangeLatitude}
                                       placeholder="Latitude"
                                       value={this.props.state.latitude}/>
                                {this.props.state.errors['latitude'] && <div className="invalid-feedback" style={{display: "block"}}>
                                    {this.props.state.errors['latitude']}
                                </div>}
                            </div>

                            <div className="col-6 col-xs-12">
                                <label htmlFor="longitude">Longitude</label>
                                <input type="text"
                                       className={`form-control ` + (this.props.state.errors.longitude ? 'is-invalid' : '')}
                                       name="longitude"
                                       id="longitude"
                                       onChange={this.props.onChangeLongitude}
                                       placeholder="Longitude"
                                       value={this.props.state.longitude}/>

                                {this.props.state.errors['longitude'] &&
                                    <div className="invalid-feedback" style={{display: "block"}}>
                                        {this.props.state.errors['longitude']}
                                    </div>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <button type="button" className="btn btn-primary" onClick={this.props.getCoordinatesFromAddress}>Buscar Coordenadas Geográficas</button>
                            </div>
                        </div>

                        { this.props.state.latitude !== '' && this.props.state.longitude !== '' && <>
                            <div className="row">
                                <div className="col-12">
                                    <p className="alert alert-info">
                                        Caso precise ajustar a latitude e Longitude do empreendimento, arraste o marcador para o local correto.

                                    </p>
                                    <div id="map" style={{width: '100%', height: '400px'}}>
                                        <SimpleMap
                                            currentPosition={{ lat: this.props.state.latitude, lng: this.props.state.longitude }}
                                            height="400px"
                                        >
                                            { this.props.state.latitude !== "" && this.props.state.longitude !== "" && <Marker
                                                draggable={true}
                                                onDragEnd={this.props.setLocation}
                                                position={{ lat: this.props.state.latitude, lng: this.props.state.longitude }} />}

                                        </SimpleMap>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </React.Fragment>)
    }
}
