import React, { useState } from "react";
import ReactDOM from "react-dom";
import TableBasico from "./TableBasico";
import TableInsumos from "./TableInsumos";
import TableDescPadroes from "./TableDescPadroes";

export default function ReferenciasCub(){

  const [ tab, setTab ] = useState('')

  return(
    <div className="cub-container">
      <div className="tab-view">

        <button type="button" className={`tab-button ${tab === 'basico'&& "tab-button-active"}`} onClick={() => setTab('basico')}>
          Lote básico de materiais
        </button>
        <button type="button" className={`tab-button ${tab === 'insumos'&& "tab-button-active"}`} onClick={() => setTab('insumos')}>
          Família de insumos
        </button>
        <button type="button" className={`tab-button ${tab === 'padroes'&& "tab-button-active"}`} onClick={() => setTab('padroes')}>
          Descrição de padrões
        </button>
      </div>

      <div className="table-view">
        { tab === 'basico'&&
          <TableBasico />
        }
        { tab === 'insumos'&&
          <TableInsumos />
        }
        { tab === 'padroes'&&
          <TableDescPadroes />
        }
      </div>
    </div>
  )
}

if (document.getElementById("referencias_cub")) {
  ReactDOM.render( <ReferenciasCub />, document.getElementById("referencias_cub"))
}