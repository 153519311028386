const { compose, withProps, lifecycle } = require("recompose");
import React from "react";
const {
    SearchBox,
} = require("react-google-maps/lib/components/places/SearchBox");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    Circle,
    KmlLayer,
} = require("react-google-maps");
const {
    DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
import CustomMarker from "./CustomMarker";
import CustomMarkerUser from "./CustomMarkerUser";
import PlaceMarker from "./PlaceMarker";
var showInfoWindow = false;

const mapUrl =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyBu0-76sJL5A8Ss84aFm9PrpdoB2pktrgI&v=3.exp&libraries=geometry,drawing,places";
const MapWithADrawingManager = compose(
    withProps({
        googleMapURL: mapUrl,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: (
            <div style={{ minHeight: `400px`, height: "90vh" }} />
        ),
        mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
        componentWillMount() {
            var refs = {};

            var fillColor = this.props.getColor();

            this.setState({
                zoom: 13,
                fillColor: fillColor,
                markers: [],
                onMapMounted: (ref) => {
                    window.googleMap = ref;
                    refs.map = ref;
                },
                handleOverlayComplete(e) {
                    this.setDrawingMode(null);
                },
                onBoundsChanged: () => {
                    this.setState({
                        bounds: refs.map.getBounds(),
                        center: refs.map.getCenter(),
                    });
                },
                onSearchBoxMounted: (ref) => {
                    refs.searchBox = ref;
                },
                markerComplete: (marker, fn) => {
                    let circle = new window.google.maps.Circle({
                        center: marker.getPosition(),
                        radius: this.props.state.raio,
                    });

                    refs.map.panTo(marker.getPosition());
                    circle.setMap(null);

                    refs.map.center = marker.getPosition();

                    this.setState({
                        zoom: 15,
                    });

                    fn(marker, refs.map);
                },
                circleComplete: (circle) => {
                    circle.setOptions({
                        fillColor: this.props.getColor(),
                    });

                    refs.map.panTo(circle.getCenter());
                    refs.map.center = circle.getCenter();

                    window.circles.push(circle);

                    this.props.onCircleComplete(circle);
                    this.setState({
                        zoom: 15,
                    });
                },

                onPlacesChanged: () => {
                    const place = refs.searchBox.getPlaces();
                    refs.map.panTo(place[0].geometry.location);

                    this.props.setLocation(place);

                    this.setState({
                        zoom: 12,
                    });
                },
            });
        },
    }),
    withScriptjs,
    withGoogleMap
)((props) => (
    <GoogleMap
        defaultZoom={13}
        zoom={props.state.zoom}
        ref={props.onMapMounted}
        onBoundsChanged={props.onBoundsChanged}
        defaultCenter={
            new google.maps.LatLng(
                props.state.currentPosition.lat,
                props.state.currentPosition.lng
            )
        }
        defaultOptions={{
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_RIGHT,
            },

            fullscreenControlOptions: {
                position: google.maps.ControlPosition.BOTTOM_RIGHT,
            },
        }}
    >
        {props.state.manualCircles.map((circle, index) => {
            let circleOverlay = (
                <Circle
                    center={circle.center}
                    key={index}
                    onRadiusChanged={(e) => {
                        props.onCircleRadiusChanged(circle.id);
                    }}
                    onCenterChanged={() => {
                        props.onCircleCenterChanged(circle.id);
                    }}
                    ref={(ref) => {
                        window.refs[circle.id] = ref;
                    }}
                    onClick={() => {}}
                    radius={circle.radius}
                    editable={true}
                    options={{
                        fillColor: circle.fillColor,
                        fillOpacity: 0.3,
                    }}
                />
            );

            window.circles.push(circleOverlay);
            return circleOverlay;
        })}

        {props.state.showMarkers && props.state.showBuildings &&
            props.state.buildings.map((empreendimento) => {
                let icon = empreendimento.marker;

                if (empreendimento.customMarker) {
                    icon = empreendimento.customMarker;
                }

                let url = "http://maps.google.com/mapfiles/ms/icons/";
                url += "purple-dot.png";
                if(props.state.uniqueProperties.length > 0 
                    && props.state.uniqueProperties.includes(empreendimento.nome)){
                        icon = url;
                }
                

                let marker = (
                    <CustomMarker
                        key={empreendimento._id}
                        onClick={() => props.onMarkerClick(empreendimento)}
                        empreendimento={empreendimento}
                        icon={icon}
                        position={{
                            lat: empreendimento.location.coordinates[1],
                            lng: empreendimento.location.coordinates[0],
                        }}
                    >
                        {/* > */}
                    </CustomMarker>
                );

                //marker.id = empreendimento._id;

                window.markers.push(marker);
                return marker;
            })}
            {props.state.showMarkers &&
            props.state.users.map((user) => {
                let location = JSON.parse(user.location).coordinates;
                let lat = location[1];
                let long = location[0];

                let icon = user.marker;

                if (user.customMarker) {
                    icon = user.customMarker;
                }

                let url = "http://maps.google.com/mapfiles/ms/icons/";
                url += "red-dot.png";

                let marker = (
                    <CustomMarkerUser
                        key={user.id}
                        onClick={() => props.onMarkerClick(user.id)}
                        user={user}
                        icon={icon}
                        position={{
                            lat: lat,
                            lng: long,
                        }}
                    >
                        {/* > */}
                    </CustomMarkerUser>
                );

                window.markers.push(marker);
                return marker;
            })}
        {props.state.selectedIcon !== undefined && (
            <Marker
                icon={props.state.selectedIcon.icon}
                position={props.state.selectedIcon.position}
            />
        )}

        {props.state.pontos.map((marker, index) => {
            return (
                <Marker
                    key={index + 1000 * 999}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    onClick={() => {}}
                />
            );
        })}

        <DrawingManager
            defaultDrawingMode={null}
            onMarkerComplete={(marker) => {
                props.markerComplete(marker, props.onMarkerComplete);
                marker.setMap(null);
            }}
            onPolygonComplete={(polygon) => {
                polygon.fillColor = "blue";
                polygon.setOptions({
                    fillColor: props.getColor(),
                });

                props.onPolygonComplete(polygon);
            }}
            onCircleComplete={props.circleComplete}
            onRectangleComplete={(rectangle) => {
                rectangle.setOptions({
                    fillColor: props.getColor(),
                });

                props.onRectangleComplete(rectangle);
            }}
            onOverlayComplete={props.handleOverlayComplete}
            defaultOptions={{
                drawingControl: true,

                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        google.maps.drawing.OverlayType.CIRCLE,
                        google.maps.drawing.OverlayType.POLYGON,
                        google.maps.drawing.OverlayType.RECTANGLE,
                        google.maps.drawing.OverlayType.MARKER,
                    ],
                },
                circleOptions: {
                    fillColor: `transparent`,
                    fillOpacity: 0.3,
                    strokeWeight: 3,
                    draggable: false,
                    clickable: true,
                    editable: true,
                    zIndex: 1,
                },
                polygonOptions: {
                    fillColor: `transparent`,
                    fillOpacity: 0.1,
                    strokeWeight: 3,
                    clickable: true,
                    editable: true,
                    zIndex: 1,
                    draggable: true,
                },
                rectangleOptions: {
                    fillColor: "transparent",
                    fillOpacity: 0.3,
                    strokeWeight: 3,
                    clickable: true,
                    editable: true,
                    zIndex: 1,
                    draggable: true,
                },
            }}
        />
        <SearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            controlPosition={google.maps.ControlPosition.TOP_CENTER}
            onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type="text"
                placeholder="Digite um endereço"
                id="search_box"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid #527440`,
                    width: `40%`,
                    height: `50px`,
                    marginTop: `5px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                }}
            />
        </SearchBox>
    </GoogleMap>
));

export default MapWithADrawingManager;
