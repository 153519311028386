import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "./Loader";

class Index extends React.Component {
    state = {
        periodBeginAt: "",
        periodEndAt: "",

        filePath: "",
        loading: false,
        error: "",
    };

    handleChange = async (e) => {
        await this.setState({
            ...this.state,
            [e.name]: e.value,
        });
    };

    clearState = async () => {
        await this.setState({
            ...this.state,
            periodBeginAt: "",
            periodEndAt: "",
            filePath: "",
            loading: false,
            error: "",
        });
    };

    submit = async () => {
        if (this.state.periodBeginAt == "" || this.state.periodEndAt == "") {
            return this.handleChange({
                name: "error",
                value: true,
            });
        }

        this.setState({
            ...this.state,
            loading: true,
        });

        await Axios.post("/report-building-location", this.state)
            .then((response) => {
                if (response.data.filePath) {
                    this.setState({
                        ...this.state,
                        loading: false,
                        filePath: response.data.filePath,
                    });
                }
            })
            .catch((error) => this.clearState);
    };

    render = () => {
        return (
            <>
                <div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Período Inicial</label>
                                    <input
                                        type="date"
                                        name="period"
                                        value={this.state.periodBeginAt}
                                        onChange={(value) =>
                                            this.handleChange({
                                                name: "periodBeginAt",
                                                value: value.target.value,
                                            })
                                        }
                                        style={{ padding: "3px" }}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Período Final</label>
                                    <input
                                        type="date"
                                        name="period"
                                        value={this.state.periodEndAt}
                                        onChange={(value) =>
                                            this.handleChange({
                                                name: "periodEndAt",
                                                value: value.target.value,
                                            })
                                        }
                                        style={{ padding: "3px" }}
                                        className="form-control"
                                    />
                                </div>
                                {this.state.error && (
                                    <div
                                        className="form-group col-12 invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        Verifique os campos de datas novamente.
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className="col-6">
                                    <button
                                        className="btn btn-sm btn-success btn-block btn-round background-brain border-brain"
                                        onClick={this.submit}
                                    >
                                        <i className="fas fa-search"></i> Gerar
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button
                                        className="btn btn-sm btn-block btn-round background-brain border-brain"
                                        style={{ backgroundColor: "#FFBB27" }}
                                        onClick={this.clearState}
                                    >
                                        <i className="far fa-file"></i> Limpar
                                    </button>
                                </div>
                                <hr />
                            </div>
                        </div>

                        {this.state.loading && <Loader />}

                        {this.state.filePath != "" && (
                            <a
                                href={this.state.filePath}
                                download
                                className="btn btn-link btn-success"
                            >
                                Baixar Relatório
                            </a>
                        )}
                    </div>
                </div>
            </>
        );
    };
}

export default Index;

if (document.getElementById("report-building-location")) {
    ReactDOM.render(<Index />, document.getElementById("report-building-location"));
}
