const ordenacoes =  [
    { value: 'Empreendimento', label: 'Empreendimento' },
    { value: 'Incorporadora', label: 'Incorporadora' },
    { value: 'Data de Lançamento', label: 'Data de Lançamento' },
    { value: 'Área Priv. m² (Mín)', label: 'Área Priv. m² (Mín)' },
    { value: 'Área Priv. m² (Max)', label: 'Área Priv. m² (Max)' },
    { value: 'Total de Unidades ', label: 'Total de Unidades ' },
    { value: 'Estoque', label: 'Estoque' },
    { value: 'Ticket Médio (R$)', label: 'Ticket Médio (R$)' },
    { value: 'Preço m² (R$)', label: 'Preço m² (R$)' },
    { value: 'VSO', label: 'VSO' },
];

module.exports = ordenacoes;