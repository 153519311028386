import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';



class AlertModal extends Component {
    state = {  }
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}
            aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="btn-block" onClick={this.props.handleClose}>
                    Fechar
                    </Button>
                    <Button variant="danger" className="btn-block" onClick={this.props.closeAndAlterStatusBuilding}>
                    Não exibir mais
                    </Button>
                </Modal.Footer>
            </Modal>
         );
    }
}

export default AlertModal;
