import {React, Component} from 'react';
import IntlCurrencyInput from "react-intl-currency-input";

export default class FormDespesa extends Component {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }


    static getDerivedStateFromProps(props, state) {
        if( props.selectedDespesa && props.selectedDespesa.uuid !== state.uuid ){
            return {
                descriminacao: props.selectedDespesa.descriminacao,
                valor: props.selectedDespesa.valor,
                momento: props.selectedDespesa.momento,
                numero_parcelas: props.selectedDespesa.numero_parcelas,
                uuid: props.selectedDespesa.uuid
            };
        }

        return null;
    }

    getInitialState = () => {
        return {
            descriminacao: '',
            valor: 0,
            momento: 1,
            uuid: '',
            numero_parcelas: 1
        }
    }

    cleanForm = async () => {
        await this.props.setSelectDespesa(null);

        this.setState({
            ...this.getInitialState()
        });

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onChangeValor = (event, value, maskedValue )=>{
        this.setState({
            ...this.state,
            valor: value
        })
    }

    isValid = ()=>
    {
        return !(this.state.descriminacao === '' || this.state.valor === '' || this.state.momento === '');

    }

    render() {
        let inputDescriminacaoClassName = 'form-control';

        if(this.state.descriminacao === 'Terreno')
        {
            inputDescriminacaoClassName += ' readonly';
        }

        return (


            <>
                <div className="card" id="card_add_despesa" style={styles.card_add_despesa}>

                    <div className="card-header">
                        <h5>Adicionar Despesas</h5>
                    </div>

                    <div className="card-body">
                        <div className="row form-group">

                            <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                <label htmlFor="descricao">Descriminação</label>
                                <input type="text" className={inputDescriminacaoClassName} id="descricao" name="descriminacao"
                                       value={this.state.descriminacao}
                                       readOnly={this.state.descriminacao === 'Terreno'}
                                       onChange={this.onChange}/>
                            </div>

                            <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                <label htmlFor="valor">Valor (R$)</label>

                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={this.props.currencyConfig}
                                    onChange={this.onChangeValor}
                                    value={this.state.valor}
                                    style={{padding: '3px'}}
                                    name="valor"
                                    className="form-control"

                                />
                            </div>

                            <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                <label htmlFor="ano">Número de Parcelas</label>
                                <input type="number" className="form-control" id="ano"
                                       name="numero_parcelas"
                                       value={this.state.numero_parcelas}
                                       onChange={this.onChange}/>
                            </div>

                            <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                <label htmlFor="ano">Mês inicial <small>Indique á partir de qual mês o custo entra no Fluxo Total</small></label>
                                <input type="number" className="form-control"
                                       id="momento" name="momento"
                                       value={this.state.momento}
                                       onChange={this.onChange}/>
                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                <button type="button" className="btn btn-success btn-block btn-sm" onClick={async() => {

                                    if(!this.isValid())
                                    {
                                        alert('Preencha todos os campos');
                                        return;
                                    }


                                    this.props.addDespesa(this.state);
                                    await this.cleanForm();
                                }}>Salvar
                                </button>
                            </div>

                            <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                <button type="button" className="btn btn-danger btn-block btn-sm" onClick={(e) => {
                                    e.preventDefault();
                                    this.cleanForm();
                                }}>Limpar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const styles = {
    card_add_despesa: {
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#5B7537",
        borderRadius: "5px !important",
    }
}
