import React from "react";
import Table from "./Table";
import Grafico from "./Grafico";


class TabTicketMedioMetro extends React.Component {
    componentDidMount() { }

    filtroPorTipo = (rows, tipo) => {
        return rows.filter((index) => { if (index.tipo == tipo) { return index } });
    }

    validate = (data) => {
        return data != undefined && data != [] && data.length != 0;
    }

    render() {

        let exibirTabelas = true;
        let exibirGraficos = this.props.state.exibirGraficos;

        return (
            <>
                {this.props.state.tipos.some(option => option.label === 'Vertical')
                    && this.validate(this.props.state.dataTicketMedioMetroTipologia)
                    && this.validate(this.props.state.dataTicketMedioMetroTipo)
                    &&
                    <>
                        {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                            tipo_detalhamento='Tipologia'
                            titulo='Ticket Médio Metro Vertical Por Tipologia'
                            rows={this.props.state.dataTicketMedioMetroTipologia.ticketMedio}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioMetroTipo.ticketMedio, "Vertical")}
                            id="excel36"

                        />}
                        {exibirGraficos && <Grafico
                            tipo_detalhamento='Tipologia'
                            titulo='Ticket Médio Metro Vertical Por Tipologia'
                            rows={this.props.state.dataTicketMedioMetroTipologia.ticketMedio}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioMetroTipo.ticketMedio, "Vertical")}
                        />}
                    </>
                }
                {this.validate(this.props.state.dataTicketMedioMetroPadrao)
                    && this.validate(this.props.state.dataTicketMedioMetroTipo)
                    &&
                    <>
                        {this.props.state.tipos.some(option => option.label === 'Vertical') &&
                            <>
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Padrão'
                                    titulo='Ticket Médio Metro Vertical por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataTicketMedioMetroPadrao.ticketMedio, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioMetroTipo.ticketMedio, "Vertical")}
                                    id="excel37"


                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Padrão'
                                    titulo='Ticket Médio Metro Vertical por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataTicketMedioMetroPadrao.ticketMedio, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioMetroTipo.ticketMedio, "Vertical")}
                                />}
                            </>}

                        {this.props.state.tipos.some(option => option.label === 'Horizontal') &&
                            <>
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Padrão'
                                    titulo='Ticket Médio Metro Horizontal Por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataTicketMedioMetroPadrao.ticketMedio, "Horizontal")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioMetroTipo.ticketMedio, "Horizontal")}
                                    id="excel38"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Padrão'
                                    titulo='Ticket Médio Metro Horizontal Por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataTicketMedioMetroPadrao.ticketMedio, "Horizontal")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioMetroTipo.ticketMedio, "Horizontal")}
                                />}
                            </>}

                        {this.props.state.tipos.some(option => option.label === 'Comercial') &&
                            <>
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Padrão'
                                    titulo='Ticket Médio Metro Comercial Por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataTicketMedioMetroPadrao.ticketMedio, "Comercial")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioMetroTipo.ticketMedio, "Comercial")}
                                    id="excel39"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Padrão'
                                    titulo='Ticket Médio Metro Comercial Por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataTicketMedioMetroPadrao.ticketMedio, "Comercial")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    mediasTotais={this.filtroPorTipo(this.props.state.dataTicketMedioMetroTipo.ticketMedio, "Comercial")}
                                />}
                            </>}
                    </>
                }
            </>
        );
    }
}

export default TabTicketMedioMetro;
