import {collect} from "collect.js";

export default class GenericDataFormatter {

    config = {
        dados: {

        },
        title: '',
        data_label: ''
    };
    headers = [];
    dataCollection = [];


    constructor( data, overlays, config ) {

        this.config = config;

        data = data.map( ( datum )=>{
            if (!datum.nome) {
                datum.nome = datum.shape_id
            }

            return datum;
        });

        this.overlays = overlays;
        this.headers = this.getHeaders(data);

        this.dataCollection = collect(data).groupBy('nome').all();
    }

    getHeaders( data ) {
        let headers = [];

        data.map((datum) => {
            let header = datum?.nome;

            if (datum?.shape_id) {
                header = this.getShapeName(datum.shape_id);
            }

            headers.push(header);
        })

        return headers;
    }

    getShapeName( id ) {
        let shape = this.overlays.find((overlay) => {
            return overlay.id === id;
        });

        if (shape) {

            let shapeType = shape.type.split(' ')[0];

            if (shapeType !== 'cidade') {
                return `${shape.type.split(' ')[0]} ${shape.name}`
            }

            return shape.name;
        }

        return '';
    }


}
