import React, {Component} from 'react';
import ReactDOM from "react-dom";
import {ToastContainer, toast} from 'react-toastify';
import AsyncSelect from "react-select/async";

import './styles.css';
import Loader from "../Loader";



class GrupoForm extends Component {

    constructor(props) {
        super(props);

        let targetRoute = route('grupos.store');
        let grupo_uuid = '';

        //está na rota de editar
        if( route().current('grupo.edit') ){
            grupo_uuid = route().params.grupo;
            targetRoute = route('grupos.update', {grupo: route().params.grupo });
        }

        this.state = {
            targetRoute: targetRoute,
            currentRoute: route().current('grupo.edit') ? 'edit' : 'create',
            name: '',
            members: [],
            owner: '',
            errors: [],
            loading: false,
            grupo_uuid: grupo_uuid,
        }
    }

    componentDidMount = async () => {
        try{
            await this.setLoading(true);
            await this.getUsers();

            if( this.state.currentRoute === 'edit' ){
                await this.getGroup();
            }
        }catch (e){
            console.log(e);
        }finally {
            await this.setLoading(false);
        }

    }

    setLoading = (loading) => {
        this.setState({...this.state, loading: loading});
    }

    getUsers = async (query) => {

        let response = await axios.get(route('viabilidade.grupo.users.options', {
            q: query
        }));

        return response.data;
    }

    handleChange = (event) => {
        this.setState({
                ...this.state,
                [event.target.name]: event.target.value
            }
        );
    }

    handleChangeMembers = (event) => {
        let members = event || [];
        this.setState({
            ...this.state,
            members: members
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        let targetRoute = this.state.targetRoute;


        let payload = {
            name: this.state.name,
            members: this.state.members.map(member => member.value),
            _method: this.state.currentRoute === 'edit' ? 'PUT' : 'POST'
        }

        try {
            await this.setLoading(true);
            let response = await axios.post(targetRoute, payload);

            if (response.status === 201 || response.status === 200) {
                toast("Grupo salvo com sucesso!", {
                    type: toast.TYPE.SUCCESS,
                    onClose: () => {
                        window.location.href = route('web.viabilidade.index');
                    }
                });
            }
        } catch (e) {
            if (e.response && e.response.status === 422) {
                this.setState({
                    ...this.state,
                    errors: e.response.data.errors
                });
            }

            console.log(e)
        } finally {
            await this.setLoading(false);
        }

    }

    getGroup = async () => {
        let response = await axios.get(route('grupos.show', this.state.grupo_uuid));

        await this.setState({
            name: response.data.nome,
            members: response.data.members.map(member => {
                return {
                    value: member.id,
                    label: member.name
                }
            })
        });

    }

    render() {
        return (<div className="container-fluid">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{width: "50%", zIndex: 9999999, fontWieght: 'bold'}}
            />
            {this.state.loading && <Loader />}
            <div className="card no-border-radius">
                <div className="card-body">
                    <form>
                        <div className="form-group">
                            <label htmlFor="name">Nome do Grupo</label>
                            <input type="text" className="form-control no-border-radius" id="name" name="name"
                                   placeholder="Nome do Grupo" value={this.state.name}
                                   onChange={this.handleChange}/>

                            {this.state.errors['name'] &&
                                <div className="invalid-feedback" style={{display: "block"}}>
                                    {this.state.errors['name'][0]}
                                </div>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="members">Membros</label>
                            <AsyncSelect
                                className="basic-single no-border-radius"
                                isMulti
                                cacheOptions
                                defaultOptions
                                value={this.state.members}
                                loadOptions={this.getUsers}
                                onChange={this.handleChangeMembers}
                                name="members"
                                id="members"
                            />
                            {this.state.errors['members'] &&
                                <div className="invalid-feedback" style={{display: "block"}}>
                                    {this.state.errors['members'][0]}
                                </div>}
                        </div>

                        <button type="submit" onClick={this.handleSubmit}
                                className="btn btn-block btn-grupos"
                                style={{height: "40px", marginTop: "10px", borderRadius: "0"}}>
                        Salvar
                        </button>
                    </form>
                </div>
            </div>
        </div>);
    }


}

if (document.getElementById('js-viabilidade-grupo-form')) {
    ReactDOM.render(<GrupoForm/>, document.getElementById('js-viabilidade-grupo-form'));
}
