class Pagination {
    constructor(props) {
        this.props = props;
    }

    loadButtonsPaginate = () => {
        const { pagination, maxPage } = this.props;
        const buttons = [];

        buttons.push(
            <button
                key="first"
                disabled={pagination === 1}
                className={pagination === 1 ? "p-pagination-btn-active" : "p-pagination-btn"}
                onClick={() => this.props.handlePageChange(1)}
            >
                1
            </button>
        );

        let startPage = Math.max(2, pagination - 1);
        let endPage = Math.min(maxPage - 1, pagination + 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    className={i === pagination ? "p-pagination-btn-active" : "p-pagination-btn"}
                    onClick={() => this.props.handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }

        if (maxPage > 1) {
            buttons.push(
                <button
                    key="last"
                    onClick={() => this.props.handlePageChange(maxPage)}
                    className={maxPage === pagination ? "p-pagination-btn-active" : "p-pagination-btn"}
                    disabled={pagination === maxPage}
                >
                    {maxPage}
                </button>
            );
        }

        return buttons;
    };
}

export default Pagination;
