class  HereMapsService{

    apiUrl = 'https://rendaibge.belogic.com.br/api/geolocation-only';

    apiKey = '';
    constructor() {
        this.apiKey = process.env.MIX_RENDA_IBGE_API_KEY;
    }

    getGeolocation( address ){
        address = encodeURIComponent(address);

        const endpoint = `${this.apiUrl}?address=${address}`;

        return axios.get( endpoint, {
            headers: {
                'x-api-key': this.apiKey,
            }
        });
    }
}

export default new HereMapsService();
