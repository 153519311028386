import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import Axios from "axios";
import Select from "react-select";

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cidades: [],
            responsavel: [],
            status: [
                {
                    label: "Concluído",
                    value: "Concluído",
                },
                {
                    label: "Em Andamento",
                    value: "Em Andamento",
                },
            ],
        };
    }

    componentDidMount = async () => {};

    searchCidade = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/cidades/json-options/" + inputQuery
            );
            return response.data;
        }
    };
    searchUser = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/mapeamento/users/json-options/" + inputQuery
            );
            return response.data;
        }
    };
    handleResponsavelChanged = async (e) => {
        await this.setState({
            ...this.state,
            responsavel: e,
        });

        this.props.handleResponsavelChanged(e);
    };
    handleStatusChanged = async (e) => {
        await this.setState({
            ...this.state,
            status: e,
        });

        this.props.handleStatusChanged(e);
    };
    clearState = async () => {
        this.setState({
            ...this.state,
            responsavel: [],
            cidades: [],
        });

        this.props.clearState();
    };

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-4">
                                <label>Tipo Relatório</label>
                                <Select
                                    value={this.props.tipo_relatorio}
                                    placeholder={this.props.tipo_relatorio}
                                    isMulti={false}
                                    onChange={
                                        this.props.handleTipoRelatorioChanged
                                    }
                                    options={[
                                        {
                                            value: "",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "gerencial",
                                            label: "Gerencial",
                                        },
                                        {
                                            value: "analitico",
                                            label: "Analítico",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Status</label>
                                <Select
                                    value={this.state.status}
                                    isMulti={true}
                                    onChange={this.handleStatusChanged}
                                    options={[
                                        {
                                            value: "Concluído",
                                            label: "Concluído",
                                        },
                                        {
                                            value: "Em Andamento",
                                            label: "Em Andamento",
                                        },
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-4">
                                <label>Tipo Mapeamento</label>
                                <Select
                                    value={this.props.tipo_mapeamento}
                                    placeholder={this.props.tipo_mapeamento}
                                    isMulti={false}
                                    onChange={
                                        this.props.handleTipoMapeamentoChanged
                                    }
                                    options={[
                                        {
                                            value: "",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "Online",
                                            label: "Online",
                                        },
                                        {
                                            value: "Fisíco",
                                            label: "Fisíco",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Tipo Criação</label>
                                <Select
                                    value={this.props.type}
                                    placeholder={this.props.type}
                                    isMulti={false}
                                    onChange={this.props.handleTypeChanged}
                                    options={[
                                        {
                                            value: "",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "Regular",
                                            label: "Regular",
                                        },
                                        {
                                            value: "Reabertura",
                                            label: "Reabertura",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Tipo do Empreendimento</label>
                                <Select
                                    value={this.props.tipo_empreendimento}
                                    placeholder={this.props.tipo_empreendimento}
                                    isMulti={false}
                                    onChange={
                                        this.props
                                            .handleTipoEmpreendimentoChanged
                                    }
                                    options={[
                                        {
                                            value: "",
                                            label: "Selecione",
                                        },
                                        {
                                            value: "horizontal",
                                            label: "Horizontal",
                                        },
                                        {
                                            value: "vertical",
                                            label: "Vertical",
                                        },
                                        {
                                            value: "comercial",
                                            label: "Comercial",
                                        },
                                        {
                                            value: "hotel",
                                            label: "Hotel",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Responsável</label>
                                <AsyncSelect
                                    cacheOptions
                                    isMulti
                                    value={this.state.responsavel}
                                    loadOptions={this.searchUser}
                                    onChange={this.handleResponsavelChanged}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Cidade</label>
                                <AsyncSelect
                                    cacheOptions
                                    isMulti
                                    value={this.props.cidade}
                                    loadOptions={this.searchCidade}
                                    onChange={this.props.handleCidadeChanged}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Período</label>
                                <input
                                    type="month"
                                    name="period"
                                    value={this.props.period}
                                    onChange={this.props.handlePeriodChanged}
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-3">
                                <label>
                                    Data Inicial de Cadastro
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-link"
                                        dataToggle="tooltip"
                                        dataPlacement="top"
                                        title="Data Inicial de Cadastro do Empreendimento"
                                    >
                                        ?
                                    </button>
                                </label>
                                <input
                                    type="date"
                                    name="date_limit_start"
                                    value={this.props.date_limit_initial}
                                    onChange={
                                        this.props.handleDateLimitInitialChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-3">
                                <label>
                                    Data Final de Cadastro
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-link"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Data Final de Cadastro do Empreendimento"
                                    >
                                        ?
                                    </button>
                                </label>
                                <input
                                    type="date"
                                    name="date_limit_end"
                                    value={this.props.date_limit_final}
                                    onChange={
                                        this.props.handleDateLimitFinalChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            {/* <div className="form-group col-3">
                                <label>Data Inicial Abertura</label>
                                <input
                                    type="date"
                                    name="date_limit_start"
                                    value={this.props.created_at_initial}
                                    onChange={
                                        this.props.handleCreatedAtInitialChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div> */}
                            {/* <div className="form-group col-3">
                                <label>Data Final Abertura</label>
                                <input
                                    type="date"
                                    name="date_limit_end"
                                    value={this.props.created_at_final}
                                    onChange={
                                        this.props.handleCreatedAtFinalChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-success btn-block btn-round background-brain border-brain"
                                    onClick={this.props.generateRelatorio}
                                >
                                    <i className="fas fa-search"></i> Gerar
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-block btn-round background-brain border-brain"
                                    style={{ backgroundColor: "#FFBB27" }}
                                    onClick={this.clearState}
                                >
                                    <i className="far fa-file"></i> Limpar
                                </button>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
