import {Component, React} from "react";
import _ from "lodash";
import InnerLoading from "./InnerLoading";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);


export default class FluxoTotalChart extends Component {
    constructor(props) {
        super(props);

        let range = _.range(0, 420);

        this.state = {
            loading: false,
            data: [],
            meses: range,
            itens: {
                despesas: [

                    {label: "Desembolso Financeiro", key: "desembolso_financeiro"},
                    {label: "Valor do terreno", key: "valor_terreno"},
                    {label: "Comissão sobre terreno", key: "comissao_sobre_terreno"},
                    {label: "Custo de manutenções futuras", key: "custo_manutencao_futura"},
                    {
                        label: "% de Admin. sobre escritório central",
                        key: "administracao_escritorio"
                    },
                    {label: "Custo com publicidade", key: "custo_publicidade"},
                    {label: "Pagamento do financiamento", key: "pagamento_financiamento"},
                    {label: "Comissão sobre vendas", key: "comissao_sobre_vendas"},
                    {label: "Impostos federais sobre o faturamento", key: "impostos_sobre_faturamento"},
                    {label: "Outros custos", key: "outros_custos"},
                    {label: "Total dos custos", key: "total_custos"},
                ],
                receitas: [
                    {label: "Receita proveniente do financiamento", key: "receita_financiamento"},
                    {label: "Receita proveniente de vendas", key: "receita_vendas"},
                    {label: "Receita proveniente de vendas pós a obra", key: "receita_venda_pos_obra"},
                    {label: "Total de Receitas", key: "total_receitas"},
                ],
                obra: [
                    {label: "Evolução fisica da Obra", key: "evolucao_fisica_obra"},
                    {label: "Evolução fisica da Obra Acumulado", key: "evolucao_fisica_obra_acumulada"},
                ],
                fechamento: [
                    {label: "Fluxo Mensal", key: "fluxo_mensal"},
                ],
            },
            showFormModalEvolucao: false,
            evolucao: [],
            imovel_uuid: '',
            downloading: false,
            chartType: 'line',
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            data: {
                ...props.fluxo_total
            },
            loading: props.loading_fluxo_total,
            imovel_uuid: props.imovel_uuid,
            evolucao: props.evolucao_obra,
            analise_uuid: props.analise_uuid,
        }

    }



    componentDidMount = async () => {

    }


    formatNumber = (number) => {

        if (!number || number == 0) {
            return "-";
        }

        number = Number(number);

        let formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return formatter.format(number).replace("R$", "");
    }

    getCategories = () => {
        let range = _.range(0, 420);

        return range;
    }

    getSeries = () => {
        //total de custos e total de receitas
        let categories = this.getCategories();
        let series = [];

        let names = [
            {
                label: "Total de Custos",
                key: "total_custos",
                dataKey: "despesas",
                color: "#dc3545"
            },
            {
                label: "Total de Receitas",
                key: "total_receitas",
                dataKey: "receitas",
                color: "#007bff"
            },
            {
                label: "Fluxo Mensal",
                key: "fluxo_mensal",
                dataKey: null,//está na raiz do objeto
                color: "#c3e6cb"
            }
        ];



        for( const name of names ){

            let data = [];
            for (let i = 0; i < categories.length; i++) {
                let mesKey = `mes_${i}`;

                if(name.dataKey){
                    data.push(this.state?.data[mesKey]?.[name.dataKey]?.[name.key] || 0);
                }else{
                    data.push(this.state?.data[mesKey]?.[name.key] || 0);
                }

            }

            series.push({
                name: name.label,
                data: data,
                color: name.color,
                lineWidth: 4,
            });
        }

        return series;

    }

    getHighChartsOptions = ()=>{

        let categories = this.getCategories();

        let series = this.getSeries();

        let options =  {
            chart: {
                type: this.state.chartType,
                plotBackgroundImage: 'https://geobrain.com.br/img/thumb-geobrain-mark.png',
                zooming: {
                    type: 'x'
                }
            },
            title: {
                text: 'Fluxo Total'
            },
            subtitle: {
                text: "Fluxo Total"
            },
            xAxis: {
                categories: categories
            },
            yAxis: {
                title: {
                    text: "Valor"
                }
            },
            plotOptions: {
                /*line: {
                    dataLabels: {
                        enabled: false
                    },
                    enableMouseTracking: false
                }*/
                series: {
                    cursor: 'pointer',
                    className: 'popup-on-click',
                    marker: {
                        lineWidth: 1
                    }
                }
            },
            tooltip: {
                shared: true,
                crosshairs: true
            },
            series: series
        };

        console.log(options);

        return options;
    }



    render() {

        if (this.state.loading) {
            return <InnerLoading/>
        }

        return (
            <div>
                <div style={{overflowX: "scroll", overflowY: "scroll"}}>
                    {!this.state.loading && <>
                        <HighchartsReact
                            options={this.getHighChartsOptions()}
                            highcharts={Highcharts}
                        />
                    </>}
                </div>
            </div>
        );
    }
}

