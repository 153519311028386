import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import moment from "moment";


class ModalAudit extends Component {
    state = {
        resultOptions: [
            'OK',
            'NOK'
        ]
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            audit_result: this.props.base.pivot.audit_result,
            followup: this.props.base.pivot.followup,
        }

        this.props.handleSubmit(data);

    }


    render() {

        let base = this.props.base;

        const componenteBuildingStatus = () => {
            switch (this.props.base.building.status) {
                case 'Ativo':
                    return <span className="badge badge-success">{this.props.base.building.status}</span>;
                case 'Esgotado':
                    return <span className="badge badge-danger">{this.props.base.building.status}</span>;
                case 'Esgotado Incompleto':
                    return <span className="badge badge-danger">{this.props.base.building.status}</span>;
                case 'Suspenso':
                    return <span className="badge badge-warning">{this.props.base.building.status}</span>;
                case 'Futuro':
                    return <span className="badge badge-info">{this.props.base.building.status}</span>;
                case 'Refutado':
                    return <span className="badge badge-danger">{this.props.base.building.status}</span>;
                default:
                    return <span className="badge badge-secondary">{this.props.base.building.status}</span>;

            }
        }
        let auditStatusComponente = <span className="badge badge-danger">{this.props.base.pivot.audit_status}</span>;

        if (this.props.base.pivot.audit_status === 'Auditada') {
            auditStatusComponente = <span className="badge badge-success">{this.props.base.pivot.audit_status}</span>;
        }

        let auditDate = '';

        if (this.props.base.pivot.audited_at) {
            auditDate = moment(this.props.base.pivot.audited_at).format('DD/MM/YYYY HH:mm:ss');
        }


        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}
                   dialogClassName="modal-60w"
                   style={{zIndex: 999999999}}
                   aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Auditoria {this.props.base.building.name}</h3>
                        </div>
                        <div className="card-body">
                            <div className="col-12">
                                <strong>Empreendimento:</strong>

                                <a className="btn btn-link" target="_blank"
                                   href={route('update-base', {'id': base.id})}>
                                    {base.building.name}
                                    <i className="fa fa-eye"></i>
                                </a>

                                {componenteBuildingStatus()} - {base.building.type}


                                <span className="font-weight-bold" style={{
                                    fontSize: '12px',
                                    display: "block"
                                }}>
                                            Cadastrado em {base.building.created_at_formatted}
                                </span>

                                {base.status === "Refutado" && <>
                                    <strong>Justificativa de Refutação</strong>
                                    <p>
                                        {base.building.refuse_justification}
                                    </p>
                                </>}
                                <strong>Data de
                                    Lançamento: </strong> {base.building.date_sale_start_formatted} <br/>
                                <strong>Construtora: </strong> {base.building.builder?.name} <br/>
                                <strong>Incorporadoras: </strong>
                                {base.building.incorporators?.map((incorporator, index) => {
                                    return <span key={index}>{incorporator.name} <br/></span>
                                })}


                                <strong>Endereço:</strong> {base.building.formatted_address} <br/>
                                <strong>Telefone de Vendas:</strong> {base.building.telefone_vendas}<br/>
                                <strong>Site:</strong> {base.building.site} <br/>
                                <strong>Informações de Contato:</strong> {base.building.contact_info}<br/>
                                {base.building.notes !== null && <>
                                    <strong>Observações:</strong> {base.building.notes} <br/>
                                </>}

                                <strong>Database:</strong> {base.period_formatted} <br/>

                                <strong>Tabelas:</strong> <br/>
                                <ul className="list-group">

                                    {this.props.tables_links.map((media, index) => {
                                         let [year, month, day] = media.period.split('-');
                                        return (
                                            <li className="list-group-item" key={`anexos-${index}`}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    alignContent: 'space-between',
                                                    flexWrap: 'nowrap',
                                                }}>
                                                    <div>

                                                        <a href={media.url} target="_blank">
                                                            <i className="fa fa-file"></i> &nbsp;
                                                            {this.props.base.building.name} - {`${month}/${year}`} <br/>
                                                        </a>

                                                    </div>
                                                    <div>
                                                        <i className="fa fa-clock"></i> &nbsp;
                                                        <small>{media.created_at}</small>
                                                    </div>
                                                </div>

                                            </li>
                                        )

                                    })}

                                </ul>
                            </div>
                            <div className="col-12">
                                <hr/>
                                <strong>Status da Auditoria:</strong> {auditStatusComponente} <br/>
                                {this.props.base.pivot.audit_status === 'Auditada' && <>
                                    <p className="alert alert-info">
                                        <strong>Auditoria feita por:</strong> {this.props.base.pivot.audited_by} <br/>
                                        <strong>Data da Auditoria:</strong> {auditDate} <br/>
                                    </p>
                                </>}

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Resultado da Auditoria</label>
                                    <select
                                        className="form-control"
                                        onChange={this.props.handleChangeResultAudit}
                                        name="result_audit" value={this.props.base.pivot.audit_result}
                                        id="result_audit">
                                        <option value="">Selecione</option>
                                        {this.state.resultOptions.map((option) => {
                                            return <option key={`op-${option}`} value={option}>{option}</option>
                                        })}
                                    </select>
                                    {this.props.errors['audit_result'] &&
                                        <div className="invalid-feedback" style={{display: "block"}}>
                                            {this.props.errors['audit_result'][0]}
                                        </div>}

                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Followup</label>
                                    <textarea
                                        className="form-control"
                                        style={{height: '100px'}}
                                        onChange={this.props.handleChangeFollowup}
                                        name="followup" value={this.props.base.pivot.followup} id="followup">
                                    </textarea>

                                    {this.props.errors['followup'] &&
                                        <div className="invalid-feedback" style={{display: "block"}}>
                                            {this.props.errors['followup'][0]}
                                        </div>}
                                </div>

                                <div className="form-group row">
                                    <div className="col-6">
                                        <button className="btn btn-success btn-block"
                                                onClick={this.handleSubmit}
                                        >
                                            <i className="fa fa-save"></i> &nbsp;
                                            Salvar
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <Button variant="danger" className="btn-block" onClick={this.props.handleClose}>
                                            <i className="fa fa-times"></i> &nbsp;
                                            Cancelar
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        );
    }
}

export default ModalAudit;
