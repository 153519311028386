import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Loader from '../Loader';
import ShimmerLoaderCards from "./ShimmerLoaderCards";

class GruposViabilidade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            grupos: [

            ],
            loading: false
        };
    }

    componentDidMount = async ()=> {
        await this.getGrupos();
    }

    setLoading = (value) => {
        this.setState({
            loading: value
        });
    }

    getGrupos = async () => {

        this.setLoading(true);
        try{
            let response = await axios.get( route('viabilidade.meus_grupos') );

            this.setState({
                grupos: response.data
            });

        }catch (error) {
            console.log(error);
        }finally {
            this.setLoading(false);
        }


    }

    render() {


        return (
            <div className="card no-border-radius" style={{borderRadius: "0"}}>
                <div className="card-body row " style={{padding: "15px"}}>

                    {this.state.loading && (
                        <ShimmerLoaderCards number_of_cards={6} />
                    )}

                    {!this.state.loading && <>
                        {this.state.grupos.map((grupo, index) => {
                            return (
                                <div className="col-12 col-md-6 col-sm-6" key={index}>
                                    <div className="card card-border-grupos" >
                                        <div className="card-body">
                                            <h5 className="card-title-grupos">{grupo.nome}</h5>
                                            Criado por {grupo.owner?.name}
                                        </div>
                                        <div className="card-footer">
                                            <a href={route('grupo.show', grupo.uuid)} className="btn btn-sm btn-grupos pull-right">Acessar</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        <div className="col-12 col-md-6 col-sm-6">
                            <div className="card">
                                <a className="btn-grupos pull-right btn-criar-grupo"
                                   href={route('grupo.create')}>
                                    <div className="card-body">
                                        <i className="fa fa-plus"></i> &nbsp; Criar novo grupo
                                    </div>
                                </a>
                            </div>
                        </div>
                    </>}


                </div>
            </div>
        );
    }
}


if (document.getElementById('js-viabilidade-grupos')) {
    ReactDOM.render(<GruposViabilidade/>, document.getElementById('js-viabilidade-grupos'));
}
