import React, { Component } from "react";
import ReactDOM from "react-dom";
import getBrowserGeolocation from "../../util/geolocation";
import MapWithADrawingManager from "./MapWithADrawingManager";
import EmpreendimentoService from "../../services/empreendimentosServiceGeo";
import SlidingPaneEmpreendimento from "./SlidingPanelEmpreendimento";
import colors from "../../util/colors";
import lodash, { add } from "lodash";
import FormFilters from "./FormFilters";
import geolocationService from "../../services/geolocationService";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";
import StateService from "../../services/KmlService";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

class DefaultMap extends React.Component {
    state = {
        currentPosition: { lat: -25.551136, lng: -49.1929618 },
        raio: 3000,
        zoom: 15,
        selectedIcon: undefined,
        isSlideOpen: false,
        showMarkers: true, //mostrar marcadores de empreendimentos
        showLegenda: false,

        buildings: [],
        users: [],

        user_type: [],
        age: [],
        genre: [],
        marital_status: [],
        children: [],
        income: 0,
        property_of_interest: [],
        typology_of_interest: [],
        bedrooms: [],
        incorporators: [],
        city: [],
        status: ["Comercialização"],

        property_of_interest: [],
        uniqueProperties: [],

        overlays: [],
        manualCircles: [],
        polygons: [],
        circles: [],
        markers: [],
        pontos: [],
        ranges: [],

        locationBuilding: [],
        showBuildings: true,
    };

    componentWillMount = async () => {
        window.circles = [];
        window.markers = [];
        window.refs = [];
        let currentPosition = await getBrowserGeolocation();

        this.timer = null;
        this.setState({
            ...this.state,
            currentPosition: currentPosition,
        });
    };

    resetForm = async () => {
        await this.setState({
            raio: 3000,
            zoom: 15,
            selectedIcon: undefined,
            isSlideOpen: false,
            showMarkers: true, //mostrar marcadores de empreendimentos
            showLegenda: false,

            buildings: [],
            users: [],

            user_type: [],
            age: [],
            genre: [],
            marital_status: [],
            children: [],
            income: 0,
            property_of_interest: [],
            typology_of_interest: [],
            bedrooms: [],
            incorporators: [],
            city: [],
            status: ["Comercialização"],

            overlays: [],
            manualCircles: [],
            polygons: [],
            circles: [],
            markers: [],
            pontos: [],
            ranges: [],

            locationBuilding: [],
            showBuildings: true,
        });
    };

    getUsers = async (circleUsers, polygonUsers) => {
        await this.setState({
            users: [],
        });

        let latAndLng = undefined;

        if (this.state.selectedIcon != undefined) {
            latAndLng =
                this.state.selectedIcon.position.lat +
                "," +
                this.state.selectedIcon.position.lng;
        }

        let users = [];

        await axios
            .get(
                "/geo-referenciamento/list?" +
                    "user_type=" +
                    this.state.user_type +
                    "&age=" +
                    this.state.age +
                    "&genre=" +
                    this.state.genre +
                    "&marital_status=" +
                    this.state.marital_status +
                    "&children=" +
                    this.state.children +
                    "&city=" +
                    this.state.city +
                    "&location=" +
                    latAndLng +
                    "&circle=" +
                    circleUsers +
                    "&polygon=" +
                    polygonUsers +
                    "&property_of_interest=" +
                    this.state.property_of_interest
            )
            .then((response) => {
                users = response.data;
            })
            .catch((error) => {
                console.log(error);
            });

        return users;
    };

    resetMarkers = async (event) => {
        let value = [];

        await this.state[event.name].map((index) => {
            index.customMarker = undefined;
            value.push(index);
        });

        this.setState({
            ...this.state,
            [event.name]: value,
        });
    };

    replaceMarkersUser = async () => {
        await this.resetMarkers({
            name: "users",
        });

        let value = [];

        await this.state.users.map((index) => {
            if (index.genre == "masculino") {
                index.customMarker = `/img/markers/masc.png`;
            } else {
                index.customMarker = `/img/markers/fem.png`;
            }
            value.push(index);
        });

        await this.setState({
            ...this.state,
            users: value,
        });
    };

    handleChanged = async (event) => {
        if (event.value == null) {
            event.value = [];
        }
        if (Array.isArray(event.value)) {
            if (event.name != "city") {
                event.value = event.value.map((index) => {
                    return index.value;
                });
            } else {
                event.value = await this.handleCityChanged(event);
            }
        }

        await this.setState({
            [event.name]: event.value,
        });

        console.log(this.state[event.name]);
    };

    handleCityChanged = async (event) => {
        if (event.value === null) {
            this.setState({
                ...this.state,
                [event.name]: [],
            });
            return;
        }

        let cities = [];
        let state;

        for (let x = 0; x < event.value.length; x++) {
            let option = event.value[x];

            state = option.value.split("-")[0].trim();

            if (!cities.includes(option.value.split("-")[1].trim())) {
                cities.push(option.value.split("-")[1].trim());
            }
        }

        try {
            let city = cities[cities.length - 1];
            let address = `${city} ${state} - Brasil`;
            let location = await geolocationService.location(address);
            window.googleMap.panTo({
                lat: location.latitude,
                lng: location.longitude,
            });

            return cities;
        } catch (error) {
            console.log(error);
        }
    };

    handleBedroomsChanged = async (e) => {
        const value = parseInt(e.target.value);

        let bedrooms = this.state.bedrooms;

        if (e.target.checked && !bedrooms.includes(value)) {
            bedrooms.push(value);
        }

        if (!e.target.checked && bedrooms.includes(value)) {
            var index = bedrooms.indexOf(value);

            if (index > -1) {
                bedrooms.splice(index, 1);
            }
        }

        await this.setState({
            ...this.state,
            bedrooms: bedrooms,
        });
    };

    searchBuildings = async (inputQuery) => {
        if (inputQuery.length > 1) {
            try {
                let endpoint = "/empreeendimentos-options?nome=" + inputQuery;

                if (this.state.city !== "" && this.state.city.length > 0) {
                    //join cidades label
                    let cities = "";

                    this.state.city.forEach((city) => {
                        cities += city + ",";
                    });

                    endpoint += `&cidade=${cities.trim().slice(0, -1)}`;
                }

                if (
                    this.state.incorporators !== "" &&
                    this.state.incorporators.length > 0
                ) {
                    //join cidades label
                    let incorporators = "";

                    this.state.incorporators.forEach((incorporator) => {
                        incorporators += incorporator + ",";
                    });

                    endpoint += `&incorporadora=${incorporators
                        .trim()
                        .slice(0, -1)}`;
                }

                let empreendimentos = await axios.get(endpoint);

                return empreendimentos.data;
            } catch (error) {}
        }
    };

    getColor = () => {
        return colors[this.state.overlays.length];
    };

    removeDuplicates = async (buildings) => {
        let newBuildings = lodash.uniqBy(buildings, function (a) {
            return a._id;
        });

        return newBuildings;
    };

    removeUsersDuplicates = async (users) => {
        let newUsers = lodash.uniqBy(users, function (a) {
            return a.id;
        });

        return newUsers;
    };

    thereAreDrownShapes() {
        return (
            this.state.polygons.length > 0 ||
            this.state.manualCircles.length > 0 ||
            this.state.circles.length > 0
        );
    }

    reloadBuildings = async () => {
        try {
            if (window.innerWidth < 700) {
                $("#btn-filter").click();
            }

            await this.setState({
                ...this.state,
                loading: true,
            });

            if (this.thereAreDrownShapes() === false) {
                if (
                    this.state.overlays.length == 0 &&
                    this.state.cidade == "" &&
                    this.state.estado.length === 0
                ) {
                    alert(
                        "Desenhe uma área no Mapa ou digite uma Cidade na caixa de busca do mapa"
                    );

                    await this.setState({
                        ...this.state,
                        loading: false,
                    });
                    return;
                }

                if (this.thereAreDrownShapes() === false) {
                    await this.reloadBuildingsInCity();

                    return;
                }
            }

            await this.setState({
                ...this.state,
                buildings: [],
                users: [],
            });

            let buildings = [];
            let users = [];

            let buildingsAndUsersInCircles =
                await this.reloadBuildingsOnCircle();
            let buildingsAndUsersInPolygons =
                await this.reloadBuildingsFromPolygons();

            buildings = [
                ...buildingsAndUsersInCircles.buildings,
                ...buildingsAndUsersInPolygons.buildings,
            ];
            users = [
                ...buildingsAndUsersInCircles.users,
                ...buildingsAndUsersInPolygons.users,
            ];

            //retira os empreendimentos duplicados
            let buildingsWithoutDuplicate = await this.removeDuplicates(
                buildings
            );
            let usersWithoutDuplicate = await this.removeUsersDuplicates(users);

            await this.setState({
                ...this.state,
                buildings: buildingsWithoutDuplicate,
                users: usersWithoutDuplicate,
                loading: false,
                selectedIcon: undefined,
                showLegenda: true,
            });
            if (usersWithoutDuplicate.length > 0) {
                this.replaceMarkersUser();
                this.removeDuplicatesPropertyOfInterest();
            }
            if (buildingsWithoutDuplicate.length > 0) {
                this.openPane();
            } else {
                toast.warn("Nenhum resultado com os parametros fornecidos");
            }
        } catch (e) {
            await this.setState({
                ...this.state,
                loading: false,
            });
        }
    };

    removeDuplicatesPropertyOfInterest = async () => {
        let uniqueProperties = lodash.uniqBy(this.state.users, function (a) {
            return a.property_of_interest;
        });
        uniqueProperties = uniqueProperties.map((prop) => {
            return prop.property_of_interest;
        });

        return await this.setState({
            uniqueProperties: uniqueProperties,
        });
    };

    openPane = () => {
        this.setState({
            isSlideOpen: true,
        });
    };

    reloadBuildingsOnCircle = async () => {
        var buildings = [];
        var users = [];
        if (this.state.manualCircles.length > 0) {
            for (let x = 0; x < this.state.manualCircles.length; x++) {
                let circle = this.state.manualCircles[x];
                const center = circle.center;
                const radius = circle.radius;

                try {
                    var lat = center.lat();
                    var lng = center.lng();
                } catch (e) {
                    var lat = center.lat;
                    var lng = center.lng;
                }
                let buildingsResponse =
                    await EmpreendimentoService.getEmpreendimentosWithinRadius(
                        lat,
                        lng,
                        radius,
                        this.state
                    );

                circle.meta_data = buildingsResponse.data.meta_data;

                let buildingsFromResponse =
                    buildingsResponse.data.empreendimentos;
                circle.buildings = buildingsFromResponse;

                let buildingsWithoutDuplicates = await this.removeDuplicates([
                    ...this.state.buildings,
                    ...buildingsFromResponse,
                ]);

                buildings = [...buildings, ...buildingsWithoutDuplicates];

                let circleUsers = [lat, lng, radius];

                let usersResponse = await this.getUsers(circleUsers, null);
                circle.users = usersResponse;

                let usersWithoutDuplicates = await this.removeUsersDuplicates([
                    ...this.state.users,
                    ...usersResponse,
                ]);

                users = [...users, ...usersWithoutDuplicates];
            }
        }
        return { buildings, users };
    }; //fim reloadBuildingsOnCircle

    reloadBuildingsFromPolygons = async () => {
        let buildings = [];
        let users = [];

        if (this.state.polygons.length > 0) {
            for (let x = 0; x < this.state.polygons.length; x++) {
                let polygon = this.state.polygons[x];
                let path = this.getPolygonPath(polygon);
                path.push(path[0]);

                let buildingsResponse =
                    await EmpreendimentoService.getEmpreendimentosWithinPolygon(
                        path,
                        this.state
                    );

                polygon.meta_data = buildingsResponse.data.meta_data;

                let buildingsFromResponse =
                    buildingsResponse.data.empreendimentos;
                polygon.buildings = buildingsFromResponse;

                buildingsFromResponse.map((building) => {
                    building.color = polygon.color;
                });

                buildings = [
                    ...buildings,
                    ...buildingsResponse.data.empreendimentos,
                ];

                let polygonUsers = [path];

                let usersResponse = await this.getUsers(null, polygonUsers);
                polygon.users = usersResponse;

                let usersWithoutDuplicates = await this.removeUsersDuplicates([
                    ...this.state.users,
                    ...usersResponse,
                ]);

                users = [...users, ...usersWithoutDuplicates];
            }
        }

        return { buildings, users };
    };

    reloadBuildingsInCity = async () => {
        let overlay = {};

        let buildingsResponse =
            await EmpreendimentoService.getEmpreendimentoOnCidade(this.state);
        let buildingsFromResponse = buildingsResponse.data.empreendimentos;
        let users = await this.getUsers(null, null);

        overlay.meta_data = buildingsResponse.data.meta_data;
        overlay.type = "cidade";
        overlay.color = this.getColor();
        overlay.users = users;
        overlay.buildings = buildingsFromResponse;

        await this.setState({
            ...this.state,
            buildings: buildingsFromResponse,
            users: users,
            overlays: [overlay, ...this.state.overlays],
            overlay_cidade: true,
            loading: false,
            showLegenda: true,
        });

        if (this.state.users.length > 0) {
            this.replaceMarkersUser();
            this.removeDuplicatesPropertyOfInterest();
        }
        if (this.state.buildings.length > 0) {
            this.openPane();
        } else {
            toast.warn("Nenhum resultado com os parametros fornecidos");
        }
    };

    generateRandomString = () => {
        return (
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15)
        );
    };

    onCircleComplete = async (circle) => {
        let newCircle = {};
        const radius = circle.getRadius();

        circle.id = this.generateRandomString();
        circle.type = "Círculo";
        circle.setMap(null);

        let ponto = {
            lat: circle.getCenter().lat(),
            lng: circle.getCenter().lng(),
        };

        newCircle.id = this.generateRandomString();
        newCircle.center = ponto;
        newCircle.radius = radius;
        newCircle.name = this.state.manualCircles.length + 1;
        newCircle.fillColor = circle.fillColor;
        newCircle.fillOpacity = 0.3;
        newCircle.type = "Círculo";
        newCircle.color = circle.fillColor;

        await this.setState({
            ...this.state,
            drawingMode: null,
            manualCircles: [newCircle, ...this.state.manualCircles],
            overlays: [newCircle, ...this.state.overlays],
        });

        await this.reloadBuildings();
    };

    onRectangleComplete = async (rectangle) => {
        let bounds = rectangle.getBounds();
        let northEast = bounds.getNorthEast();
        let southWest = bounds.getSouthWest();

        let northWest = [northEast.lat(), southWest.lng()];
        let southEast = [southWest.lat(), northEast.lng()];

        let path = [
            [northEast.lat(), northEast.lng()],
            [northWest[0], northWest[1]],
            [southWest.lat(), southWest.lng()],
            [southEast[0], southEast[1]],
            [northEast.lat(), northEast.lng()],
        ];

        rectangle.color = rectangle.fillColor;
        rectangle.id = "retangulo-" + this.generateRandomString();
        rectangle.name = this.state.countRectangles + 1;

        rectangle.type = "Retângulo";
        rectangle.path = path;

        await this.setState({
            ...this.state,
            drawingMode: null,
            polygons: [rectangle, ...this.state.polygons],
            overlays: [rectangle, ...this.state.overlays],
            countRectangles: this.state.countRectangles + 1,
        });
        this.reloadBuildings();
    };

    onPolygonComplete = async (polygon) => {
        try {
            let path = this.getPolygonPath(polygon);

            path.push(path[0]);

            polygon.type = "Polígono";
            polygon.color = polygon.fillColor;
            polygon.id = this.generateRandomString();
            polygon.name = this.state.countPolygons + 1;

            await this.setState({
                ...this.state,
                drawingMode: null,
                polygons: [polygon, ...this.state.polygons],
                overlays: [polygon, ...this.state.overlays],
                countPolygons: this.state.countPolygons + 1,
            });

            this.reloadBuildings();
        } catch (error) {
            console.log(error);
        }
    };

    getPolygonPath = (polygon) => {
        if (polygon.getBounds !== undefined) {
            let bounds = polygon.getBounds();
            let northEast = bounds.getNorthEast();
            let southWest = bounds.getSouthWest();

            let northWest = [northEast.lat(), southWest.lng()];
            let southEast = [southWest.lat(), northEast.lng()];

            let path = [
                [northEast.lat(), northEast.lng()],
                [northWest[0], northWest[1]],
                [southWest.lat(), southWest.lng()],
                [southEast[0], southEast[1]],
                [northEast.lat(), northEast.lng()],
            ];

            return path;
        }

        let path = [];

        for (var i = 0; i < polygon.getPath().getLength(); i++) {
            let coordinate = polygon
                .getPath()
                .getAt(i)
                .toUrlValue(10)
                .split(",");

            path.push([Number(coordinate[0]), Number(coordinate[1])]);
        }

        return path;
    };

    setLocation = async (place) => {
        try {
            await this.setState({
                ...this.state,
                loading: true,
            });

            let latitude = place[0].geometry.location.lat();
            let longitude = place[0].geometry.location.lng();

            //adiciona um Marca no Ponto da Busca
            let ponto = {
                lat: latitude,
                lng: longitude,
            };

            const reverse = await geolocationService.reverse(
                latitude,
                longitude,
                place
            );

            let cities = this.state.city;
            if (!cities.includes(reverse.cidade)) {
                cities.push(reverse.cidade);
            }
            await this.setState({
                city: cities,
                overlay_cidade: true,
                pontos: [...this.state.pontos, ponto],
            });

            await this.reloadBuildings();
            document.querySelector("#search_box").value = "";
        } catch (error) {
            console.log("erro ao buscar cidade");
            console.log(error);
        } finally {
            await this.setState({
                ...this.state,
                loading: false,
            });
        }
    };

    handleSubmit = async () => {
        await this.setState({
            loading: true,
        });
        await this.reloadBuildings();
        await this.setState({
            loading: false,
        });
    };

    onMarkerClick = async (building) => {
        let selectedIcon = {
            position: {
                lat: building.location.coordinates[1],
                lng: building.location.coordinates[0],
            },
            icon: "http://maps.google.com/mapfiles/kml/paddle/grn-stars.png",
        };

        await this.setState({
            selectedIcon: selectedIcon,
        });
        document.getElementById("btn-results").click();

        let users = await this.getUsers(null, null);

        await this.setState({
            ...this.state,
            users: users,
        });

        this.replaceMarkersUser();
        this.removeDuplicatesPropertyOfInterest();
    };

    render() {
        return (
            <>
                {this.state.loading && <Loader />}

                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: "60%", color: "#000", zIndex: 9999999 }}
                />

                <div className="row">
                    <div
                        className="col-3 ed-mapa-filtro"
                        style={{ paddingLeft: 0, paddingRight: 0, zIndex: 1 }}
                    >
                        <button
                            id="btn-filter"
                            className="btn btn-sm"
                            style={{
                                position: "relative",
                                float: "right",
                                top: "70px",
                                zIndex: 1500,
                                borderRadius: 0,
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                $("#sidebar").toggleClass("collapsed");
                                $("#content").toggleClass(
                                    "col-12 col-9 map-filter-off"
                                );
                                $("#btn-filter").toggleClass("btn-filter-off");

                                if (
                                    $("#filter-icon").hasClass(
                                        "fa-angle-double-left"
                                    )
                                ) {
                                    $("#filter-icon").removeClass(
                                        "fa-angle-double-left"
                                    );
                                    $("#filter-icon").addClass(
                                        "fa-angle-double-right"
                                    );
                                } else if (
                                    $("#filter-icon").hasClass(
                                        "fa-angle-double-right"
                                    )
                                ) {
                                    $("#filter-icon").removeClass(
                                        "fa-angle-double-right"
                                    );
                                    $("#filter-icon").addClass(
                                        "fa-angle-double-left"
                                    );
                                }
                            }}
                        >
                            <i
                                id="filter-icon"
                                className="fas fa-angle-double-left"
                            ></i>
                        </button>
                        <div
                            id="sidebar"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <div
                                className="card"
                                style={{ borderRadius: 0, height: "100vh" }}
                            >
                                <div className="card-body">
                                    <FormFilters
                                        state={this.state}
                                        handleChanged={this.handleChanged}
                                        handleBedroomsChanged={
                                            this.handleBedroomsChanged
                                        }
                                        searchBuildings={this.searchBuildings}
                                        handleSubmit={this.handleSubmit}
                                        resetForm={this.resetForm}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-9 ed-mapa"
                        id="content"
                        style={{ position: "relative", left: -47 }}
                    >
                        {this.state.users.length > 0 && (
                            <SlidingPaneEmpreendimento state={this.state} />
                        )}
                        <MapWithADrawingManager
                            state={this.state}
                            onMarkerComplete={this.onMarkerComplete}
                            onPolygonComplete={this.onPolygonComplete}
                            onCircleComplete={this.onCircleComplete}
                            onRectangleComplete={this.onRectangleComplete}
                            onMarkerClick={this.onMarkerClick}
                            setLocation={this.setLocation}
                            onCircleRadiusChanged={this.onCircleRadiusChanged}
                            onCircleCenterChanged={this.onCircleCenterChanged}
                            getColor={this.getColor}
                            showInfoWindow={this.showInfoWindow}
                            handleShowInfoWindow={this.handleShowInfoWindow}
                            handleHideInfoWindow={this.handleHideInfoWindow}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default DefaultMap;

if (document.getElementById("geo_referenciamento_mapa")) {
    ReactDOM.render(
        <DefaultMap />,
        document.getElementById("geo_referenciamento_mapa")
    );
}
