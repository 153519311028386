import React, { Component } from "react";

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var totalEmpreendimentos = 0;
        return (
            <table className="table table-striped table-condensed table-padroes">
                <thead>
                    <tr
                        className="text-center"
                        style={{
                            backgroundColor: this.props.color,
                            color: "#ffffff",
                            fontWeight: "bold",
                        }}
                    >
                        <td colSpan="8">
                            <span style={{ fontWeight: "bold" }}>
                                {this.props.title}
                            </span>
                        </td>
                    </tr>
                    <tr
                        className="text-center"
                        style={{
                            backgroundColor: this.props.color,
                            color: "#ffffff",
                            fontWeight: "bold",
                        }}
                    >
                        <th
                            className="text-center"
                            style={{ color: "#ffffff", padding: "7px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>
                                Tipo Imóvel
                            </span>
                        </th>
                        <th
                            className="text-center"
                            style={{ color: "#ffffff", padding: "7px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>Nº Imóveis</span>
                        </th>
                        <th
                            className="text-center"
                            style={{ color: "#ffffff", padding: "7px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>(%)</span>
                        </th>
                        <th
                            className="text-center"
                            style={{ color: "#ffffff", padding: "7px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>
                                Área Média Total
                            </span>
                        </th>
                        <th
                            className="text-center"
                            style={{ color: "#ffffff", padding: "7px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>
                                Área Média Útil
                            </span>
                        </th>
                        <th
                            className="text-center"
                            style={{ color: "#ffffff", padding: "7px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>
                                Preço Médio
                            </span>
                        </th>
                        <th
                            className="text-center"
                            style={{ color: "#ffffff", padding: "7px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>
                                Preço Médio M²
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>

                    {this.props.tipos.map((index, key) => {
                        totalEmpreendimentos += parseInt(index.count_buildings);

                        return (
                            <tr className="text-center" key={key}>
                                <td className="text-center">{index.tipo}</td>
                                <td className="text-center">{index.count_buildings.toLocaleString("pt-BR")}</td>
                                <td className="text-center">{Number((index.count_buildings * 100) / this.props.count_buildings).toFixed(1).toLocaleString().replace('.',',')}%</td>
                                <td className="text-center">{index.area_total_media}</td>
                                <td className="text-center">{index.area_util_media}</td>
                                <td className="text-center">{index.preco_medio}</td>
                                <td className="text-center">{index.preco_medio_metro}</td>
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td className="text-center">Total</td>
                        <td className="text-center">
                            {totalEmpreendimentos.toLocaleString("pt-BR")}
                        </td>
                        <td className="text-center">100%</td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                    </tr>
                </tfoot>
            </table>
        );
    }
}

export default Table;
