import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Form from "./Form";
import TableGerencial from "./TableGerencial";
import TableAnalitico from "./TableAnalitico";
import TableAnaliticoPorPeso from "./TableAnaliticoPorPeso";
import Loader from "./Loader";

class Index extends React.Component {
    defaultValues = {
        users: [],
        periodBeginAt: "",
        periodEndAt: "",
        type: { value: "", label: "Selecione" },
    };

    state = {
        currentForm: this.defaultValues,
        errors: [],
        loading: false,
        reverse: false,

        result: [],
    };

    onChange = async (e) => {
        let currentForm = { ...this.state.currentForm };
        currentForm[e.name] = e.value;

        await this.setState({
            ...this.state,
            currentForm: currentForm,
        });
    };

    clearState = async () => {
        await this.setState({
            ...this.state,
            currentForm: this.defaultValues,
            errors: [],
            loading: false,
            reverse: false,

            result: [],
        });
    };

    submit = async () => {
        await this.setState({
            ...this.state,
            result: [],
            loading: true,
        });

        var data = this.state.currentForm;
        data["type"] = data["type"].value;
        var users = [];
        if (data["users"] != []) {
            data["users"] = data["users"].map((user) => {
                users.push({ value: user.value, label: user.label });
                return user.value;
            });
        }

        var result = await Axios.post(
            "/mapeamento/painel/generateReport",
            data
        );

        let currentForm = { ...this.state.currentForm };
        currentForm["type"] = { value: data["type"], label: data["type"] };
        currentForm["users"] = users;

        await this.setState({
            ...this.state,
            result: result.data,
            currentForm: currentForm,
            loading: false,
        });
    };

    render = () => {
        return (
            <>
                <Form
                    state={this.state}
                    onChange={this.onChange}
                    submit={this.submit}
                    clearState={this.clearState}
                />

                {this.state.loading && <Loader />}

                {this.state.result.type == "Gerencial" && (
                    <TableGerencial data={this.state.result} />
                )}
                {this.state.result.type == "Analítico" && (
                    <TableAnalitico data={this.state.result} />
                )}
                 {this.state.result.type == "Analítico Por Peso" && (
                    <TableAnaliticoPorPeso data={this.state.result} />
                )}
            </>
        );
    };
}

export default Index;

if (document.getElementById("mapeamento-report")) {
    ReactDOM.render(<Index />, document.getElementById("mapeamento-report"));
}
