export default {
    domicilios_por_condicao_ocupacao:{
        dados:{
            'alugados' : 'Alugados',
            'cedidos' : 'Cedidos',
            'proprios' : 'Próprios',
        },
        data_label: 'Condição de Ocupação',
        title: 'Domicílios por Condição de Ocupação',
        uses_subheader: true,
    },
    domicilios_por_faixa_de_renda: {
        dados:{
            'ate_um_salario' : 'Até 1 Salário',
            'de_1_a_2_salarios' : 'De 1 a 2 Salários',
            'de_2_a_3_salarios' : 'De 2 a 3 Salários',
            'de_3_a_5_salarios' : 'De 3 a 5 Salários',
            'de_5_a_10_salarios' : 'De 5 a 10 Salários',
            'mais_de_10_salarios' : 'Mais de 10 Salários',
        },
        data_label: 'Faixas de Renda',
        title: 'Domicílios por Faixa de Renda',
        uses_subheader: true,
    },
    domicilios_por_numero_moradores: {
        dados:{
            'um_moradores' : '1 Morador',
            'dois_moradores' : '2 Moradores',
            'tres_moradores' : '3 Moradores',
            'quatro_moradores' : '4 Moradores',
            'cinco_moradores' : '5 Moradores',
            'seis_moradores' : '6 Moradores',
            'sete_moradores' : '7 Moradores',
            'oito_moradores' : '8 Moradores',
            'nove_moradores' : '9 Moradores',
            'dez_moradores' : '10 Moradores',
        },
        data_label: 'Número de Moradores',
        title: 'Domicílios por Número de Moradores',
        uses_subheader: true,
    },
    domicilios_por_tipo_moradia:{
        dados:{
            'apartamentos' : 'Apartamentos',
            'casas' : 'Casas',
        },
        data_label: 'Tipos de Moradia',
        title: 'Domicílios por Tipo de Moradia',
        uses_subheader: true,
    },
    indice_verticalizacao: {
        dados:{
            'apartamentos' : 'Apartamentos',
            'indice_verticalizacao' : 'Índice de Verticalização',
        },
        data_label: 'Tipos de Moradia',
        title: 'Índice de Verticalização',
        uses_subheader: false,
    },
    faixa_etaria:{
        dados: {
            total_0_19: 'de 0 a 19 anos',
            total_20_24: 'de 20-24',
            total_25_34: 'de 25-34',
            total_35_49: 'de 35-49',
            total_50_59: 'de 50-59',
            total_60_mais: 'mais de 60',
        },
        data_label: 'Faixas Etárias',
        title: 'População por Faixa Etária',
        uses_subheader: true,
    },
    populacao_por_faixa_de_renda:{
        dados:{
            'ate_um_salario' : 'Até 1 Salário',
            'de_1_a_2_salarios' : 'De 1 a 2 Salários',
            'de_2_a_3_salarios' : 'De 2 a 3 Salários',
            'de_3_a_5_salarios' : 'De 3 a 5 Salários',
            'de_5_a_10_salarios' : 'De 5 a 10 Salários',
            'de_10_a_15_salarios' : 'De 10 a 15 Salários',
            'de_15_a_20_salarios' : 'De 15 a 20 Salários',
            'mais_de_20_salarios' : 'Mais de 20 Salários',
        },
        data_label: 'Faixas de Renda',
        title: 'População por Faixa de Renda',
        uses_subheader: true,
    },
    'mapa_coropetico_renda_domiciliar':{
        title: 'Mapa coropético - Renda Média Domiciliar',
        dados_info_window: [{
            type: 'currency',
            field: 'renda_media_domiciliar',
            label: 'Renda Média Domiciliar'
        }],
        dado_range: 'renda_media_domiciliar',
        dado_type : 'currency',
        color_ranges: [
            { min: 0, max: 1525, color: '#666666' },
            { min: 1525.01, max: 2713, color: '#f7f48b' },
            { min: 2713.01, max: 4607, color: '#ffff00' },
            { min: 4607.01, max: 8150, color: '#ffd21c' },
            { min: 8150.01, max: 16186, color:  '#a8ffa8'},
            { min: 16186.01, max: 28096, color: '#7fff00' },
            { min: 28096.01, max: Infinity, color: '#00cc00' }
        ]
    }
    ,'mapa_coropetico_indice_verticalizacao': {
        title: 'Mapa coroplético - Indíce de Verticalização',
        dados_info_window: [
            {
                type: 'percentage',
                field: 'indice_verticalizacao',
                label: 'Indíce de Verticalização'
            },
            {
                type: 'integer',
                field: 'apartamentos',
                label: 'Número de Apartamentos'
            },
            {
                type: 'integer',
                field: 'casas',
                label: 'Número de Casas'
            },
            {
                type: 'integer',
                field: 'total',
                label: 'Total de Moradias'
            },
        ],
        dado_range: 'indice_verticalizacao',
        dado_type : 'percentage',
        color_ranges: [
            { min: 80, max: 100, color: '#7a2811'   },
            { min: 60, max: 79.99, color: '#826c6e' },
            { min: 40, max: 59.99, color: '#d2b48c' },
            { min: 1, max: 39.99, color: '#ffdeb3'  },
            { min: 0.1, max: 0.99, color: '#f5f5dc' },
            { min: 0, max: 0, color:  '#bbbbbb'     },
        ]
    },
    'mapa_coropetico_indice_propriedade': {
        title: 'Mapa coroplético - Indíce de Propriedade',
        dados_info_window: [
            {
                type: 'percentage',
                field: 'indice_propriedade',
                label: 'Indíce de Propriedade'
            },
            {
                type: 'integer',
                field: 'proprios',
                label: 'Número de domicílios  Próprios'
            },
            {
                type: 'integer',
                field: 'alugados',
                label: 'Número de domicílios Alugados'
            },
            {
                type: 'integer',
                field: 'cedidos',
                label: 'Número de domicílios Cedidos'
            }
            ,{
                type: 'integer',
                field: 'total',
                label: 'Número Total de Domílios'
            },

        ],
        dado_range: 'indice_propriedade',
        dado_type : 'percentage',
        color_ranges: [
            { min: 0, max: 50, color: '#fffde7'   },
            { min: 50.01, max: 65, color: '#768fbc' },
            { min: 65.01, max: 80, color: '#a4d9e2' },
            { min: 80.01, max: 90, color: '#d3ebdb'  },
            { min: 90.01, max: 100, color: '#495875'  },
        ]
    },
    'mapa_coropetico_densidade_demografica_2010': {
        title: 'Mapa coroplético - Densidade Demográfica 2010',
        dados_info_window: [
            {
                type: 'double',
                field: 'densidade_demografica',
                label: 'Densidade Demográfica - hab/km²'
            },
            {
                type: 'integer',
                field: 'populacao',
                label: 'População'
            },
            {
                type: 'km2',
                field: 'area_km2',
                label: 'Área em km²'
            },

        ],
        dado_range: 'densidade_demografica',
        dado_type : 'double',
        color_ranges: [
            { min: 0, max: 0, color: '#dbdbdb'   },
            { min: 0.0001, max: 1000, color: '#999999' },
            { min: 1001, max: 5000, color: '#ffc9c9' },
            { min: 5001, max: 10000, color: '#ff5959'  },
            { min: 10000, max: 25000, color: '#495875'  },
            { min: 25001, max: Infinity, color: '#cc0000'  },
        ]
    }
    ,'mapa_coropetico_densidade_demografica_2022': {
        title: 'Mapa coroplético - Densidade Demográfica 2022',
        dados_info_window: [
            {
                type: 'double',
                field: 'densidade_demografica',
                label: 'Densidade Demográfica - hab/km²'
            },
            {
                type: 'integer',
                field: 'populacao',
                label: 'População'
            },
            {
                type: 'km2',
                field: 'area_km2',
                label: 'Área em km²'
            },
        ],
        dado_range: 'densidade_demografica',
        dado_type : 'double',
        color_ranges: [
            { min: 0, max: 0, color: '#dbdbdb'   },
            { min: 0.0001, max: 1000, color: '#999999' },
            { min: 1001, max: 5000, color: '#ffc9c9' },
            { min: 5001, max: 10000, color: '#ff5959'  },
            { min: 10000, max: 25000, color: '#495875'  },
            { min: 25001, max: Infinity, color: '#cc0000'  },
        ]
    },


}
