import React, { useState } from 'react'
import TablePerPattern  from "./TablePerPattern";

export default function TableViewer(props){




    async function getOnlyBuildingsIds(){
        let set = new Set();

        for (let i = 0; i < props.grandParentState.empreendimentos.length; i++) {
            set.add(props.grandParentState.empreendimentos[i].id_legado)
        }

        console.table( [...set]);

        return [...set]
    }

    async function downloadFile(){

        let payload ={...props.filters};

        payload.format = 'excel';
        console.log(props.type);

        let aditionalParams = {
            responseType: 'blob',
        };

        try {
            let response = ''
            if (props.type.label === 'Por padrão') {
                response = await axios.post(route('web.supplies.reportSuppliesByPattern'), payload, aditionalParams );
            }
            if (props.type.label === 'Por fase de obra') {
                response = await axios.post(route('web.supplies.reportSuppliesByStage'), payload, aditionalParams );
            }
            if (props.type.label === 'Por Incorporadora') {
                response = await axios.post(route('web.supplies.reportSuppliesByIncorporator'), payload, aditionalParams );
            }

            if (response.status === 200) {
                if( response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
                    const type = response.headers['content-type'];
                    const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = response.headers['content-disposition'].split('=')[1];
                    link.click();

                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className='table-background' style={{ height : "auto", minHeight : "100vh" }}>
            <div className='table-box'>

                <div className='table-header'>
                    <button
                        className="btn btn-sm btn-round btn-filter btn-download"
                        style={{
                            marginTop: "15px",
                            backgroundColor: "#5B7537",
                            borderColor: "#5B7537",
                        }}
                        onClick={() => downloadFile()}
                    >
                        <i class="fa fa-download"></i>
                    </button>
                    <button
                        className="btn btn-close"
                        style={{
                            marginTop: "15px",
                            backgroundColor: "#5B7537",
                            borderColor: "#5B7537",
                        }}
                        onClick={() => props.closeSupplyFilter() }
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </div>

                <TablePerPattern
                    type={ props.type }
                    payload={ props.filters }
                    grandParentState={ props.parentState }
                />

            </div>
        </div>
    )
}
