import React, { Component } from "react";

class Legenda extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = async () => { };

    render() {
        return (
            <table className="table table-striped table-condensed legenda-mapa">
                <thead>
                    <tr>
                        <th
                            colSpan="2"
                            className=""
                            style={{ paddingBottom: 7 }}
                        >
                            <span
                                className="font-weight-bold text-center"
                                style={{
                                    color: "rgb(0, 72, 14)",
                                    fontSize: 16,
                                }}
                            >
                                LEGENDA
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <img src="/img/markers/apartamentos.png" alt="" />
                        </td>
                        <td>Apartamentos Revenda</td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/markers/apartamentos_aluguel.png" alt="" />
                        </td>
                        <td>Apartamentos Locação</td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/markers/residencial.png" alt="" />
                        </td>
                        <td>Residencias Revenda</td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/markers/residencial_aluguel.png" alt="" />
                        </td>
                        <td>Residencias Locação</td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/markers/comercial.png" alt="" />
                        </td>
                        <td>Comercial Revenda</td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/markers/comercial_aluguel.png" alt="" />
                        </td>
                        <td>Comercial Locação</td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/markers/lotes-terrenos.png" alt="" />
                        </td>
                        <td>Lotes e Terrenos Revenda</td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/markers/lotes-terrenos_aluguel.png" alt="" />
                        </td>
                        <td>Lotes e Terrenos Locação</td>
                    </tr>
                    <tr>
                        <td>
                            <img
                                src="/img/markers/industrial-garagem.png"
                                alt=""
                            />
                        </td>
                        <td>Industrial e Garagens Revenda</td>
                    </tr>
                    <tr>
                        <td>
                            <img
                                src="/img/markers/industrial-garagem_aluguel.png"
                                alt=""
                            />
                        </td>
                        <td>Industrial e Garagens Locação</td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/markers/rural.png" alt="" />
                        </td>
                        <td>Rural Revenda</td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/markers/rural_aluguel.png" alt="" />
                        </td>
                        <td>Rural Locação</td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default Legenda;
