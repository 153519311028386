import React, { Component } from "react";
import { render } from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { each } from "jquery";

class Grafico extends Component {
    constructor(state) {
        super(state);
        this.state = {};
    }

    componentDidMount = async () => {};

    checkRegionPattern(region, pattern) {
        for (let item of this.props.data) {
            if (item.region === region && item.pattern === pattern) {
                return item.value; // Correspondência encontrada
            }
        }
        return 0; // Correspondência não encontrada
    }
    checkRegionPatternVariation(region, pattern) {
        if (this.props.dataForCompare != undefined) {
            for (let item of this.props.dataForCompare) {
                if (item.region === region && item.pattern === pattern) {
                    return item.value; // Correspondência encontrada
                }
            }
            return 0; // Correspondência não encontrada
        }
        return 0;
    }

    render() {
        let max = 0;

        let region = this.props.data.map((index) => {
            return index.region;
        });
        region = region.filter((item, index) => region.indexOf(item) === index);

        region.sort();

        let pattern = this.props.data.map((index) => {
            return index.pattern;
        });

        pattern = pattern.filter(
            (item, index) => pattern.indexOf(item) === index
        );

        let series = [];

        for (let i = 0; i < pattern.length; i++) {
            let data = [];

            for (let regionIndex of region) {
                let variation = 0;
                let value = this.checkRegionPattern(regionIndex, pattern[i]);
                let valueComparation = this.checkRegionPatternVariation(
                    regionIndex,
                    pattern[i]
                );
                if (valueComparation > 0 && value > 0) {
                    variation = ((valueComparation - value) / value) * 100;
                }
                if (this.props.type == "Média dos Preços") {
                    value = Number(value).toFixed();
                }
                if(this.props.dataForCompare != undefined){
                    value = Number(variation).toFixed();
                }
                data.push({
                    region: regionIndex,
                    y: +value,
                    v: +variation,
                });
                if (+value > max) {
                    max = +value;
                }
            }
            series.push({ name: pattern[i], data: data });
        }

        const options = {
            chart: {
                type: "column",
            },
            title: {
                text: this.props.type,
            },
            subtitle: {
                text: this.props.search + " " + this.props.period,
            },
            xAxis: { categories: region },
            yAxis: {
                max: max,
                title: {
                    text: "",
                },
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            series: series,
        };

        if (this.props.dataForCompare != undefined) {
            options.tooltip = {
                headerFormat:
                    '<span style="font-size:11px">{series.region}</span><br>',
                pointFormat:
                    '<span style="color:{point.color}">{series.name} teve uma variação de </span>: <b>{point.v:.2f}%</b> em relação ao valor deste período.<br/>',
            };

            options.plotOptions.series = {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.1f}%'
                }
            }
        }

        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Grafico;
