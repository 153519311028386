import React, {Component} from 'react';

class EstoqueValoresTable extends Component {

    render() {

        return (<React.Fragment>
            <div>
                <h3>Valores Informados</h3>

                <table className={"table table-striped"}>
                    <thead>
                    <tr>
                        <th className={'text-center'}>DORM.</th>
                        <th className={'text-center'}>Área Privativa</th>
                        <th className={'text-center'}>QTY</th>
                        <th className={'text-center'}>Tipo</th>
                        <th className={'text-center'}>Valor</th>
                        <th className={'text-center'}>Valor Negociado</th>
                        <th className={'text-center'}>Venda</th>
                        <th className={'text-center'}>Distratos</th>
                        <th className={'text-center'}>Estoque</th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.props.tipologias.map((tipologia, index) => {

                            if (tipologia.vendas === undefined) {
                                tipologia.vendas = null;
                            }

                            if (tipologia.distratos === undefined) {
                                tipologia.distratos = null;
                            }

                            if (tipologia.estoque === undefined) {
                                tipologia.estoque = null;
                            }

                            if (tipologia.valor_negociado === undefined) {
                                tipologia.valor_negociado = null;
                            }

                            return (<tr key={tipologia.id}>
                                <td>
                                    {tipologia.number_bedroom}
                                </td>
                                <td>
                                    {tipologia.private_area}
                                </td>
                                <td>
                                    {tipologia.qty}
                                </td>
                                <td>
                                    {tipologia.type_of_typology}
                                </td>
                                <td>
                                    {tipologia.valor}
                                </td>
                                <td>
                                    {tipologia.valor_negociado}
                                </td>

                                <td>
                                    {tipologia.vendas}
                                </td>
                                <td>
                                    {tipologia.distratos}
                                </td>
                                <td>
                                    {tipologia.estoque}
                                </td>

                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>);
    }

}

export default EstoqueValoresTable;
