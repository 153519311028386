import React from 'react';
import ReactDOM from 'react-dom';
import DefaultMap from './DefaulMap';




class App extends React.Component {
    state = {
        isSlideOpen: false,

    }


    render = () => {
        return (
            <>
                <DefaultMap />
            </>
        );
    }
}

export default App;

if (document.getElementById('mapa')) {
    ReactDOM.render(<App />, document.getElementById('mapa'));
}
