const { compose, withProps, lifecycle } = require("recompose");
import React from "react";
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    Circle,
    KmlLayer,
} = require("react-google-maps");

const mapUrl =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyBu0-76sJL5A8Ss84aFm9PrpdoB2pktrgI&v=3.exp&libraries=geometry,drawing,places,visualization";
const SimpleMap = compose(
    withProps({
        googleMapURL: mapUrl,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: (<div style={{ height: `400px` }} />),
        mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
        componentDidMount() {
            var refs = {};
            this.setState({
                zoom: 13,
                markers: [],
                onMapMounted: (ref) => {
                    window.googleMap = ref;
                    refs.map = ref;
                },
                handleOverlayComplete(e) {
                    this.setDrawingMode(null);
                },
                onBoundsChanged: () => {
                    this.setState({
                        bounds: refs.map.getBounds(),
                        center: refs.map.getCenter(),
                    });
                },
            });
        },
    }),
    withScriptjs,
    withGoogleMap
)((props) => (
    <GoogleMap
        defaultZoom={13}
        zoom={props.zoom}
        ref={props.onMapMounted}
        onBoundsChanged={props.onBoundsChanged}
        defaultCenter={
            new google.maps.LatLng(
                props.currentPosition.lat,
                props.currentPosition.lng
            )
        }
        defaultOptions={{
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_RIGHT,
            },

            fullscreenControlOptions: {
                position: google.maps.ControlPosition.BOTTOM_RIGHT,
            },
        }}
    >
        {props.children}
    </GoogleMap>
));

export default SimpleMap;
