import React from "react";
import Table from "./Table";
import Grafico from "./Grafico";


class TabIVV extends React.Component {
    componentDidMount() { }

    filtroPorTipo = (rows, tipo) => {
        return rows.filter((index) => { if (index.tipo == tipo) { return index } });
    }

    validate = (data) => {
        return data != undefined && data != [] && data.length != 0;
    }

    render() {
        let exibirTabelas = true;
        let exibirGraficos = this.props.state.exibirGraficos;
        return (
            <>
                {this.props.state.tipos.some(option => option.label === 'Vertical') && this.validate(this.props.state.dataIVVTipologia)
                    &&
                    <>
                        {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                            tipo_detalhamento='Tipologia'
                            titulo='Indíce de Velocidade Vendas Vertical Por Tipologia'
                            rows={this.props.state.dataIVVTipologia.ivv}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            id="excel25"
                        />}
                        {exibirGraficos &&
                            <Grafico
                                tipo_detalhamento='Tipologia'
                                titulo='Indíce de Velocidade Vendas Vertical Por Tipologia'
                                rows={this.props.state.dataIVVTipologia.ivv}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                setLoading={this.setLoading}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                            />}
                    </>
                }
                {this.validate(this.props.state.dataIVVPadrao) && this.validate(this.props.state.dataIVVTipo) &&
                    <>
                        {this.props.state.tipos.some(option => option.label === 'Vertical') && <>
                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Padrão'
                                titulo='Indíce de Velocidade Vendas Vertical Por Padrão'
                                rows={this.filtroPorTipo(this.props.state.dataIVVPadrao.ivv, "Vertical")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                setLoading={this.setLoading}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                id="excel26"

                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Padrão'
                                titulo='Indíce de Velocidade Vendas Vertical Por Padrão'
                                rows={this.filtroPorTipo(this.props.state.dataIVVPadrao.ivv, "Vertical")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                setLoading={this.setLoading}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                            />}
                            {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                tipo_detalhamento='Tipo'
                                titulo='Indíce de Velocidade Vendas Vertical'
                                rows={this.filtroPorTipo(this.props.state.dataIVVTipo.ivv, "Vertical")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                setLoading={this.setLoading}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                                id="excel27"

                            />}
                            {exibirGraficos && <Grafico
                                tipo_detalhamento='Tipo'
                                titulo='Indíce de Velocidade Vendas Vertical'
                                rows={this.filtroPorTipo(this.props.state.dataIVVTipo.ivv, "Vertical")}
                                detalhesLabel={this.props.state.detalhesLabel}
                                response_period_type={this.props.state.response_period_type}
                                periodos={this.props.state.periodos}
                                setLoading={this.setLoading}
                                variacao={this.props.state.response_variacao}
                                periodos_variacao={this.props.state.periodos_variacao}
                            />}
                        </>}

                        {this.props.state.tipos.some(option => option.label === 'Horizontal') &&
                            <>
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Padrão'
                                    titulo='Indíce de Velocidade Vendas Horizontal Por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataIVVPadrao.ivv, "Horizontal")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    id="excel28"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Padrão'
                                    titulo='Indíce de Velocidade Vendas Horizontal Por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataIVVPadrao.ivv, "Horizontal")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}

                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Tipo'
                                    titulo='Indíce de Velocidade Vendas Horizontal'
                                    rows={this.filtroPorTipo(this.props.state.dataIVVTipo.ivv, "Horizontal")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    id="excel29"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Tipo'
                                    titulo='Indíce de Velocidade Vendas Horizontal'
                                    rows={this.filtroPorTipo(this.props.state.dataIVVTipo.ivv, "Horizontal")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}
                            </>
                        }

                        {this.props.state.tipos.some(option => option.label === 'Comercial') &&

                            <>
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Padrão'
                                    titulo='Indíce de Velocidade Vendas Comercial Por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataIVVPadrao.ivv, "Comercial")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    id="excel30"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Padrão'
                                    titulo='Indíce de Velocidade Vendas Comercial Por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataIVVPadrao.ivv, "Comercial")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Tipo'
                                    titulo='Indíce de Velocidade Vendas Comercial'
                                    rows={this.filtroPorTipo(this.props.state.dataIVVTipo.ivv, "Comercial")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    id="excel31"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Tipo'
                                    titulo='Indíce de Velocidade Vendas Comercial'
                                    rows={this.filtroPorTipo(this.props.state.dataIVVTipo.ivv, "Comercial")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}
                            </>
                        }
                    </>
                }
            </>
        );
    }
}

export default TabIVV;
