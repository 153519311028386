import React, {Component} from "react";
import ReactDOM from "react-dom";
import Loader from "../Loader";
import {toast, ToastContainer} from "react-toastify";


class UpdatePointConfig extends Component {

    initialCurrentEditingConfig = {
        building_type: "",
        building_status: "",
        weight: 0,
        audit_weight: 0,
        has_font_info: false,
    }

    state = {
        configs: [],
        current_editing_config: this.initialCurrentEditingConfig,
        building_status: [],
        building_types: [],
        errors: [],
        loading: false

    }

    componentDidMount = async ()=> {
        await this.setLoadding(true);
        await Promise.all([
             this.loadConfigs(),
             this.loadStatus(),
             this.loadTypes(),
        ]).finally(()=>{
            this.setLoadding(false);
        });
    }

    setLoadding = async (loading)=>{
        await this.setState({
            ...this.state,
            loading
        });
    }

    loadStatus = async ()=> {
        try {
            this.setLoadding(true);
            let response = await axios.get( route('web.buildings.status.index') );

            if( response.status === 200 ){
                this.setState({
                    ...this.state,
                    building_status: response.data
                });
            }
        }catch (e) {
            console.log(e);
        }
    }

    loadTypes = async ()=> {
        try {
            let response = await axios.get( route('web.buildings.types.index') );

            if( response.status === 200 ){
                this.setState({
                    ...this.state,
                    building_types: response.data
                });
            }
        }catch (e) {
            console.log(e);
        }
    }



     loadConfigs = async ()=> {
        try {
            let response = await axios.get( route('web.pesos_atualizacao.json.index') );

            if( response.status === 200 ){
                this.setState({
                    ...this.state,
                    configs: response.data
                });
            }
        }catch (e) {
            console.log(e);
        }
    }

    handleInputChange = async (e)=>{
        let current_editing_config = this.state.current_editing_config;
        current_editing_config[e.target.name] = e.target.value;

        await this.setState({
            ...this.state,
            current_editing_config,
        });

    }

    handleCheckBoxChange = async (e)=>{
        let current_editing_config = this.state.current_editing_config;
        current_editing_config[e.target.name] = e.target.value == "true" ? true : false;

        await this.setState({
            ...this.state,
            current_editing_config,
        });

        console.log(current_editing_config);

    }

    submit = async (e)=>{
        e.preventDefault();
        console.log(this.state.current_editing_config);

        try{
            await this.setLoadding(true)
            let response = await axios.post( route('web.pesos_atualizacao.store'), this.state.current_editing_config );

            if( response.status === 200 ){
                this.setState({
                    ...this.state,
                    current_editing_config: this.initialCurrentEditingConfig
                });

                await this.loadConfigs();

                toast.success(`Registro salvo com sucesso!`);


            }
        }catch (e){
            if( e.response && e.response.status === 422 ){
                this.setState({
                    ...this.state,
                    errors: e.response.data.errors
                });
            }

        }finally {
            await this.setLoadding(false);
        }
    }


    render(){
        return(
            <div className="container-fluid">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{width: "50%", zIndex: 9999999, fontWieght: 'bold'}}
                />
                {this.state.loading && <Loader />}
                <div className="row">
                    <div className="col-sm-8 col-lg-8 col-md-8">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Configurações Pesos de Atualização de Empreendimentos</h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Tipo do Empreendimento</th>
                                        <th>Status do Empreendimento</th>
                                        <th>Fonte de Informação</th>
                                        <th>Peso</th>
                                        <th>Peso Auditoria</th>
                                        <th>Editar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    { this.state.configs.map((config)=>{
                                        return (
                                            <tr key={config.id}>
                                                <td>{config.building_type}</td>
                                                <td>{config.building_status}</td>
                                                <td>{config.has_font_info == true ? "Sim" : "Não"}</td>
                                                <td>{config.weight_formatted}</td>
                                                <td>{config.audit_weight_formatted}</td>
                                                <td>
                                                    <button className="btn btn-sm btn-success" onClick={(e)=>{
                                                        e.preventDefault();
                                                        this.setState({
                                                            ...this.state,
                                                            current_editing_config: config
                                                        })
                                                    }}>
                                                        <i className="fa fa-edit"></i> &nbsp; Editar
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Editar/Cadastrar Peso de Atualização</h3>
                            </div>
                            <div className="card-body">
                                <form method="POST">
                                    <div className="form-group">
                                        <label htmlFor="building_status">Status do Empreendimento</label>
                                        <select name="building_status"
                                                onChange={this.handleInputChange}
                                                value={this.state.current_editing_config?.building_status} id="building_status" className="form-control">
                                            <option value="">Selecione</option>
                                            { this.state.building_status.map((status)=>{
                                                return (
                                                    <option key={status.value} value={status.value}>{status.value}</option>
                                                )
                                            })}
                                        </select>

                                        {this.state.errors['building_status'] && <div className="invalid-feedback" style={{display: "block"}}>
                                            {this.state.errors['building_status'][0]}
                                        </div>}

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor={"building_type"}>Tipo do Empreendimento</label>
                                        <select name="building_type"
                                                onChange={this.handleInputChange}
                                                value={this.state.current_editing_config?.building_type} id="building_type" className="form-control">
                                            <option value="">Selecione</option>
                                            { this.state.building_types.map((type)=>{
                                                return (
                                                    <option key={type.value} value={type.value}>{type.value}</option>
                                                )
                                            })}
                                        </select>

                                        {this.state.errors['building_type'] && <div className="invalid-feedback" style={{display: "block"}}>
                                            {this.state.errors['building_type'][0]}
                                        </div>}

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="weight">Peso</label>
                                        <input
                                            onChange={this.handleInputChange}
                                            type="number" name="weight" value={this.state.current_editing_config?.weight} id="weight" className="form-control"/>

                                        {this.state.errors['weight'] && <div className="invalid-feedback" style={{display: "block"}}>
                                            {this.state.errors['weight']}
                                        </div>}
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="audit_weight">Peso Auditoria</label>
                                        <input
                                            onChange={this.handleInputChange}
                                            type="number" name="audit_weight" value={this.state.current_editing_config?.audit_weight} id="audit_weight" className="form-control"/>

                                        {this.state.errors['audit_weight'] && <div className="invalid-feedback" style={{display: "block"}}>
                                            {this.state.errors['audit_weight']}
                                        </div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox"
                                                id="has_font_info"
                                                checked={this.state.current_editing_config?.has_font_info} 
                                                onChange={this.handleCheckBoxChange}
                                                name="has_font_info"
                                                value={!this.state.current_editing_config?.has_font_info}
                                                style={{left:"40px"}}
                                            />
                                            <label className="form-check-label" htmlFor="has_font_info">Fonte de Informação</label>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <button className="btn btn-success btn-round btn-block" onClick={this.submit}>
                                            <i className="fa fa-save"></i> &nbsp; Salvar
                                        </button>
                                    </div>


                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

if (document.getElementById('js-update-points-config')) {
    ReactDOM.render(<UpdatePointConfig />, document.getElementById('js-update-points-config'));
}
