 import React, { Component } from 'react';
import Select from 'react-select';
import Legenda from '../Legenda';
import AsyncSelect from 'react-select/async';
import Axios from 'axios';

import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';

import IntlCurrencyInput from "react-intl-currency-input"

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};


class FormFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            estados: [
                { value: 'AC', label: 'Acre' },
                { value: 'AL', label: 'Alagoas' },
                { value: 'AP', label: 'Amapá' },
                { value: 'AM', label: 'Amazonas' },
                { value: 'BA', label: 'Bahia' },
                { value: 'CE', label: 'Ceará' },
                { value: 'DF', label: 'Distrito Federal' },
                { value: 'ES', label: 'Espírito Santo' },
                { value: 'GO', label: 'Goías' },
                { value: 'MA', label: 'Maranhão' },
                { value: 'MT', label: 'Mato Grosso' },
                { value: 'MS', label: 'Mato Grosso do Sul' },
                { value: 'MG', label: 'Minas Gerais' },
                { value: 'PA', label: 'Pará' },
                { value: 'PB', label: 'Paraíba' },
                { value: 'PR', label: 'Paraná' },
                { value: 'PE', label: 'Pernambuco' },
                { value: 'PI', label: 'Piauí' },
                { value: 'RJ', label: 'Rio de Janeiro' },
                { value: 'RN', label: 'Rio Grande do Norte' },
                { value: 'RS', label: 'Rio Grande do Sul' },
                { value: 'RO', label: 'Rondônia' },
                { value: 'RR', label: 'Roraíma' },
                { value: 'SC', label: 'Santa Catarina' },
                { value: 'SP', label: 'São Paulo' },
                { value: 'SE', label: 'Sergipe' },
                { value: 'TO', label: 'Tocantins' },
            ],
            cidades: [

            ],
            address : '',
            incorporadoras: [],
            tipos : [
                { label : "Andar/Laje", value : "Andar/Laje" },
                { label : "Apartamento", value : "Apartamento" },
                { label : "Casa", value : "Casa" },
                { label : "Centro", value : "Centro" },
                { label : "Chácara", value : "Chácara" },
                { label : "Cobertura", value : "Cobertura" },
                { label : "Consultório", value : "Consultório" },
                { label : "Duplex", value : "Duplex" },
                { label : "Edifício", value : "Edifício" },
                { label : "Escritório", value : "Escritório" },
                { label : "Fazenda/Sítio/Chácara", value : "Fazenda/Sítio/Chácara" },
                { label : "Flat", value : "Flat" },
                { label : "Galeria", value : "Galeria" },
                { label : "Galpão/Depósito/Armazém", value : "Galpão/Depósito/Armazém" },
                { label : "Hotel/Motel/Pousada", value : "Hotel/Motel/Pousada" },
                { label : "Imóvel", value : "Imóvel" },
                { label : "Kitnet/Conjugado", value : "Kitnet/Conjugado" },
                { label : "Loft", value : "Loft" },
                { label : "Lote/Terreno", value : "Lote/Terreno" },
                { label : "Ponto", value : "Ponto" },
                { label : "Prédio/Edificio", value : "Prédio/Edificio" },
                { label : "Prédio/Edifício", value : "Prédio/Edifício" },
                { label : "Sala/Conjunto", value : "Sala/Conjunto" },
                { label : "Shopping", value : "Shopping" },
                { label : "Sobrado", value : "Sobrado" },
                { label : "Studio", value : "Studio" },
                { label : "Térrea", value : "Térrea" }
            ]
        }
    }


    componentDidMount = async () =>{

    }

    searchIncorporadora = async (inputQuery) => {
        if (inputQuery.length >=2  ){
            const response = await Axios.get('/incorporadoras-json?incorporadora=' + inputQuery);
            return response.data;
        }
    }

    searchCidade = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get('/cidades/json-options/' + inputQuery);
            return response.data;
        }
    }

    handleChange = address => {
        this.setState({ ...this.state, address : address });
    };

    handleSelect = address => {
        console.log( address );

        this.setState({ ...this.state, address: address });

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                console.log('Success', latLng);

                window.googleMap.panTo( latLng );


                this.props.handleCidadeChanged(latLng.lat, latLng.lng, address)
            })
            .catch(error => console.error('Error', error));
    };

    refreshIconButton = ()=>{
        return(
            <>
                <i class="fas fa-sync-alt"></i> Atualizar
            </>
        )
    }

    buscarIconButton = ()=>{
        return (
            <>
                <i class="fas fa-search"></i> Buscar
            </>
        )
    }

    handleValorMaximoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMaximoChanged( value );
    };

    handleValorMinimoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMinimoChanged(value);
    };

    handleValorMetroMaximoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMetroMaximoChanged(value);
    };

    handleValorMetroMinimoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMetroMinimoChanged(value);
    };

    handleValorParcelaMaximaChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorParcelaMaximaChanged(value);
    };

    handleValorParcelaMinimaChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorParcelaMinimaChanged(value);
    };


    render() {



        return (
            <div style={{
                overflowX: 'hidden',
                height: '90vh'
            }}>
                <div className="card filtro-mapa">
                    <div className="card-body">
                        <div className="form-group">
                            <label>Tipo de Imóvel</label><br />
                            <Select
                                placeholder="Selecione"
                                options={this.state.tipos}
                                value={this.props.tipo_imovel}
                                onChange={this.props.handleTipoImovelChanged}
                                isMulti={true}
                            />

                        </div>
                        <div className="form-group">
                            <label>Tipo de Negócio</label><br />
                            <Select
                                placeholder="Selecione"
                                options={
                                    [
                                        { label: 'Venda', value: 'Venda'},
                                        { label: 'Aluguel', value: 'Aluguel'}
                                    ]
                                }
                                value={this.props.tipo_negocio}
                                onChange={this.props.handleTipoNegocioChanged}
                            />

                        </div>
                        <div className="form-group">
                            <label>Cidade</label>
                            <AsyncSelect
                                cacheOptions
                                isMulti
                                value={this.props.cidade}
                                loadOptions={this.searchCidade}
                                onChange={this.props.handleCidadeChanged}
                            />

                        </div>
                        <div className="form-group row">
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Área Mínima</label>
                                <input type="number"
                                    name="area_minima"
                                    value={this.props.area_minima}
                                    onChange={this.props.handleAreaMinimaChanged}
                                    style={{ padding: '3px' }}
                                    className="form-control"
                                    />
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Área Máxima</label>
                                <input type="number"
                                    name="area_maxima"
                                    value={this.props.area_maxima}
                                    onChange={this.props.handleAreaMaximaChanged}
                                    style={{ padding: '3px' }}
                                    className="form-control" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Valor Mínimo</label>

                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    value={this.props.valor_maior_que}
                                    onChange={this.handleValorMinimoChange}
                                    style={{ padding: '3px' }}
                                    className="valor form-control"

                                />
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Valor Máximo</label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    onChange={this.handleValorMaximoChange}
                                    style={{ padding: '3px' }}
                                    className="valor form-control"
                                    value={this.props.valor_menor_que}

                                    />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Valor R$/m<sup>2</sup> Mínimo</label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    onChange={this.handleValorMetroMinimoChange}
                                    style={{ padding: '3px' }}
                                    className="form-control"
                                    value={this.props.valor_metro_maior_que}
                                    style={{ padding: '3px' }}
                                    className="valor form-control"

                                />
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Valor R$/m<sup>2</sup> Máximo</label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    onChange={this.handleValorMetroMaximoChange}
                                    style={{ padding: '3px' }}
                                    className="form-control"
                                    value={this.props.valor_metro_menor_que}
                                    style={{ padding: '3px' }} className="valor form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Quartos</label><br />
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input className="form-check-input"
                                        checked={this.props.quartos.includes(1)}
                                        onChange={this.props.handleQuartosChanged}
                                        type="checkbox" value="1" />
                                    <span className="form-check-sign"></span>
                                        1
                                    </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input className="form-check-input" onChange={this.props.handleQuartosChanged}
                                        checked={this.props.quartos.includes(2)}
                                        type="checkbox" value="2" />
                                    <span className="form-check-sign"></span>
                                        2
                                    </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input className="form-check-input"
                                        checked={this.props.quartos.includes(3)}
                                        onChange={this.props.handleQuartosChanged} type="checkbox" value="3" />
                                    <span className="form-check-sign"></span>
                                        3
                                    </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <label className="form-check-label">
                                    <input className="form-check-input"
                                        checked={this.props.quartos.includes(4)}
                                        onChange={this.props.handleQuartosChanged} type="checkbox" value="4" />
                                    <span className="form-check-sign"></span>
                                        4
                                    </label>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer btn-filtro-mobile" style={{position : 'sticky', 'bottom' : '14px', backgroundColor: '#FFF', padding: '5px 0', boxShadow: '0, 0, 3px, rgba(0,0,0, .2'}}>
                        <div className="row">
                            <div className="col-4 tira-margin-right btn-limpar">
                                <button className="btn btn-sm btn-block"
                                    style={{ marginTop: '15px', backgroundColor: '#FFBB27', borderColor: '#FFBB27' }}
                                    onClick={this.props.cleanState}>
                                    <i class="far fa-file"></i> Limpar
                                </button>
                            </div>
                            <div className="col-4 tira-margin-right btn-salvar">
                                <button className="btn btn-sm btn-success btn-block"
                                    style={{ marginTop: '15px' }} onClick={this.props.handleSubmit}>

                                    { (this.props.overlays.length > 0 ) ? this.refreshIconButton() : this.buscarIconButton() }
                                </button>
                            </div>
                            <div className="col-4 tira-margin-left btn-salvar-estado">
                                <button className="btn btn-sm btn-primary btn-block" style={{ marginTop: '12px' }}  onClick={this.props.saveState}>
                                    Salvar Estado
                                </button>
                            </div>
                            <hr />

                        </div>
                    </div>
                </div>

                {this.props.showLegenda && <Legenda />}

            </div>
        );
    }
}

export default FormFilters;
