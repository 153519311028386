const defaultCircleOptions = {
    fillColor: `transparent`,
    fillOpacity: 0.5,
    strokeColor: `#000000`,
    strokeWeight: 3,
    draggable: false,
    clickable: false,
    editable: true,
    zIndex: 1,
};

const defaultPolygonOptions = {
    fillColor: `transparent`,
    fillOpacity: 0.5,
    strokeColor: `#000000`,
    strokeWeight: 3,
    clickable: false,
    editable: true,
    zIndex: 1,
    draggable: true,
}

const defaultRectangleOptions = {
    fillColor: 'transparent',
    fillOpacity: 0.5,
    strokeWeight: 3,
    strokeColor: `#000000`,
    clickable: false,
    editable: true,
    zIndex: 1,
    draggable: true,
}


export { defaultCircleOptions, defaultPolygonOptions, defaultRectangleOptions };
