import React, { Component } from "react";
import SlidingPanel from "react-sliding-side-panel";
import EmpreendimentoCard from "./EmpreendimentoCard";
import ModalEmpreendimentos from "./ModalEmpreendimentos";
import InfoCard from "./InfoCard";
import Table from "./Table";
import ModalCompareArea from "./ModalCompareArea";
import ModalComparePolosGeradores from "./ModalComparePolosGeradores";
import ModalRanges from "./ModalRanges";

class SlidingPanelEmpreendimento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: props.isOpen,
            isPaneOpenLeft: false,
            selectedAreas: this.props.selectedAreas,
            panel_empreendimento_visible:
                this.props.panel_empreendimento_visible,
            estados: [
                { value: "AC", label: "Acre" },
                { value: "AL", label: "Alagoas" },
                { value: "AP", label: "Amapá" },
                { value: "AM", label: "Amazonas" },
                { value: "BA", label: "Bahia" },
                { value: "CE", label: "Ceará" },
                { value: "DF", label: "Distrito Federal" },
                { value: "ES", label: "Espírito Santo" },
                { value: "GO", label: "Goías" },
                { value: "MA", label: "Maranhão" },
                { value: "MT", label: "Mato Grosso" },
                { value: "MS", label: "Mato Grosso do Sul" },
                { value: "MG", label: "Minas Gerais" },
                { value: "PA", label: "Pará" },
                { value: "PB", label: "Paraíba" },
                { value: "PR", label: "Paraná" },
                { value: "PE", label: "Pernambuco" },
                { value: "PI", label: "Piauí" },
                { value: "RJ", label: "Rio de Janeiro" },
                { value: "RN", label: "Rio Grande do Norte" },
                { value: "RS", label: "Rio Grande do Sul" },
                { value: "RO", label: "Rondônia" },
                { value: "RR", label: "Roraíma" },
                { value: "SC", label: "Santa Catarina" },
                { value: "SP", label: "São Paulo" },
                { value: "SE", label: "Sergipe" },
                { value: "TO", label: "Tocantins" },
            ],
            cidades: [],
            modalCompareAreasOpen: false,
            modalRangesOpen: false,
            ranges: [],
            show_polos_geradores: this.props.show_polos_geradores,
            modalComparePolosOpen: false,
        };
    }

    componentDidMount() {
        //this.getEstados();
    }

    print = () => {
        var element = document.getElementById("pdf");

        axios
            .post(
                "/map-print",
                {
                    html: element.innerHTML,
                    empreendimentos: this.props.empreendimentos,
                },
                {
                    responseType: "arraybuffer",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/pdf",
                    },
                }
            )
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "relatorio.pdf"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    showCompareModal = () => {
        this.setState({
            ...this.state,
            modalCompareAreasOpen: true,
        });

        $("#btn-results").click();
    };

    showCompareModalPolos = () => {
        this.setState({
            ...this.state,
            modalComparePolosOpen: true,
        });

        $("#btn-results").click();
    };

    showRangesModal = () => {
        this.setState({
            ...this.state,
            modalRangesOpen: true,
        });

        $("#btn-results").click();
    };

    render = () => {
        let selectedAreas = this.props.overlays;
        let setPolosGeradoresCurrentShowing =
            this.props.setPolosGeradoresCurrentShowing;
        return (
            <div
                className="col-md-4"
                style={{
                    backgroundColor: "#ffffff",
                    overflow: "scroll",
                    height: 0,
                }}
            >
                <div ref={(ref) => (this.el = ref)}>
                    <SlidingPanel
                        style={{ backgroundColor: "#ffffff" }}
                        className="some-custom-class"
                        panelContainerClassName="side-panel slider-align"
                        panelClassName=""
                        isOpen={this.props.isOpen}
                        title="Informaçoes"
                        // size={25}
                        size={35}
                        noBackdrop={true}
                        type={"right"}
                    >
                        <>
                            <button
                                id="btn-results"
                                className="btn btn-sm background-brain"
                                style={{
                                    position: "absolute",
                                    float: "left",
                                    top: "48%",
                                    right: "35%",
                                    zIndex: 1500,
                                    height: 80,
                                    width: 20,
                                    borderTopLeftRadius: 5,
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: 0,
                                    borderTopRightRadius: 0,
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    $("#card-results").toggleClass("collapsed");
                                    $("#btn-results").toggleClass(
                                        "btn-results-off"
                                    );
                                    $(".side-panel").toggleClass(
                                        "side-panel-off"
                                    );
                                    $(".sliding-panel-container").toggleClass(
                                        "active"
                                    );
                                    if (
                                        $("#results-icon").hasClass(
                                            "fa-angle-double-left"
                                        )
                                    ) {
                                        $("#results-icon").removeClass(
                                            "fa-angle-double-left"
                                        );
                                        $("#results-icon").addClass(
                                            "fa-angle-double-right"
                                        );
                                    } else if (
                                        $("#results-icon").hasClass(
                                            "fa-angle-double-right"
                                        )
                                    ) {
                                        $("#results-icon").removeClass(
                                            "fa-angle-double-right"
                                        );
                                        $("#results-icon").addClass(
                                            "fa-angle-double-left"
                                        );
                                    }
                                }}
                            >
                                <i
                                    id="results-icon"
                                    className="fas fa-angle-double-right"
                                    aria-hidden="true"
                                    style={{ position: "relative", left: -5 }}
                                ></i>
                            </button>

                            <div
                                id="card-results"
                                className="card card-results"
                                style={{ marginBottom: 0 }}
                            >
                                <div
                                    className="card-header"
                                    style={{ paddingTop: 40 }}
                                ></div>
                                <div
                                    className="card-body"
                                    style={{
                                        paddingRight: 25,
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div
                                        className="container"
                                        style={{ overflowX: "visible" }}
                                    >
                                        {this.props.empreendimento !==
                                            undefined && (
                                            <>
                                                <ModalEmpreendimentos
                                                    closeModalEmpreendimento={
                                                        this.props
                                                            .closeModalEmpreendimento
                                                    }
                                                    show={
                                                        this.props
                                                            .showModalEmpreendimento
                                                    }
                                                    empreendimento={
                                                        this.props
                                                            .empreendimento
                                                    }
                                                    togglePainelEmpreendimento={
                                                        this.props
                                                            .togglePainelEmpreendimento
                                                    }
                                                    showModalEmpreendimento={
                                                        this.props
                                                            .showModalEmpreendimento
                                                    }
                                                    aria-labelledby="example-custom-modal-styling-title"
                                                ></ModalEmpreendimentos>
                                            </>
                                        )}

                                        <div id="pdf">
                                            {this.props.get_meta_data ==
                                                true &&
                                                this.props.metada_data_filled ==
                                                    false && (
                                                    <>
                                                        <h3>
                                                            Carregando Meta
                                                            Dados
                                                        </h3>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                height: "100%",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <img src="/img/loader.gif" />
                                                        </div>
                                                    </>
                                                )}
                                            {this.props.metada_data_filled ==
                                                true && (
                                                <>
                                                    <h3 class="sliding-map-title">
                                                        Áreas Selecionadas
                                                    </h3>
                                                    <hr />
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                            alignContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div className="row">
                                                            {this.props
                                                                .kml_bairros
                                                                .length > 0 && (
                                                                <div className="col-12">
                                                                    <button
                                                                        className="btn btn-sm btn-block btn-round btn-info-darkblue"
                                                                        onClick={
                                                                            this
                                                                                .props
                                                                                .kmlFunction
                                                                        }
                                                                    >
                                                                        {this
                                                                            .props
                                                                            .showKMl ===
                                                                        false
                                                                            ? "Mostrar"
                                                                            : "Esconder"}{" "}
                                                                        Bairros
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {selectedAreas.map(
                                                        (
                                                            selectedArea,
                                                            index
                                                        ) => {
                                                            let type =
                                                                selectedArea.type;

                                                            if (
                                                                type ===
                                                                "cidade"
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .cidades
                                                                        .length ==
                                                                        0 &&
                                                                    this.props
                                                                        .estados
                                                                        .length >
                                                                        0
                                                                ) {
                                                                    type =
                                                                        "Estado";
                                                                }
                                                            }
                                                            return (
                                                                <div
                                                                    key={`selected-area-${index}`}
                                                                >
                                                                    {selectedArea.count_buildings !==
                                                                        undefined && (
                                                                        <>
                                                                            <InfoCard
                                                                                key={
                                                                                    index
                                                                                }
                                                                                index={
                                                                                    index
                                                                                }
                                                                                selectedArea={
                                                                                    selectedArea
                                                                                }
                                                                                color={
                                                                                    selectedArea.color
                                                                                }
                                                                                type={
                                                                                    type
                                                                                }
                                                                                excel={
                                                                                    selectedArea.excel
                                                                                }
                                                                                pdf={
                                                                                    selectedArea.pdf
                                                                                }
                                                                                count_buildings={
                                                                                    selectedArea.count_buildings
                                                                                }
                                                                                count_buildings_venda={
                                                                                    selectedArea.count_buildings_venda
                                                                                }
                                                                                count_buildings_aluguel={
                                                                                    selectedArea.count_buildings_aluguel
                                                                                }
                                                                                info_venda={
                                                                                    selectedArea.info_venda
                                                                                }
                                                                                info_aluguel={
                                                                                    selectedArea.info_aluguel
                                                                                }
                                                                                setPolosGeradoresCurrentShowing={
                                                                                    setPolosGeradoresCurrentShowing
                                                                                }
                                                                                polos={
                                                                                    selectedArea.polos_geradores
                                                                                }
                                                                                polos_cache_key={
                                                                                    selectedArea.polos_geradores_cache_key
                                                                                }
                                                                                cidades={
                                                                                    this
                                                                                        .props
                                                                                        .cidades
                                                                                }
                                                                                estados={
                                                                                    this
                                                                                        .props
                                                                                        .estados
                                                                                }
                                                                            />

                                                                            <Table
                                                                                totalEmpreendimentos={
                                                                                    selectedArea.info_venda
                                                                                }
                                                                                count_buildings={
                                                                                    selectedArea.count_buildings_venda
                                                                                }
                                                                                tipos={
                                                                                    selectedArea.info_venda
                                                                                }
                                                                                color={
                                                                                    selectedArea.color
                                                                                }
                                                                                title={
                                                                                    "Revenda"
                                                                                }
                                                                            />

                                                                            <Table
                                                                                totalEmpreendimentos={
                                                                                    selectedArea.info_venda
                                                                                }
                                                                                count_buildings={
                                                                                    selectedArea.count_buildings_aluguel
                                                                                }
                                                                                tipos={
                                                                                    selectedArea.info_aluguel
                                                                                }
                                                                                color={
                                                                                    selectedArea.color
                                                                                }
                                                                                title={
                                                                                    "Aluguel"
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </>
                    </SlidingPanel>
                </div>
            </div>
        );
    };
}

export default SlidingPanelEmpreendimento;
