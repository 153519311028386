import Carousel from "react-bootstrap/Carousel";
import React from "react";

class ImageSlider extends React.Component {
    state = {
        images: [],
    };

    componentDidMount = () => {
        this.setState({
            images: this.props.images,
        });
    };

    render() {
        return (
            <div>
                <Carousel>
                    {this.state.images.map((index, key) => {
                        return (
                            <Carousel.Item key={`slider-image-${key}`}>
                                <img
                                    className="d-block w-100"
                                    src={index["url"]}
                                    alt="slider image"
                                    style={{
                                        width: "100%",
                                        height: "400px",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "100% 100%",
                                    }}
                                />
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
                <hr />
            </div>
        );
    }
}

export default ImageSlider;
