import React  from "react";

const ClientCard = ({ client, styles }) => {
    return (
        <div className="card" style={styles.card}>
            <div className="card-header" style={styles.card_header}>
                <h6 className="card-title" style={styles.card_title}>Cliente</h6>
            </div>
            <div className="card-body">
               <table className="table table-bordered table-striped">
                     <tbody>
                            <tr>
                                <th>Nome</th>
                                <th>{client?.name}</th>
                            </tr>
                            <tr>
                                <th>E-mail</th>
                                <th>
                                    <i className="fa fa-envelope"></i> &nbsp;
                                    {client?.email}
                                </th>
                            </tr>
                            <tr>
                                <th>Empresa</th>
                                <th>{client?.empresa?.name}</th>
                            </tr>
                     </tbody>

               </table>
                {/*<h5 className="card-title">{client?.name} - {client?.empresa?.name}</h5>
                <p className="mb-2 text-muted">{client?.email}</p>
                <p className="card-text"></p>*/}
            </div>
        </div>
    );
}

export default ClientCard;
