import React from "react";
import Table from "./Table";
import Grafico from "./Grafico";


class TabVendasIncorporadora extends React.Component {
    componentDidMount() { }

    filtroPorTipo = (rows, tipo) => {
        return rows.filter((index) => { if (index.tipo == tipo) { return index } });
    }
    validate = (data) => {
        return data != undefined && data != [] && data.length != 0;
    }
    render() {
        let exibirTabelas = true;
        let exibirGraficos = this.props.state.exibirGraficos;
        return (
            <>
                {this.validate(this.props.state.dataVendasIncorporadora)
                    && this.validate(this.props.state.dataVendasTipo) &&
                    <>
                        {
                            this.props.state.tipos.some(option => option.label === 'Vertical') &&
                            <>
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Incorporadora'
                                    titulo='Unidades Vendidas Líquidas Vertical Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.unidadesVendidas, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataVendasTipo.unidadesVendidas, "Vertical")}
                                    id="excel46"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Incorporadora'
                                    titulo='Unidades Vendidas Líquidas Vertical Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.unidadesVendidas, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo='Vertical'
                                    tipo_detalhamento='Incorporadora'
                                    titulo='VGV Unidades Vendidas Líquidas Vertical Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.vgv, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataVendasTipo.vgv, "Vertical")}
                                    id="excel47"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo='Vertical'
                                    tipo_detalhamento='Incorporadora'
                                    titulo='VGV Unidades Vendidas Líquidas Vertical Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.vgv, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}
                            </>
                        }
                        {this.props.state.tipos.some(option => option.label === 'Horizontal') &&

                            <>
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo='Horizontal'
                                    tipo_detalhamento='Incorporadora'
                                    titulo='Unidades Vendidas Líquidas Horizontal Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.unidadesVendidas, "Horizontal")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataVendasTipo.unidadesVendidas, "Horizontal")}
                                    id="excel48"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo='Horizontal'
                                    tipo_detalhamento='Incorporadora'
                                    titulo='Unidades Vendidas Líquidas Horizontal Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.unidadesVendidas, "Horizontal")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo='Horizontal'
                                    tipo_detalhamento='Incorporadora'
                                    titulo='VGV Unidades Vendidas Líquidas Horizontal Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.vgv, "Horizontal")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataVendasTipo.vgv, "Horizontal")}
                                    id="excel49"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo='Horizontal'
                                    tipo_detalhamento='Incorporadora'
                                    titulo='VGV Unidades Vendidas Líquidas Horizontal Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.vgv, "Horizontal")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}
                            </>
                        }
                        {this.props.state.tipos.some(option => option.label === 'Comercial') &&
                            <>
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo='Comercial'
                                    tipo_detalhamento='Incorporadora'
                                    titulo='Unidades Vendidas Líquidas Comercial Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.unidadesVendidas, "Comercial")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataVendasTipo.unidadesVendidas, "Comercial")}

                                    id="excel50"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo='Comercial'
                                    tipo_detalhamento='Incorporadora'
                                    titulo='Unidades Vendidas Líquidas Comercial Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.unidadesVendidas, "Comercial")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo='Comercial'
                                    tipo_detalhamento='Incorporadora'
                                    titulo='VGV Unidades Vendidas Líquidas Comercial Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.vgv, "Comercial")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataVendasTipo.vgv, "Comercial")}

                                    id="excel51"
                                />}
                                {exibirGraficos && <Grafico
                                    tipo='Comercial'
                                    tipo_detalhamento='Incorporadora'
                                    titulo='VGV Unidades Vendidas Líquidas Comercial Por Incorporadora'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasIncorporadora.vgv, "Comercial")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}
                            </>
                        }
                    </>
                }
            </>
        );
    }
}

export default TabVendasIncorporadora;
