import React, { Component } from "react";
import SlidingPanel from "react-sliding-side-panel";
import InfoCard from "./InfoCard";
import Table from "./Table";

class SlidingPanelEmpreendimento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: props.isOpen,
            isPaneOpenLeft: false,
            selectedAreas: this.props.selectedAreas,
            panel_empreendimento_visible:
                this.props.panel_empreendimento_visible,
            estados: [
                { value: "AC", label: "Acre" },
                { value: "AL", label: "Alagoas" },
                { value: "AP", label: "Amapá" },
                { value: "AM", label: "Amazonas" },
                { value: "BA", label: "Bahia" },
                { value: "CE", label: "Ceará" },
                { value: "DF", label: "Distrito Federal" },
                { value: "ES", label: "Espírito Santo" },
                { value: "GO", label: "Goías" },
                { value: "MA", label: "Maranhão" },
                { value: "MT", label: "Mato Grosso" },
                { value: "MS", label: "Mato Grosso do Sul" },
                { value: "MG", label: "Minas Gerais" },
                { value: "PA", label: "Pará" },
                { value: "PB", label: "Paraíba" },
                { value: "PR", label: "Paraná" },
                { value: "PE", label: "Pernambuco" },
                { value: "PI", label: "Piauí" },
                { value: "RJ", label: "Rio de Janeiro" },
                { value: "RN", label: "Rio Grande do Norte" },
                { value: "RS", label: "Rio Grande do Sul" },
                { value: "RO", label: "Rondônia" },
                { value: "RR", label: "Roraíma" },
                { value: "SC", label: "Santa Catarina" },
                { value: "SP", label: "São Paulo" },
                { value: "SE", label: "Sergipe" },
                { value: "TO", label: "Tocantins" },
            ],
            cidades: [],
            ranges: [],
        };
    }

    componentDidMount() { }

    slidingPanel = () => {
            $("#card-results").toggleClass("collapsed");
            $("#btn-results").toggleClass("btn-results-off");
            $(".side-panel").toggleClass("side-panel-off");
            $(".sliding-panel-container").toggleClass("active");
            
            if ($("#results-icon").hasClass("fa-angle-double-left")) {
                $("#results-icon")
                .removeClass("fa-angle-double-left")
                .addClass("fa-angle-double-right");
            } else if ($("#results-icon").hasClass("fa-angle-double-right")) 
            {
                $("#results-icon")
                .removeClass("fa-angle-double-right")
                .addClass("fa-angle-double-left");
            };
    }

    render = () => {
        let selectedAreas = this.props.state.overlays;
        return (
            <div
                className="col-md-4"
                style={{
                    backgroundColor: "#ffffff",
                    overflow: "scroll",
                    height: 0,
                }}
            >
                <div ref={(ref) => (this.el = ref)}>
                    <SlidingPanel
                        style={{ backgroundColor: "#ffffff" }}
                        className="some-custom-class"
                        panelContainerClassName="side-panel"
                        panelClassName=""
                        isOpen={this.props.state.isSlideOpen}
                        title="Informaçoes"
                        size={35}
                        noBackdrop={true}
                        type={"right"}
                    >
                        <>
                            <button
                                id="btn-results"
                                className="btn btn-sm btn-success"
                                style={{
                                    position: "absolute",
                                    float: "left",
                                    top: "48%",
                                    right: "35%",
                                    zIndex: 1500,
                                    height: 80,
                                    width: 20,
                                    borderTopLeftRadius: 5,
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: 0,
                                    borderTopRightRadius: 0,
                                }}
                                onClick={(e) => this.slidingPanel()}
                            >
                                <i id="results-icon"
                                    className="fas fa-angle-double-right"
                                    aria-hidden="true"
                                    style={{ position: "relative", left: -5 }}
                                ></i>
                            </button>

                            <div
                                id="card-results"
                                className="card card-results"
                                style={{ marginBottom: 0 }}
                            >
                                <div
                                    className="card-header"
                                    style={{ paddingTop: 40 }}
                                ></div>
                                <div
                                    className="card-body"
                                    style={{
                                        paddingRight: 25,
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div
                                        className="container"
                                        style={{ overflowX: "visible" }}
                                    >
                                        <div id="pdf">
                                            <h3>Áreas Selecionadas</h3>
                                            <hr />

                                            <div
                                                className="container-fluid"
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                }}
                                            ></div>
                                            {selectedAreas.map(
                                                (selectedArea, index) => {
                                                    return (
                                                        <div key={`selected-area-${index}`}>
                                                            {this.props.state
                                                                .users.length >
                                                                0 && (
                                                                    <>
                                                                        <InfoCard
                                                                            state={this.props.state}
                                                                            selectedArea={selectedArea}
                                                                            color={selectedArea.color}
                                                                            type={selectedArea.type}
                                                                        />

                                                                        <Table
                                                                        selectedArea={selectedArea}
                                                                            state={this.props.state}
                                                                            color={selectedArea.color}
                                                                            title={"Imóveis de interesse"}
                                                                        />
                                                                    </>
                                                                )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </>
                    </SlidingPanel>
                </div>
            </div>
        );
    };
}

export default SlidingPanelEmpreendimento;
