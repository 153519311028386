import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Loader from "../Loader";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt_BR from 'date-fns/locale/pt-BR';
import AsyncSelect from 'react-select/async';
import Select from "react-select";

registerLocale('pt-BR', pt_BR);


export default class TableAuditLotsIndex extends Component {

    constructor(props) {
        super();

        this.state = {
            audit_lots: [],
            loading: false,
            pagination: {
                total: 0,
                per_page: 20,
                current_page: 1,
                last_page: 1,
                from: 1,
                to: 0
            },
            filters: {
                cidade: '',
                data_inicial: '',
                data_final: '',
                separado_por: null
            }
        }
    }


    /**
     * Faz busca de cidades enquanto o usuário digita algo no campo de busca
     * @param inputQuery
     * @returns {Promise<any>}
     */
    searchCidade = async (inputQuery) => {

        if (inputQuery.length >= 2) {
            const response = await axios.get(route('web.util.city.autocompleteWithIdsAsValues', {
                search: inputQuery
            }));

            return response.data;

        }
    }


    loadData = async () => {
        try {
            await this.setLoading(true);

            //filter
            let urlParams = {
                page: this.state.pagination.current_page,
            }

            if (this.state.filters.cidade !== '' && this.state.filters.cidade !== null) {

                urlParams.cidade = this.state.filters.cidade.value;
            }

            if (this.state.filters.data_inicial !== '') {

                urlParams.data_inicial = new Date(this.state.filters.data_inicial).toLocaleDateString('pt-BR');
            }

            if (this.state.filters.data_final !== '') {
                urlParams.data_final = new Date(this.state.filters.data_final).toLocaleDateString('pt-BR');
            }

            if( this.state.filters.separado_por?.value ){
                urlParams.separado_por = this.state.filters.separado_por.value;
            }

            console.log( urlParams );


            let response = await axios.get(route('web.auditoria_tabelas.json.index', urlParams));

            if (response.status === 200) {

                this.setState({
                    ...this.state,
                    audit_lots: response.data.data,
                    pagination: {
                        total: response.data.total,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        last_page: response.data.last_page,
                        from: response.data.from,
                        to: response.data.to
                    }, filters: {
                        ...this.state.filters,
                    }
                });
            }
        } catch (e) {
            if (e.response && e.response.status === 422) {
                this.setState({errors: e.response.data.errors});

            }
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }

    loadAuthUser = async () => {
        try {
            let response = await axios.get(route('user.auth'));
            if (response.status === 200) {
                this.setState({
                    ...this.state,
                    user: response.data
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Carrega os operadores para montar o campo para filtrar por operador
     * @returns {Promise<void>}
     */
    loadOperators = async ( name ) => {
        try {
            if( name.length >= 2 ){
                let response = await axios.get(route('web.auditoria_tabelas.users', {
                    search: name
                }));


                return response.data;
            }

        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount = async () => {

        try {
            await Promise.all([
                this.loadData(),
                this.loadAuthUser(),
            ])
        } catch (e) {
            console.log(e);
        }
    }

    alterPage = async (page) => {
        await this.setState({
            ...this.state,
            pagination: {
                ...this.state.pagination,
                current_page: page
            }
        });

        await this.loadData();
    }


    render() {


        return (<>
            {this.state.loading && <Loader/>}
            <div className="card">
                <div className="card-header">

                    <form action="">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="status">Data Inicial</label>
                                    <DatePicker
                                        locale="pt-BR"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.filters.data_inicial}
                                        onChange={async (e) => {
                                            await this.setState({
                                                ...this.state,
                                                filters: {
                                                    ...this.state.filters,
                                                    data_inicial: e
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="status">Data Final</label>
                                    <DatePicker
                                        locale="pt-BR"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.filters.data_final}
                                        onChange={async (e) => {
                                            await this.setState({
                                                ...this.state,
                                                filters: {
                                                    ...this.state.filters,
                                                    data_final: e
                                                }
                                            });
                                        }} //only when value has changed
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="">Cidade</label>
                                <AsyncSelect
                                    cacheOptions
                                    isClearable
                                    noOptionsMessage={() => 'Nenhum Resultado'}
                                    placeholder="Digite para buscar..."
                                    value={this.state.filters.cidade}
                                    loadOptions={this.searchCidade}
                                    onChange={async (e) => {
                                        await this.setState({
                                            ...this.state,
                                            filters: {
                                                ...this.state.filters,
                                                cidade: e
                                            }
                                        });
                                    }}
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="status">Separado Por</label>
                                <AsyncSelect
                                    name="separado_por"
                                    isClearable
                                    loadOptions={this.loadOperators}
                                    className="basic-multi-select"
                                    value={this.state.filters.separado_por}
                                    classNamePrefix="select"
                                    placeholder="Selecione"
                                    onChange={async (e) => {
                                        await this.setState({
                                            ...this.state,
                                            filters: {
                                                ...this.state.filters,
                                                separado_por: e
                                            }
                                        });
                                    }}></AsyncSelect>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-6">
                                <button className={"btn btn-primary btn-sm btn-block btn-round"} onClick={async () => {
                                    await this.setState({
                                        ...this.state,
                                        pagination: {
                                            ...this.pagination,
                                            current_page: 1
                                        }
                                    });
                                    this.loadData();
                                }} type="button">Filtrar
                                </button>
                            </div>
                            <div className="col-6">

                            </div>
                        </div>
                    </form>


                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-6">
                            <h4>Lista de Auditorias</h4>
                        </div>
                        <div className="col-6">
                            <a href={route('web.auditoria_tabelas.create')} className="btn btn-success float-right">
                                <i className={"fa fa-plus"}></i> &nbsp;
                                Criar Nova Auditoria
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '25px'}}>
                        <div className="col-12">
                            <table className="table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Data Inicial</th>
                                    <th>Data Final</th>
                                    <th>Total Separado</th>
                                    <th>Separado Por</th>
                                    <th>Data de Criação</th>
                                    <th className="pull-right">Ações</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.audit_lots.map((lot, index) => {
                                    const dataCriacao = new Date(lot.created_at).toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'});


                                    return (
                                        <tr key={index}>
                                            <td>{lot.id}</td>
                                            <td>{lot.start_date_formatted}</td>
                                            <td>{lot.end_date_formatted}</td>
                                            <td>{lot.bases_count}</td>
                                            <td>{lot.created_by?.name}</td>
                                            <td>{dataCriacao}</td>
                                            <td className="pull-right">
                                                <a href={route('web.auditoria_tabelas.bases', lot.id)} className={'btn btn-link'}>
                                                    <i className="fa fa-eye"></i>&nbsp;
                                                    Bases
                                                </a>
                                            </td>
                                        </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">

                        <div className="col-12">
                            <nav aria-label="navigation" style={{float: "right"}}>
                                <ul className="pagination">
                                    {this.state.pagination.current_page > 1 &&
                                        <li className="page-item">
                                            <a className="page-link" href="#">Anterior</a>
                                        </li>
                                    }

                                    {this.state.pagination.last_page > 1 && [...Array(this.state.pagination.last_page)].map((page, index) => {
                                        let pageNumber = index + 1;
                                        return (

                                            <li className={(pageNumber === this.state.pagination.current_page) ? 'page-item active' : 'page-item '}
                                                key={index}>
                                                <a
                                                    className={`page-link`}
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.alterPage(pageNumber);
                                                    }}>
                                                    {pageNumber}
                                                </a>
                                            </li>
                                        )
                                    })}

                                    {this.state.pagination.current_page < this.state.pagination.last_page &&
                                        <li className="page-item">
                                            <a className="page-link" href="#" onClick={(e) => {
                                                e.preventDefault();
                                                this.alterPage(this.state.pagination.current_page + 1);
                                            }}>
                                                Próximo
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}


if (document.getElementById('js-list-audit-lots')) {
    ReactDOM.render(<TableAuditLotsIndex/>, document.getElementById('js-list-audit-lots'));
}
