import React from 'react';
import {InputNumber} from "primereact/inputnumber";
import {Dialog} from "primereact/dialog";
import {ColorPicker} from "primereact/colorpicker";

const currencyConfig = {
    locale: 'pt-BR',
    formats: {
        number: {
            BRL: {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
}

export default class ModalLegendaRendaIbge extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            currentEditing: {
                min: 0,
                max: 0,
                color: '#000000'
            },
            index: -1,
            error: null,
            colorRanges: [],
            color: 'fff0000'
        };
    }

    timeout = null;

    componentDidMount() {
        console.log(this.props.colorRanges);
    }

    validateOverlap = ()=>{
        let ranges = this.props.colorRanges;

        let error = null;

        for( let i = 0; i < ranges.length; i++ ){
            let range = ranges[i];

            for( let j = 0; j < ranges.length; j++ ) {
                let range2 = ranges[j];

                if (i !== j) {
                    if (range.min <= range2.max && range.max >= range2.min) {
                        error = 'Os valores mínimos e máximos não podem se sobrepor.';
                    }
                }
            }

        }

        this.setState({
            ...this.state,
            error: error
        });
    }

    updateRange = async ()=>{

        let index = this.state.index;

        if( index === -1 ){
            index = this.props.colorRanges.length;
        }

        //validate min and max
        if( this.state.currentEditing.min >= this.state.currentEditing?.max ){
            alert('O valor mínimo deve ser menor que o valor máximo.');
            return;
        }

        await this.validateOverlap();

        this.props.updateRange(this.state.currentEditing, index );

        let currentEditing = null;
        index = -1;
        if ( this.state.error ){
            currentEditing = this.state.currentEditing;
            index = this.state.index;
        }

        this.setState({
            ...this.state,
            currentEditing: currentEditing,
            index: index,
        });
    }

    addRange = ()=>{
        this.setState({
            ...this.state,
            currentEditing: {
                min: 0,
                max: 0,
                color: '#000000'
            },
            index: -1,
        });
    }

    handleColorChange = async ( e )=>{

        this.setState({
            ...this.state,
            currentEditing: {
                ...this.state.currentEditing,
                color: `#${e.value}`,
                rawColor: e.value
            }
        });
    }


    render() {

        return (<>
            <Dialog
                header="Editar Legenda - Dados de Renda"
                visible={this.props.show}
                style={{width: '50vw', marginTop: '50px'}}
                onHide={this.props.handleClose}
                blockScroll={true}
            >

                <div className="card" id="c-form-edit-ranges-renda-ibge">
                    <div className="card-body">
                        {this.state.error && <div className="alert alert-danger">{this.state.error}</div>}

                        <div id="container-form-ranges-renda-ibge">


                            <div style={{width: '30%'}}>
                                <label htmlFor="min">Mínimo</label>
                                <InputNumber id="min" name="min"
                                             className="d-block"
                                             value={this.state.currentEditing?.min}
                                             inputStyle={{width: '99%'}}
                                             locale="pt-BR" minFractionDigits={2}
                                             onChange={(e) => this.setState({

                                                 currentEditing: {
                                                     ...this.state.currentEditing,
                                                     min: e.value
                                                 }
                                             })}/>
                            </div>

                            <div style={{width: '30%'}}>
                                <label htmlFor="min">Máximo</label>
                                <InputNumber id="max" name="max"
                                             className="d-block"
                                             inputStyle={{width: '99%'}}
                                             locale="pt-BR" minFractionDigits={2}
                                             value={this.state.currentEditing?.max}
                                             onChange={(e) => this.setState({
                                                 currentEditing: {
                                                     ...this.state.currentEditing,
                                                     max: e.value
                                                 }
                                             })}/>
                            </div>
                            <div style={{width: '15%'}}>
                                <label htmlFor="color">Cor</label>
                                <ColorPicker className={'d-block'}
                                                defaultColor={this.state.currentEditing?.color}
                                                onChange={this.handleColorChange}/>
                            </div>
                            <div style={{width: '25%'}}>
                                <label htmlFor="color">&nbsp;</label>
                                <button className="btn btn-default btn-sm btn-block" onClick={this.updateRange}>
                                    Salvar
                                    <i className="fa fa-save"/>
                                </button>

                            </div>
                        </div>

                        <hr/>
                        <div style={{overflowY: "auto", height: '45vh'}}>
                            <div className="float-left">
                                <button className="btn btn-success btn-sm btn-block" onClick={this.addRange}>
                                    Adicionar
                                    <i className="fa fa-plus"/>
                                </button>
                            </div>
                            <table className="table table-striped table-condensed">
                                <thead>
                                <tr>
                                    <th scope="col" colSpan="2" style={{textAlign: 'center'}}>
                                <span className="font-weight-bold text-center"
                                      style={{color: 'rgb(0, 72, 14)', fontSize: 16}}>
                                            Renda Per Capita - Setores Censitários
                                </span>
                                    </th>
                                    <th className="text-right">Ações</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.props.colorRanges?.map((item, index) => (<tr key={index} style={styles.legend}>
                                    <td style={{...styles.legend, backgroundColor: hexToRGB(item.color, '0.5')}}>
                                        <div style={{width: '13px', height: '13px'}}></div>
                                    </td>
                                    <td style={styles.legend}>
                                        {item.max !== Infinity && <>
                                            {Number(item.min).toLocaleString('pt-BR', {
                                                currency: 'BRL',
                                                style: 'currency',
                                                minimumFractionDigits: 0
                                            })}
                                            &nbsp;- {Number(item.max).toLocaleString('pt-BR', {
                                            currency: 'BRL',
                                            style: 'currency',
                                            minimumFractionDigits: 0
                                        })}
                                        </>}
                                        {item.max === Infinity && <>
                                            Acima de {Number(item.min).toLocaleString('pt-BR', {
                                            currency: 'BRL',
                                            style: 'currency',
                                            minimumFractionDigits: 0
                                        })}
                                        </>}
                                    </td>
                                    <td className="text-right">
                                        <button className="btn btn-default btn-sm btn-success" onClick={async (e) => {
                                            e.preventDefault();

                                            this.setState({
                                                ...this.state,
                                                currentEditing: item,
                                                index: index
                                            });
                                        }}>
                                            <i className="fa fa-edit"/>
                                        </button>
                                        &nbsp;
                                        <button className="btn btn-default btn-sm btn-danger" onClick={()=>{
                                            this.props.removeRange(index);
                                            this.validateOverlap();
                                        }}>
                                            <i className="fa fa-trash"/>
                                        </button>
                                    </td>
                                </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <button className="btn btn-default btn-sm btn-block" disabled={this.state.loading || this.state.error}
                            onClick={()=>{
                                this.props.handleAplyColorRanges();
                                this.props.handleClose();
                            }}>
                        {this.state.loading && <i className="fa fa-spin fa-spinner"/>}
                        Aplicar
                    </button>
                </div>

            </Dialog>
        </>);
    }
}
const styles = {
    legend: {
        padding: '5px',
        margin: '4px',
    }
}
