import {Component, React} from 'react';
import {v4 as uuidv4} from 'uuid';

export default class FormAdicionarVenda extends Component {

    constructor() {
        super();
        this.state = {
            mes: '',
            uuid: '',
            tipologia: '',
            venda_uuid: uuidv4(),
            numero_vendas: 0,
            estoque: 0,

        }
    }

    onValueChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
            <>
                <div className="form-group">
                    <label htmlFor="mes">Unidade</label>
                    <select className="form-control select-height" id="unidade"
                            style={{height: '38px!important'}}
                            value={this.state.uuid}
                            onChange={(e) => {
                                let unidade = this.props.unidades.find(unidade => unidade.uuid === e.target.value);
                                this.setState({
                                    uuid: e.target.value,
                                    tipologia: unidade.nome,
                                    oferta_inicial: unidade.quantidade,
                                })
                            }}
                            name="unidade">
                        <option value="">Selecione uma unidade</option>
                        {this.props.unidades.map((unidade, index) => {
                            return (
                                <option key={index} value={unidade.uuid}>{unidade.nome}</option>
                            );
                        })}
                    </select>

                    <label htmlFor="mes">Mês</label>
                    <input type="number" className="form-control" id="mes"
                           name="mes"
                           onChange={this.onValueChange}
                           value={this.state.mes}/>

                    <label htmlFor="mes">Quantidade</label>
                    <input type="number"
                           onChange={this.onValueChange}
                           className="form-control" id="quantidade"
                           name="numero_vendas"
                           value={this.state.numero_vendas}/>

                </div>

                <div className="form-group">
                    <button className="btn btn-success btn-block"
                            onClick={(e) => {

                                if (this.state.uuid === '') {
                                    alert('Selecione uma unidade');
                                    return;
                                }

                                if (this.state.mes === '') {
                                    alert('Informe o mês');
                                    return;
                                }

                                if (this.state.numero_vendas === '') {
                                    alert('Informe a quantidade de vendas');
                                    return;
                                }

                                this.props.addVenda({
                                    ...this.state,
                                    mes: parseInt(this.state.mes),
                                    numero_vendas: parseInt(this.state.numero_vendas),
                                });

                            }}
                    >
                        <i className="fa fa-plus"></i>
                        &nbsp;
                        Adicionar mês
                    </button>
                </div>
            </>
        );
    }
}
