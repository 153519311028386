import React from "react";
import Loader from "./Loader";
import ReactDOM from "react-dom";
import ClientCard from "./ClientCard";
import {ToastContainer, toast} from 'react-toastify';
import ModalFinishTicket from "./ModalFinishTicket";
import ModalAssignTicket from "./ModalAssignTicket";
import EstoqueValoresTable from "./TicketsSupport/EstoqueValoresTable";
import SimpleMap from "./SimpleMap/SimpleMap";
import {Marker} from "react-google-maps";

class TicketSuport extends React.Component {


    constructor(props) {
        super();

        this.state = {
            ticket: '', mensagem: '', loading: false, user: '', errors: [], show_modal_finish: false, show_modal_assign: false
        }
    }

    setLoading = async (loading) => {
        await this.setState({
            ...this.state, loading: loading
        });
    }

    handleFinished = async () => {
        await this.setState({
            ...this.state, show_modal_finish: false
        });

        toast('Ticket Finalizado com sucesso!');

        this.loadTicket();
    }

    handleAssigned = async () => {
        await this.setState({
            ...this.state, show_modal_assign: false
        });

        toast('Ticket atribuido com sucesso!');

        this.loadTicket();
    }

    handleCloseModalAssign = async () => {
        await this.setState({
            ...this.state, show_modal_assign: false
        });
    }

    updateBuildinAddress = async () => {

        try {
            await this.setLoading(true);
            let address = this.state.ticket.building_address;

            let responseUpdateAddress = await axios.put(route('web.building.updateAddress', this.state.ticket.building_id), {
                address: address
            });

            let mensagem = 'A localização informada foi adicionada ao empreendimento.\n' +
                '\n' +
                '\n' +
                '\n' +
                'Nós agradecemos a parceria e ficamos à disposição.\n';

            if (responseUpdateAddress.status === 200) {

                let response = await axios.post(route('ticket.finish', this.state.ticket.id), {
                    mensagem: mensagem
                });

                if (response.status === 200) {
                    this.handleFinished();

                    this.loadTicket();
                    toast.success('Endereço atualizado com sucesso!');
                }
            }

        } catch (e) {
            if (e.response && e.response.status === 422) {
                this.setState({errors: e.response.data.errors});
            }
            console.log(e);
        } finally {
            await this.setLoading(false);
        }

    }

    sendNewMessage = async () => {

        try {
            this.setLoading(true);
            let response = await axios.post(route('ticket.contact', this.state.ticket.id), {
                mensagem: this.state.mensagem
            });

            if (response.status === 201) {
                this.setState({
                    ...this.state, mensagem: '', errors: [], ticket: {
                        ...this.state.ticket, contacts: response.data
                    }

                });

                toast.success('Mensagem enviada com sucesso!');
            }

        } catch (error) {
            if (error.response && error.response.status == 422) {
                this.setState({
                    ...this.state, errors: error.response.data.errors
                });
            }

            console.log(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadTicket = async () => {
        let urlParams = new URL(window.location);
        let parts = urlParams.pathname.split('/');
        let id = parts[parts.length - 1];

        try {
            await this.setLoading(true);
            let response = await axios.get(route('ticket.show.json', id));
            this.setState({ticket: response.data.ticket, user: response.data.user});
        } catch (e) {
            if (e.response && e.response.status === 404) {
                alert('Ticket não encontrado');
                window.location = route('ticket.index');
            }
        } finally {
            await this.setLoading(false);
        }
    }

    capturarTicket = async () => {

        try {

            await this.setLoading(true);
            let response = await axios.post(route('ticket.capture', this.state.ticket.id));
            this.loadTicket();

        } catch (e) {
            if (e.response && e.response.status === 422) {
                this.setState({
                    ...this.state, errors: e.response.data.errors
                });
            }
        } finally {
            await this.setLoading(false);
        }
    }

    componentDidMount = async () => {
        await this.loadTicket();
    }

    render() {
        let dataAbertura = '';
        if (this.state.ticket.created_at) {
            dataAbertura = new Date(this.state.ticket.created_at).toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'});
        }

        let dataFinalizacao = '';
        if (this.state.ticket.finished_at) {
            dataFinalizacao = new Date(this.state.ticket.finished_at).toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'});
        }

        let statusBadge = '';

        if (this.state.ticket?.status === 'Aberto') {
            statusBadge = <span className="badge badge-success">Aberto</span>;
        }

        if (this.state.ticket?.status === 'Finalizado') {
            statusBadge = <span className="badge badge-danger">Finalizado</span>;
        }

        if (this.state.ticket?.status === 'Em Atendimento') {
            statusBadge = <span className="badge badge-warning">Em Atendimento</span>;
        }

        let canSeeBuildingLink = this.state.user?.can_gerenciar_tickets == true;
        let canAttribute = this.state.user?.can_gerenciar_tickets == true;

        let buildingLink = <p>{this.state.building?.name}</p>;

        if (canSeeBuildingLink && this.state.ticket?.building) {
            buildingLink = <><br/><a href={route('building.show', this.state.ticket.building.id)}
                                     className="btn btn-sm btn-link" target="_blank">
                <i className={'fa fa-link'}/> &nbsp;
                {this.state.ticket.building?.name}
            </a></>
        }

        let canSendMessage = this.state.ticket?.current_responsible_id == this.state.user?.id || this.state.user?.id === this.state.ticket?.client_id;

        let columnAddressLabels = {
            "bairro": "Bairro",
            "cep": "CEP",
            "cidade": "Cidade",
            "complemento": "Complemento",
            "estado": "Estado",
            "endereco": "Endereço",
            "numero": "Número",
            "latitude": "Latitude",
            "longitude": "Longitude",
        }

        return (

            <>

                <ModalFinishTicket
                    handleFinished={this.handleFinished}
                    show={this.state.show_modal_finish} ticket={this.state.ticket} user={this.state.user}
                    handleCloseModal={() => {
                        this.setState({
                            ...this.state, show_modal_finish: false
                        })
                    }}/>

                <ModalAssignTicket
                    show={this.state.show_modal_assign}
                    ticket={this.state.ticket}
                    user={this.state.user}
                    handleAssigned={this.handleAssigned}
                    handleCloseModal={this.handleCloseModalAssign} />

                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{width: "50%", zIndex: 9999999, fontWieght: 'bold'}}
                />
                {this.state.loading && <Loader/>}


                <div className="row" style={{marginBottom: '20px'}}>
                    <div className="col-12">
                        <div className="pull-right">
                            <a href={route('tickets.index')} className="btn btn-sm btn-success">
                                Listagem de Tickets
                            </a>
                        </div>
                    </div>
                </div>

                <ClientCard client={this.state.ticket.client} styles={styles}/>

                <div className="row">
                    <div className="col-8">
                        <div className="card" style={styles.card}>
                            <div className="card-header" style={styles.card_header}>
                                <h6 className={'card-title'} style={styles.card_title}>
                                    Assunto: {this.state.ticket?.subject}
                                </h6>
                            </div>
                            <div className="card-body">
                                {this.state.ticket.observation && <div className={'form-group'}>
                                    <label htmlFor="exampleFormControlTextarea1">Observação</label>
                                    <p className={'form-control-static'}>
                                        {this.state.ticket?.observation}
                                    </p>
                                </div>}

                                {this.state.ticket.observation && this.state.ticket.observation === 'Estoque e/ou valor incorretos'
                                    && <EstoqueValoresTable tipologias={this.state.ticket.tipologias}/>
                                }

                                <div className={'form-group'}>
                                    <label htmlFor="exampleFormControlTextarea1">Cidade</label>
                                    <p className={'form-control-static'}>
                                        {this.state.ticket?.city} {this.state.ticket?.state}
                                    </p>
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="exampleFormControlTextarea1">Empreendimento</label>
                                    {this.state.ticket?.building && buildingLink}
                                    {this.state.ticket?.building && <p>{this.state.ticket?.building.name}</p>}
                                </div>
                                {this.state.ticket.building_address && this.state.ticket.observation === 'Localização Incorreta' && <div className={'form-group'}>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Endereço Informado pelo Cliente</h3>

                                        </div>
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <tbody>
                                                { Object.keys(this.state.ticket.building_address).map((key, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{columnAddressLabels[key]}</td>
                                                            <td>{this.state.ticket.building_address[key]}</td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                            { canAttribute && this.state.ticket.status !== 'Finalizado' &&
                                            <button className="btn btn-primary btn-sm" onClick={this.updateBuildinAddress}>
                                                Atualizar Empreendimento com o Endereço Informado
                                            </button>}
                                            <hr/>
                                            <SimpleMap currentPosition={{
                                                lat: this.state.ticket.building_address.latitude,
                                                lng: this.state.ticket.building_address.longitude
                                            }}>
                                                <Marker draggable={false} position={{
                                                    lat: parseFloat(this.state.ticket.building_address.latitude),
                                                    lng: parseFloat(this.state.ticket.building_address.longitude)
                                                }}/>
                                            </SimpleMap>
                                        </div>
                                    </div>
                                </div>}
                                <div className={'form-group'}>
                                    <label htmlFor="exampleFormControlTextarea1">Descricão</label>
                                    <p className={'form-control-static'}>
                                        {this.state.ticket?.message}
                                    </p>
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="exampleFormControlTextarea1">Anexos</label>
                                    <ul className="list-group">
                                    {this.state.ticket?.anexos?.map((attachment, index) => {
                                        return <li className="list-group-item" key={index}><a href={attachment.url} target={'_blank'}>{attachment.name}</a></li>
                                    })}

                                    </ul>
                                </div>
                            </div>
                        </div>
                        {this.state.ticket.status !== 'Finalizado' && canSendMessage && <div className="card" style={styles.card}>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlTextarea1">Mensagem</label>
                                    <textarea
                                        className={`form-control ` + (this.state.errors.mensagem ? 'is-invalid' : '')}
                                        onChange={(e) => this.setState({...this.state, mensagem: e.target.value})}
                                        id="exampleFormControlTextarea1" style={{height: '100px'}}
                                        value={this.state.mensagem} rows="3"></textarea>
                                    {this.state.errors['mensagem'] &&
                                        <div className="invalid-feedback" style={{display: "block"}}>
                                            {this.state.errors['mensagem']}
                                        </div>}
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-block btn-primary" onClick={this.sendNewMessage}>Enviar
                                    </button>
                                </div>
                            </div>
                        </div>}

                        <div className="card" style={styles.card}>
                            <div className="card-header">
                                <h5 className={'card-title'}>
                                    Histórico de Contatos
                                </h5>
                            </div>
                            <div className="card-body">
                                {this.state.ticket?.contacts?.map((message, index) => {
                                    let dataCriacao = new Date(message.created_at).toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'});
                                    return (<div className="card" key={index} style={styles.card}>
                                            <div className="card-header">
                                                <h6 className="card-title font-weight-bold">{message.sender.name}</h6>
                                            </div>
                                            <div className="card-body border-primary">

                                                <p className="card-text">{message.message}</p>

                                            </div>
                                            <div className="card-footer">
                                                <div className="pull-right">
                                                    <i className="fa fa-clock-o"/> &nbsp;
                                                    <small className="text-muted font-weight-bold">{dataCriacao}</small>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card" style={styles.card}>
                            <div className="card-body">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>
                                                Status
                                            </th>
                                            <th>
                                                {statusBadge}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>Data de Abertura</th>
                                            <td>{dataAbertura}</td>
                                        </tr>
                                        <tr>
                                            <th>Atribuído Para</th>
                                            <td>{this.state.ticket?.responsible?.name}</td>
                                        </tr>
                                        {this.state.ticket.status == 'Finalizado' && <>
                                            <tr>
                                                <th>Data de Finalização</th>
                                                <td>{dataFinalizacao}</td>
                                            </tr>
                                            <tr>
                                                <th>Finalizado Por</th>
                                                <td>{this.state.ticket?.finished_by?.name}</td>
                                            </tr>
                                            <tr>
                                                <th colSpan="2">
                                                    Mensagem de Finalização:<br/>
                                                    <p>
                                                        {this.state.ticket.finish_message}
                                                    </p>
                                                </th>
                                            </tr>
                                        </>}
                                    </tbody>
                                </table>

                                <hr/>
                                {this.state.ticket?.status !== 'Finalizado' && this.state.user?.id !== this.state?.ticket?.current_responsible_id && canAttribute &&
                                    <button className="btn btn-block btn-success" onClick={this.capturarTicket}>Capturar</button>
                                }

                                {this.state.ticket?.status != 'Finalizado' && this.state.ticket?.status != 'Aberto' &&
                                    <button className="btn btn-block btn-danger" onClick={(e) => {
                                        this.setState({...this.state, show_modal_finish: true})
                                    }}>Finalizar</button>
                                }

                                {this.state.ticket?.status != 'Finalizado' && canAttribute &&
                                    <button className="btn btn-block btn-secondary" onClick={(e) => {
                                        this.setState({...this.state, show_modal_assign: true})
                                    }}>Atribuir</button>
                                }

                            </div>
                        </div>
                    </div>
                </div>


            </>);
    }


}

let styles = {
    card_header: {
        backgroundColor: '#046F19',
        padding: '10px',
        borderTopColor: '#046F19',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopRadius: '5px',
    }, card_title: {
        color: '#fff',
        fontWeight: 'bold',
        fontFamily: 'Arial, verdana, tahoma !important',
        textTransform: 'capitalize',
        fontSize: '14px',
    }, card: {
        borderColor: '#E8E8E8', borderWidth: '1px', borderStyle: 'solid', borderRadius: '5px',
    },

}

export default TicketSuport;

//js-ticket-show
if (document.getElementById('js-ticket-show')) {
    ReactDOM.render(<TicketSuport/>, document.getElementById('js-ticket-show'));
}
