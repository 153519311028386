import React, {Fragment, useRef} from 'react';
import {getPercentageFromTotal} from '../../../util/helpers';
import './estilos.css';

const degradeColors = ['#3d2ff6', '#3bdaaf', '#64da3b', '#dead1a', '#da983b', '#3bb0da', '#f6842f',
    '#3d2ff6', '#3bdaaf', '#64da3b', '#dead1a', '#da983b', '#3bb0da', '#f6842f',
    '#3d2ff6', '#3bdaaf', '#64da3b', '#dead1a', '#da983b', '#3bb0da', '#f6842f',
    '#3d2ff6', '#3bdaaf', '#64da3b', '#dead1a', '#da983b', '#3bb0da', '#f6842f',
    '#3d2ff6', '#3bdaaf', '#64da3b', '#dead1a', '#da983b', '#3bb0da', '#f6842f'
]

const styles = {
    rowHead: {
        backgroundColor: '#5B7537',
        borderColor: '#5B7537',
        color: '#474242 !important',
    }

}

class GenericTable extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            data: [],
            headers: [],
            config: {
                dados: {},
                title: '',
                data_label: ''
            },
            downloading: false
        }
    }


    static getDerivedStateFromProps(props, state) {

        let dataFormatter = props.formatter;

        console.log("Formatter");
        console.log(dataFormatter);

        return {
            data: dataFormatter?.dataCollection,
            headers: dataFormatter?.headers,
            config: dataFormatter?.config,
        }
    }

    formatNumber = (number, precision = null ) => {

        if( !precision ){
            precision = 0;
        }

        return new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits: precision,
            minimumFractionDigits: precision
        }).format(Number(number));
    }

    componentDidMount() {

    }

    exportExcel = async() => {
        try{

            this.setState({
                downloading: true
            });

            let dadosKeys = Object.keys(this.state.config?.dados);
            let collectionKeys = Object.keys(this.state.data);

            let payload = {
                config: this.state.config,
                headers: this.state.headers,
                dadosKeys: dadosKeys,
                collectionKeys: collectionKeys,
                data: this.state.data
            }

            let response = await axios.post( route('web.export.dados.sociodemografia'), payload, {
                responseType: 'blob'
            });

            let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download =  response.headers['content-disposition'].split('filename=')[1];
            a.click();
            window.URL.revokeObjectURL(url);

        }catch (e) {
            console.log(e);
        }finally {
            this.setState({
                downloading: false
            });
        }

    }

    render() {

        let dadosKeys = Object.keys(this.state.config?.dados);
        let collectionKeys = Object.keys(this.state.data);

        return (
            <div style={{overflowX: 'scroll', padding: '10px'}}>

                <h4 style={{marginBottom: '15px'}}>{this.state?.config?.title?.toUpperCase()}</h4>

                <table className="table">
                    <thead>
                    <tr style={styles.rowHead}>
                        <th style={{color: 'white', fontWeight: 'bold'}} rowSpan={2} scope="col"
                            className="text-center">{this.state.config?.data_label}
                        </th>
                        {this.state.headers.map((header, index) => {

                            return (
                                <th key={`header-faixa-etaria-${index}`} colSpan={2}
                                    style={{color: 'white', fontWeight: 'bold'}} scope="col" className="text-center">
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                    {this.state.config.uses_subheader && <>
                        <tr style={styles.rowHead}>

                            {this.state.headers.map((header, index) => {
                                return (
                                    <Fragment key={`subheader-${header}-${index}`}>
                                        <th style={{color: 'white',fontWeight: 'bold'}} className="text-center">
                                            Absoluto
                                        </th>
                                        <th style={{color: 'white', fontWeight: 'bold'}} className="text-center">
                                            Percentual
                                        </th>
                                    </Fragment>
                                );
                            })}
                        </tr>

                    </>}
                    </thead>
                    <tbody>
                    {dadosKeys.map((key, index) => {
                        return (
                            <Fragment key={`row-${key}-${index}`}>
                                <tr style={{fontWeight: 'bold'}} scope="col" className="text-center">
                                    <td style={{backgroundColor: 'rgb(242, 242, 242)'}}>{this.state.config?.dados?.[key]}</td>

                                    {collectionKeys.map((collectionKey, indexTwo) => {
                                        let porcentagem = getPercentageFromTotal(
                                            this.state.data[collectionKey]?.items[0]?.['total'],
                                            this.state.data[collectionKey]?.items[0]?.[key]
                                        );

                                        let gradienteColor = degradeColors[indexTwo];


                                        return (
                                            <Fragment key={collectionKey}>
                                                <td className="text-center">
                                                    {this.formatNumber(this.state.data[collectionKey]?.items?.[0]?.[key])}
                                                </td>
                                                <td className="text-center" style={{
                                                    backgroundImage: `linear-gradient(to right, ${gradienteColor}, white)`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: `${porcentagem}%`
                                                }}>
                                                    <div className="pg">
                                                        {this.formatNumber(porcentagem, 2 )}%
                                                    </div>

                                                </td>
                                            </Fragment>
                                        )


                                    })}
                                </tr>
                            </Fragment>
                        );
                    })}
                    </tbody>
                    <tfoot>
                    <tr style={{borderTopWidth: '4px', ...styles.rowHead}} scope="col"
                        className="text-center">
                        <td style={{color: 'white', fontWeight: 'bold'}}>TOTAL</td>
                        {collectionKeys.map((collectionKey, index) => {
                            return (
                                <Fragment key={collectionKey}>
                                    <td className="text-center" style={{color: 'white', fontWeight: 'bold'}}>
                                        {this.formatNumber(this.state.data[collectionKey]?.items?.[0]?.['total'])}
                                    </td>
                                    <td className="text-center" style={{color: 'white', fontWeight: 'bold'}}>
                                        100%
                                    </td>
                                </Fragment>
                            );
                        })}
                    </tr>
                    </tfoot>
                </table>
                <button className="btn btn-default pull-right" onClick={this.exportExcel}>
                    Exportar Excel &nbsp;
                    <i className="fas fa-file-excel"></i>
                    &nbsp;
                    {this.state.downloading && <i className="fas fa-spinner fa-spin"></i>}

                </button>
            </div>
        );
    }
}

export default GenericTable;
