import {Component, React} from 'react';
import {ShimmerThumbnail} from "react-shimmer-effects";

export default class ShimmerLoaderCards extends Component {
    render() {
        let numberOfCards = Array.from({
            length: this.props.number_of_cards
        }, () => Math.floor(Math.random() * 10));

        return (
            <>
                { numberOfCards.map((item, index) => {
                    return (
                        <div key={index} className="col-12 col-md-6 col-sm-6">
                            <div className="card">
                                <div className="card-body">
                                    <ShimmerThumbnail rounded/>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );

    }
}
