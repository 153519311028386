
class KmlService {

    download = async ( stateObject ) =>{
        let response = await axios.post( route('download-kml-mapa'), {
            state : stateObject
        });

        console.log( response );
        if( response.status === 201 ){
            //alert('Dados Salvos com sucesso');
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'geobrain_export.kml'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
    }

    load = async ( id ) =>{

        let response = await axios.get('/mapa-state/' + id );

        if(response.status === 200 ){
            return response.data;
        }
    }
}

export default new KmlService();
