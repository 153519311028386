import {React, Component} from 'react';
import Loader from "react-loader-spinner";


export default class InnerLoading extends Component{

    render() {
        return (
            <div className="d-flex justify-content-center align-items-center"
                 style={{height: '40vh'}}>
                <Loader type="TailSpin" color="#CCCCCC" height={100} width={100} />
            </div>
        );
    }
}
